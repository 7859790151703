// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import { enCA } from "date-fns/locale";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalActionsLoadingButton,
  ModalDatePicker,
  ModalDatePickerSlotProps,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalModifyMt5TickDataPlanLimitations({
  distributor,
  dashboardId,
  permissionEnd,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalModifyMt5TickDataPlanLimitations.propTypes = {
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    permissionEnd: PropTypes.any,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageTickDataDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let formPermissionInitValue = "";
  if (permissionEnd !== null) {
    formPermissionInitValue = new Date(
      `${permissionEnd.slice(0, 10)}T${permissionEnd.slice(11, 19)}`
    );
  }

  const [formPermissionEnd, setFormPermissionEnd] = useState(
    formPermissionInitValue
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {},
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {

      let permissionEndConverted = "-";
      if (
        formPermissionEnd !== null &&
        formPermissionEnd !== "" &&
        formPermissionEnd !== undefined
      ) {
        if (
          permissionEnd === null ||
          permissionEnd === "" ||
          permissionEnd === undefined
        ) {
          permissionEndConverted = `${formPermissionEnd.toLocaleDateString(
            "en-CA"
          )} 23:59:59`;
        } else {
          permissionEndConverted = `${formPermissionEnd.toLocaleDateString(
            "en-CA"
          )} ${permissionEnd.slice(11, 19)}`;
        }
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "mt5_tick_data_customers",
        operation: "modify_mt5_tick_data_limitations",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        permission: {
          selected_dashboard_id: dashboardId,
        },
        limits: {
          permission_end: permissionEndConverted,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny
        variant="outlined"
        onClick={handleClickOpen("paper")}
        fullWidth
        sx={{ mb: 1 }}
      >
        Modify tick data plan limitations
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Modify tick data plan limitations
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="contentMiddle">
                      Use this dialog to modify your customer's tick data plan limitations.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="contentSmall">
                      Plan end date
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enCA}
                    >
                      <ModalDatePicker
                        disablePast
                        value={formPermissionEnd}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            const value = new Date(
                              `${newValue.toLocaleDateString("en-CA")}T00:00:00`
                            );
                            setFormPermissionEnd(value);
                          } else {
                            setFormPermissionEnd(null);
                          }
                        }}
                        slotProps={ModalDatePickerSlotProps}
                      />
                    </LocalizationProvider>
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Modify
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
