// react and js

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
// Default local settings values (OBFUSCATED!)
export const myLocalSettingStorage = {
  // Page: account_settings
  // ...

  // Page: subscriptions
  // ...

  // Page: promotion
  obfsb16ea0b10c32f5c5: 0, // Clear: promotionPartner

  // Page: downloads
  obfs203515b7ac68d72a: 0, // Clear: downloadApplicationVersion
  obfs4935af76d5753ad6: 0, // Clear: downloadTickDataSymbol

  // Page: accounting
  obfsa1d78ea6c9cbdd85: false, // Clear: accountingInvoicesTableFilterCollapse
  obfs049620f0953ec14a: 10, // Clear: accountingInvoicesTableFilterItemsPerPage
  obfs096db587116cc1b1: "", // Clear: accountingInvoicesTableFilterId
  obfsb8477d8d7dabcef1: "", // Clear: accountingInvoicesTableFilterDate
  obfs1842ca6643e81b58: "", // Clear: accountingInvoicesTableFilterProduct
  obfs2601c6855dfd60a8: "", // Clear: accountingInvoicesTableFilterAmount

  // Page: inspector
  obfsb07ce3792cd977cc: true, // Clear: inspectorSimplified

  // Page: promoter_portal
  obfscaf9a3348bfafbb0: 0, // Clear: promoterPortalPromoter
  obfs56a28e49b19186d0: 0, // Clear: promoterPortalPromotionBrokerLimitations
  obfs8648d371d1918ac3: "2_0", // Clear: promoterPortalPromotionApplicationLimitationsVersion
  obfs2117bcbf09344a45: 0, // Clear: promoterPortalPromotionApplicationLimitationsApplication
  obfs3827fc2cbf1e3dd5: 0, // Clear: promoterPortalManagePromotionPartner
  obfs134bb88b27d909cb: true, // Clear: promoterPortalManageClientInspectorSimplified
  //
  obfs0553a784b04b9d56: false, // Clear: promoterPortalPendingClientsTableFilterCollapse
  obfs39d5baca000e7644: 3, // Clear: promoterPortalPendingClientsTableFilterItemsPerPage
  obfs83d596569215d962: "", // Clear: promoterPortalPendingClientsTableFilterBroker
  obfsbbbeeceb19351e8f: "", // Clear: promoterPortalPendingClientsTableFilterEmail
  //
  obfs46288ac185160376: false, // Clear: promoterPortalCurrentClientsTableFilterCollapse
  obfs441f4c6f1f5ed559: 3, // Clear: promoterPortalCurrentClientsTableFilterItemsPerPage
  obfs8b9373d42250fe5d: "", // Clear: promoterPortalCurrentClientsTableFilterId
  obfs849eb0f6c4bcf135: "", // Clear: promoterPortalCurrentClientsTableFilterForename
  obfs005106e24f4726c5: "", // Clear: promoterPortalCurrentClientsTableFilterSurname
  obfsfc66613dc73f53e1: "", // Clear: promoterPortalCurrentClientsTableFilterEmail
  obfs63543a10dc126a65: "", // Clear: promoterPortalCurrentClientsTableFilterCountry

  // Page: distributor_portal
  obfs02964be666064015: 0, // Clear: distributorPortalDistributor
  obfs9125e01e3d862e2e: 0, // Clear: distributorPortalAvailablePlans
  obfs67750d1b034e81c6: 0, // Clear: distributorPortalAvailableTickDataPlans
  obfscc67442277b56b01: false, // Clear: distributorPortalAvailableTickDataPlansCollapse
  obfsd043b7a5866a81b2: "2_0", // Clear: distributorPortalDistributionApplicationLimitationsVersion
  obfs4bebfc736e2f169f: 0, // Clear: distributorPortalDistributionApplicationLimitationsApplication
  obfs4811e418494111b0: true, // Clear: distributorPortalManageCustomerInspectorSimplified
  //
  obfsee608a5915392cd7: false, // Clear: distributorPortalApplicationCustomersTableFilterCollapse
  obfs4c600de4f4f0573b: 3, // Clear: distributorPortalApplicationCustomersTableFilterItemsPerPage
  obfs56152826f5e79efa: "", // Clear: distributorPortalApplicationCustomersTableFilterId
  obfse81f2b9b35f6c1ee: "", // Clear: distributorPortalApplicationCustomersTableFilterForename
  obfsc539eee8491fc3e4: "", // Clear: distributorPortalApplicationCustomersTableFilterSurname
  obfs3b11728390a578a5: "", // Clear: distributorPortalApplicationCustomersTableFilterEmail
  obfs0f54405943bd0fb8: "", // Clear: distributorPortalApplicationCustomersTableFilterCountry
  //
  obfs58ba9f5b3dcba6b8: false, // Clear: distributorPortalTickDataCustomersTableFilterCollapse
  obfsf5da3a01c268f44f: 3, // Clear: distributorPortalTickDataCustomersTableFilterItemsPerPage
  obfsdef96eac893350d4: "", // Clear: distributorPortalTickDataCustomersTableFilterId
  obfsb527ce61f771e6bd: "", // Clear: distributorPortalTickDataCustomersTableFilterForename
  obfs02749bbdae439090: "", // Clear: distributorPortalTickDataCustomersTableFilterSurname
  obfs45d87d82ab47e8bd: "", // Clear: distributorPortalTickDataCustomersTableFilterEmail
  obfs18894a63ab319423: "", // Clear: distributorPortalTickDataCustomersTableFilterCountry
  //
  obfs5e703cefbe0a57a2: false, // Clear: distributorPortalDistributionApplicationInvoicesTableFilterCollapse
  obfs2e50f66cd66f4baa: 3, // Clear: distributorPortalDistributionApplicationInvoicesTableFilterItemsPerPage
  obfs87d49b0e01d07f47: "", // Clear: distributorPortalDistributionApplicationInvoicesTableFilterId
  obfs362883a2a0b07b7a: "", // Clear: distributorPortalDistributionApplicationInvoicesTableFilterDate
  obfs3dd8ea76e3692868: "", // Clear: distributorPortalDistributionApplicationInvoicesTableFilterProduct
  obfs53ad8797135dd313: "", // Clear: distributorPortalDistributionApplicationInvoicesTableFilterAmount
  //
  obfs96854d3129b87ed0: false, // Clear: distributorPortalDistributionTickDataInvoicesTableFilterCollapse
  obfs082651ab4c5d253a: 3, // Clear: distributorPortalDistributionTickDataInvoicesTableFilterItemsPerPage
  obfs235a4a7bf7ebefb4: "", // Clear: distributorPortalDistributionTickDataInvoicesTableFilterId
  obfsa54285f5418f87d0: "", // Clear: distributorPortalDistributionTickDataInvoicesTableFilterDate
  obfsd9d6a55cbb2ffc59: "", // Clear: distributorPortalDistributionTickDataInvoicesTableFilterProduct
  obfs71166139d97e4623: "", // Clear: distributorPortalDistributionTickDataInvoicesTableFilterAmount

  // Page: evaluation_portal
  obfsc1f7fcfaa4896859: false, // Clear: evaluationPortalTableFilterCollapse
  obfscbdd0930afb23b49: 3, // Clear: evaluationPortalTableFilterItemsPerPage
  obfs21e0919001c115ad: "", // Clear: evaluationPortalTableFilterId
  obfsf89d594e2078650c: "", // Clear: evaluationPortalTableFilterAccountNumber
  obfs545d251b417763e1: "", // Clear: evaluationPortalTableFilterTradingServer

  // Page: form_manager
  obfsca50c326072310ba: false, // Clear: formManagerFreeDownloadTableFilterCollapse
  obfs11b3a2911a9fbd3e: 3, // Clear: formManagerFreeDownloadTableFilterItemsPerPage
  obfsff4c0adfc1d7ffc5: "", // Clear: formManagerFreeDownloadTableFilterId
  obfs5a8e1a90a7495ae0: "", // Clear: formManagerFreeDownloadTableFilterDate
  obfs5bb3fb5d24ebcae7: "", // Clear: formManagerFreeDownloadTableFilterEmail
  obfsc7b5f1b8062ec420: "", // Clear: formManagerFreeDownloadTableFilterAnswer
  obfs39abaf9f64c6351d: "", // Clear: formManagerFreeDownloadTableFilterDetails
  //
  obfsf9312a36a9e0a16f: false, // Clear: formManagerContactTableFilterCollapse
  obfs047fbb8b63f5ea15: 3, // Clear: formManagerContactTableFilterItemsPerPage
  obfs9e4f36a0b26921cc: "", // Clear: formManagerContactTableFilterId
  obfs14888aa8cc899f28: "", // Clear: formManagerContactTableFilterDate
  obfs697950d9f30042be: "", // Clear: formManagerContactTableFilterAnswer
  obfs8015664ccd09d72e: "", // Clear: formManagerContactTableFilterDetails
  //
  obfs1f94eb0798c15743: false, // Clear: formManagerServiceRequestTableFilterCollapse
  obfs627075c8e17c54dd: 3, // Clear: formManagerServiceRequestTableFilterItemsPerPage
  obfs89981513c567c19c: "", // Clear: formManagerServiceRequestTableFilterId
  obfs97b398c69eef7f88: "", // Clear: formManagerServiceRequestTableFilterDate
  obfs2e0f935ad2b1f44a: "", // Clear: formManagerServiceRequestTableFilterAnswer
  obfs26c2d879a1000fda: "", // Clear: formManagerServiceRequestTableFilterDetails
  //
  obfs4054013c80ad7013: false, // Clear: formManagerSupportRequestTableFilterCollapse
  obfs1c8047fba103b3c5: 3, // Clear: formManagerSupportRequestTableFilterItemsPerPage
  obfsf13dd43d8c4650c9: "", // Clear: formManagerSupportRequestTableFilterId
  obfsda78fb92aafab16e: "", // Clear: formManagerSupportRequestTableFilterDate
  obfs9af75115c07134fd: "", // Clear: formManagerSupportRequestTableFilterAnswer
  obfs91c949cdabc5ba48: "", // Clear: formManagerSupportRequestTableFilterDetails
  //
  obfs5ba24dda3b8968bd: false, // Clear: formManagerNewsletterSubscriptionTableFilterCollapse
  obfs0c21a17700af1113: 3, // Clear: formManagerNewsletterSubscriptionTableFilterItemsPerPage
  obfs0e0c53e498f44d8e: "", // Clear: formManagerNewsletterSubscriptionTableFilterId
  obfsab36a297b1f4b84c: "", // Clear: formManagerNewsletterSubscriptionTableFilterDate
  obfs91df544a6dbdfc53: "", // Clear: formManagerNewsletterSubscriptionTableFilterAnswer
  obfsd0617076c80a73c1: "", // Clear: formManagerNewsletterSubscriptionTableFilterDetails
  //
  obfse0450da3037a394d: false, // Clear: formManagerNewsletterUnsubscribeTableFilterCollapse
  obfsdad510f6517305db: 3, // Clear: formManagerNewsletterUnsubscribeTableFilterItemsPerPage
  obfs703c1542538bd373: "", // Clear: formManagerNewsletterUnsubscribeTableFilterId
  obfs8d9cdc037ca21ce4: "", // Clear: formManagerNewsletterUnsubscribeTableFilterDate
  obfs8ed680391b39a26a: "", // Clear: formManagerNewsletterUnsubscribeTableFilterAnswer
  obfs7922dd960314adcc: "", // Clear: formManagerNewsletterUnsubscribeTableFilterDetails

  // Page: event_listeners
  obfs92fd163c7a26d982: false, // Clear: eventListenersManualPromotionApprovalEventsTableFilterCollapse
  obfs8e98259618dd0d5b: 3, // Clear: eventListenersManualPromotionApprovalEventsTableFilterItemsPerPage
  obfs94a70a601a908031: "", // Clear: eventListenersManualPromotionApprovalEventsTableFilterId
  obfsde1bc431985dd383: "", // Clear: eventListenersManualPromotionApprovalEventsTableFilterDate
  obfs2d7175299904c2b6: "", // Clear: eventListenersManualPromotionApprovalEventsTableFilterPromoterId
  obfse823b305289bb791: "", // Clear: eventListenersManualPromotionApprovalEventsTableFilterPromoterLinkId
  //
  obfsff2d90c09b7016c8: false, // Clear: eventListenersAutomatedPromotionApprovalEventsTableFilterCollapse
  obfs3afc6f9ed694b4a8: 3, // Clear: eventListenersAutomatedPromotionApprovalEventsTableFilterItemsPerPage
  obfsfd4549204a2d8e1e: "", // Clear: eventListenersAutomatedPromotionApprovalEventsTableFilterId
  obfs46ff14b57a4f12b3: "", // Clear: eventListenersAutomatedPromotionApprovalEventsTableFilterDate
  obfsd50ba3859b1dd55f: "", // Clear: eventListenersAutomatedPromotionApprovalEventsTableFilterProcessed
  //
  obfs8feae78ed9452a07: false, // Clear: eventListenersDistributionApprovalEventsPayPalTableFilterCollapse
  obfs30086419832bca76: 3, // Clear: eventListenersDistributionApprovalEventsPayPalTableFilterItemsPerPage
  obfs84bf0ab7483b92ac: "", // Clear: eventListenersDistributionApprovalEventsPayPalTableFilterId
  obfsd9832f01206572d5: "", // Clear: eventListenersDistributionApprovalEventsPayPalTableFilterDate
  obfsc269859b456acbb5: "", // Clear: eventListenersDistributionApprovalEventsPayPalTableFilterType
  obfs0c1c7575c0747308: "", // Clear: eventListenersDistributionApprovalEventsPayPalTableFilterPayPalId

  // Page: application_settings
  obfsc3bf8f9d4ed9afbe: false, // Clear: applicationSettingsPrivateApplicationSettingsTableFilterCollapse
  obfsd913338dd282e0c8: 50, // Clear: applicationSettingsPrivateApplicationSettingsTableFilterItemsPerPage
  obfs772f1a80dd99905e: "", // Clear: applicationSettingsPrivateApplicationSettingsTableFilterId
  obfs4dc7dcdff571f6b0: "", // Clear: applicationSettingsPrivateApplicationSettingsTableFilterProductId
  obfsa05d5c5342ed08df: "", // Clear: applicationSettingsPrivateApplicationSettingsTableFilterUserIdendifier
  obfsba82f5913005101c: "", // Clear: applicationSettingsPrivateApplicationSettingsTableFilterPlanId
  obfs93b1f3c9fe678b8d: "", // Clear: applicationSettingsPrivateApplicationSettingsTableFilterBrokerId
  obfs3e9e9f34f5414730: "", // Clear: applicationSettingsPrivateApplicationSettingsTableFilterValue
  //
  obfsaef18eb5e60a8c61: false, // Clear: applicationSettingsPublicApplicationSettingsTableFilterCollapse
  obfsb2f73d3a058209ea: 50, // Clear: applicationSettingsPublicApplicationSettingsTableFilterItemsPerPage
  obfs33628e6db9542c05: "", // Clear: applicationSettingsPublicApplicationSettingsTableFilterId
  obfs6332e0ca833e5390: "", // Clear: applicationSettingsPublicApplicationSettingsTableFilterProductId
  obfs141fae1ceccdfc6d: "", // Clear: applicationSettingsPublicApplicationSettingsTableFilterPlanId
  obfse44b33540c0d7849: "", // Clear: applicationSettingsPublicApplicationSettingsTableFilterBrokerId
  obfs7bbe3154cb531b61: "", // Clear: applicationSettingsPublicApplicationSettingsTableFilterValue
};
