// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Collapse,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import ModalDialog from "../../components/ModalDialog";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandlePromoterPortalIgnorePendingPromotionClient from "./actions/HandlePromoterPortalIgnorePendingPromotionClient";
import HandlePromoterPortalApprovePendingPromotionClient from "./actions/HandlePromoterPortalApprovePendingPromotionClient";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function ClientDetails({ clientDetailsData, tradingAccountDetailsData }) {
  ClientDetails.propTypes = {
    clientDetailsData: PropTypes.any.isRequired,
    tradingAccountDetailsData: PropTypes.any.isRequired,
  };

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny fullWidth variant="outlined" onClick={handleClickOpen("paper")}>
        Details
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Pending promotion client details
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              {clientDetailsData.length === 0 ? (
                <>Error, no client details found!</>
              ) : (
                <>
                  <B3Tiny
                    fullWidth
                    sx={{ mb: 1 }}
                    variant="outlined"
                    onClick={() => {
                      navigate(
                        `/promoter-portal/${clientDetailsData.dashboard_id}`
                      );
                      setOpen(false);
                    }}
                  >
                    Manage
                  </B3Tiny>

                  <Typography variant="contentSmall">
                    <>
                      {Object.keys(tradingAccountDetailsData).map(
                        (parameter, index) => (
                          <ValueLine
                            key={index}
                            parameter={parameter}
                            value={tradingAccountDetailsData[parameter]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                        )
                      )}

                      {clientDetailsData.dashboard_id !== null ? (
                        <ValueLine
                          parameter="Client user ID (UID)"
                          value={clientDetailsData.dashboard_id}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.email !== null ? (
                        <ValueLine
                          parameter="Client email"
                          value={clientDetailsData.email}
                          widthLeft="20%"
                          widthRight="80%"
                        />
                      ) : null}
                      {clientDetailsData.forename !== null ? (
                        <ValueLine
                          parameter="Client forename"
                          value={clientDetailsData.forename}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.surname !== null ? (
                        <ValueLine
                          parameter="Client surname"
                          value={clientDetailsData.surname}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.birth !== null ? (
                        <ValueLine
                          parameter="Client birth"
                          value={clientDetailsData.birth}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.gender !== null ? (
                        <ValueLine
                          parameter="Client gender"
                          value={clientDetailsData.gender}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.organization !== null ? (
                        <ValueLine
                          parameter="Client organization"
                          value={clientDetailsData.organization}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.street !== null ? (
                        <ValueLine
                          parameter="Client street"
                          value={clientDetailsData.street}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.optional_line !== null ? (
                        <ValueLine
                          parameter="Client optional address line"
                          value={clientDetailsData.optional_line}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.postal_code !== null ? (
                        <ValueLine
                          parameter="Client postal code"
                          value={clientDetailsData.postal_code}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.city !== null ? (
                        <ValueLine
                          parameter="Client city"
                          value={clientDetailsData.city}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.province !== null ? (
                        <ValueLine
                          parameter="Client province"
                          value={clientDetailsData.province}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.country !== null ? (
                        <ValueLine
                          parameter="Client country"
                          value={clientDetailsData.country}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                      {clientDetailsData.phone !== null ? (
                        <ValueLine
                          parameter="Client phone"
                          value={clientDetailsData.phone}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      ) : null}
                    </>
                  </Typography>
                </>
              )}
            </DialogContent>
          </>
        }
      />
    </>
  );
}

function FetchTable({ tableArray, promoter, refreshLoading }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="15%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Partner </Typography>
              </TableCell>
              <TableCell
                width="30%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Link </Typography>
              </TableCell>
              <TableCell
                width="25%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Email </Typography>
              </TableCell>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[12]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall">
                      {" "}
                      {row[17].Link}{" "}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {row[15]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <HandlePromoterPortalIgnorePendingPromotionClient
                      promoter={promoter}
                      eventNo={row[0]}
                    />
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <HandlePromoterPortalApprovePendingPromotionClient
                      promoter={promoter}
                      eventNo={row[0]}
                    />
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <ClientDetails
                      clientDetailsData={row[16]}
                      tradingAccountDetailsData={row[17]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Partner </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[12]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Link </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[17].Link}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    {" "}
                    Client email{" "}
                  </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {row[15]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <HandlePromoterPortalIgnorePendingPromotionClient
                    promoter={promoter}
                    eventNo={row[0]}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <HandlePromoterPortalApprovePendingPromotionClient
                    promoter={promoter}
                    eventNo={row[0]}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <ClientDetails
                    clientDetailsData={row[16]}
                    tradingAccountDetailsData={row[17]}
                  />
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  promoter,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterBroker,
  setFilterBroker,
  filterEmail,
  setFilterEmail,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterBroker: PropTypes.any.isRequired,
    setFilterBroker: PropTypes.any.isRequired,
    filterEmail: PropTypes.any.isRequired,
    setFilterEmail: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let promoterPortalPendingClientsTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs0553a784b04b9d56 !== null &&
      myLocalSettingStorage.obfs0553a784b04b9d56 !== undefined
    ) {
      promoterPortalPendingClientsTableFilterCollapseInitValue =
        myLocalSettingStorage.obfs0553a784b04b9d56;
    }
  }
  const [collapse, setCollapse] = useState(
    promoterPortalPendingClientsTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (filterBroker !== "" || filterEmail !== "") {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let promoterPortalPendingClientsTableFilterItemsPerPageInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs39d5baca000e7644 !== null &&
      myLocalSettingStorage.obfs39d5baca000e7644 !== undefined
    ) {
      promoterPortalPendingClientsTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfs39d5baca000e7644;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    promoterPortalPendingClientsTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterBroker, setLocalFilterBroker] = useState(filterBroker);
  const [localFilterEmail, setLocalFilterEmail] = useState(filterEmail);

  const contentFiltered = [];
  if (content.t_promotion_approval_events !== undefined) {
    content.t_promotion_approval_events.data.forEach((approvalEvent, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(approvalEvent);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterBroker(localFilterBroker);
    setFilterEmail(localFilterEmail);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdatePromoterPortalPendingPromotionClients: true,
    }));
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Pending Promotion Clients"
          subheader="If a promotion client requires manual approval, you can find pending requests here. You will also receive these requests via email!"
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No pending promotions") ? null : (
              <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
                {isDesktop === false ? (
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setMobileCollapse(!mobileCollapse);
                    }}
                  >
                    {mobileCollapse ? "Close" : "Open"}
                  </B3Tiny>
                ) : null}

                <Collapse
                  sx={{ mx: -1, px: 1 }}
                  in={mobileCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setCollapse(!collapse);

                      const newLocalSettingStorage = JSON.parse(
                        localStorage.getItem("myLocalSettingStorage")
                      );

                      newLocalSettingStorage.obfs0553a784b04b9d56 = !collapse;
                      localStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                  >
                    {collapse ? "Hide filters" : "Show filters"}
                  </B3Tiny>

                  <Collapse
                    sx={{ mx: -1 }}
                    in={collapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                      <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Broker</Typography>
                          <FetchTableTextField
                            value={localFilterBroker}
                            onChange={(event) => {
                              setLocalFilterBroker(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs83d596569215d962 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Email</Typography>
                          <FetchTableTextField
                            value={localFilterEmail}
                            onChange={(event) => {
                              setLocalFilterEmail(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfsbbbeeceb19351e8f =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Items per page
                          </Typography>
                          <Box sx={{ px: 1 }}>
                            <FetchTableSlider
                              size="small"
                              min={1}
                              max={100}
                              align="right"
                              value={itemsPerPages}
                              onChange={(event, newValue) => {
                                setItemsPerPages(newValue);
                                setTotalPages(
                                  Math.ceil(content.num_rows / newValue)
                                );
                                setCurrentPage(1);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfs39d5baca000e7644 =
                                  newValue;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <B1Tiny
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setFilters();
                        }}
                      >
                        Set filters and refresh output
                      </B1Tiny>
                    </Box>
                  </Collapse>

                  <Box sx={{ mt: 0, mb: 1 }} />

                  <FetchTable
                    tableArray={contentFiltered}
                    refreshLoading={refreshLoading}
                    promoter={promoter}
                  />

                  <Stack sx={{ mt: 3 }} alignItems="center">
                    <FetchTablePagination
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                      }}
                    />
                  </Stack>
                </Collapse>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  promoter,
  loadingWidgetPendingPromotionClients,
  setLoadingWidgetPendingPromotionClients,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    promoter: PropTypes.any.isRequired,
    loadingWidgetPendingPromotionClients: PropTypes.any.isRequired,
    setLoadingWidgetPendingPromotionClients: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let promoterPortalPendingClientsTableFilterBrokerInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs83d596569215d962 !== null &&
      myLocalSettingStorage.obfs83d596569215d962 !== undefined
    ) {
      promoterPortalPendingClientsTableFilterBrokerInitValue =
        myLocalSettingStorage.obfs83d596569215d962;
    }
  }
  const [filterBroker, setFilterBroker] = useState(
    promoterPortalPendingClientsTableFilterBrokerInitValue
  );

  // Filter
  let promoterPortalPendingClientsTableFilterEmailInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsbbbeeceb19351e8f !== null &&
      myLocalSettingStorage.obfsbbbeeceb19351e8f !== undefined
    ) {
      promoterPortalPendingClientsTableFilterEmailInitValue =
        myLocalSettingStorage.obfsbbbeeceb19351e8f;
    }
  }
  const [filterEmail, setFilterEmail] = useState(
    promoterPortalPendingClientsTableFilterEmailInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (promoter !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdatePromoterPortalPendingPromotionClients: false,
          }));

          setError(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "promoter_portal",
          widget: "pending_promotion_clients",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_promoter_id: promoter,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "desc",
            page_order_by: "event_no",
          },
          filters: {
            event_broker_name: filterBroker,
            event_client_email: filterEmail,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetPendingPromotionClients(false);
                setRefreshLoading(false);
                setContent(contentFromBackend);

                if (contentFromBackend.num_rows === 0) {
                  if (filterBroker === "" && filterEmail === "") {
                    setSuccess(true);
                    setReturnMessage(
                      "No pending promotions requests found ..."
                    );
                  } else {
                    setReturnMessage("empty_filtered");
                  }
                } else {
                  setSuccess(false);
                  setReturnMessage("");
                }
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setLoadingWidgetPendingPromotionClients(false);
                setRefreshLoading(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setLoadingWidgetPendingPromotionClients(false);
                setWarning(false);
                setSuccess(false);
                setRefreshLoading(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetPendingPromotionClients(false);
              setWarning(false);
              setSuccess(false);
              setRefreshLoading(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetPendingPromotionClients(false);
              setWarning(false);
              setSuccess(false);
              setRefreshLoading(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh is promoter changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoter]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdatePromoterPortalPendingPromotionClients) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdatePromoterPortalPendingPromotionClients]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalPromoterPortalPendingPromotionClients);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Pending Promotion Clients" />;
  if (loading || loadingWidgetPendingPromotionClients) {
    WidgetOutlet = <LoadingAnimation title="Pending Promotion Clients" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Pending Promotion Clients" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        promoter={promoter}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterBroker={filterBroker}
        setFilterBroker={setFilterBroker}
        filterEmail={filterEmail}
        setFilterEmail={setFilterEmail}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
