// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext, useRef } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  FormControl,
  Typography,
  IconButton,
  Popover,
  Stack,
  Select,
  NativeSelect,
  Collapse,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleDistributorPortalAddNewDistributionCustomerApplication from "./actions/HandleDistributorPortalAddNewDistributionCustomerApplication";
import HandleDistributorPortalAddToExistingDistributionCustomerApplication from "./actions/HandleDistributorPortalAddToExistingDistributionCustomerApplication";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { B3Tiny, CardOutlinedInput, CardMenuItem } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ActionPopover({ row, distributor }) {
  ActionPopover.propTypes = {
    row: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          color: "#474A4C",
          borderRadius: "0px",
        }}
      >
        <AddCircleOutlineIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            p: 1,
            border: "0px solid #C8C8C8",
            boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 50%)",
          },
        }}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          <B3Tiny
            fullWidth
            variant="outlined"
            href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution?plan_id=${row.plan_id}&hidden_tag=fbcd6ad7c7bee06a77661dd7eb02d334`}
            target="_blank"
          >
            Checkout
          </B3Tiny>

          <HandleDistributorPortalAddNewDistributionCustomerApplication
            distributor={distributor}
            planId={row.plan_id}
            plan={row}
          />

          <HandleDistributorPortalAddToExistingDistributionCustomerApplication
            distributor={distributor}
            planId={row.plan_id}
            plan={row}
          />
        </Stack>
      </Popover>
    </>
  );
}

function ConvertedRowDesktop({ row, distributor, referenceTradingCurrency }) {
  ConvertedRowDesktop.propTypes = {
    row: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
  };

  let DistributionDemoIncludedAccountChanges =
    row.distribution_demo_included_account_changes;
  let DistributionDemoIncludedAccounts =
    row.distribution_demo_included_accounts;
  let DistributionDemoLimits = row.distribution_demo_limits;
  let DistributionLiveIncludedAccountChanges =
    row.distribution_live_included_account_changes;
  let DistributionLiveIncludedAccounts =
    row.distribution_live_included_accounts;
  let DistributionLiveLimits = row.distribution_live_limits;
  let DistributionMaxApplicationEquity =
    row.distribution_max_application_equity;
  let DistributionMaxApplicationLeverage =
    row.distribution_max_application_leverage;
  let DistributionMinApplicationEquity =
    row.distribution_min_application_equity;
  let DistributionMinApplicationLeverage =
    row.distribution_min_application_leverage;

  if (DistributionDemoIncludedAccountChanges === "-1") {
    DistributionDemoIncludedAccountChanges = "unlimited";
  }
  if (DistributionDemoIncludedAccounts === "-1") {
    DistributionDemoIncludedAccounts = "unlimited";
  }
  if (DistributionDemoLimits === "server") {
    DistributionDemoLimits = "remote only";
  } else if (DistributionDemoLimits === "local") {
    DistributionDemoLimits = "local only";
  } else if (DistributionDemoLimits === "local_server") {
    DistributionDemoLimits = "remote and local";
  }

  if (DistributionLiveIncludedAccountChanges === "-1") {
    DistributionLiveIncludedAccountChanges = "unlimited";
  }
  if (DistributionLiveIncludedAccounts === "-1") {
    DistributionLiveIncludedAccounts = "unlimited";
  }
  if (DistributionLiveLimits === "server") {
    DistributionLiveLimits = "remote only";
  } else if (DistributionLiveLimits === "local") {
    DistributionLiveLimits = "local only";
  } else if (DistributionLiveLimits === "local_server") {
    DistributionLiveLimits = "remote and local";
  }

  if (DistributionMaxApplicationEquity === null) {
    DistributionMaxApplicationEquity = "-";
  } else {
    DistributionMaxApplicationEquity = `${numberWithCommas(
      DistributionMaxApplicationEquity
    )} ${referenceTradingCurrency}`;
  }

  if (DistributionMaxApplicationLeverage === null) {
    DistributionMaxApplicationLeverage = "-";
  }

  if (DistributionMinApplicationEquity === null) {
    DistributionMinApplicationEquity = "-";
  } else {
    DistributionMaxApplicationEquity = `${numberWithCommas(
      DistributionMinApplicationEquity
    )} ${referenceTradingCurrency}`;
  }

  if (DistributionMinApplicationLeverage === null) {
    DistributionMinApplicationLeverage = "-";
  }

  let paymentTenure = "loading";
  if (row.plan_interval_unit === "DAY") {
    paymentTenure = "daily";
  } else if (row.plan_interval_unit === "WEEK") {
    paymentTenure = "weekly";
  } else if (row.plan_interval_unit === "MONTH") {
    paymentTenure = "monthly";
  } else if (row.plan_interval_unit === "YEAR") {
    paymentTenure = "yearly";
  }

  return (
    <>
      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <ActionPopover row={row} distributor={distributor} />
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall">{row.plan_name}</Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall">{`${numberWithCommas(
          (Math.floor(row.plan_fixed_price_value * 100) / 100).toFixed(2)
        )} ${row.plan_fixed_price_currency_code.replace(
          "EUR",
          "€"
        )}`}</Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall">{paymentTenure}</Typography>
      </TableCell>

      <TableCell
        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
        align="center"
      >
        <Typography variant="contentSmall">
          {DistributionDemoIncludedAccounts}
          <br />
          {DistributionDemoIncludedAccountChanges}
          <br />
          {DistributionDemoLimits}
        </Typography>
      </TableCell>

      <TableCell
        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
        align="center"
      >
        <Typography variant="contentSmall">
          {DistributionLiveIncludedAccounts}
          <br />
          {DistributionLiveIncludedAccountChanges}
          <br />
          {DistributionLiveLimits}
        </Typography>
      </TableCell>

      <TableCell
        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
        align="center"
      >
        <Typography variant="contentSmall">
          {DistributionMinApplicationEquity}
          <br />
          {DistributionMaxApplicationEquity}
        </Typography>
      </TableCell>

      <TableCell
        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
        align="center"
      >
        <Typography variant="contentSmall">
          {DistributionMinApplicationLeverage}
          <br />
          {DistributionMaxApplicationLeverage}
        </Typography>
      </TableCell>
    </>
  );
}

function ConvertedRowMobile({ row, distributor, referenceTradingCurrency }) {
  ConvertedRowMobile.propTypes = {
    row: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
  };

  let DistributionDemoIncludedAccountChanges =
    row.distribution_demo_included_account_changes;
  let DistributionDemoIncludedAccounts =
    row.distribution_demo_included_accounts;
  let DistributionDemoLimits = row.distribution_demo_limits;
  let DistributionLiveIncludedAccountChanges =
    row.distribution_live_included_account_changes;
  let DistributionLiveIncludedAccounts =
    row.distribution_live_included_accounts;
  let DistributionLiveLimits = row.distribution_live_limits;
  let DistributionMaxApplicationEquity =
    row.distribution_max_application_equity;
  let DistributionMaxApplicationLeverage =
    row.distribution_max_application_leverage;
  let DistributionMinApplicationEquity =
    row.distribution_min_application_equity;
  let DistributionMinApplicationLeverage =
    row.distribution_min_application_leverage;

  if (DistributionDemoIncludedAccountChanges === "-1") {
    DistributionDemoIncludedAccountChanges = "unlimited";
  }
  if (DistributionDemoIncludedAccounts === "-1") {
    DistributionDemoIncludedAccounts = "unlimited";
  }
  if (DistributionDemoLimits === "server") {
    DistributionDemoLimits = "remote only";
  } else if (DistributionDemoLimits === "local") {
    DistributionDemoLimits = "local only";
  } else if (DistributionDemoLimits === "local_server") {
    DistributionDemoLimits = "remote and local";
  }

  if (DistributionLiveIncludedAccountChanges === "-1") {
    DistributionLiveIncludedAccountChanges = "unlimited";
  }
  if (DistributionLiveIncludedAccounts === "-1") {
    DistributionLiveIncludedAccounts = "unlimited";
  }
  if (DistributionLiveLimits === "server") {
    DistributionLiveLimits = "remote only";
  } else if (DistributionLiveLimits === "local") {
    DistributionLiveLimits = "local only";
  } else if (DistributionLiveLimits === "local_server") {
    DistributionLiveLimits = "remote and local";
  }

  if (DistributionMaxApplicationEquity === null) {
    DistributionMaxApplicationEquity = "-";
  } else {
    DistributionMaxApplicationEquity = `${numberWithCommas(
      DistributionMaxApplicationEquity
    )} ${referenceTradingCurrency}`;
  }

  if (DistributionMaxApplicationLeverage === null) {
    DistributionMaxApplicationLeverage = "-";
  }

  if (DistributionMinApplicationEquity === null) {
    DistributionMinApplicationEquity = "-";
  } else {
    DistributionMaxApplicationEquity = `${numberWithCommas(
      DistributionMinApplicationEquity
    )} ${referenceTradingCurrency}`;
  }

  if (DistributionMinApplicationLeverage === null) {
    DistributionMinApplicationLeverage = "-";
  }

  let paymentTenure = "loading";
  if (row.plan_interval_unit === "DAY") {
    paymentTenure = "daily";
  } else if (row.plan_interval_unit === "WEEK") {
    paymentTenure = "weekly";
  } else if (row.plan_interval_unit === "MONTH") {
    paymentTenure = "monthly";
  } else if (row.plan_interval_unit === "YEAR") {
    paymentTenure = "yearly";
  }

  return (
    <Stack
      spacing="3px"
      sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Plan </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">{row.plan_name}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Fee </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">
            {`${numberWithCommas(
              (Math.floor(row.plan_fixed_price_value * 100) / 100).toFixed(2)
            )} ${row.plan_fixed_price_currency_code.replace("EUR", "€")}`}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Tenure </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">{paymentTenure}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold">Demo Accounts</Typography>
          <br />
          <Typography variant="contentSmall">
            included accounts <br /> account changes <br /> trading limits
          </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">
            <br />
            {DistributionDemoIncludedAccounts}
            <br />
            {DistributionDemoIncludedAccountChanges}
            <br />
            {DistributionDemoLimits}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold">Live Accounts</Typography>
          <br />
          <Typography variant="contentSmall">
            included accounts <br /> account changes <br /> trading limits
          </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">
            <br />
            {DistributionLiveIncludedAccounts}
            <br />
            {DistributionLiveIncludedAccountChanges}
            <br />
            {DistributionLiveLimits}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold">Equity Limits</Typography>
          <br />
          <Typography variant="contentSmall">
            min. <br /> max.
          </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">
            <br />
            {DistributionMinApplicationEquity}
            <br />
            {DistributionMaxApplicationEquity}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", pr: 1 }}>
          <Typography variant="contentSmallBold">Leverage Limits</Typography>
          <br />
          <Typography variant="contentSmall">
            min. <br /> max.
          </Typography>
        </Box>
        <Box sx={{ width: "50%", pl: 1 }}>
          <Typography variant="contentSmall">
            <br />
            {DistributionMinApplicationLeverage}
            <br />
            {DistributionMaxApplicationLeverage}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <B3Tiny
            fullWidth
            variant="outlined"
            href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution?plan_id=${row.plan_id}&hidden_tag=fbcd6ad7c7bee06a77661dd7eb02d334`}
            target="_blank"
          >
            Checkout
          </B3Tiny>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <HandleDistributorPortalAddNewDistributionCustomerApplication
            distributor={distributor}
            planId={row.plan_id}
            plan={row}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <HandleDistributorPortalAddToExistingDistributionCustomerApplication
            distributor={distributor}
            planId={row.plan_id}
            plan={row}
          />
        </Box>
      </Box>
    </Stack>
  );
}

function FetchTable({ tableArray, distributor, referenceTradingCurrency }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              width="5%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            />

            <TableCell
              width="15%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Plan </Typography>
            </TableCell>

            <TableCell
              width="13%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Fee </Typography>
            </TableCell>

            <TableCell
              width="7%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Tenure </Typography>
            </TableCell>

            <TableCell
              width="15%"
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">
                Demo <br /> Accounts
              </Typography>
              <br />
              <Typography variant="contentSmall">
                included accounts <br /> account changes <br /> trading limits
              </Typography>
            </TableCell>

            <TableCell
              width="15%"
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">
                Live <br /> Accounts
              </Typography>
              <br />
              <Typography variant="contentSmall">
                included accounts <br /> account changes <br /> trading limits
              </Typography>
            </TableCell>

            <TableCell
              width="10%"
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">
                Equity <br /> Limits
              </Typography>
              <br />
              <Typography variant="contentSmall">
                min. <br /> max.
              </Typography>
            </TableCell>

            <TableCell
              width="10%"
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">
                Leverage <br /> Limits
              </Typography>
              <br />
              <Typography variant="contentSmall">
                min. <br /> max.
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableArray.map((row, index) => (
            <TableRow key={index}>
              <ConvertedRowDesktop
                row={row}
                distributor={distributor}
                referenceTradingCurrency={referenceTradingCurrency.replace(
                  "EUR",
                  "€"
                )}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.map((row, index) => (
        <ConvertedRowMobile
          key={index}
          row={row}
          distributor={distributor}
          referenceTradingCurrency={referenceTradingCurrency.replace(
            "EUR",
            "€"
          )}
        />
      ))}
    </>
  );
}

function WidgetMainContentProcessed({ content, distributor }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const keys = Object.keys(content.distribution_plans);
  const applications = [];

  for (let index = 0; index < keys.length; index += 1) {
    applications.push(keys[index]);
  }

  let distributorPortalAvailablePlansInitValue = applications[0];

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs9125e01e3d862e2e !== null &&
      myLocalSettingStorage.obfs9125e01e3d862e2e !== undefined
    ) {
      if (keys.includes(myLocalSettingStorage.obfs9125e01e3d862e2e) === true) {
        distributorPortalAvailablePlansInitValue =
          myLocalSettingStorage.obfs9125e01e3d862e2e;
      }
    }
  }

  let renderComponent = false;

  if (applications.length > 0) {
    renderComponent = true;
  }

  const [applicationApplication, setApplicationApplication] = useState(
    distributorPortalAvailablePlansInitValue
  );

  if (keys.includes(applicationApplication) !== true) {
    setApplicationApplication(applications[0]);
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return renderComponent ? (
    <Grid item xs={12} md={12} lg={12}>
      <CardOutlet
        variant="outlet"
        backgroundProp="#F2F2F2"
        title="Available Plans"
        subheader="For your convenience, the latest available plans are listed."
        mainOutlet={
          <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
            {isDesktop === false ? (
              <B3Tiny
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                onClick={() => {
                  setMobileCollapse(!mobileCollapse);
                }}
              >
                {mobileCollapse ? "Close" : "Open"}
              </B3Tiny>
            ) : null}

            <Collapse
              sx={{ mx: -1, px: 1 }}
              in={mobileCollapse}
              timeout="auto"
              unmountOnExit
            >
              <Grid container spacing={1}>
                {applications.length > 1 ? (
                  <Grid item xs={12} md={12} lg={12} sx={{ mb: 4 }}>
                    {isDesktop ? (
                      <Box>
                        <Typography variant="contentSmall">
                          Application
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            value={applicationApplication}
                            onChange={(event) => {
                              setApplicationApplication(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs9125e01e3d862e2e =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            input={<CardOutlinedInput />}
                          >
                            {applications.map((currentApplication, index) => (
                              <CardMenuItem
                                key={index}
                                value={currentApplication}
                              >
                                {currentApplication}
                              </CardMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : (
                      <Box>
                        <Typography variant="contentSmall">
                          Application
                        </Typography>
                        <FormControl fullWidth>
                          <NativeSelect
                            value={applicationApplication}
                            onChange={(event) => {
                              setApplicationApplication(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs9125e01e3d862e2e =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            input={<CardOutlinedInput />}
                          >
                            {applications.map((currentApplication, index) => (
                              <option key={index} value={currentApplication}>
                                {currentApplication}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    )}
                  </Grid>
                ) : null}

                <Grid item xs={12} md={12} lg={12}>
                  {content.distribution_plans !== undefined &&
                  content.distribution_plans[applicationApplication] !==
                    undefined ? (
                    <FetchTable
                      referenceTradingCurrency={content.reference_trading_currency.replace(
                        "EUR",
                        "€"
                      )}
                      tableArray={
                        content.distribution_plans[applicationApplication]
                      }
                      distributor={distributor}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        }
      />
    </Grid>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  distributor,
  loadingWidgetAvailablePlans,
  setLoadingWidgetAvailablePlans,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    distributor: PropTypes.any.isRequired,
    loadingWidgetAvailablePlans: PropTypes.any.isRequired,
    setLoadingWidgetAvailablePlans: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalAvailablePlans: false,
          }));

          setLoading(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "plans",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "",
            page_order_by: "",
          },
          filters: {},
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetAvailablePlans(false);
                setContent(contentFromBackend);
              } else if (
                contentFromBackend.message.includes(
                  "no application distribution"
                )
              ) {
                setSuccess(true);
                setContent(null);
                setLoading(false);
                setLoadingWidgetAvailablePlans(false);
                setReturnMessage(
                  "No application distribution dustomers found yet! Wait for them or add a few ..."
                );
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setLoadingWidgetAvailablePlans(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoadingWidgetAvailablePlans(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetAvailablePlans(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetAvailablePlans(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalAvailablePlans) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalAvailablePlans]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalAvailablePlans);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <Box sx={{mt:3}}>
      <MissingContent message="Available Plans" />
    </Box>
  );

  if (loading || loadingWidgetAvailablePlans) {
    WidgetOutlet = (
      <Box sx={{mt:3}}>
        <LoadingAnimation title="Available Plans" />
      </Box>
    );
  } else if (error) {
    WidgetOutlet = (
      <Box sx={{mt:3}}>
        <MissingContent message="Available Plans" />
      </Box>
    );
  } else if (content) {
    if(content.hide_widget === false)
    {
      WidgetOutlet = (
        <Box sx={{mt:3}}>
          <WidgetMainContentProcessed content={content} distributor={distributor} />
        </Box>
      );
    }
    else
    {
      WidgetOutlet = null
    }
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
