// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import { enCA } from "date-fns/locale";

// mui
import {
  Box,
  Stack,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";
import CountrySelect, { countries } from "../../../components/CountrySelect";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B1Small,
  ModalContentSwitch,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalDatePicker,
  ModalDatePickerSlotProps,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalUpdateCustomersPersonalInformation({
  distributor,
  dashboardId,
  configDataClient,
  isBillingAddressRequiredProp,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalUpdateCustomersPersonalInformation.propTypes = {
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    configDataClient: PropTypes.any.isRequired,
    isBillingAddressRequiredProp: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageDistributionCustomer: true,
        widgetUpdateDistributorPortalManageTickDataDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let emailNotificationSettings = configDataClient.email_notification_settings;

  emailNotificationSettings = emailNotificationSettings.split(",");

  let newsletterInitValue = false;
  emailNotificationSettings.forEach((entry) => {
    if (entry.search("newsletter") !== -1) {
      const entrySplitted = entry.split("=");

      if (entrySplitted[1] === "1") {
        newsletterInitValue = true;
      }
    }
  });

  const [formMarketingShares, setFormMarketingShares] = useState(
    configDataClient.marketing_share === "1" ? Boolean(true) : Boolean(false)
  );
  const [formTailorizedAdds, setFormTailorizedAdds] = useState(
    configDataClient.tailorized_adds === "1" ? Boolean(true) : Boolean(false)
  );
  const [formEmailNewsletter, setFormEmailNewsletter] =
    useState(newsletterInitValue);

  let initFormBirth = null;

  if (configDataClient.birth !== null) {
    if (configDataClient.birth !== "") {
      initFormBirth = new Date(`${configDataClient.birth}T00:00:00`);
    }
  }

  const [formBirth, setFormBirth] = useState(initFormBirth);

  const [formGender, setFormGender] = useState(null);
  if (configDataClient.gender != null) {
    if (configDataClient.gender !== "" && formGender === null) {
      setFormGender(configDataClient.gender);
    }

    if (configDataClient.gender === "" && formGender === null) {
      setFormGender("");
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  let myValidationScheme = null;
  if (isBillingAddressRequiredProp === false) {
    myValidationScheme = Yup.object().shape({
      organization: Yup.string(),
      gender: Yup.string(),
      forename: Yup.string(),
      surname: Yup.string(),
      province: Yup.string(),
      city: Yup.string(),
      postal_code: Yup.string(),
      street: Yup.string(),
      optional_line: Yup.string(),
      phone: Yup.string().matches(phoneRegExp, "phone is not valid"),
    });
  } else {
    myValidationScheme = Yup.object().shape({
      organization: Yup.string(),
      gender: Yup.string(),
      forename: Yup.string().required("Forename is required"),
      surname: Yup.string().required("Surname is required"),
      province: Yup.string().required("Province / State is required"),
      city: Yup.string().required("City is required"),
      postal_code: Yup.string().required("Postal code is required"),
      street: Yup.string().required("Account number is required"),
      optional_line: Yup.string(),
      phone: Yup.string().matches(phoneRegExp, "phone is not valid"),
    });
  }

  let initCountry = null;
  if (configDataClient.country != null) {
    countries.forEach((country) => {
      if (country.label === configDataClient.country) {
        initCountry = country;
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      organization:
        configDataClient.organization !== null
          ? configDataClient.organization
          : "",
      gender: configDataClient.gender !== null ? configDataClient.gender : "",
      forename:
        configDataClient.forename !== null ? configDataClient.forename : "",
      surname:
        configDataClient.surname !== null ? configDataClient.surname : "",
      country: initCountry !== null ? initCountry.label : "",
      province:
        configDataClient.province !== null ? configDataClient.province : "",
      city: configDataClient.city !== null ? configDataClient.city : "",
      postal_code:
        configDataClient.postal_code !== null
          ? configDataClient.postal_code
          : "",
      street: configDataClient.street !== null ? configDataClient.street : "",
      optional_line:
        configDataClient.optional_line !== null
          ? configDataClient.optional_line
          : "",
      phone: configDataClient.phone !== null ? configDataClient.phone : "",
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      let birthConverted = "";
      if (formBirth !== null && formBirth !== "" && formBirth !== undefined) {
        birthConverted = `${formBirth.toLocaleDateString("en-CA")}`;
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_and_account_management",
        operation: "update_customers_personal_information",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        config_data_client: {
          selected_dashboard_id: dashboardId,
          forename: values.forename,
          surname: values.surname,
          organization: values.organization,
          gender: formGender,
          birth: birthConverted,
          marketing_share: formMarketingShares ? "1" : "0",
          tailorized_adds: formTailorizedAdds ? "1" : "0",
          email_notification_settings: `newsletter=${
            formEmailNewsletter ? "1" : "0"
          },`,
          country: values.country === null ? "" : values.country,
          province: values.province,
          city: values.city,
          postal_code: values.postal_code,
          street: values.street,
          optional_line: values.optional_line,
          phone: values.phone,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);

    formik.values.organization =
      configDataClient.organization !== null
        ? configDataClient.organization
        : "";
    formik.values.gender =
      configDataClient.gender !== null ? configDataClient.gender : "";
    formik.values.forename =
      configDataClient.forename !== null ? configDataClient.forename : "";
    formik.values.surname =
      configDataClient.surname !== null ? configDataClient.surname : "";
    formik.values.country = initCountry !== null ? initCountry.label : "";
    formik.values.province =
      configDataClient.province !== null ? configDataClient.province : "";
    formik.values.city =
      configDataClient.city !== null ? configDataClient.city : "";
    formik.values.postal_code =
      configDataClient.postal_code !== null ? configDataClient.postal_code : "";
    formik.values.street =
      configDataClient.street !== null ? configDataClient.street : "";
    formik.values.optional_line =
      configDataClient.optional_line !== null
        ? configDataClient.optional_line
        : "";
    formik.values.phone =
      configDataClient.phone !== null ? configDataClient.phone : "";
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B1Small
        fullWidth
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 2 }}
      >
        Update customer's personal information
      </B1Small>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Update customer's personal information
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.forename && errors.forename) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.forename && errors.forename) === true
                        ? "Forename is required"
                        : "Forename"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("forename")}
                      error={Boolean(touched.forename && errors.forename)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.surname && errors.surname) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.surname && errors.surname) === true
                        ? "Surname is required"
                        : "Surname"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("surname")}
                      error={Boolean(touched.surname && errors.surname)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.organization && errors.organization
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.organization && errors.organization) ===
                      true
                        ? "Invalid organization"
                        : "Organization"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("organization")}
                      error={Boolean(
                        touched.organization && errors.organization
                      )}
                    />
                  </Box>

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">Gender</Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formGender || ""}
                          onChange={(event) => {
                            setFormGender(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="">
                            <em>Choose gender ...</em>
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="male">
                            Male
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="female">
                            Female
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="other">
                            Other
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">Gender</Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formGender}
                          onChange={(event) => {
                            setFormGender(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="">Choose gender ...</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box>
                    <Typography variant="contentSmall">Birth</Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enCA}
                    >
                      <ModalDatePicker
                        disableFuture
                        value={formBirth}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            const value = new Date(
                              `${newValue.toLocaleDateString("en-CA")}T00:00:00`
                            );
                            setFormBirth(value);
                          } else {
                            setFormBirth(null);
                          }
                        }}
                        slotProps={ModalDatePickerSlotProps}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box>
                    <Typography variant="contentSmall">Country</Typography>
                    <CountrySelect
                      error={Boolean(touched.country && errors.country)}
                      initCountry={initCountry}
                      setFormCountry={(newValue) => {
                        values.country = newValue;
                      }}
                      nullable={!isBillingAddressRequiredProp}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.province && errors.province) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.province && errors.province) === true
                        ? "Province / State is required"
                        : "Province / State"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("province")}
                      error={Boolean(touched.province && errors.province)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.city && errors.city) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.city && errors.city) === true
                        ? "City is required"
                        : "City"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("city")}
                      error={Boolean(touched.city && errors.city)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.postal_code && errors.postal_code) ===
                          true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.postal_code && errors.postal_code) ===
                      true
                        ? "Postal code is required"
                        : "Postal code"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("postal_code")}
                      error={Boolean(touched.postal_code && errors.postal_code)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.street && errors.street) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.street && errors.street) === true
                        ? "Street is required"
                        : "Street"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("street")}
                      error={Boolean(touched.street && errors.street)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.optional_line && errors.optional_line
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.optional_line && errors.optional_line
                      ) === true
                        ? "Invalid optional address line"
                        : "Optional address line"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("optional_line")}
                      error={Boolean(
                        touched.optional_line && errors.optional_line
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.phone && errors.phone) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.phone && errors.phone) === true
                        ? "Invalid phone"
                        : "Phone"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      py: 2,
                    }}
                  >
                    <Typography variant="contentSmall">
                      Use of personal data for marketing purposes
                    </Typography>
                    <ModalContentSwitch
                      checked={formMarketingShares}
                      onChange={(event) => {
                        setFormMarketingShares(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pb: 2,
                    }}
                  >
                    <Typography variant="contentSmall">
                      Use of personal data for tailorized adds and product
                      offers
                    </Typography>
                    <ModalContentSwitch
                      checked={formTailorizedAdds}
                      onChange={(event) => {
                        setFormTailorizedAdds(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pb: 0,
                    }}
                  >
                    <Typography variant="contentSmall">Newsletter</Typography>
                    <ModalContentSwitch
                      checked={formEmailNewsletter}
                      onChange={(event) => {
                        setFormEmailNewsletter(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Update
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
