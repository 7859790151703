// react and js

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
// Default state storage values
export const stateStorageInit = {
  // Backbone
  authenticated: false,
  userInformation: "",

  // Layout update flag for sidebar
  layoutUpdateFeatures: true,
  //
  layoutUpdateIntervalFeatures: 7500,

  // Page: account_settings
  widgetUpdateAccountSettingChangePassword: true,
  widgetUpdateAccountSettingPersonalInformation: true,
  widgetUpdateAccountSettingPromoterInformation: true,
  widgetUpdateAccountSettingDistributorInformation: true,
  widgetUpdateAccountSettingAdministratorInformation: true,
  //
  widgetUpdateIntervalAccountSetting: 5000,

  // Page: subscriptions
  widgetUpdateSubscriptionsSubscriptions: true,
  //
  widgetUpdateIntervalSubscriptionsSubscriptions: 5000,

  // Page: promotion
  widgetUpdatePromotionPromotions: true,
  //
  widgetUpdateIntervalPromotionPromotions: 5000,

  // Page: downloads
  widgetUpdateDownloadsApplications: true,
  widgetUpdateDownloadsMt5TickData: true,
  //
  widgetUpdateIntervalDownloadsApplications: 5000,
  widgetUpdateIntervalDownloadsMt5TickData: 5000,

  // Page: accounting
  widgetUpdateBillingAccountingNote: true,
  widgetUpdateBillingInvoicesAndReceipes: true,
  //
  widgetUpdateIntervalBillingAccountingNote: 5000,
  widgetUpdateIntervalBillingInvoicesAndReceipes: 5000,

  // Page: inspector
  widgetUpdateInspectorInspector: true,
  //
  widgetUpdateIntervalInspectorInspector: 4000,

  // Page: promoter_portal
  widgetUpdatePromoterPortalPromotionClients: true,
  widgetUpdatePromoterPortalManagePromotionClient: true,
  widgetUpdatePromoterPortalClientAndClickContingent: true,
  widgetUpdatePromoterPortalBrokerLimitations: true,
  widgetUpdatePromoterPortalApplicationLimitations: true,
  widgetUpdatePromoterPortalPendingPromotionClients: true,
  //
  widgetUpdateIntervalPromoterPortalPromotionClients: 10000,
  widgetUpdateIntervalPromoterPortalManagePromotionClient: 10000,
  widgetUpdateIntervalPromoterPortalClientAndClickContingent: 10000,
  widgetUpdateIntervalPromoterPortalBrokerLimitations: 10000,
  widgetUpdateIntervalPromoterPortalApplicationLimitations: 10000,
  widgetUpdateIntervalPromoterPortalPendingPromotionClients: 10000,
  //
  widgetPerformBackScrollPromoterPortalManagePromotionClient: false,

  // Page: distributor_portal
  widgetUpdateDistributorPortalDistributionCustomers: true,
  widgetUpdateDistributorPortalManageDistributionCustomer: true,
  widgetUpdateDistributorPortalManageDistributionCustomerInvoices: true,
  widgetUpdateDistributorPortalTickDataDistributionCustomers: true,
  widgetUpdateDistributorPortalManageTickDataDistributionCustomer: true,
  widgetUpdateDistributorPortalManageTickDataDistributionCustomerInvoices: true,
  widgetUpdateDistributorPortalContingents: true,
  widgetUpdateDistributorPortalAvailablePlans: true,
  widgetUpdateDistributorPortalAvailableTickDataPlans: true,
  widgetUpdateDistributorPortalApplicationLimitations: true,
  //
  widgetUpdateIntervalDistributorPortalDistributionCustomers: 10000,
  widgetUpdateIntervalDistributorPortalManageDistributionCustomer: 10000,
  widgetUpdateIntervalDistributorPortalTickDataDistributionCustomers: 10000,
  widgetUpdateIntervalDistributorPortalManageTickDataDistributionCustomer: 10000,
  widgetUpdateIntervalDistributorPortalContingents: 10000,
  widgetUpdateIntervalDistributorPortalAvailablePlans: 10000,
  widgetUpdateIntervalDistributorPortalAvailableTickDataPlans: 10000,
  widgetUpdateIntervalDistributorPortalApplicationLimitations: 10000,
  //
  widgetPerformBackScrollDistributorPortalManageDistributionCustomer: false,
  widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer: false,

  // Page: evaluation_portal
  widgetUpdateEvaluationPortalEvaluationPortal: true,
  //
  widgetUpdateIntervalEvaluationPortalEvaluationPortal: 5000,

  // Page: form_manager
  widgetUpdateFormManagerFormFreeDownload: true,
  widgetUpdateFormManagerFormContact: true,
  widgetUpdateFormManagerFormServiceRequest: true,
  widgetUpdateFormManagerFormSupport: true,
  widgetUpdateFormManagerFormNewsletter: true,
  widgetUpdateFormManagerFormNewsletterUnsubscribe: true,
  //
  widgetUpdateIntervalFormManagerFormFreeDownload: 5000,
  widgetUpdateIntervalFormManagerFormContact: 5000,
  widgetUpdateIntervalFormManagerFormServiceRequest: 5000,
  widgetUpdateIntervalFormManagerFormSupport: 5000,
  widgetUpdateIntervalFormManagerFormNewsletter: 5000,
  widgetUpdateIntervalFormManagerFormNewsletterUnsubscribe: 5000,

  // Page: event_listeners
  widgetUpdateEventListenersPromotionApprovalEvents: true,
  widgetUpdateEventListenersAutoApprovedPromotionClients: true,
  widgetUpdateEventListenersDistributionApprovalEvents: true,
  //
  widgetUpdateIntervalEventListenersPromotionApprovalEvents: 10000,
  widgetUpdateIntervalEventListenersAutoApprovedPromotionClients: 10000,
  widgetUpdateIntervalEventListenersDistributionApprovalEvents: 30000,

  // Page: application_settings
  widgetUpdateApplicationSettingsApplicationSettingsMine: true,
  widgetUpdateApplicationSettingsApplicationSettingsAllUser: true,
  //
  widgetUpdateIntervalApplicationSettingsApplicationSettingsMine: 5000,
  widgetUpdateIntervalApplicationSettingsApplicationSettingsAllUser: 5000,
};
