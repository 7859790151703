// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext, useMemo } from "react";

// mui
import { Grid, Container } from "@mui/material";

// components
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
import Page from "../../components/Page";
import backendRequest from "../../components/BackendRequest";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme

// utils

// widgets
import WidgetChangePassword from "../../widgets/account_settings/ChangePassword";
import WidgetPersonalInformation from "../../widgets/account_settings/PersonalInformation";
import WidgetPromoterInformation from "../../widgets/account_settings/PromoterInformation";
import WidgetDistributorInformation from "../../widgets/account_settings/DistributorInformation";
import WidgetAdministratorInformation from "../../widgets/account_settings/AdministratorInformation";
import WidgetDeleteAccount from "../../widgets/account_settings/DeleteAccount";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function PageOutletChangePassword({ content }) {
  PageOutletChangePassword.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  let keyOnly = Object.keys(stateStorage);
  let checkKey = keyOnly.includes("userInformation");

  if (content !== undefined && stateStorage !== undefined) {
    if (checkKey === true) {
      keyOnly = Object.keys(stateStorage.userInformation);
      checkKey = keyOnly.includes("account_settings");

      if (checkKey === true) {
        keyOnly = Object.keys(stateStorage.userInformation.account_settings);
        checkKey = keyOnly.includes("config_data");

        if (checkKey === true) {
          keyOnly = Object.keys(
            stateStorage.userInformation.account_settings.config_data
          );
          checkKey = keyOnly.includes("config_data_client");

          if (checkKey === true) {
            return checkKey ? (
              <Grid item xs={12} md={12} lg={12}>
                <WidgetChangePassword
                  title="Change Password"
                  subheader="If you wish to configure a new password, please click the button below. This action will trigger a password reset email containing detailed instructions, which will be delivered to your inbox."
                  content={content}
                />
              </Grid>
            ) : (
              <> </>
            );
          }
        }
      }
    }
  }
}

function PageOutletPersonalInformation({ content }) {
  PageOutletPersonalInformation.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  let keyOnly = Object.keys(stateStorage);
  let checkKey = keyOnly.includes("userInformation");

  if (content !== undefined && stateStorage !== undefined) {
    if (checkKey === true) {
      keyOnly = Object.keys(stateStorage.userInformation);
      checkKey = keyOnly.includes("account_settings");
      if (checkKey === true) {
        keyOnly = Object.keys(stateStorage.userInformation.account_settings);
        checkKey = keyOnly.includes("config_data");

        if (checkKey === true) {
          keyOnly = Object.keys(
            stateStorage.userInformation.account_settings.config_data
          );
          checkKey = keyOnly.includes("config_data_client");

          if (checkKey === true) {
            return checkKey ? (
              <Grid item xs={12} md={12} lg={12}>
                <WidgetPersonalInformation
                  title="Personal Information"
                  subheader="To revise your personal information, please click the button at the bottom of this widget. To ensure accurate invoicing, keeping your details updated is crucial."
                  content={content}
                />
              </Grid>
            ) : (
              <> </>
            );
          }
        }
      }
    }
  }
}

function PageOutletPromoterInformation({ content }) {
  PageOutletPromoterInformation.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  let keyOnly = Object.keys(stateStorage);
  let checkKey = keyOnly.includes("userInformation");

  if (content !== undefined && stateStorage !== undefined) {
    if (checkKey === true) {
      keyOnly = Object.keys(stateStorage.userInformation);
      checkKey = keyOnly.includes("account_settings");

      if (checkKey === true) {
        keyOnly = Object.keys(stateStorage.userInformation.account_settings);
        checkKey = keyOnly.includes("config_data");

        if (checkKey === true) {
          keyOnly = Object.keys(
            stateStorage.userInformation.account_settings.config_data
          );
          checkKey = keyOnly.includes("config_data_promoter");

          if (checkKey === true) {
            return checkKey ? (
              <Grid item xs={12} md={12} lg={12}>
                <WidgetPromoterInformation
                  title="Promoter Information"
                  subheader={`The section below displays all internal promoter details. For any modifications, please get in touch with the administrator at ${process.env.REACT_APP_EMAIL_PRIMARY_ADMINISTRATOR}.`}
                  content={content}
                />
              </Grid>
            ) : (
              <> </>
            );
          }
        }
      }
    }
  }
}

function PageOutletDistributorInformation({ content }) {
  PageOutletDistributorInformation.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  let keyOnly = Object.keys(stateStorage);
  let checkKey = keyOnly.includes("userInformation");

  if (content !== undefined && stateStorage !== undefined) {
    if (checkKey === true) {
      keyOnly = Object.keys(stateStorage.userInformation);
      checkKey = keyOnly.includes("account_settings");

      if (checkKey === true) {
        keyOnly = Object.keys(stateStorage.userInformation.account_settings);
        checkKey = keyOnly.includes("config_data");

        if (checkKey === true) {
          keyOnly = Object.keys(
            stateStorage.userInformation.account_settings.config_data
          );
          checkKey = keyOnly.includes("config_data_distributor");

          if (checkKey === true) {
            return checkKey ? (
              <Grid item xs={12} md={12} lg={12}>
                <WidgetDistributorInformation
                  title="Distributor Information"
                  subheader={`The section below displays all internal distributor details. For any modifications, please get in touch with the administrator ${process.env.REACT_APP_EMAIL_PRIMARY_ADMINISTRATOR}.`}
                  content={content}
                />
              </Grid>
            ) : (
              <> </>
            );
          }
        }
      }
    }
  }
}

function PageOutletAdministratorInformation({ content }) {
  PageOutletAdministratorInformation.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  let keyOnly = Object.keys(stateStorage);
  let checkKey = keyOnly.includes("userInformation");

  if (content !== undefined && stateStorage !== undefined) {
    if (checkKey === true) {
      keyOnly = Object.keys(stateStorage.userInformation);
      checkKey = keyOnly.includes("account_settings");

      if (checkKey === true) {
        keyOnly = Object.keys(stateStorage.userInformation.account_settings);
        checkKey = keyOnly.includes("config_data");

        if (checkKey === true) {
          keyOnly = Object.keys(
            stateStorage.userInformation.account_settings.config_data
          );
          checkKey = keyOnly.includes("config_data_administrator");

          if (checkKey === true) {
            return checkKey ? (
              <Grid item xs={12} md={12} lg={12}>
                <WidgetAdministratorInformation
                  title="Administrator Information"
                  subheader={`The section below displays all internal administrator details. For any modifications, please get in touch with the administrator at ${process.env.REACT_APP_EMAIL_PRIMARY_ADMINISTRATOR}.`}
                  content={content}
                />
              </Grid>
            ) : (
              <> </>
            );
          }
        }
      }
    }
  }
}

function PageOutletDeleteAccount({ content }) {
  PageOutletDeleteAccount.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  let keyOnly = Object.keys(stateStorage);
  let checkKey = keyOnly.includes("userInformation");

  if (content !== undefined && stateStorage !== undefined) {
    if (checkKey === true) {
      keyOnly = Object.keys(stateStorage.userInformation);
      checkKey = keyOnly.includes("account_settings");

      if (checkKey === true) {
        keyOnly = Object.keys(stateStorage.userInformation.account_settings);
        checkKey = keyOnly.includes("config_data");

        if (checkKey === true) {
          keyOnly = Object.keys(
            stateStorage.userInformation.account_settings.config_data
          );
          checkKey = keyOnly.includes("config_data_client");

          if (checkKey === true) {
            return checkKey ? (
              <Grid item xs={12} md={12} lg={12}>
                <WidgetDeleteAccount
                  title="Delete Account"
                  subheader="If you no longer require your account, please click the button below to begin the account deletion process."
                  content={content}
                />
              </Grid>
            ) : (
              <> </>
            );
          }
        }
      }
    }
  }
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  let showAccountDelete = true;
  if (content.config_data.config_data_administrator !== undefined) {
    showAccountDelete = false;
  }
  if (content.config_data.config_data_distributor !== undefined) {
    showAccountDelete = false;
  }
  if (content.config_data.config_data_promoter !== undefined) {
    showAccountDelete = false;
  }

  return (
    <>
      <PageOutletChangePassword content={content} />
      <PageOutletPersonalInformation content={content} />
      <PageOutletPromoterInformation content={content} />
      <PageOutletDistributorInformation content={content} />
      <PageOutletAdministratorInformation content={content} />
      {showAccountDelete === true ? <PageOutletDeleteAccount content={content} /> : null}
    </>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function AccountSettings() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  AccountSettings.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateAccountSettingPersonalInformation: false,
        }));

        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateAccountSettingPromoterInformation: false,
        }));

        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateAccountSettingDistributorInformation: false,
        }));

        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateAccountSettingAdministratorInformation: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "account_settings",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateAccountSettingPersonalInformation) return;
    if (!stateStorage.widgetUpdateAccountSettingPromoterInformation) return;
    if (!stateStorage.widgetUpdateAccountSettingDistributorInformation) return;
    if (!stateStorage.widgetUpdateAccountSettingAdministratorInformation)
      return;

    MainRequest(false);
    /* eslint-disable */
  }, [
    stateStorage.widgetUpdateAccountSettingPersonalInformation ||
      stateStorage.widgetUpdateAccountSettingPromoterInformation ||
      stateStorage.widgetUpdateAccountSettingDistributorInformation ||
      stateStorage.widgetUpdateAccountSettingAdministratorInformation,
  ]);
  /* eslint-enable */

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalAccountSetting);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Account Settings" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Account Settings" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Account Settings" />;
  } else if (content) {
    WidgetOutlet = <WidgetMainContentProcessed content={content} />;
  }

  // ######################################################
  // Outlet
  // ######################################################

  // eslint-disable-next-line
  return useMemo(() => {
    return (
      <Page title="Account Settings">
        <Container maxWidth="false">
          <Grid container spacing={3}>
            {WidgetOutlet}
          </Grid>
        </Container>
      </Page>
    );
  });
}
