// react and js
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect, useContext } from "react";

// mui
import {
  Stack,
  Grid,
  IconButton,
  Collapse,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import { DownloadInvoice } from "../../components/AccountingDownloads/AccountingDownloads";
//
import ModalDialog from "../../components/ModalDialog";
import ModalDialogInternalNotes from "../../components/ModalDialogInternalNotes";
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleDistributorPortalResendApprovalEmail from "./actions/HandleDistributorPortalResendApprovalEmail";
import HandleDistributorPortalResendPasswordResetEmail from "./actions/HandleDistributorPortalResendPasswordResetEmail";
import HandleDistributorPortalUpdateCustomersPersonalInformation from "./actions/HandleDistributorPortalUpdateCustomersPersonalInformation";
import HandleDistributorPortalAddNewApplicationPlanToDistributionCustomer from "./actions/HandleDistributorPortalAddNewApplicationPlanToDistributionCustomer";
//
import {
  SubscriptionItem,
  LimitationsItem,
  AccountItem,
} from "../../components/SubscriptionItem";
//
import { ApplicationItem } from "../../components/InspectorItem";
//
import DownloadPaddleInvoice from "../../components/DownloadPaddleInvoice";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  B1Tiny,
  B3Tiny,
  CardSwitch,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ModalValueLine({ parameter, value, widthLeft, widthRight }) {
  ModalValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function FreeDownloadDetails({ freeDownloadDetailsData }) {
  FreeDownloadDetails.propTypes = {
    freeDownloadDetailsData: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  function FreeDownloadDetailsOutlet() {
    let FreeDownloadDetailsData = null;

    if (freeDownloadDetailsData.meta.length !== 0) {
      const freeDownloadDetails = JSON.parse(
        freeDownloadDetailsData.data[0][3].replaceAll("&quot;", '"')
      );

      FreeDownloadDetailsData = (
        <>
          <ModalValueLine
            parameter="Form country"
            value={JSON.stringify(
              freeDownloadDetails["form-country"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form email"
            value={JSON.stringify(
              freeDownloadDetails["form-email"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form equity"
            value={JSON.stringify(
              freeDownloadDetails["form-equity"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form message"
            value={JSON.stringify(
              freeDownloadDetails["form-message"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form name"
            value={JSON.stringify(
              freeDownloadDetails["form-name"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form newsletter"
            value={JSON.stringify(
              freeDownloadDetails["form-newsletter"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form orga"
            value={JSON.stringify(
              freeDownloadDetails["form-orga"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form phone"
            value={JSON.stringify(
              freeDownloadDetails["form-phone"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form product"
            value={JSON.stringify(
              freeDownloadDetails["form-product"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form referral"
            value={JSON.stringify(
              freeDownloadDetails["form-referral"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form skill"
            value={JSON.stringify(
              freeDownloadDetails["form-skill"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
        </>
      );
    }

    return FreeDownloadDetailsData;
  }

  return freeDownloadDetailsData.meta.length !== 0 ? (
    <>
      <B1Small
        fullWidth
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 2 }}
      >
        Free download form details
      </B1Small>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Free download form details
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Typography variant="contentSmall">
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[0].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[0]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][0]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[1].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[1]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][1]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[2].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[2]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][2]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[4].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[4]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][4]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <FreeDownloadDetailsOutlet />
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  ) : null;
}

function EvaluationDetailsOutlet({ evaluationDetailsData }) {
  EvaluationDetailsOutlet.propTypes = {
    evaluationDetailsData: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return evaluationDetailsData.meta.length !== 0 ? (
    <>
      <B1Small
        fullWidth
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 2 }}
      >
        Evaluation details
      </B1Small>
      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">Evaluation details</Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Typography variant="contentSmall">
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[0].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[0].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[0]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[1].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[1].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[1]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[2].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[2].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[2]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[3].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[3].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[3]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[4].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[4].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[4]}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[5].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[5].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[5]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[6].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[6].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[6]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[7].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[7].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[7]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[8].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[8].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[8]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[9].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[9].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[9]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[10].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[10].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[10]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[11].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[11].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[11]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[12].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[12].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[12]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[13].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[13].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[13]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[14].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[14].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[14]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[15].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[15].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[15]}
                  widthLeft="50%"
                  widthRight="50%"
                />
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  ) : null;
}

function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value === "1" || value.includes("newsletter=1")) {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "0" || value.includes("newsletter=0")) {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "extended_client_data_missing") {
      valueOutlet = (
        <Typography variant="contentSmall" align="right">
          Missing
        </Typography>
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function DashboardData({ content, distributor }) {
  DashboardData.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  let evaluationDetails = null;

  content.subscriptions_data.forEach((distrubutionProduct) => {
    distrubutionProduct.accounts.forEach((distributionPermissionAccount) => {
      if (distributionPermissionAccount.evaluation_id !== "-1") {
        if (distributionPermissionAccount.evaluation_id.meta.length !== 0) {
          evaluationDetails = {
            meta: distributionPermissionAccount.evaluation_id.meta,
            data: distributionPermissionAccount.evaluation_id.data[0],
          };
        }
      }
    });
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  let isBillingAddressRequired = false;
  if (
    content.dashboard_features !== null &&
    content.dashboard_features !== undefined
  ) {
    if (
      content.dashboard_features.obfsaa8911d51f968403 !== null &&
      content.dashboard_features.obfsaa8911d51f968403 !== undefined
    ) {
      if (
        content.dashboard_features.obfsaa8911d51f968403 ===
        "obfs5828c1b7bf8ebf20"
      ) {
        isBillingAddressRequired = true;
      }
    }
  }

  return (
    <Box sx={{ mt: 0, mb: 3 }}>
      <CardOutlet
        variant="outlet"
        backgroundProp="#F2F2F2"
        title="Customers's Personal Information"
        subheader="Please use the widgets below to manage your distribution customers."
        mainOutlet={
          <>
            <HandleDistributorPortalUpdateCustomersPersonalInformation
              distributor={distributor}
              dashboardId={content.dashboard_data.dashboard_id}
              configDataClient={content.dashboard_data}
              isBillingAddressRequiredProp={isBillingAddressRequired}
            />
            <HandleDistributorPortalResendApprovalEmail
              distributor={distributor}
              dashboardId={content.dashboard_data.dashboard_id}
            />
            <HandleDistributorPortalResendPasswordResetEmail
              distributor={distributor}
              dashboardId={content.dashboard_data.dashboard_id}
            />
            {typeof content.dashboard_data.form_free_download_ticket_id ===
            "object" ? (
              <FreeDownloadDetails
                freeDownloadDetailsData={
                  content.dashboard_data.form_free_download_ticket_id
                }
              />
            ) : null}
            {evaluationDetails !== null ? (
              <EvaluationDetailsOutlet
                evaluationDetailsData={evaluationDetails}
              />
            ) : null}

            <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
              Visible by customer
            </Typography>

            <ValueLine
              parameter="User ID (UID)"
              value={`${content.dashboard_data.dashboard_id}`}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Email"
              value={content.dashboard_data.email}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Forename"
              value={content.dashboard_data.forename}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Surname"
              value={content.dashboard_data.surname}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Birth"
              value={content.dashboard_data.birth}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Gender"
              value={
                content.dashboard_data.gender !== null
                  ? content.dashboard_data.gender.charAt(0).toUpperCase() +
                    content.dashboard_data.gender.slice(1)
                  : ""
              }
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Organization"
              value={content.dashboard_data.organization}
              widthLeft="35%"
              widthRight="65%"
            />
            <ValueLine
              parameter="Street"
              value={content.dashboard_data.street}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Optional address line"
              value={content.dashboard_data.optional_line}
              widthLeft="45%"
              widthRight="55%"
            />
            <ValueLine
              parameter="Postal code"
              value={content.dashboard_data.postal_code}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="City"
              value={content.dashboard_data.city}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Province / State"
              value={content.dashboard_data.province}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Country"
              value={content.dashboard_data.country}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Phone"
              value={content.dashboard_data.phone}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Newsletter"
              value={content.dashboard_data.email_notification_settings}
              widthLeft="25%"
              widthRight="75%"
            />

            <Typography variant="h3" sx={{ mt: 4, mb: 1 }}>
              Internal
            </Typography>

            <ValueLine
              parameter="Date added"
              value={`${content.dashboard_data.datetime_added} GMT`}
              widthLeft="50%"
              widthRight="50%"
            />

            {content.dashboard_data.account_state !== null &&
            content.dashboard_data.account_state !== undefined ? (
              <ValueLine
                parameter="Account state"
                value={
                  content.dashboard_data.account_state.charAt(0).toUpperCase() +
                  content.dashboard_data.account_state.slice(1)
                }
                widthLeft="45%"
                widthRight="65%"
              />
            ) : null}

            <ValueLine
              parameter="Use personal data for marketing"
              value={content.dashboard_data.marketing_share}
              widthLeft="80%"
              widthRight="20%"
            />
            <ValueLine
              parameter="Use personal data for tailorized adds"
              value={content.dashboard_data.tailorized_adds}
              widthLeft="80%"
              widthRight="20%"
            />
            <ValueLine
              parameter="Last dashboard login"
              value={
                content.dashboard_data.last_login !== null
                  ? `${content.dashboard_data.last_login} GMT`
                  : ""
              }
              widthLeft="50%"
              widthRight="50%"
            />
            <ValueLine
              parameter="Last application start"
              value={content.dashboard_data.last_application_connection}
              widthLeft="50%"
              widthRight="50%"
            />
            <ValueLine
              parameter="Last used application name"
              value={content.dashboard_data.last_application_name}
              widthLeft="55%"
              widthRight="45%"
            />
            <B3Tiny
              fullWidth
              variant="outlined"
              onClick={handleClickOpen("paper")}
              sx={{ mt: 2 }}
            >
              Account notes
            </B3Tiny>
            <ModalDialogInternalNotes
              open={open}
              handleClose={handleClose}
              handleClickClose={handleClickClose}
              scroll={scroll}
              data={content.dashboard_data.internal_notes}
              notesType={`Account notes for user ID (UID) ${content.dashboard_data.dashboard_id}`}
            />
          </>
        }
      />
    </Box>
  );
}

function DistributionSubscriptionItem({
  content,
  distributor,
  distributionPlansApplication,
  KnownAccountServer,
  referenceTradingCurrency,
}) {
  DistributionSubscriptionItem.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    distributionPlansApplication: PropTypes.any.isRequired,
    KnownAccountServer: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
  };

  let checkKey = false;
  let isOnlyOneAccountLeft = false;

  if (content !== undefined) {
    const keyOnly = Object.keys(content);

    if (keyOnly.includes("accounts") === true) {
      if (content.accounts.length !== 0) {
        checkKey = true;
      }

      if (content.accounts.length === 1) {
        isOnlyOneAccountLeft = true;
      }
    }
  }

  let isSponsored = false;
  if (content.plan.payment_method === "sponsored") {
    isSponsored = true;
  }

  return content.product.length !== 0 &&
    content.plan.length !== 0 &&
    content.accounts.length !== 0 ? (
    <Grid item xs={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box>
          {content.product.product_tag !== "mt5_tick_data" ? (
            <>
              <SubscriptionItem
                sx={{ px: 0 }}
                mode="distribution_application"
                distributor={distributor}
                content={content}
                distributionPlansApplication={distributionPlansApplication}
                KnownAccountServer={KnownAccountServer}
                managed={Boolean(true)}
                referenceTradingCurrency={referenceTradingCurrency}
                categories="none"
              />
              <LimitationsItem
                sx={{ px: 2 }}
                mode="distribution_application"
                content={content}
                managed={Boolean(true)}
              />
            </>
          ) : (
            <> </>
          )}
        </Box>
        {checkKey ? (
          <Stack>
            {content.accounts.map((currentAccount, index) => (
              <AccountItem
                mode="distribution_application"
                currentAccount={currentAccount}
                key={index}
                distributor={distributor}
                content={content}
                managed={Boolean(true)}
                isOnlyOneAccountLeft={isOnlyOneAccountLeft}
                isSponsored={isSponsored}
                plan={content.plan}
              />
            ))}
          </Stack>
        ) : (
          <> </>
        )}
      </Box>
    </Grid>
  ) : null;
}

function SubscriptionData({ content, distributor }) {
  SubscriptionData.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  return (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 10, mb: 0 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Customer's Application Subscriptions"
            subheader="Please use the widgets below to manage your distribution customers."
            mainOutlet={
              <HandleDistributorPortalAddNewApplicationPlanToDistributionCustomer
                distributor={distributor}
                dashboardId={content.dashboard_data.dashboard_id}
                distributionPlans={content.distribution_plans}
                KnownAccountServer={content.known_account_server}
              />
            }
          />
        </Box>
      </Grid>

      {content.subscriptions_data.map((currentSubscription) =>
        currentSubscription.product.product_tag !== "mt5_tick_data" ? (
          <DistributionSubscriptionItem
            key={currentSubscription.product.product_tag}
            content={currentSubscription}
            distributor={distributor}
            distributionPlansApplication={
              content.distribution_plans[
                currentSubscription.product.product_name
              ]
            }
            KnownAccountServer={content.known_account_server}
            referenceTradingCurrency={content.reference_trading_currency}
          />
        ) : null
      )}
    </Grid>
  );
}

function SessionData({ content, distributor }) {
  SessionData.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  let distributorPortalManageCustomerInspectorSimplifiedInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs4811e418494111b0 !== null &&
      myLocalSettingStorage.obfs4811e418494111b0 !== undefined
    ) {
      distributorPortalManageCustomerInspectorSimplifiedInitValue =
        myLocalSettingStorage.obfs4811e418494111b0;
    }
  }

  const [simplified, setSimplified] = useState(
    distributorPortalManageCustomerInspectorSimplifiedInitValue
  );

  const handleChange = (event) => {
    setSimplified(event.target.checked);

    const newLocalSettingStorage = JSON.parse(
      localStorage.getItem("myLocalSettingStorage")
    );
    newLocalSettingStorage.obfs4811e418494111b0 = event.target.checked;
    localStorage.setItem(
      "myLocalSettingStorage",
      JSON.stringify(newLocalSettingStorage)
    );
  };

  return (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 13, mb: 0 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Customer's Inspector Sessions"
            subheader="Please use the widgets below to manage your distribution customer's inspector sessions."
            mainOutlet={
              <FormGroup>
                <Box
                  justifyContent="space-between"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="contentMiddle">
                    Simplified output
                  </Typography>
                  <Typography variant="contentMiddle" align="right">
                    <CardSwitch checked={simplified} onChange={handleChange} />
                  </Typography>
                </Box>
              </FormGroup>
            }
          />
        </Box>
      </Grid>

      {content.inspector_data.length !== 0 ? (
        content.inspector_data.map((currentApplication, index) => (
          <ApplicationItem
            key={index}
            content={currentApplication}
            simplified={simplified}
            managed={Boolean(true)}
            distributor={distributor}
          />
        ))
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <Box
            display="block"
            align="center"
            sx={{
              borderRadius: "0px",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
              py: 1,
            }}
          >
            <Typography
              variant="contentBig"
              display="block"
              align="center"
              sx={{ m: "14px" }}
            >
              No active applications found ...
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

function FetchTable({ tableArray, refreshLoading, distributor }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="16%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> ID </Typography>
              </TableCell>
              <TableCell
                width="14%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Date </Typography>
              </TableCell>
              <TableCell
                width="40%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">Description</Typography>
              </TableCell>
              <TableCell
                width="14%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Amount </Typography>
              </TableCell>
              <TableCell
                width="16%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[35] !== "" ? (
                      <Typography variant="contentSmall">{row[35]}</Typography>
                    ) : (
                      <Typography variant="contentSmall">{row[0]}</Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall">
                      {row[2].substring(0, 10)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[7]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[10] !== "0" ? (
                      <Typography variant="contentSmall">
                        {numberWithCommas(
                          (Math.ceil(row[10] * 100) / 100).toFixed(2)
                        )}{" "}
                        {row[29].replace("EUR", "€")}
                      </Typography>
                    ) : (
                      <Typography variant="contentSmall">{row[11]}</Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[6].toLowerCase() !== "paddle" ? (
                      <B3Tiny
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          DownloadInvoice(row);
                        }}
                      >
                        Download invoice
                      </B3Tiny>
                    ) : (
                      <DownloadPaddleInvoice
                        invoiceData={row}
                        userRole="distributor"
                        distributor={distributor}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> ID </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  {row[35] !== "" ? (
                    <Typography variant="contentSmall">{row[35]}</Typography>
                  ) : (
                    <Typography variant="contentSmall">{row[0]}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Date </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">
                    {row[2].substring(0, 10)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Description
                  </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[7]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Amount </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  {row[10] !== "0" ? (
                    <Typography variant="contentSmall">
                      {numberWithCommas(
                        (Math.ceil(row[10] * 100) / 100).toFixed(2)
                      )}{" "}
                      {row[29].replace("EUR", "€")}
                    </Typography>
                  ) : (
                    <Typography variant="contentSmall">{row[11]}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  {row[6].toLowerCase() !== "paddle" ? (
                    <B3Tiny
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        DownloadInvoice(row);
                      }}
                    >
                      Download invoice
                    </B3Tiny>
                  ) : (
                    <DownloadPaddleInvoice
                      invoiceData={row}
                      userRole="distributor"
                      distributor={distributor}
                    />
                  )}
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetSubAccountingContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterDate,
  setFilterDate,
  filterProduct,
  setFilterProduct,
  filterAmount,
  setFilterAmount,
  distributor,
}) {
  WidgetSubAccountingContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterDate: PropTypes.any.isRequired,
    setFilterDate: PropTypes.any.isRequired,
    filterProduct: PropTypes.any.isRequired,
    setFilterProduct: PropTypes.any.isRequired,
    filterAmount: PropTypes.any.isRequired,
    setFilterAmount: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  let distributorPortalDistributionApplicationInvoicesTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs5e703cefbe0a57a2 !== null &&
      myLocalSettingStorage.obfs5e703cefbe0a57a2 !== undefined
    ) {
      distributorPortalDistributionApplicationInvoicesTableFilterCollapseInitValue =
        myLocalSettingStorage.obfs5e703cefbe0a57a2;
    }
  }
  const [collapse, setCollapse] = useState(
    distributorPortalDistributionApplicationInvoicesTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterDate !== "" ||
      filterProduct !== "" ||
      filterAmount !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let distributorPortalDistributionApplicationInvoicesTableFilterItemsPerPageInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs2e50f66cd66f4baa !== null &&
      myLocalSettingStorage.obfs2e50f66cd66f4baa !== undefined
    ) {
      distributorPortalDistributionApplicationInvoicesTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfs2e50f66cd66f4baa;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    distributorPortalDistributionApplicationInvoicesTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterDate, setLocalFilterDate] = useState(filterDate);
  const [localFilterProduct, setLocalFilterProduct] = useState(filterProduct);
  const [localFilterAmount, setLocalFilterAmount] = useState(filterAmount);

  const contentFiltered = [];
  if (content.billing_data !== undefined) {
    content.billing_data.data.forEach((invoice, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(invoice);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterDate(localFilterDate);
    setFilterProduct(localFilterProduct);
    setFilterAmount(localFilterAmount);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateDistributorPortalManageDistributionCustomerInvoices: true,
    }));
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Customer's Invoices"
          subheader="Use the navigation below to search, filter, and download individual invoices. Invoices and receipts may take a couple of minutes to appear here."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No invoices") ? null : (
              <Box>
                <B3Tiny
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setCollapse(!collapse);

                    const newLocalSettingStorage = JSON.parse(
                      localStorage.getItem("myLocalSettingStorage")
                    );

                    newLocalSettingStorage.obfs5e703cefbe0a57a2 = !collapse;
                    localStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }}
                >
                  {collapse ? "Hide filters" : "Show filters"}
                </B3Tiny>

                <Collapse
                  sx={{ mx: -1 }}
                  in={collapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">ID</Typography>
                        <FetchTableTextField
                          value={localFilterId}
                          onChange={(event) => {
                            setLocalFilterId(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs87d49b0e01d07f47 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">Date</Typography>
                        <FetchTableTextField
                          value={localFilterDate}
                          onChange={(event) => {
                            setLocalFilterDate(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs362883a2a0b07b7a =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">
                          Description
                        </Typography>
                        <FetchTableTextField
                          value={localFilterProduct}
                          onChange={(event) => {
                            setLocalFilterProduct(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs3dd8ea76e3692868 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">Amount</Typography>
                        <FetchTableTextField
                          value={localFilterAmount}
                          onChange={(event) => {
                            setLocalFilterAmount(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs53ad8797135dd313 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="contentSmall">
                          Items per page
                        </Typography>
                        <Box sx={{ px: 1 }}>
                          <FetchTableSlider
                            size="small"
                            min={1}
                            max={100}
                            align="right"
                            value={itemsPerPages}
                            onChange={(event, newValue) => {
                              setItemsPerPages(newValue);
                              setTotalPages(
                                Math.ceil(content.num_rows / newValue)
                              );
                              setCurrentPage(1);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs2e50f66cd66f4baa =
                                newValue;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <B1Tiny
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        setFilters();
                      }}
                    >
                      Set filters and refresh output
                    </B1Tiny>
                  </Box>
                </Collapse>

                <Box sx={{ mt: 0, mb: 1 }} />

                <FetchTable
                  tableArray={contentFiltered}
                  refreshLoading={refreshLoading}
                  distributor={distributor}
                />

                <Stack sx={{ mt: 3 }} alignItems="center">
                  <FetchTablePagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    onChange={(event, value) => {
                      setCurrentPage(value);
                    }}
                  />
                </Stack>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

function Accounting({ distributor, dashboardId }) {
  Accounting.propTypes = {
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let distributorPortalDistributionApplicationInvoicesTableFilterIdInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs87d49b0e01d07f47 !== null &&
      myLocalSettingStorage.obfs87d49b0e01d07f47 !== undefined
    ) {
      distributorPortalDistributionApplicationInvoicesTableFilterIdInitValue =
        myLocalSettingStorage.obfs87d49b0e01d07f47;
    }
  }
  const [filterId, setFilterId] = useState(
    distributorPortalDistributionApplicationInvoicesTableFilterIdInitValue
  );

  // Filter
  let distributorPortalDistributionApplicationInvoicesTableFilterDateInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs362883a2a0b07b7a !== null &&
      myLocalSettingStorage.obfs362883a2a0b07b7a !== undefined
    ) {
      distributorPortalDistributionApplicationInvoicesTableFilterDateInitValue =
        myLocalSettingStorage.obfs362883a2a0b07b7a;
    }
  }
  const [filterDate, setFilterDate] = useState(
    distributorPortalDistributionApplicationInvoicesTableFilterDateInitValue
  );

  // Filter
  let distributorPortalDistributionApplicationInvoicesTableFilterProductInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs3dd8ea76e3692868 !== null &&
      myLocalSettingStorage.obfs3dd8ea76e3692868 !== undefined
    ) {
      distributorPortalDistributionApplicationInvoicesTableFilterProductInitValue =
        myLocalSettingStorage.obfs3dd8ea76e3692868;
    }
  }
  const [filterProduct, setFilterProduct] = useState(
    distributorPortalDistributionApplicationInvoicesTableFilterProductInitValue
  );

  // Filter
  let distributorPortalDistributionApplicationInvoicesTableFilterAmountInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs53ad8797135dd313 !== null &&
      myLocalSettingStorage.obfs53ad8797135dd313 !== undefined
    ) {
      distributorPortalDistributionApplicationInvoicesTableFilterAmountInitValue =
        myLocalSettingStorage.obfs53ad8797135dd313;
    }
  }
  const [filterAmount, setFilterAmount] = useState(
    distributorPortalDistributionApplicationInvoicesTableFilterAmountInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        // Reset update flag
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateDistributorPortalManageDistributionCustomerInvoices: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_invoices",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        selection: {
          selected_dashboard_id: dashboardId,
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "desc",
          page_order_by: "billing_id",
        },
        filters: {
          billing_id: filterId,
          invoice_date: filterDate,
          product: filterProduct,
          total_amount: filterAmount,
        },
      };

      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setRefreshLoading(false);
              setContent(contentFromBackend);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterDate === "" &&
                  filterProduct === "" &&
                  filterAmount === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No invoices found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalManageDistributionCustomer)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalManageDistributionCustomer]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalDistributionCustomers);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Customer's Invoices" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Customer's Invoices" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Customer's Invoices" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetSubAccountingContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        filterAmount={filterAmount}
        setFilterAmount={setFilterAmount}
        distributor={distributor}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}

function WidgetMainContentProcessed({ content, distributor, dashboardId }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
  };

  let dashboardIdFound = true;

  if (content.message.includes("this is not not your distribution customer!")) {
    dashboardIdFound = false;
  }

  return dashboardIdFound ? (
    <Stack>
      <DashboardData content={content} distributor={distributor} />
      <SubscriptionData content={content} distributor={distributor} />
      <SessionData content={content} distributor={distributor} />
      <Box sx={{ mt: 13, mb: 0 }}>
        <Accounting distributor={distributor} dashboardId={dashboardId} />
      </Box>
    </Stack>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  category,
  title,
  subheader,
  dashboardId,
  distributor,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    category: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    subheader: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalManageDistributionCustomer: false,
          }));

          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "manage_customer",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            selected_dashboard_id: dashboardId,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setWarning(false);
                setSuccess(false);
                setContent(contentFromBackend);
                setReturnMessage("");
              } else if (
                contentFromBackend.message.includes(
                  "this is not not your distribution customer!"
                )
              ) {
                setSuccess(false);
                setWarning(true);
                setContent(contentFromBackend);
                setLoading(false);
                setReturnMessage("Distribution customer not found ...");
              } else if (contentFromBackend.message === "token invalid") {
                setSuccess(false);
                setWarning(true);
                setError(false);
                setLoading(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setSuccess(false);
                setWarning(false);
                setError(true);
                setLoading(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setSuccess(false);
              setWarning(false);
              setError(true);
              setLoading(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setWarning(false);
              setLoading(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalManageDistributionCustomer)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalManageDistributionCustomer]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalDistributionCustomers);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Customer" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Customer" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Customer" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        distributor={distributor}
        dashboardId={dashboardId}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return (
    <>
      <Box sx={{ mt: 0, mb: 13 }}>
        <CardOutlet
          category={category}
          title={title}
          subheader={subheader}
          variant="outlet"
          mainOutlet={
            <B1Small
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => {
                navigate("/distributor-portal");

                setStateStorage((previousState) => ({
                  ...previousState,
                  widgetPerformBackScrollDistributorPortalManageDistributionCustomer: true,
                }));
              }}
            >
              Back to distributor portal
            </B1Small>
          }
          backgroundProp="#F2F2F2"
          loading={loading}
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
        />
      </Box>

      {WidgetOutlet}
    </>
  );
}
