// react and js
import PropTypes from "prop-types";
import { React, useEffect, useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  IconButton,
  Link,
  Typography,
  List,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";

// components
import Iconify from "../../components/Iconify";

// hooks
import defines from "../../hooks/defines";
import features from "../../hooks/features";
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
const StyledNavigationListItemIcon = styled(ListItemIcon)(() => ({
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledNavigationListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "0px 0px 0px 0px",
  margin: "4px 0px 0px 0px",
  color: "#474A4C",
  backgroundColor: "transparent",
  //
  height: 45,
  [theme.breakpoints.up("lg")]: {
    height: 35,
  },
  //
  "&:hover": {
    backgroundColor: "#F2F2F2",
  },
}));

function SelectableStyledNavigationListItemButton({
  typographyVariant,
  featureTitle,
  featurePath,
  featureIcon,
}) {
  SelectableStyledNavigationListItemButton.propTypes = {
    typographyVariant: PropTypes.any.isRequired,
    featureTitle: PropTypes.any.isRequired,
    featurePath: PropTypes.any.isRequired,
    featureIcon: PropTypes.any.isRequired,
  };

  const { pathname } = useLocation();

  let isActiveRoot = false;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  if(pathname.includes(featurePath) === true)
  {
    isActiveRoot = true;
  }

  if(pathname === "/")
  {
    const myLocalStateStorage = JSON.parse(
      localStorage.getItem("myLocalStateStorage")
    );

    if (myLocalStateStorage !== null) {
      if (myLocalStateStorage.account_settings !== undefined) 
      {
        if(myLocalStateStorage.account_settings.promotion_mode === true && featurePath === "/promotion")
        {
            isActiveRoot = true;
        }
        else if(myLocalStateStorage.account_settings.promotion_mode === false && featurePath === "/subscriptions")
        {
          isActiveRoot = true;
        }
      }
    }
  }

  return (
    <StyledNavigationListItemButton
      component={RouterLink}
      to={featurePath}
      sx={{
        color: isActiveRoot ? "black" : null,
        background: isActiveRoot ? "#E6E6E6" : null,
      }}
    >
      <StyledNavigationListItemIcon>
        <Iconify
          icon={featureIcon}
          minHeight={
            isDesktop
              ? defines.sidebarIconWidthHeightDesktop
              : defines.sidebarIconWidthHeightMobile
          }
          minWidth={
            isDesktop
              ? defines.sidebarIconWidthHeightDesktop
              : defines.sidebarIconWidthHeightMobile
          }
        />
      </StyledNavigationListItemIcon>
      <Typography variant={typographyVariant}>{featureTitle}</Typography>
    </StyledNavigationListItemButton>
  );
}

function NavigationSection({ sidebarItems, typographyVariant }) {
  NavigationSection.propTypes = {
    sidebarItems: PropTypes.any.isRequired,
    typographyVariant: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <List
      sx={{
        background: "transparent",
        padding: isDesktop ? "9px 6px 0px 7px" : "9px 0px 0px 0px",
      }}
    >
      <>
        {sidebarItems.map((feature, index) => (
          <Box key={index}>
            {stateStorage.userInformation.account_settings
              .dashboard_features !== undefined ? (
              // eslint-disable-next-line
              <>
                {stateStorage.userInformation.account_settings
                  .dashboard_features[feature.backendLinking] ===
                feature.trueObfs ? (
                  <SelectableStyledNavigationListItemButton
                    typographyVariant={typographyVariant}
                    featureTitle={feature.title}
                    featurePath={feature.path}
                    featureIcon={feature.icon}
                    currentPath={pathname}
                  />
                ) : null}
              </>
            ) : null}
          </Box>
        ))}

        <StyledNavigationListItemButton>
          <StyledNavigationListItemIcon>
            <Iconify
              icon="eva:external-link-outline"
              minHeight={
                isDesktop
                  ? defines.sidebarIconWidthHeightDesktop
                  : defines.sidebarIconWidthHeightMobile
              }
              minWidth={
                isDesktop
                  ? defines.sidebarIconWidthHeightDesktop
                  : defines.sidebarIconWidthHeightMobile
              }
            />
          </StyledNavigationListItemIcon>
          <Typography variant={typographyVariant}>
            <Link
              variant={typographyVariant}
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_WEBSITE}
            >
              Website
            </Link>
          </Typography>
        </StyledNavigationListItemButton>

        <StyledNavigationListItemButton>
          <StyledNavigationListItemIcon>
            <Iconify
              icon="eva:external-link-outline"
              minHeight={
                isDesktop
                  ? defines.sidebarIconWidthHeightDesktop
                  : defines.sidebarIconWidthHeightMobile
              }
              minWidth={
                isDesktop
                  ? defines.sidebarIconWidthHeightDesktop
                  : defines.sidebarIconWidthHeightMobile
              }
            />
          </StyledNavigationListItemIcon>
          <Typography variant={typographyVariant}>
            <Link
              variant={typographyVariant}
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_DOCUMENTATION}
            >
              Documentation
            </Link>
          </Typography>
        </StyledNavigationListItemButton>
      </>
    </List>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PrivateSidebar({ isOpenSidebar, onCloseSidebar }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  PrivateSidebar.propTypes = {
    isOpenSidebar: PropTypes.any.isRequired,
    onCloseSidebar: PropTypes.any.isRequired,
  };

  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );

  return (
    myLocalStateStorage !== null &&
    stateStorage.userInformation !== "" && (
      <>
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: {
                zIndex: 0,
                top: 0,
                width: defines.sidebarWidthMobile,
                borderRight: "1px solid #C8C8C8",
                borderColor: "#C8C8C8",
              },
            }}
          >
            <Box
              sx={{
                background: "#F2F2F2",
                display: "flex",
                minHeight: defines.headerHeightMobile,
                boxShadow: "0px 0px 2px rgba(0,0,0,0.5)",
                justifyContent: "left",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <IconButton
                onClick={onCloseSidebar}
                sx={{ marginLeft: "12px", borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={35}
                  minHeight={35}
                  color="#474A4C"
                />
              </IconButton>

              <RouterLink to="/" style={{ display: "flex" }}>
                <Box
                  component="img"
                  src={process.env.REACT_APP_PATH_LOGO}
                  sx={{
                    marginTop: "1px",
                    width: "100px",
                    minHeight: "47px",
                    marginLeft: "12px",
                  }}
                />
              </RouterLink>
            </Box>

            <NavigationSection
              sidebarItems={features}
              typographyVariant="sidebarMobile"
            />
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                zIndex: 0,
                background: "white",
                top: defines.headerHeightDesktop,
                width: defines.sidebarWidthDesktop,
                borderRight: "1px solid #C8C8C8",
                borderColor: "#C8C8C8",
              },
            }}
          >
            <NavigationSection
              sidebarItems={features}
              typographyVariant="sidebarDesktop"
            />

            <Box sx={{ minHeight: defines.headerHeightMobile }} />
          </Drawer>
        )}
      </>
    )
  );
}
