// react and js
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { React, useState, useEffect, useContext, useMemo } from "react";

// mui
import { Grid, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import Page from "../../components/Page";

// hooks
import defines from "../../hooks/defines";
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme

// utils

// widgets
import WidgetPromoterSelector from "../../widgets/promoter_portal/PromoterSelector";
import WidgetPromoterWelcome from "../../widgets/promoter_portal/PromoterWelcome";
//
import WidgetPromotionClients from "../../widgets/promoter_portal/PromotionClients";
import WidgetManagePromotionClient from "../../widgets/promoter_portal/ManagePromotionClient";
import WidgetClientAndClickContingent from "../../widgets/promoter_portal/ClientAndClickContingent";
import WidgetBrokerLimitations from "../../widgets/promoter_portal/BrokerLimitations";
import WidgetApplicationLimitations from "../../widgets/promoter_portal/ApplicationLimitations";
import WidgetPendingPromotionClients from "../../widgets/promoter_portal/PendingPromotionClients";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PromoterPortal() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const navigate = useNavigate();
  const location = useLocation();

  const [
    regularRefreshIntervalCounterComponent,
    setRegularRefreshIntervalCounterComponent,
  ] = useState(0);

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Outlet protection
  // ######################################################
  let isOutletAllowed = false;
  if (stateStorage.userInformation !== "") {
    if (
      stateStorage.userInformation.account_settings !== null &&
      stateStorage.userInformation.account_settings !== undefined
    ) {
      if (
        stateStorage.userInformation.account_settings.dashboard_features !==
          null &&
        stateStorage.userInformation.account_settings.dashboard_features !==
          undefined
      ) {
        if (
          stateStorage.userInformation.account_settings.dashboard_features
            .obfsefb710ed690a6335 !== null &&
          stateStorage.userInformation.account_settings.dashboard_features
            .obfsefb710ed690a6335 !== undefined
        ) {
          if (
            stateStorage.userInformation.account_settings.dashboard_features
              .obfsefb710ed690a6335 === "obfsf2c9be0f5184f441"
          ) {
            isOutletAllowed = true;
          }
        }
      }
    }
  }

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [loading, setLoading] = useState(navigator.onLine);
  const [loadingWidgetPromotionClients, setLoadingWidgetPromotionClients] =
    useState(navigator.onLine);
  const [
    loadingWidgetPendingPromotionClients,
    setLoadingWidgetPendingPromotionClients,
  ] = useState(navigator.onLine);
  const [
    loadingWidgetClientAndClickContingent,
    setLoadingWidgetClientAndClickContingent,
  ] = useState(navigator.onLine);
  const [loadingWidgetBrokerLimitations, setLoadingWidgetBrokerLimitations] =
    useState(navigator.onLine);
  const [
    loadingWidgetApplicationLimitations,
    setLoadingWidgetApplicationLimitations,
  ] = useState(navigator.onLine);

  const [widgetManagePromotionClientOpen, setWidgetManagePromotionClientOpen] =
    useState(false);

  const routeParams = useParams();

  if (routeParams.dashboard_id !== undefined) {
    if (widgetManagePromotionClientOpen === false) {
      setWidgetManagePromotionClientOpen(true);
    }
  } else if (widgetManagePromotionClientOpen === true) {
    setWidgetManagePromotionClientOpen(false);
  }

  let promoterPortalPromoterInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfscaf9a3348bfafbb0 !== null &&
      myLocalSettingStorage.obfscaf9a3348bfafbb0 !== undefined
    ) {
      promoterPortalPromoterInitValue =
        myLocalSettingStorage.obfscaf9a3348bfafbb0;
    }
  }

  const [promoter, setPromoter] = useState(0);

  // ######################################################
  // Promoter change
  // ######################################################
  useEffect(() => {
    setLoadingWidgetPendingPromotionClients(true);
    setLoadingWidgetPromotionClients(true);
    setLoadingWidgetClientAndClickContingent(true);
    setLoadingWidgetBrokerLimitations(true);
    setLoadingWidgetApplicationLimitations(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoter]);

  // ######################################################
  // Back rescroll
  // ######################################################
  useEffect(() => {
    if (
      !stateStorage.widgetPerformBackScrollPromoterPortalManagePromotionClient
    )
      return;

    if (
      !loadingWidgetPendingPromotionClients &&
      !loadingWidgetPromotionClients &&
      !loadingWidgetClientAndClickContingent &&
      !loadingWidgetBrokerLimitations &&
      !loadingWidgetApplicationLimitations
    ) {
      setStateStorage((previousState) => ({
        ...previousState,
        widgetPerformBackScrollPromoterPortalManagePromotionClient: false,
      }));

      const autoScrollContainer = document.querySelector(
        "#auto-scroll-container"
      );
      const element = document.getElementById("promotion-clients-scroll");
      const elementPosition = element.getBoundingClientRect().top;

      let offsetPosition = 0;
      if (isDesktop) {
        offsetPosition =
          elementPosition +
          autoScrollContainer.scrollTop -
          defines.headerHeightDesktop;
      } else {
        offsetPosition =
          elementPosition +
          autoScrollContainer.scrollTop -
          defines.headerHeightMobile;
      }

      if (autoScrollContainer !== null) {
        autoScrollContainer.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingWidgetPendingPromotionClients,
    loadingWidgetPromotionClients,
    loadingWidgetClientAndClickContingent,
    loadingWidgetBrokerLimitations,
    loadingWidgetApplicationLimitations,
  ]);

  useEffect(() => {
    if (
      !stateStorage.widgetPerformBackScrollPromoterPortalManagePromotionClient
    )
      return;

    setLoadingWidgetPendingPromotionClients(true);
    setLoadingWidgetPromotionClients(true);
    setLoadingWidgetClientAndClickContingent(true);
    setLoadingWidgetBrokerLimitations(true);
    setLoadingWidgetApplicationLimitations(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetPerformBackScrollPromoterPortalManagePromotionClient]);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "promoter_portal",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "",
          page_order_by: "",
        },
        filters: {},
      };

      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
              if (promoterPortalPromoterInitValue === 0) {
                setPromoter(contentFromBackend.promoters.data[0][0]);

                const newLocalSettingStorage = JSON.parse(
                  localStorage.getItem("myLocalSettingStorage")
                );

                const myLocalStateStorage = JSON.parse(
                  localStorage.getItem("myLocalStateStorage")
                );

                newLocalSettingStorage.obfscaf9a3348bfafbb0 =
                  contentFromBackend.promoters.data[0][0];

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );

                if (
                  myLocalStateStorage.account_settings.config_data
                    .config_data_promoter !== null &&
                  myLocalStateStorage.account_settings.config_data
                    .config_data_promoter !== undefined
                ) {
                  const myPromoterId =
                    myLocalStateStorage.account_settings.config_data
                      .config_data_promoter.promoter_id;

                  if (promoter !== myPromoterId) {
                    setPromoter(myPromoterId);

                    newLocalSettingStorage.obfscaf9a3348bfafbb0 = myPromoterId;
                    localStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }
                }
              } else {
                setPromoter(promoterPortalPromoterInitValue);

                const newLocalSettingStorage = JSON.parse(
                  localStorage.getItem("myLocalSettingStorage")
                );

                newLocalSettingStorage.obfscaf9a3348bfafbb0 =
                  promoterPortalPromoterInitValue;
                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );
              }
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Regular refreshs
  // ######################################################

  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounterComponent(
        regularRefreshIntervalCounterComponent + 1
      );
    }, 5000);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounterComponent]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounterComponent]);

  let PromoterPortalSelectable = false;

  if (stateStorage !== undefined && stateStorage !== null) {
    if (
      stateStorage.userInformation.account_settings.config_data !== undefined &&
      stateStorage.userInformation.account_settings.config_data !== null
    ) {
      if (
        stateStorage.userInformation.account_settings.config_data
          .config_data_administrator !== undefined &&
        stateStorage.userInformation.account_settings.config_data
          .config_data_administrator !== null
      ) {
        const PromoterPortalFeatureSetting =
          stateStorage.userInformation.account_settings.config_data
            .config_data_administrator.dashboard_feature_settings;

        const PromoterPortalExtracted = PromoterPortalFeatureSetting.split(",");

        if (
          PromoterPortalExtracted.find((user) =>
            user.includes("promoter_portal")
          ) === "promoter_portal=1"
        ) {
          PromoterPortalSelectable = true;
        }
      }
    }
  }

  useEffect(() => {
    if (isOutletAllowed === false) {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Main outlet
  // ######################################################

  // eslint-disable-next-line
  return useMemo(() => {
    return isOutletAllowed ? (
      <Page title="Promoter Protal">
        <Container maxWidth="false">
          <Grid container spacing={3}>
            {widgetManagePromotionClientOpen === true ? (
              <Grid item xs={12} md={12} lg={12}>
                {routeParams.dashboard_id !== undefined ? (
                  <WidgetManagePromotionClient
                    category="Promotion Client Manager"
                    title={`Selected User ID (UID): ${routeParams.dashboard_id}`}
                    subheader="Use the widgets below to manage your promotion client. Each modification on this page will be visible to your client in his dashboard."
                    dashboardId={routeParams.dashboard_id}
                    promoter={promoter}
                  />
                ) : null}
              </Grid>
            ) : (
              <>
                {content !== undefined ? (
                  <Grid item xs={12} md={12} lg={12}>
                    {PromoterPortalSelectable === true ? (
                      <WidgetPromoterSelector
                        promoter={promoter}
                        onChange={(event) => {
                          setPromoter(event.target.value);

                          const newLocalSettingStorage = JSON.parse(
                            localStorage.getItem("myLocalSettingStorage")
                          );

                          newLocalSettingStorage.obfscaf9a3348bfafbb0 =
                            event.target.value;
                          localStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                        loading={loading}
                        content={content}
                        error={error}
                        warning={warning}
                        success={success}
                        returnMessage={returnMessage}
                      />
                    ) : (
                      // eslint-disable-next-line
                      <>
                        {stateStorage.userInformation.account_settings
                          .config_data.config_data_promoter
                          .promoter_forename === undefined ? (
                          <WidgetPromoterWelcome
                            title="Hi there!"
                            subheader="Use this page to manage your promotion clients!"
                          />
                        ) : (
                          <WidgetPromoterWelcome
                            title={`Hello, ${stateStorage.userInformation.account_settings.config_data.config_data_promoter.promoter_forename}!`}
                            subheader="Use this page to manage your promotion clients!"
                          />
                        )}
                      </>
                    )}
                  </Grid>
                ) : null}

                <Grid item xs={12} md={12} lg={12}>
                  {promoter !== undefined ? (
                    <WidgetPendingPromotionClients
                      promoter={promoter}
                      loadingWidgetPendingPromotionClients={
                        loadingWidgetPendingPromotionClients
                      }
                      setLoadingWidgetPendingPromotionClients={
                        setLoadingWidgetPendingPromotionClients
                      }
                    />
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <div id="promotion-clients-scroll">
                    <WidgetPromotionClients
                      promoter={promoter}
                      loadingWidgetPromotionClients={
                        loadingWidgetPromotionClients
                      }
                      setLoadingWidgetPromotionClients={
                        setLoadingWidgetPromotionClients
                      }
                      mobileBackOpen={
                        stateStorage.widgetPerformBackScrollPromoterPortalManagePromotionClient
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <WidgetClientAndClickContingent
                    promoter={promoter}
                    loadingWidgetClientAndClickContingent={
                      loadingWidgetClientAndClickContingent
                    }
                    setLoadingWidgetClientAndClickContingent={
                      setLoadingWidgetClientAndClickContingent
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <WidgetBrokerLimitations
                    promoter={promoter}
                    loadingWidgetBrokerLimitations={
                      loadingWidgetBrokerLimitations
                    }
                    setLoadingWidgetBrokerLimitations={
                      setLoadingWidgetBrokerLimitations
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <WidgetApplicationLimitations
                    promoter={promoter}
                    loadingWidgetApplicationLimitations={
                      loadingWidgetApplicationLimitations
                    }
                    setLoadingWidgetApplicationLimitations={
                      setLoadingWidgetApplicationLimitations
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Page>
    ) : null;
  });
}
