// react and js
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import PropTypes from "prop-types";
import { initializePaddle } from "@paddle/paddle-js";

// mui
import {
  Box,
  Stack,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";
import NewPlanItemTickData from "../../../components/NewPlanItemTickData";
import { countries } from "../../../components/CountrySelect";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalActionsLoadingButton,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  paymentMethod,
  paddleCheckoutOpen,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");
    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            if (paymentMethod.toLowerCase() === "paypal") {
              setSuccess(true);
              combineReturnMessage(contentFromBackend, setReturnMessage);
              postRequestUpdate(750);

              const h = 720;
              const w = 800;

              const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
              const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
              const openedWindow = window.open(
                contentFromBackend.approval_url,
                "checkout",
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
              );
              if (openedWindow !== null) {
                openedWindow.focus();
              }
            } else if (paymentMethod.toLowerCase() === "stripe") {
              setSuccess(true);
              combineReturnMessage(contentFromBackend, setReturnMessage);
              postRequestUpdate(750);

              if (contentFromBackend.approval_url !== "none") {
                const h = 720;
                const w = 1000;

                const y =
                  window.top.outerHeight / 2 + window.top.screenY - h / 2;
                const x =
                  window.top.outerWidth / 2 + window.top.screenX - w / 2;
                const openedWindow = window.open(
                  contentFromBackend.approval_url,
                  "checkout",
                  `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
                );
                if (openedWindow !== null) {
                  openedWindow.focus();
                }
              }
            } else if (paymentMethod.toLowerCase() === "paddle") {
              if (contentFromBackend.approval_url !== "none") {
                combineReturnMessage(contentFromBackend, setReturnMessage);
                postRequestUpdate(250);

                paddleCheckoutOpen(
                  contentFromBackend.paddle_customer_id,
                  contentFromBackend.paddle_address_id,
                  contentFromBackend.paddle_price_id,
                  contentFromBackend.paddle_discount_id
                );
              } else {
                setSuccess(true);
                combineReturnMessage(contentFromBackend, setReturnMessage);
                postRequestUpdate(750);
              }
            }
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

function PaddleCustomerSync(
  dashboardAccessToken,
  dashboardId,
  pspPaddleAddressId,
  paymentInfoPostalCode,
  paymentInfoCountry
) {
  const sendData = {
    request_type: "dashboard",
    route_info: "subscriptions",
    operation: "paddle_customer_sync",
    authentication: {
      dashboard_access_token: dashboardAccessToken,
      dashboard_id: dashboardId,
    },
    payment_info: {
      psp_paddle_address_id: pspPaddleAddressId,
      postal_code: paymentInfoPostalCode,
      country: paymentInfoCountry,
    },
  };

  backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData);
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleSubscriptionsReviseMt5TickDataSubscription({
  distributionPlans,
  currentPlanId,
  subscriptionId,
  categories,
  prorataDiscount,
  reviseInformation,
  paymentMethod,
  currentEntirePlan,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleSubscriptionsReviseMt5TickDataSubscription.propTypes = {
    distributionPlans: PropTypes.any.isRequired,
    currentPlanId: PropTypes.any.isRequired,
    subscriptionId: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    prorataDiscount: PropTypes.any.isRequired,
    reviseInformation: PropTypes.any.isRequired,
    paymentMethod: PropTypes.any.isRequired,
    currentEntirePlan: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateSubscriptionsSubscriptions: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let tempNewPlan = 0;
  const plans = [];
  const [planPushExemption, setPlanPushExemption] = useState(null);

  let FullCurrentPlanStructure = null;
  let FullNewPlanStructure = null;

  distributionPlans.forEach((distrubutionPlan) => {
    if (distrubutionPlan.checkout_visibility === "1") {
      plans.push({
        name: distrubutionPlan.plan_name,
        id: distrubutionPlan.plan_id,
      });
    }
    if (distrubutionPlan.plan_id === currentPlanId) {
      tempNewPlan = currentPlanId;
      FullCurrentPlanStructure = distrubutionPlan;
    }
  });

  if (planPushExemption !== null) {
    plans.push({
      name: planPushExemption.plan_name,
      id: planPushExemption.plan_id,
    });
  }

  if (tempNewPlan === 0) {
    tempNewPlan = plans[0].id;
  }

  const [formNewPlan, setFormNewPlan] = useState(tempNewPlan);

  distributionPlans.forEach((distrubutionPlan) => {
    if (distrubutionPlan.plan_id === formNewPlan) {
      FullNewPlanStructure = distrubutionPlan;
    }
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({});

  const dashboardId =
    stateStorage.userInformation.account_settings.config_data.config_data_client
      .dashboard_id;

  const [paddle, setPaddle] = useState(null);
  const [paddleClose, setPaddleClose] = useState(false);

  // Action mode
  let myLocalAction = sessionStorage.getItem("myLocalAction");
  if (myLocalAction != null) {
    myLocalAction = JSON.parse(myLocalAction);
    if (
      myLocalAction.actionType === "revise_mt5_tick_data_subscription" &&
      myLocalAction.distributionLinkingToken === subscriptionId
    ) {
      // Check if action is required
      setOpen(true);
      sessionStorage.removeItem("myLocalAction");

      distributionPlans.forEach((distrubutionPlan) => {
        if (distrubutionPlan.plan_id === myLocalAction.newPlanId) {
          setFormNewPlan(myLocalAction.newPlanId);
          if (distrubutionPlan.checkout_visibility === "0") {
            setPlanPushExemption(distrubutionPlan);
          }
        }
      });
    }
  }

  const postPaddleSyncUpdate = (syncData) => {
    const newUserInformation = JSON.parse(
      localStorage.getItem("myLocalStateStorage")
    );

    // Update locally stored paddle address id, updated postal code and country
    newUserInformation.account_settings.config_data.config_data_client.psp_paddle_address_id =
      syncData.psp_paddle_address_id;
    newUserInformation.account_settings.config_data.config_data_client.postal_code =
      syncData.postal_code;
    newUserInformation.account_settings.config_data.config_data_client.country =
      syncData.country;

    localStorage.setItem(
      "myLocalStateStorage",
      JSON.stringify(newUserInformation)
    );

    setStateStorage((previousState) => ({
      ...previousState,
      userInformation: newUserInformation,
    }));
  };

  // Event callback for paddle checkout
  const paddleEventCallback = (EventData) => {
    // checkout.customer.created OR checkout.customer.updated -> Update paddle data if address id is not null
    if (EventData.name === "checkout.customer.updated") {
      // Sync paddle customer
      if (EventData.data.customer.address !== null) {
        // Read and convert country and postal from paddle data chunk and sync with checkout
        let countryLabel = null;
        countries.forEach((countryIterate) => {
          if (
            countryIterate.code === EventData.data.customer.address.country_code
          ) {
            countryLabel = countryIterate.label;
          }
        });

        PaddleCustomerSync(
          EventData.data.custom_data.dashboard_access_token,
          EventData.data.custom_data.dashboard_id,
          EventData.data.customer.address.id,
          EventData.data.customer.address.postal_code,
          countryLabel
        );

        const syncData = {
          dashboard_access_token:
            EventData.data.custom_data.dashboard_access_token,
          dashboard_id: EventData.data.custom_data.dashboard_id,
          psp_paddle_address_id: EventData.data.customer.address.id,
          postal_code: EventData.data.customer.address.postal_code,
          country: countryLabel,
        };
        postPaddleSyncUpdate(syncData);
      }
    }

    if (EventData.name === "checkout.completed") {
      // Close paddle checkout dialogue
      setPaddleClose(true);
    }
  };

  // Download and initialize Paddle instance from CDN
  if (stateStorage.userInformation !== "" && paddle === null) {
    if (
      stateStorage.userInformation.account_settings.retailer.psp_mode ===
      "sandbox"
    ) {
      initializePaddle({
        environment: "sandbox",
        token:
          stateStorage.userInformation.account_settings.retailer
            .paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }

    if (
      stateStorage.userInformation.account_settings.retailer.psp_mode === "live"
    ) {
      initializePaddle({
        environment: "production",
        token:
          stateStorage.userInformation.account_settings.retailer
            .paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }
  }

  // Method to open paddle checkout
  function paddleCheckoutOpen(
    paddleCustomerId,
    paddleAddressId,
    paddlePriceId,
    paddleDiscountId
  ) {
    paddle.Checkout.open({
      items: [{ priceId: paddlePriceId }],
      settings: {
        showAddTaxId: false,
        allowLogout: false,
      },
      customer: {
        id: paddleCustomerId,
        address: {
          id: paddleAddressId,
        },
      },
      discountId: paddleDiscountId,
      customData: {
        operation: "revise_upgrade",
        new_plan_id: formNewPlan,
        dashboard_id: dashboardId,
        distribution_linking_token: subscriptionId,
        dashboard_access_token:
          stateStorage.userInformation.answer.dashboard_access_token,
        postal_code:
          stateStorage.userInformation.account_settings.config_data
            .config_data_client.postal_code,
        country:
          stateStorage.userInformation.account_settings.config_data
            .config_data_client.country,
      },
    });
  }

  // Method to close paddle checkout
  if (paddleClose === true) {
    paddle.Checkout.close();
  }

  // Determine if it's the same plan, and upgrade or downgrade
  let reviseAction = "unchanged";
  let isStopPossible = false;

  if (
    parseFloat(FullNewPlanStructure.plan_fixed_price_value) >
    parseFloat(FullCurrentPlanStructure.plan_fixed_price_value)
  ) {
    reviseAction = "upgrade";
  } else if (
    parseFloat(FullNewPlanStructure.plan_fixed_price_value) <
    parseFloat(FullCurrentPlanStructure.plan_fixed_price_value)
  ) {
    reviseAction = "downgrade";
  } else {
    FullCurrentPlanStructure.plan_fixed_price_value =
      currentEntirePlan.fee.replace(/[^0-9.-]+/g, "");
  }

  if (FullCurrentPlanStructure.plan_id === formNewPlan) {
    isStopPossible = true;
  }

  const formik = useFormik({
    initialValues: {},
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      if (
        reviseAction === "downgrade" ||
        FullCurrentPlanStructure.plan_id === formNewPlan
      ) {
        // Downgrade actions
        // Use same logic to stop plan changes
        const SubmitRequestContent = {
          request_type: "dashboard",
          route_info: "subscriptions",
          operation: "revise_mt5_tick_data_subscription",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          permission: {
            new_plan_id: formNewPlan,
            return_url: `${process.env.REACT_APP_URL_API_EVENT_LISTENER}?request_type=revise_downgrade&new_plan_id=${formNewPlan}&dashboard_id=${dashboardId}&distribution_linking_token=${subscriptionId}`,
            cancel_url: `${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/subscriptions`,
          },
        };

        RequestBackendAction(
          setSubmitting,
          setError,
          setSuccess,
          setWarning,
          setReturnMessage,
          postRequestUpdate,
          paymentMethod,
          paddleCheckoutOpen,
          SubmitRequestContent
        );
      } else if (reviseAction === "upgrade" && isStopPossible === false) {
        // Upgrade actions
        const SubmitRequestContent = {
          request_type: "dashboard",
          route_info: "subscriptions",
          operation: "revise_mt5_tick_data_subscription",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          permission: {
            new_plan_id: formNewPlan,
            return_url: `${process.env.REACT_APP_URL_API_EVENT_LISTENER}?request_type=revise_upgrade&new_plan_id=${formNewPlan}&dashboard_id=${dashboardId}&distribution_linking_token=${subscriptionId}`,
            cancel_url: `${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/subscriptions`,
          },
        };

        RequestBackendAction(
          setSubmitting,
          setError,
          setSuccess,
          setWarning,
          setReturnMessage,
          postRequestUpdate,
          paymentMethod,
          paddleCheckoutOpen,
          SubmitRequestContent
        );
      } else if (reviseAction === "unchanged") {
        // Do nothing
        setSubmitting(false);
      }
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  let discountShown = "";
  if (paymentMethod.toLowerCase() === "paddle") {
    discountShown = `${(
      (parseFloat(prorataDiscount) /
        parseFloat(FullNewPlanStructure.plan_fixed_price_value)) *
      100
    ).toFixed(1)} % net price discount`;
  } else {
    discountShown = `${prorataDiscount} discount`;
  }

  return plans.length > 1 ? (
    <>
      <B3Tiny
        fullWidth
        variant="outlined"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 1 }}
      >
        Change tick data plan
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Change tick data plan
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 1 }}>
                  <Typography variant="contentMiddle">
                    Use this form to downgrade or upgrade your tick data
                    subscription plan. You will then be redirected to our
                    payment service partner to approve the plan change.
                  </Typography>

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        New tick data plan
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formNewPlan}
                          onChange={(event) => {
                            setFormNewPlan(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          {plans.map((currentPlan, index) => (
                            <ModalContentMenuItem
                              key={index}
                              value={currentPlan.id}
                            >
                              {currentPlan.name}
                            </ModalContentMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        New tick data plan
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formNewPlan}
                          onChange={(event) => {
                            setFormNewPlan(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          {plans.map((currentPlan, index) => (
                            <option key={index} value={currentPlan.id}>
                              {currentPlan.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ pt: 1 }}>
                    <NewPlanItemTickData
                      planInformation={FullNewPlanStructure}
                      categories={categories}
                      sponsored={Boolean(false)}
                      reviseAction={reviseAction}
                      isStopPossible={isStopPossible}
                    />
                  </Box>

                  {reviseAction === "downgrade" ? (
                    <Typography variant="contentSmall">
                      <sup>*</sup> The plan change will take effect at the start
                      of the next billing period.
                    </Typography>
                  ) : null}

                  {reviseAction === "upgrade" && isStopPossible === false ? (
                    <Typography variant="contentSmall">
                      <sup>*</sup> {discountShown} on the first payment due to
                      an already paid cycle. The upgrade will initiate a new
                      billing cycle starting from today.
                    </Typography>
                  ) : null}
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                {reviseInformation === "none" ? (
                  <ModalActionsLoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={
                      reviseAction === "unchanged"
                        ? Boolean(true)
                        : Boolean(false)
                    }
                  >
                    {reviseAction === "downgrade" ? "Downgrade" : null}
                    {reviseAction === "unchanged" ? "Current plan" : null}
                    {reviseAction === "upgrade" ? "Upgrade" : null}
                  </ModalActionsLoadingButton>
                ) : (
                  <ModalActionsLoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={
                      reviseInformation.revise_plan_id === formNewPlan
                        ? Boolean(true)
                        : Boolean(false)
                    }
                  >
                    {reviseAction === "downgrade" &&
                    reviseInformation.revise_plan_id !== formNewPlan
                      ? "Downgrade"
                      : null}
                    {reviseAction === "downgrade" &&
                    reviseInformation.revise_plan_id === formNewPlan
                      ? "Pending plan change"
                      : null}

                    {isStopPossible === true
                      ? "Stop pending plan change"
                      : null}

                    {reviseAction === "upgrade" &&
                    reviseInformation.revise_plan_id !== formNewPlan
                      ? "Upgrade"
                      : null}
                    {reviseAction === "upgrade" &&
                    reviseInformation.revise_plan_id === formNewPlan
                      ? "Pending plan change"
                      : null}
                  </ModalActionsLoadingButton>
                )}
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  ) : null;
}
