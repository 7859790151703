// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import { Dialog } from "@mui/material";

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function ModalDialog({ outlet, open, handleClose, scroll }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  ModalDialog.propTypes = {
    outlet: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    handleClose: PropTypes.any.isRequired,
    scroll: PropTypes.any.isRequired,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            margin: 0,
            maxHeight: {
              xs: "calc(100% - 20px)",
              sm: "calc(100% - 40px)",
              md: "calc(100% - 60px)",
              lg: "calc(100% - 60px)",
              xl: "calc(100% - 60px)",
            },
            minWidth: {
              xs: "calc(100% - 20px)",
              sm: "calc(100% - 40px)",
              md: "calc(60vw)",
              lg: "calc(49vw)",
              xl: "calc(40vw)",
            },
            maxWidth: {
              xs: "calc(100% - 20px)",
              sm: "calc(100% - 40px)",
              md: "calc(60vw)",
              lg: "calc(49vw)",
              xl: "calc(40vw)",
            },
          },
          "& .MuiAlert-root": {
            margin: 1,
            width: "unset",
            height: "unset",
            minHeight: "unset",
            minWidth: "unset",
            maxHeight: "unset",
            maxWidth: "unset",
          },
        },
      }}
    >
      {outlet}
    </Dialog>
  );
}

export default ModalDialog;
