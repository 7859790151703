// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect } from "react";

// mui
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, Stack, Collapse, Link } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

// components
import ModalDialogInternalNotes from "./ModalDialogInternalNotes";
//
import HandlePromoterPortalRemoveTradingAccount from "../widgets/promoter_portal/actions/HandlePromoterPortalRemoveTradingAccount";
import HandlePromoterPortalModifyPermissionLimitations from "../widgets/promoter_portal/actions/HandlePromoterPortalModifyPermissionLimitations";
//
import HandleSubscriptionsUpdatePaymentMethod from "../widgets/subscriptions/actions/HandleSubscriptionsUpdatePaymentMethod";
import HandleSubscriptionsAddNewTradingAccount from "../widgets/subscriptions/actions/HandleSubscriptionsAddNewTradingAccount";
import HandleSubscriptionsRemoveTradingAccount from "../widgets/subscriptions/actions/HandleSubscriptionsRemoveTradingAccount";
import HandleSubscriptionsReviseSubscription from "../widgets/subscriptions/actions/HandleSubscriptionsReviseSubscription";
import HandleSubscriptionsReactivateSubscription from "../widgets/subscriptions/actions/HandleSubscriptionsReactivateSubscription";
import HandleSubscriptionsCancelSubscription from "../widgets/subscriptions/actions/HandleSubscriptionsCancelSubscription";
import HandleSubscriptionsReviseMt5TickDataSubscription from "../widgets/subscriptions/actions/HandleSubscriptionsReviseMt5TickDataSubscription";
import HandleSubscriptionsReactivateMt5TickDataSubscription from "../widgets/subscriptions/actions/HandleSubscriptionsReactivateMt5TickDataSubscription";
import HandleSubscriptionsCancelMt5TickDataSubscription from "../widgets/subscriptions/actions/HandleSubscriptionsCancelMt5TickDataSubscription";
//
import HandleDistributorPortalReactivateSubscription from "../widgets/distributor_portal/actions/HandleDistributorPortalReactivateSubscription";
import HandleDistributorPortalReviseSubscription from "../widgets/distributor_portal/actions/HandleDistributorPortalReviseSubscription";
import HandleDistributorPortalCancelSubscription from "../widgets/distributor_portal/actions/HandleDistributorPortalCancelSubscription";
import HandleDistributorPortalAddNewTradingAccount from "../widgets/distributor_portal/actions/HandleDistributorPortalAddNewTradingAccount";
import HandleDistributorPortalRemoveTradingAccount from "../widgets/distributor_portal/actions/HandleDistributorPortalRemoveTradingAccount";
import HandleDistributorPortalModifyPlanLimitations from "../widgets/distributor_portal/actions/HandleDistributorPortalModifyPlanLimitations";
import HandleDistributorPortalModifyPermissionLimitations from "../widgets/distributor_portal/actions/HandleDistributorPortalModifyPermissionLimitations";
//
import HandleDistributorPortalReactivateMt5TickDataSubscription from "../widgets/distributor_portal/actions/HandleDistributorPortalReactivateMt5TickDataSubscription";
import HandleDistributorPortalReviseMt5TickDataSubscription from "../widgets/distributor_portal/actions/HandleDistributorPortalReviseMt5TickDataSubscription";
import HandleDistributorPortalModifyMt5TickDataPlanLimitations from "../widgets/distributor_portal/actions/HandleDistributorPortalModifyMt5TickDataPlanLimitations";
import HandleDistributorPortalCancelMt5TickDataSubscription from "../widgets/distributor_portal/actions/HandleDistributorPortalCancelMt5TickDataSubscription";

// hooks

// layouts

// pages

// theme
import typography from "../theme/typography";
import { B3Tiny, ModalAlertWarning, ModalAlertError } from "../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
  },
});

function ValueLine({ parameter, value, tooltipLeft, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    tooltipLeft: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        {tooltipLeft !== undefined ? (
          <CustomWidthTooltip
            title={tooltipLeft}
            followCursor
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            enterDelay={200}
            leaveDelay={0}
          >
            <Typography variant="contentTiny" align="left">
              {parameter}
            </Typography>
          </CustomWidthTooltip>
        ) : (
          <Typography variant="contentTiny" align="left">
            {parameter}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

export function SubscriptionItem({
  mode,
  content,
  promoter,
  distributor,
  promoterLinkId,
  dashboardId,
  distributionPlansApplication,
  distributionPlansTickData,
  KnownAccountServer,
  managed,
  referenceTradingCurrency,
  categories,
}) {
  SubscriptionItem.propTypes = {
    mode: PropTypes.any,
    content: PropTypes.any,
    promoter: PropTypes.any,
    distributor: PropTypes.any,
    promoterLinkId: PropTypes.any,
    dashboardId: PropTypes.any,
    distributionPlansApplication: PropTypes.any,
    distributionPlansTickData: PropTypes.any,
    KnownAccountServer: PropTypes.any,
    managed: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any,
    categories: PropTypes.any,
  };

  let paymentTenure = "";

  if (content.plan.plan_interval_unit === "DAY") {
    paymentTenure = "daily";
  } else if (content.plan.plan_interval_unit === "WEEK") {
    paymentTenure = "weekly";
  } else if (content.plan.plan_interval_unit === "MONTH") {
    paymentTenure = "monthly";
  } else if (content.plan.plan_interval_unit === "YEAR") {
    paymentTenure = "yearly";
  }

  let contingentAvailable = false;
  let LiveAdable = false;
  let DemoAdable = false;

  if (content.plan.live_included_accounts !== "unlimited") {
    if (
      Number(content.plan.live_current_accounts) <
      Number(content.plan.live_included_accounts)
    ) {
      if (content.plan.live_included_account_changes !== "unlimited") {
        if (
          Number(content.plan.live_current_account_changes) <
          Number(content.plan.live_included_account_changes)
        ) {
          contingentAvailable = true;
          LiveAdable = true;
        }
      } else {
        contingentAvailable = true;
        LiveAdable = true;
      }
    }
  } else if (content.plan.live_included_accounts === "unlimited") {
    if (content.plan.live_included_account_changes !== "unlimited") {
      if (
        Number(content.plan.live_current_account_changes) <
        Number(content.plan.live_included_account_changes)
      ) {
        contingentAvailable = true;
        LiveAdable = true;
      }
    } else {
      contingentAvailable = true;
      LiveAdable = true;
    }
  }

  if (content.plan.demo_included_accounts !== "unlimited") {
    if (
      Number(content.plan.demo_current_accounts) <
      Number(content.plan.demo_included_accounts)
    ) {
      if (content.plan.demo_included_account_changes !== "unlimited") {
        if (
          Number(content.plan.demo_current_account_changes) <
          Number(content.plan.demo_included_account_changes)
        ) {
          contingentAvailable = true;
          DemoAdable = true;
        }
      } else {
        contingentAvailable = true;
        DemoAdable = true;
      }
    }
  } else if (content.plan.demo_included_accounts === "unlimited") {
    if (content.plan.demo_included_account_changes !== "unlimited") {
      if (
        Number(content.plan.demo_current_account_changes) <
        Number(content.plan.demo_included_account_changes)
      ) {
        contingentAvailable = true;
        DemoAdable = true;
      }
    } else {
      contingentAvailable = true;
      DemoAdable = true;
    }
  }

  if (
    content.plan.live_included_accounts === "unlimited" &&
    content.plan.live_included_account_changes === "unlimited" &&
    content.plan.demo_included_accounts === "unlimited" &&
    content.plan.demo_included_account_changes === "unlimited"
  ) {
    contingentAvailable = true;
  }

  let paymentState = "";
  if (content.plan.payment_state === "distribution_active") {
    paymentState = "active";
  } else if (content.plan.payment_state === "distribution_approval_pending") {
    paymentState = "payment processing";
  } else if (content.plan.payment_state === "distribution_inactive") {
    paymentState = "cancelled";
  } else if (content.plan.payment_state === "promotion_active") {
    if (content.product.time_left < 0) {
      paymentState = "expired";
    } else if (content.product.permission_note === "ending_soon") {
      paymentState = "almost expired";
    } else {
      paymentState = "active";
    }
  } else if (content.plan.payment_state === "promotion_approval_pending") {
    paymentState = "approval pending";
  } else if (content.plan.payment_state === "promotion_inactive") {
    paymentState = "inactive";
  }

  const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
  const handleClosePaymentHistory = () => setOpenPaymentHistory(false);

  const descriptionElementRefPaymentHistory = useRef(null);
  useEffect(() => {
    if (openPaymentHistory) {
      const { current: descriptionElementPaymentHistory } =
        descriptionElementRefPaymentHistory;
      if (descriptionElementPaymentHistory !== null) {
        descriptionElementPaymentHistory.focus();
      }
    }
  }, [openPaymentHistory]);
  const [scrollPaymentHistory, setScrollPaymentHistory] = useState("paper");

  const handleClickOpenPaymentHistory = (scrollType) => () => {
    setOpenPaymentHistory(true);
    setScrollPaymentHistory(scrollType);
  };
  const handleClickClosePaymentHistory = () => {
    setOpenPaymentHistory(false);
  };

  return (
    <Stack>
      <Box
        sx={{ pb: "10px", px: 2, mb: 2, backgroundColor: "#F2F2F2" }}
        display="block"
        align="center"
      >
        <Typography
          variant="h5"
          display="block"
          align="center"
          sx={{ mt: "14px" }}
        >
          <Link
            variant="h5"
            underline="none"
            color="inherit"
            target="_blank"
            href={
              content.plan.payment_method !== "promoted by partner"
                ? content.product.home_url
                : `${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution?product_tag=${content.product.product_tag}`
            }
          >
            {content.product.product_name}
          </Link>
        </Typography>

        <Typography variant="contentMiddle">
          {content.plan.plan_description}
        </Typography>
      </Box>

      {}
      <Box sx={{ px: 2, pb: 0 }}>
        {content.plan.payment_state === "distribution_inactive" ? (
          <ModalAlertError
            severity="error"
            sx={{
              mb: 1,
              lineHeight: 1.5,
              fontSize: typography.contentSmall.fontSize,
            }}
          >
            This subscription is cancelled. By the end of the current billing
            cycle it will be deactivated!
          </ModalAlertError>
        ) : null}

        {content.plan.psp_status === "past_due" ? (
          <ModalAlertError
            severity="error"
            sx={{
              mb: 1,
              lineHeight: 1.5,
              fontSize: typography.contentSmall.fontSize,
            }}
          >
            We couldn’t process your recent payment. Please check your payment
            method to prevent possible subscription cancellation. The next
            payment attempt will occur in a few days. If the attempt is
            successful, this warning will disappear.
          </ModalAlertError>
        ) : null}

        {content.plan.revise_information !== "none" ? (
          <ModalAlertWarning
            severity="warning"
            sx={{
              mb: 1,
              lineHeight: 1.5,
              fontSize: typography.contentSmall.fontSize,
            }}
          >
            By the end of current billing cycle this plan will change to
            <Typography variant="contentSmallBold">
              {` ${content.plan.revise_information.revise_plan_description.toLowerCase()}`}
            </Typography>
            . To stop change, please use plan change dialogue again!
          </ModalAlertWarning>
        ) : null}

        {mode === "distribution_application" &&
        distributor === undefined &&
        content.accounts.length !== 0 ? (
          <>
            {contingentAvailable ? (
              <HandleSubscriptionsAddNewTradingAccount
                SelectedPermissionId={content.accounts[0].permission_id}
                KnownAccountServer={KnownAccountServer}
                LiveAdable={LiveAdable}
                DemoAdable={DemoAdable}
              />
            ) : null}

            {content.plan.payment_state === "distribution_active" ? (
              <>
                {content.plan.payment_method !== "sponsored" ? (
                  <HandleSubscriptionsUpdatePaymentMethod
                    subscriptionId=""
                    distributionLinkingToken={
                      content.plan.distribution_linking_token
                    }
                    paymentDetails={
                      content.plan.payment_details
                    }
                  />
                ) : null}

                {content.plan.payment_method !== "sponsored" &&
                content.plan.psp_status !== "past_due" ? (
                  <HandleSubscriptionsReviseSubscription
                    SelectedPermissionId={content.accounts[0].permission_id}
                    distributionPlans={distributionPlansApplication}
                    currentPlanId={content.plan.plan_id}
                    distributionLinkingToken={
                      content.plan.distribution_linking_token
                    }
                    referenceTradingCurrency={referenceTradingCurrency}
                    prorataDiscount={content.plan.prorata_discount_upgrade}
                    reviseInformation={content.plan.revise_information}
                    paymentMethod={content.plan.payment_method}
                    currentEntirePlan={content.plan}
                    currentEntirePermission={content.accounts[0]}
                  />
                ) : null}

                <HandleSubscriptionsCancelSubscription
                  SelectedPermissionId={content.accounts[0].permission_id}
                />
              </>
            ) : (
              // eslint-disable-next-line
              <>
                {content.plan.payment_method !== "sponsored" &&
                content.plan.payment_state === "distribution_inactive" ? (
                  <HandleSubscriptionsReactivateSubscription
                    SelectedPermissionId={content.accounts[0].permission_id}
                    distributionPlans={distributionPlansApplication}
                    currentPlanId={content.plan.plan_id}
                    distributionLinkingToken={
                      content.plan.distribution_linking_token
                    }
                    referenceTradingCurrency={referenceTradingCurrency}
                    prorataDiscount={content.plan.prorata_discount_reactivate}
                    paymentMethod={content.plan.payment_method}
                  />
                ) : null}
              </>
            )}
          </>
        ) : null}

        {mode === "distribution_application" &&
        distributor !== undefined &&
        content.accounts.length !== 0 ? (
          <>
            {contingentAvailable ? (
              <HandleDistributorPortalAddNewTradingAccount
                distributor={distributor}
                permissionId={content.accounts[0].permission_id}
                KnownAccountServer={KnownAccountServer}
                LiveAdable={LiveAdable}
                DemoAdable={DemoAdable}
              />
            ) : null}

            {content.plan.payment_state === "distribution_active" ? (
              <>
                {content.plan.psp_status !== "past_due" ? (
                  <HandleDistributorPortalReviseSubscription
                    distributor={distributor}
                    permissionId={content.accounts[0].permission_id}
                    distributionPlans={distributionPlansApplication}
                    currentPlanId={content.plan.plan_id}
                    currentPlanProp={content.plan}
                    referenceTradingCurrency={referenceTradingCurrency}
                    sponsored={
                      content.plan.payment_method === "sponsored"
                        ? Boolean(true)
                        : Boolean(false)
                    }
                    prorataDiscount={content.plan.prorata_discount_upgrade}
                    reviseInformation={content.plan.revise_information}
                    paymentMethod={content.plan.payment_method}
                    currentEntirePlan={content.plan}
                    currentEntirePermission={content.accounts[0]}
                  />
                ) : null}
                <HandleDistributorPortalCancelSubscription
                  distributor={distributor}
                  permissionId={content.accounts[0].permission_id}
                />
              </>
            ) : (
              // eslint-disable-next-line
              <>
                {content.plan.payment_method !== "sponsored" &&
                content.plan.payment_state === "distribution_inactive" ? (
                  <HandleDistributorPortalReactivateSubscription
                    distributor={distributor}
                    permissionId={content.accounts[0].permission_id}
                    distributionPlans={distributionPlansApplication}
                    currentPlanId={content.plan.plan_id}
                    currentPlanProp={content.plan}
                    referenceTradingCurrency={referenceTradingCurrency}
                    sponsored={
                      content.plan.payment_method === "sponsored"
                        ? Boolean(true)
                        : Boolean(false)
                    }
                    prorataDiscount={content.plan.prorata_discount_reactivate}
                    paymentMethod={content.plan.payment_method}
                  />
                ) : null}
              </>
            )}

            <HandleDistributorPortalModifyPlanLimitations
              distributor={distributor}
              permissionId={content.accounts[0].permission_id}
              plan={content.plan}
            />
          </>
        ) : null}

        {mode === "distribution_tick_data" &&
        distributor === undefined &&
        content.accounts.length === 0 ? (
          // eslint-disable-next-line
          <>
            {content.plan.payment_state === "distribution_active" ? (
              <>
                {content.plan.payment_method !== "sponsored" ? (
                  <HandleSubscriptionsUpdatePaymentMethod
                    subscriptionId={content.plan.distribution_linking_token}
                    distributionLinkingToken=""
                    paymentDetails={
                      content.plan.payment_details
                    }
                  />
                ) : null}

                {content.plan.payment_method !== "sponsored" &&
                content.plan.psp_status !== "past_due" ? (
                  <HandleSubscriptionsReviseMt5TickDataSubscription
                    distributionPlans={distributionPlansTickData}
                    currentPlanId={content.plan.plan_id}
                    subscriptionId={content.plan.distribution_linking_token}
                    categories={categories}
                    prorataDiscount={content.plan.prorata_discount_upgrade}
                    reviseInformation={content.plan.revise_information}
                    paymentMethod={content.plan.payment_method}
                    currentEntirePlan={content.plan}
                  />
                ) : null}
                <HandleSubscriptionsCancelMt5TickDataSubscription
                  distributionPlans={distributionPlansTickData}
                  currentPlanId={content.plan.plan_id}
                  subscriptionId={content.plan.distribution_linking_token}
                />
              </>
            ) : (
              // eslint-disable-next-line
              <>
                {content.plan.payment_method !== "sponsored" &&
                content.plan.payment_state === "distribution_inactive" ? (
                  <HandleSubscriptionsReactivateMt5TickDataSubscription
                    distributionPlans={distributionPlansTickData}
                    currentPlanId={content.plan.plan_id}
                    subscriptionId={content.plan.distribution_linking_token}
                    categories={categories}
                    prorataDiscount={content.plan.prorata_discount_reactivate}
                    paymentMethod={content.plan.payment_method}
                  />
                ) : null}
              </>
            )}
          </>
        ) : null}

        {mode === "distribution_tick_data" &&
        distributor !== undefined &&
        content.accounts.length === 0 &&
        content.plan.payment_state === "distribution_active" ? (
          <>
            {content.plan.psp_status !== "past_due" ? (
              <HandleDistributorPortalReviseMt5TickDataSubscription
                distributor={distributor}
                dashboardId={content.dashboard_data.dashboard_id}
                distributionPlans={
                  content.distribution_mt5_tick_data_plans
                    .distribution_mt5_tick_data_plans
                }
                currentPlanId={content.dashboard_data.tick_data_plan_id}
                currentPlanProp={content.plan}
                categories={categories}
                sponsored={
                  content.plan.payment_method === "sponsored"
                    ? Boolean(true)
                    : Boolean(false)
                }
                prorataDiscount={content.plan.prorata_discount_upgrade}
                reviseInformation={content.plan.revise_information}
                paymentMethod={content.plan.payment_method}
                currentEntirePlan={content.plan}
              />
            ) : null}

            <HandleDistributorPortalCancelMt5TickDataSubscription
              distributor={distributor}
              dashboardId={content.dashboard_data.dashboard_id}
            />

            {content.plan.payment_history !== null ? (
              <>
                <B3Tiny
                  fullWidth
                  variant="outlined"
                  onClick={handleClickOpenPaymentHistory("paper")}
                  sx={{ mt: 0, mb: 1 }}
                >
                  Payment history
                </B3Tiny>

                <ModalDialogInternalNotes
                  open={openPaymentHistory}
                  handleClose={handleClosePaymentHistory}
                  handleClickClose={handleClickClosePaymentHistory}
                  scroll={scrollPaymentHistory}
                  data={content.plan.payment_history}
                  notesType={`Payment history for ${content.plan.plan_name}`}
                />
              </>
            ) : null}

            {content.plan.payment_method === "sponsored" ? (
              <HandleDistributorPortalModifyMt5TickDataPlanLimitations
                distributor={distributor}
                dashboardId={content.dashboard_data.dashboard_id}
                permissionEnd={content.plan.active_until}
              />
            ) : null}
          </>
        ) : null}

        {mode === "distribution_tick_data" &&
        distributor !== undefined &&
        content.accounts.length === 0 &&
        content.plan.payment_state === "distribution_inactive" ? (
          <HandleDistributorPortalReactivateMt5TickDataSubscription
            distributor={distributor}
            dashboardId={content.dashboard_data.dashboard_id}
            distributionPlans={
              content.distribution_mt5_tick_data_plans
                .distribution_mt5_tick_data_plans
            }
            currentPlanId={content.dashboard_data.tick_data_plan_id}
            currentPlanProp={content.plan}
            categories={categories}
            sponsored={
              content.plan.payment_method === "sponsored"
                ? Boolean(true)
                : Boolean(false)
            }
            prorataDiscount={content.plan.prorata_discount_reactivate}
            paymentMethod={content.plan.payment_method}
          />
        ) : null}

        {mode === "distribution_tick_data" &&
        distributor !== undefined &&
        content.accounts.length === 0 &&
        (content.plan.payment_state === "distribution_inactive" ||
          content.plan.payment_state === "distribution_approval_pending") ? (
          <>
            {content.plan.payment_history !== null ? (
              <>
                <B3Tiny
                  fullWidth
                  variant="outlined"
                  onClick={handleClickOpenPaymentHistory("paper")}
                  sx={{ mt: 0, mb: 1 }}
                >
                  Payment history
                </B3Tiny>

                <ModalDialogInternalNotes
                  open={openPaymentHistory}
                  handleClose={handleClosePaymentHistory}
                  handleClickClose={handleClickClosePaymentHistory}
                  scroll={scrollPaymentHistory}
                  data={content.plan.payment_history}
                  notesType={`Payment history for ${content.plan.plan_name}`}
                />
              </>
            ) : null}

            {content.plan.payment_method === "sponsored" ? (
              <HandleDistributorPortalModifyMt5TickDataPlanLimitations
                distributor={distributor}
                dashboardId={content.dashboard_data.dashboard_id}
                permissionEnd={content.plan.active_until}
              />
            ) : null}
          </>
        ) : null}

        {promoter !== undefined ? (
          <HandlePromoterPortalModifyPermissionLimitations
            promoter={promoter}
            promoterLinkId={promoterLinkId}
            dashboardId={dashboardId}
            productId={content.product.product_id}
            content={content}
          />
        ) : null}

        {content.plan.payment_method === "promoted by partner" &&
        content.product.permission_note === "ending_soon" ? (
          <ModalAlertWarning
            severity="warning"
            sx={{
              mb: 1,
              lineHeight: 1.5,
              fontSize: typography.contentSmall.fontSize,
            }}
          >
            Promotion period almost at its end. Discover our
            <Typography variant="contentSmallBold">
              {/* eslint-disable-next-line */}
              <Link
                href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution?product_tag=${content.product.product_tag}`}
                target="_blank"
                underline="none"
                variant="contentSmallLink"
                color="inherit"
              >
                {` regular subscriptions`}
              </Link>
            </Typography>
            !
          </ModalAlertWarning>
        ) : null}

        {content.plan.payment_method === "promoted by partner" &&
        content.product.permission_note === "expired" ? (
          <ModalAlertError
            severity="error"
            sx={{
              mb: 1,
              lineHeight: 1.5,
              fontSize: typography.contentSmall.fontSize,
            }}
          >
            Promotion period is over. Discover our
            <Typography variant="contentSmallBold">
              {/* eslint-disable-next-line */}
              <Link
                href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution?product_tag=${content.product.product_tag}`}
                target="_blank"
                underline="none"
                variant="contentSmallLink"
                color="inherit"
              >
                {` regular subscriptions`}
              </Link>
            </Typography>
            !
          </ModalAlertError>
        ) : null}

        {content.plan.payment_method === "promoted by partner" ? (
          <ValueLine
            parameter="Promotion status"
            value={paymentState}
            widthLeft="50%"
            widthRight="50%"
          />
        ) : (
          <ValueLine
            parameter="Subscription status"
            value={paymentState}
            widthLeft="50%"
            widthRight="50%"
          />
        )}

        <ValueLine
          parameter="Payment service"
          value={content.plan.payment_method}
          widthLeft="35%"
          widthRight="65%"
        />

        {content.plan.plan_interval_unit !== undefined || managed === true ? (
          // eslint-disable-next-line
          <>
            {content.plan.active_until !== null &&
            content.plan.payment_method === "sponsored" ? (
              <ValueLine
                parameter="Active until"
                value={
                  managed === true
                    ? `${content.plan.active_until} GMT`
                    : content.plan.active_until.substring(0, 10)
                }
                widthLeft="35%"
                widthRight="65%"
              />
            ) : null}
          </>
        ) : null}

        {content.plan.plan_interval_unit !== undefined || managed === true ? (
          // eslint-disable-next-line
          <>
            {content.plan.payment_method !== "sponsored" &&
            content.plan.payment_method !== "promoted by partner" ? (
              <>
                {content.plan.active_until !== null ? (
                  <ValueLine
                    parameter="Payed until"
                    value={
                      managed === true
                        ? `${content.plan.active_until} GMT`
                        : content.plan.active_until.substring(0, 10)
                    }
                    widthLeft="35%"
                    widthRight="65%"
                  />
                ) : null}

                <ValueLine
                  parameter="Payment tenure"
                  value={paymentTenure}
                  widthLeft="35%"
                  widthRight="65%"
                />
              </>
            ) : null}
          </>
        ) : null}

        <ValueLine
          parameter="Recurring fee"
          value={content.plan.fee}
          widthLeft="35%"
          widthRight="65%"
        />
      </Box>
    </Stack>
  );
}

export function LimitationsItem({ mode, content, managed }) {
  LimitationsItem.propTypes = {
    mode: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
    managed: PropTypes.any.isRequired,
  };

  let liveShown = null;
  let liveLimitsValue = "";
  //
  let liveStateLimits = false;
  let liveStateTradingAccounts = false;
  let liveStateAccountChanges = false;

  let demoShown = null;
  let demoLimitsValue = "";
  //
  let demoStateLimits = false;
  let demoStateTradingAccounts = false;
  let demoStateAccountChanges = false;

  if (mode === "promotion") {
    if (managed === true) {
      liveStateLimits = true;
      liveStateTradingAccounts = true;
      liveStateAccountChanges = true;

      demoStateLimits = true;
      demoStateTradingAccounts = true;
      demoStateAccountChanges = true;

      liveShown = 0;
      demoShown = 0;

      content.accounts.forEach((account, index) => {
        if (account.given_account_type === "live") {
          liveShown = index;
        }
        if (account.given_account_type === "demo") {
          demoShown = index;
        }
      });
    } else {
      liveStateLimits = true;
      liveStateTradingAccounts = false;
      liveStateAccountChanges = false;

      demoStateLimits = true;
      demoStateTradingAccounts = false;
      demoStateAccountChanges = false;

      content.accounts.forEach((account, index) => {
        if (account.given_account_type === "live") {
          liveShown = index;
        }
        if (account.given_account_type === "demo") {
          demoShown = index;
        }
      });
    }

    if (liveShown !== null) {
      if (content.accounts[liveShown].live_limits === "server") {
        liveLimitsValue = "remote only";
      } else if (content.accounts[liveShown].live_limits === "local") {
        liveLimitsValue = "local only";
      } else if (content.accounts[liveShown].live_limits === "local_server") {
        liveLimitsValue = "remote and local";
      }

      if (content.accounts[liveShown].permission_live_limits !== "") {
        if (content.accounts[liveShown].permission_live_limits === "server") {
          liveLimitsValue = "remote only";
        } else if (
          content.accounts[liveShown].permission_live_limits === "local"
        ) {
          liveLimitsValue = "local only";
        } else if (
          content.accounts[liveShown].permission_live_limits === "local_server"
        ) {
          liveLimitsValue = "remote and local";
        }
      }
    }
    if (demoShown !== null) {
      if (content.accounts[demoShown].demo_limits === "server") {
        demoLimitsValue = "remote only";
      } else if (content.accounts[demoShown].demo_limits === "local") {
        demoLimitsValue = "local only";
      } else if (content.accounts[demoShown].demo_limits === "local_server") {
        demoLimitsValue = "remote and local";
      }

      if (content.accounts[demoShown].permission_demo_limits !== "") {
        if (content.accounts[demoShown].permission_demo_limits === "server") {
          demoLimitsValue = "remote only";
        } else if (
          content.accounts[demoShown].permission_demo_limits === "local"
        ) {
          demoLimitsValue = "local only";
        } else if (
          content.accounts[demoShown].permission_demo_limits === "local_server"
        ) {
          demoLimitsValue = "remote and local";
        }
      }
    }
  } else {
    if (managed === true) {
      liveStateLimits = false;
      liveStateTradingAccounts = true;
      liveStateAccountChanges = true;

      demoStateLimits = false;
      demoStateTradingAccounts = true;
      demoStateAccountChanges = true;
    } else {
      liveStateLimits = false;

      if (
        content.plan.live_included_accounts !== "0" &&
        content.plan.live_included_accounts !== "unlimited"
      ) {
        liveStateTradingAccounts = true;
      }
      if (
        content.plan.live_included_account_changes !== "0" &&
        content.plan.live_included_account_changes !== "unlimited"
      ) {
        liveStateAccountChanges = true;
      }

      demoStateLimits = false;

      if (
        content.plan.demo_included_accounts !== "0" &&
        content.plan.demo_included_accounts !== "unlimited"
      ) {
        demoStateTradingAccounts = true;
      }
      if (
        content.plan.demo_included_account_changes !== "0" &&
        content.plan.demo_included_account_changes !== "unlimited"
      ) {
        demoStateAccountChanges = true;
      }
    }

    liveShown = true;
    demoShown = true;

    if (content.accounts[0].live_limits === "server") {
      liveLimitsValue = "remote only";
    } else if (content.accounts[0].live_limits === "local") {
      liveLimitsValue = "local only";
    } else if (content.accounts[0].live_limits === "local_server") {
      liveLimitsValue = "remote and local";
    }

    if (content.accounts[0].demo_limits === "server") {
      demoLimitsValue = "remote only";
    } else if (content.accounts[0].demo_limits === "local") {
      demoLimitsValue = "local only";
    } else if (content.accounts[0].demo_limits === "local_server") {
      demoLimitsValue = "remote and local";
    }
  }

  const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
  const handleClosePaymentHistory = () => setOpenPaymentHistory(false);

  const descriptionElementRefPaymentHistory = useRef(null);
  useEffect(() => {
    if (openPaymentHistory) {
      const { current: descriptionElementPaymentHistory } =
        descriptionElementRefPaymentHistory;
      if (descriptionElementPaymentHistory !== null) {
        descriptionElementPaymentHistory.focus();
      }
    }
  }, [openPaymentHistory]);
  const [scrollPaymentHistory, setScrollPaymentHistory] = useState("paper");

  const handleClickOpenPaymentHistory = (scrollType) => () => {
    setOpenPaymentHistory(true);
    setScrollPaymentHistory(scrollType);
  };
  const handleClickClosePaymentHistory = () => {
    setOpenPaymentHistory(false);
  };

  const [openPlanNotes, setOpenPlanNotes] = useState(false);
  const handleClosePlanNotes = () => setOpenPlanNotes(false);

  const descriptionElementRefPlanNotes = useRef(null);
  useEffect(() => {
    if (openPlanNotes) {
      const { current: descriptionElementPlanNotes } =
        descriptionElementRefPlanNotes;
      if (descriptionElementPlanNotes !== null) {
        descriptionElementPlanNotes.focus();
      }
    }
  }, [openPlanNotes]);
  const [scrollPlanNotes, setScrollPlanNotes] = useState("paper");

  const handleClickOpenPlanNotes = (scrollType) => () => {
    setOpenPlanNotes(true);
    setScrollPlanNotes(scrollType);
  };
  const handleClickClosePlanNotes = () => {
    setOpenPlanNotes(false);
  };

  return (
    <Stack display="block" align="center" sx={{ pb: 1 }}>
      <Box sx={{ px: 2 }}>
        {liveShown !== null ? (
          <>
            {liveStateLimits === true ? (
              <ValueLine
                parameter="Live - SL and TP options"
                value={liveLimitsValue}
                tooltipLeft="Selectable stop loss and take profit variant within application / trading module. Local means that SL and TP limits are handled locally - your broker won’t be informed about existing stop loss and take profits. Server means traditional limits handling by sending the stop loss and take profit value to the broker's server."
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {liveStateTradingAccounts === true ? (
              <ValueLine
                parameter="Live - Trading accounts"
                value={`${content.plan.live_current_accounts} / ${content.plan.live_included_accounts}`}
                tooltipLeft="Amount of in your subscription plan included live trading accounts. If the limit is reached, please consider to revise your subscription or remove unused trading accounts."
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {liveStateAccountChanges === true ? (
              <ValueLine
                parameter="Live - Account changes"
                value={`${content.plan.live_current_account_changes} / ${content.plan.live_included_account_changes}`}
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}
          </>
        ) : null}

        {demoShown !== null ? (
          <>
            {demoStateLimits === true ? (
              <ValueLine
                parameter="Demo - SL and TP options"
                value={demoLimitsValue}
                tooltipLeft="Selectable stop loss and take profit variant within application / trading module. Local means that SL and TP limits are handled locally - your broker won’t be informed about existing stop loss and take profits. Server means traditional limits handling by sending the stop loss and take profit value to the broker's server."
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {demoStateTradingAccounts === true ? (
              <ValueLine
                parameter="Demo - Trading accounts"
                value={`${content.plan.demo_current_accounts} / ${content.plan.demo_included_accounts}`}
                tooltipLeft="Amount of in your subscription plan included demo trading accounts. If the limit is reached, please consider to revise your subscription or remove unused trading accounts."
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {demoStateAccountChanges === true ? (
              <ValueLine
                parameter="Demo - Account changes"
                value={`${content.plan.demo_current_account_changes} / ${content.plan.demo_included_account_changes}`}
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}
          </>
        ) : null}

        {managed === true &&
        content.plan.distribution_linking_token !== undefined &&
        content.plan.payment_history !== null ? (
          <>
            <ValueLine
              parameter="Linking token"
              value={content.plan.distribution_linking_token}
              widthLeft="25%"
              widthRight="75%"
            />

            <B3Tiny
              fullWidth
              variant="outlined"
              onClick={handleClickOpenPaymentHistory("paper")}
              sx={{ mt: 1, mb: 0 }}
            >
              Payment history
            </B3Tiny>

            <ModalDialogInternalNotes
              open={openPaymentHistory}
              handleClose={handleClosePaymentHistory}
              handleClickClose={handleClickClosePaymentHistory}
              scroll={scrollPaymentHistory}
              data={content.plan.payment_history}
              notesType={`Payment history for '${content.plan.plan_name}'`}
            />
          </>
        ) : null}

        {managed === true && content.plan.internal_notes !== null ? (
          <>
            <B3Tiny
              fullWidth
              variant="outlined"
              onClick={handleClickOpenPlanNotes("paper")}
              sx={{ mt: 1, mb: 1 }}
            >
              Plan notes
            </B3Tiny>

            <ModalDialogInternalNotes
              open={openPlanNotes}
              handleClose={handleClosePlanNotes}
              handleClickClose={handleClickClosePlanNotes}
              scroll={scrollPlanNotes}
              data={content.plan.internal_notes}
              notesType={`Plan notes for '${content.plan.plan_name}'`}
            />
          </>
        ) : null}
      </Box>
    </Stack>
  );
}

export function AccountItem({
  mode,
  currentAccount,
  promoter,
  distributor,
  managed,
  isOnlyOneAccountLeft,
  isSponsored,
  plan,
}) {
  AccountItem.propTypes = {
    mode: PropTypes.any.isRequired,
    currentAccount: PropTypes.any.isRequired,
    promoter: PropTypes.any,
    distributor: PropTypes.any,
    managed: PropTypes.any,
    isOnlyOneAccountLeft: PropTypes.any,
    isSponsored: PropTypes.any.isRequired,
    plan: PropTypes.any.isRequired,
  };

  let isAffectedByPlanChange = false;
  if (plan.revise_information !== "none") {
    if (currentAccount.given_account_type === "live") {
      if (
        parseInt(currentAccount.live_permission_index, 10) >
        parseInt(
          plan.revise_information
            .revise_init_distribution_live_included_accounts,
          10
        )
      ) {
        isAffectedByPlanChange = true;
      }
    } else if (currentAccount.given_account_type === "demo") {
      if (
        parseInt(currentAccount.demo_permission_index, 10) >
        parseInt(
          plan.revise_information
            .revise_init_distribution_demo_included_accounts,
          10
        )
      ) {
        isAffectedByPlanChange = true;
      }
    }
  }

  const [collapse, setCollapse] = useState(false);

  let limitOptions = "";
  let noLimitsShown = false;

  if (currentAccount.given_account_type === "live") {
    limitOptions = currentAccount.live_limits;
  }
  if (currentAccount.given_account_type === "demo") {
    limitOptions = currentAccount.demo_limits;
  }

  if (limitOptions === "server") {
    limitOptions = "remote only";
  } else if (limitOptions === "local") {
    limitOptions = "local only";
  } else if (limitOptions === "local_server") {
    limitOptions = "remote and local";
  }

  if (mode === "promotion") {
    if (managed === false) {
      limitOptions = null;
      noLimitsShown = false;
    }
  }

  if (mode === "distribution_application") {
    if (managed === false) {
      if (
        limitOptions === "remote and local" &&
        (currentAccount.min_equity === "-" ||
          currentAccount.min_equity === "disabled") &&
        (currentAccount.max_equity === "-" ||
          currentAccount.max_equity === "disabled") &&
        (currentAccount.min_leverage === "-" ||
          currentAccount.min_leverage === "disabled") &&
        (currentAccount.max_leverage === "-" ||
          currentAccount.max_leverage === "disabled")
      ) {
        noLimitsShown = true;
      }

      if (limitOptions === "remote and local") {
        limitOptions = null;
      }
    } else {
      noLimitsShown = false;
    }
  }

  return (
    <Box
      sx={{
        border: "2px solid #D6D6D6",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        mt: 0,
        mb: 1,
        px: 2,
      }}
    >
      <Grid
        container
        sx={{
          pt: 2,
          pb: 0,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid container justifyContent="space-between">
          <Typography variant="h6">Account</Typography>
          <Typography variant="h6" align="right">
            Server
          </Typography>
        </Grid>

        <Grid container justifyContent="space-between">
          <Typography variant="contentSmall">
            {currentAccount.given_account_number} <br />
          </Typography>
          <Typography variant="contentSmall" align="right">
            {currentAccount.given_account_server} <br />
          </Typography>
        </Grid>
      </Grid>

      {managed === true ? (
        <>
          <ValueLine
            parameter="Permission ID"
            value={currentAccount.permission_id}
            widthLeft="50%"
            widthRight="50%"
          />
          <Box sx={{ mt: 2 }} />
        </>
      ) : null}

      <Box sx={{ mt: 1 }} />

      {isAffectedByPlanChange === true ? (
        <ModalAlertError
          severity="error"
          sx={{
            mb: 1,
            lineHeight: 1.5,
            fontSize: typography.contentSmall.fontSize,
          }}
        >
          This trading account permission will deactivated when plan changes!
        </ModalAlertError>
      ) : null}

      {mode === "distribution_application" &&
      distributor === undefined &&
      isOnlyOneAccountLeft === false ? (
        <HandleSubscriptionsRemoveTradingAccount
          SelectedPermissionId={currentAccount.permission_id}
        />
      ) : null}
      {mode === "distribution_application" && distributor !== undefined ? (
        <>
          <HandleDistributorPortalModifyPermissionLimitations
            distributor={distributor}
            permissionId={currentAccount.permission_id}
            permission={currentAccount}
            isSponsored={isSponsored}
          />
          {isOnlyOneAccountLeft === false ? (
            <HandleDistributorPortalRemoveTradingAccount
              distributor={distributor}
              permissionId={currentAccount.permission_id}
            />
          ) : null}
        </>
      ) : null}
      {promoter !== undefined && isOnlyOneAccountLeft === false ? (
        <HandlePromoterPortalRemoveTradingAccount
          promoter={promoter}
          permissionId={currentAccount.permission_id}
          isOnlyOneAccountLeft={isOnlyOneAccountLeft}
        />
      ) : null}

      {noLimitsShown === false ? (
        <>
          <B3Tiny
            fullWidth
            sx={{ mb: 1 }}
            variant="outlined"
            onClick={() => {
              setCollapse(!collapse);
            }}
          >
            {collapse ? "Hide limitations" : "Show limitations"}
          </B3Tiny>
          <br />
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            {currentAccount.end !== null &&
            (managed === true || mode === "promotion") ? (
              <ValueLine
                parameter="License end"
                value={
                  managed === true
                    ? `${currentAccount.end} GMT`
                    : currentAccount.end.substring(0, 10)
                }
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {limitOptions !== null ? (
              <ValueLine
                parameter="SL and TP options "
                value={limitOptions}
                tooltipLeft={
                  "Selectable stop loss and take profit variant within application / trading module. Local means that SL and TP limits are handled locally - your broker won’t be informed about existing stop loss and take profits. Server means traditional limits handling by sending the stop loss and take profit value to the broker's server."
                }
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {(currentAccount.min_equity !== "-" &&
              currentAccount.min_equity !== "disabled") ||
            managed === true ? (
              <ValueLine
                parameter={
                  <>
                    Minimum account equity <sup>1</sup>
                  </>
                }
                value={currentAccount.min_equity}
                widthLeft="60%"
                widthRight="40%"
              />
            ) : null}

            {(currentAccount.max_equity !== "-" &&
              currentAccount.max_equity !== "disabled") ||
            managed === true ? (
              <ValueLine
                parameter={
                  <>
                    Maximum account equity <sup>1</sup>
                  </>
                }
                value={currentAccount.max_equity}
                widthLeft="60%"
                widthRight="40%"
              />
            ) : null}

            {(currentAccount.min_leverage !== "-" &&
              currentAccount.min_leverage !== "disabled") ||
            managed === true ? (
              <ValueLine
                parameter={
                  <>
                    Minimum account leverage <sup>1</sup>
                  </>
                }
                value={currentAccount.min_leverage}
                widthLeft="60%"
                widthRight="40%"
              />
            ) : null}

            {(currentAccount.max_leverage !== "-" &&
              currentAccount.max_leverage !== "disabled") ||
            managed === true ? (
              <ValueLine
                parameter={
                  <>
                    Maximum account leverage <sup>1</sup>
                  </>
                }
                value={currentAccount.max_leverage}
                widthLeft="60%"
                widthRight="40%"
              />
            ) : null}

            <Box
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              <Typography variant="contentTiny">
                <sup>1</sup> If trading currency is different, the converted
                amount in your trading currency is used instead.
              </Typography>
            </Box>
          </Collapse>
        </>
      ) : null}
    </Box>
  );
}
