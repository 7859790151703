// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import { Stack, Collapse } from "@mui/material";

// components

// hooks

// layouts

// pages

// theme
import {
  ModalAlertWarning,
  ModalAlertError,
  ModalAlertSuccess,
} from "../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function RequestMessage(props) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  RequestMessage.propTypes = {
    wrapperRef: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
  };

  const { wrapperRef, error, warning, success, returnMessage } = props;

  return (
    <Stack sx={{ width: "100%" }}>
      <Collapse ref={wrapperRef} in={error} timeout="auto" unmountOnExit>
        <ModalAlertError severity="error">{returnMessage}</ModalAlertError>
      </Collapse>

      <Collapse ref={wrapperRef} in={warning} timeout="auto" unmountOnExit>
        <ModalAlertWarning severity="warning">
          {returnMessage}
        </ModalAlertWarning>
      </Collapse>

      <Collapse ref={wrapperRef} in={success} timeout="auto" unmountOnExit>
        <ModalAlertSuccess severity="success">
          {returnMessage}
        </ModalAlertSuccess>
      </Collapse>
    </Stack>
  );
}

export default RequestMessage;
