// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";
import CountrySelect, { countries } from "../../../components/CountrySelect";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B1Small,
  ModalContentTextField,
  ModalActionsLoadingButton,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleBillingAddressUpdateInformation({
  configDataClient,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleBillingAddressUpdateInformation.propTypes = {
    configDataClient: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateBillingInvoicesAndReceipes: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({
    forename: Yup.string().required("Forename is required"),
    surname: Yup.string().required("Surname is required"),
    street: Yup.string().required("Account number is required"),
    optional_line: Yup.string(),
    postal_code: Yup.string().required("Postal code is required"),
    city: Yup.string().required("City is required"),
    province: Yup.string().required("Province / State is required"),
  });

  let initCountry = null;
  if (configDataClient.country != null) {
    countries.forEach((country) => {
      if (country.label === configDataClient.country) {
        initCountry = country;
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      forename:
        configDataClient.forename !== null ? configDataClient.forename : "",
      surname:
        configDataClient.surname !== null ? configDataClient.surname : "",
      country: initCountry !== null ? initCountry.label : "",
      province:
        configDataClient.province !== null ? configDataClient.province : "",
      city: configDataClient.city !== null ? configDataClient.city : "",
      postal_code:
        configDataClient.postal_code !== null
          ? configDataClient.postal_code
          : "",
      street: configDataClient.street !== null ? configDataClient.street : "",
      optional_line:
        configDataClient.optional_line !== null
          ? configDataClient.optional_line
          : "",
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "account_settings",
        operation: "update_information",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        config_data_client: {
          forename: values.forename,
          surname: values.surname,
          country: values.country === null ? "" : values.country,
          province: values.province,
          city: values.city,
          postal_code: values.postal_code,
          street: values.street,
          optional_line: values.optional_line,
        },
        config_data_promoter: {},
        config_data_distributor: {},
        config_data_administrator: {},
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);

    formik.values.forename =
      configDataClient.forename !== null ? configDataClient.forename : "";
    formik.values.surname =
      configDataClient.surname !== null ? configDataClient.surname : "";
    formik.values.country = initCountry !== null ? initCountry.label : "";
    formik.values.province =
      configDataClient.province !== null ? configDataClient.province : "";
    formik.values.city =
      configDataClient.city !== null ? configDataClient.city : "";
    formik.values.postal_code =
      configDataClient.postal_code !== null ? configDataClient.postal_code : "";
    formik.values.street =
      configDataClient.street !== null ? configDataClient.street : "";
    formik.values.optional_line =
      configDataClient.optional_line !== null
        ? configDataClient.optional_line
        : "";
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B1Small
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mt: 2 }}
        fullWidth
      >
        Update billing address
      </B1Small>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Update billing address
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px", ml: 1 }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.forename && errors.forename) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.forename && errors.forename) === true
                        ? "Forename is required"
                        : "Forename"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("forename")}
                      error={Boolean(touched.forename && errors.forename)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.surname && errors.surname) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.surname && errors.surname) === true
                        ? "Surname is required"
                        : "Surname"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("surname")}
                      error={Boolean(touched.surname && errors.surname)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.street && errors.street) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.street && errors.street) === true
                        ? "Street is required"
                        : "Street"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("street")}
                      error={Boolean(touched.street && errors.street)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.optional_line && errors.optional_line
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.optional_line && errors.optional_line
                      ) === true
                        ? "Invalid optional address line"
                        : "Optional address line"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("optional_line")}
                      error={Boolean(
                        touched.optional_line && errors.optional_line
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.postal_code && errors.postal_code) ===
                          true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.postal_code && errors.postal_code) ===
                      true
                        ? "Postal code is required"
                        : "Postal code"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("postal_code")}
                      error={Boolean(touched.postal_code && errors.postal_code)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.city && errors.city) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.city && errors.city) === true
                        ? "City is required"
                        : "City"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("city")}
                      error={Boolean(touched.city && errors.city)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.province && errors.province) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.province && errors.province) === true
                        ? "Province / State is required"
                        : "Province / State"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("province")}
                      error={Boolean(touched.province && errors.province)}
                    />
                  </Box>

                  <Box>
                    <Typography variant="contentSmall">Country</Typography>

                    {configDataClient.country_locked === "0" ? (
                      <CountrySelect
                        error={Boolean(touched.country && errors.country)}
                        initCountry={initCountry}
                        setFormCountry={(newValue) => {
                          values.country = newValue;
                        }}
                        nullable={Boolean(false)}
                      />
                    ) : (
                      <Box sx={{ my: 1, ml: "14px"}}>
                        <Typography variant="contentMiddleBold">
                          {initCountry.label}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Update
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
