// react and js
import { React } from "react";

// mui
import { Typography, Link, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PublicFooter() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box>
      {isDesktop === true ? (
        <Box>
          <Typography variant="footerPublic">
            <Link
              variant="footerPublicLink"
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_WEBSITE}
            >
              © {new Date().getFullYear()} {process.env.REACT_APP_NAME_LEGAL}
            </Link>
            &nbsp;&bull;&nbsp;
            <Link
              variant="footerPublicLink"
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_IMPRINT}
            >
              Imprint
            </Link>
            &nbsp;&bull;&nbsp;
            <Link
              variant="footerPublicLink"
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_PRIVACY_POLICY}
            >
              Privacy Policy
            </Link>
            &nbsp;&bull;&nbsp;
            <Link
              variant="footerPublicLink"
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_TERMS_AND_CONDITIONS}
            >
              Terms and Conditions
            </Link>
            &nbsp;&bull;&nbsp;
            <Link
              variant="footerPublicLink"
              underline="none"
              color="inherit"
              href={process.env.REACT_APP_URL_FRONTEND_RISK_DISCLOSURE_NOTICE}
            >
              Risk Disclosure Notice
            </Link>
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="footerPublic">
              <Link
                variant="footerPublicLink"
                underline="none"
                color="inherit"
                href={process.env.REACT_APP_URL_FRONTEND_WEBSITE}
              >
                © {new Date().getFullYear()} {process.env.REACT_APP_NAME_LEGAL}
              </Link>
              &nbsp;&bull;&nbsp;
              <Link
                variant="footerPublicLink"
                underline="none"
                color="inherit"
                href={process.env.REACT_APP_URL_FRONTEND_IMPRINT}
              >
                Imprint
              </Link>
              &nbsp;&bull;&nbsp;
              <Link
                variant="footerPublicLink"
                underline="none"
                color="inherit"
                href={process.env.REACT_APP_URL_FRONTEND_PRIVACY_POLICY}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="footerPublic">
              <Link
                variant="footerPublicLink"
                underline="none"
                color="inherit"
                href={process.env.REACT_APP_URL_FRONTEND_TERMS_AND_CONDITIONS}
              >
                Terms and Conditions
              </Link>
              &nbsp;&bull;&nbsp;
              <Link
                variant="footerPublicLink"
                underline="none"
                color="inherit"
                href={process.env.REACT_APP_URL_FRONTEND_RISK_DISCLOSURE_NOTICE}
              >
                Risk Disclosure Notice
              </Link>
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
