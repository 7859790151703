// react and js
import * as Yup from "yup";
import { React, useContext, useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

// mui
import {
  Link,
  IconButton,
  InputAdornment,
  Collapse,
  Card,
  Box,
  Typography,
  Grid,
} from "@mui/material";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";

// hooks
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme
import {
  AuthenticationButton,
  AuthenticationTextField,
  AuthenticationAlertWarning,
  AuthenticationAlertError,
  AuthenticationAlertSuccess,
} from "../../theme/styled";

// utils
import { combineReturnMessage } from "../../utils/common";

// widgets

// app
import { myLocalSettingStorage } from "../../hooks/myLocalSettingStorage";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const wrapperRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const [recaptcha, setRecaptcha] = useState(false);

  const [forcedLoggedOut, setForcedLoggedOut] = useState(false);

  const localStorageForcedLoggedOut = localStorage.getItem("forcedLoggedOut");
  if (localStorageForcedLoggedOut !== null) {
    if (localStorageForcedLoggedOut === "true" && forcedLoggedOut !== true) {
      setForcedLoggedOut(true);
    }

    if (localStorageForcedLoggedOut === "false" && forcedLoggedOut !== false) {
      setForcedLoggedOut(false);
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setSuccess(false);
        setWarning(false);

        localStorage.setItem("forcedLoggedOut", JSON.stringify(false));
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email address must be a valid email address")
      .required("Email address is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleResendApprovalEmail = (emailParam) => {
    if (navigator.onLine === true) {
      setError(false);
      setWarning(false);
      setSuccess(false);

      const sendData = {
        request_type: "dashboard",
        route_info: "authentication",
        client: {
          mode: "resend_approval_email_alt",
          email: emailParam,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("answer");

          if (checkKey === true) {
            setReturnMessage("Approval email resend, please check your inbox!");
            setSuccess(true);
          } else {
            combineReturnMessage(contentFromBackend, setReturnMessage);
            setError(true);
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setReturnMessage("Unknown error!");
            setError(true);
          }
        });
    }
  };

  const handleLogin = (values, setSubmitting) => {
    if (navigator.onLine === true) {
      setError(false);
      setWarning(false);
      setSuccess(false);

      const sendData = {
        request_type: "dashboard",
        route_info: "authentication",
        client: {
          mode: "login",
          email: values.email,
          password: values.password,
          request_known_accounts: "0",
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("answer");

          if (checkKey === true) {
            setStateStorage((previousState) => ({
              ...previousState,
              authenticated: true,
              userInformation: contentFromBackend,
            }));
            localStorage.setItem(
              "myLocalStateStorage",
              JSON.stringify(contentFromBackend)
            );

            // Action mode
            let myLocalAction = localStorage.getItem("myLocalAction");
            if (myLocalAction != null) {
              myLocalAction = JSON.parse(myLocalAction);
              if (
                myLocalAction.dashboardId !==
                contentFromBackend.account_settings.config_data
                  .config_data_client.dashboard_id
              ) {
                localStorage.removeItem("myLocalAction");
              }
            }

            localStorage.setItem("myReauthentication", false);

            const myLocalSettingStorageVerify = JSON.parse(
              localStorage.getItem("myLocalSettingStorage")
            );

            if (myLocalSettingStorageVerify === null) {
              localStorage.setItem(
                "myLocalSettingStorage",
                JSON.stringify(myLocalSettingStorage)
              );
            }
          } else {
            setStateStorage((previousState) => ({
              ...previousState,
              authenticated: false,
            }));

            if (
              contentFromBackend.message.includes("please check your inbox")
            ) {
              setReturnMessage(
                <Typography variant="contentSmall">
                  Email not approved, please check your inbox and confirm your
                  email address! Click
                  {/* eslint-disable */}
                  <Link
                    component={RouterLink}
                    underline="none"
                    variant="contentSmallLink"
                    color="inherit"
                    onClick={() => {
                      handleResendApprovalEmail(values.email);
                    }}
                  >
                    {` here `}
                  </Link>
                  {/* eslint-enable */}
                  to resend the approval email.
                </Typography>
              );
            } else {
              combineReturnMessage(contentFromBackend, setReturnMessage);
            }

            setWarning(true);
          }

          setSubmitting(false);
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setReturnMessage("Unknown error!");
            setError(true);
            setSubmitting(false);
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleLogin(values, setSubmitting);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "70vw",
            md: "50vw",
            lg: "40vw",
            xl: "35vw",
          },
        }}
      >
        <Card
          sx={{
            background: "#F2F2F2",
            borderColor: "#6D6E70",
            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
          }}
        >
          <Box sx={{ p: 4 }} dir="ltr">
            <Typography
              variant="h2"
              sx={{
                mt: 0,
                mb: 0,
                pl: 2,
                borderLeft: 1,
                borderWidth: "3px",
                borderColor: "#FF9600",
                borderRadius: "0px 0px 0px 0px",
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
              }}
            >
              Dashboard Login
            </Typography>

            <Box sx={{ mt: 1, mb: 2 }} dir="ltr">
              <Typography
                variant="contentSmall"
                sx={{ mt: 1, textAlign: "center" }}
              >
                Manage your subscription and inspect active applications.
                Subscribe to one of our
                <Link
                  variant="contentSmallLink"
                  underline="none"
                  color="inherit"
                  href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
                >
                  {` applications `}
                </Link>
                or
                <Link
                  variant="contentSmallLink"
                  underline="none"
                  color="inherit"
                  href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution-tick-data`}
                >
                  {` tick data plans `}
                </Link>
                to gain access to your personal dashboard.
              </Typography>
            </Box>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.email && errors.email) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.email && errors.email) === true
                    ? errors.email
                    : "Email address"}
                </Typography>

                <AuthenticationTextField
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                />

                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.password && errors.password) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.password && errors.password) === true
                    ? errors.password
                    : "Password"}
                </Typography>

                <AuthenticationTextField
                  autoComplete="off" // Avoids chrome warning
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Collapse
                  ref={wrapperRef}
                  in={warning}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertError
                    severity="warning"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertError>
                </Collapse>

                <Collapse
                  ref={wrapperRef}
                  in={error}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertWarning
                    severity="error"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertWarning>
                </Collapse>

                <Collapse
                  ref={wrapperRef}
                  in={success}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertSuccess
                    severity="success"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertSuccess>
                </Collapse>

                <Collapse
                  ref={wrapperRef}
                  in={forcedLoggedOut}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertWarning
                    severity="warning"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    Logged out due to inactivity!
                  </AuthenticationAlertWarning>
                </Collapse>

                <Box sx={{ textAlign: "center", mt: 1, mb: 1 }}>
                  <Typography variant="contentSmall">
                    <Link
                      component={RouterLink}
                      to="/password-reset"
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      Forgot password?
                    </Link>
                  </Typography>
                </Box>

                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                      onErrored={() => {
                        setRecaptcha(false);
                      }}
                      onExpired={() => {
                        setRecaptcha(false);
                      }}
                      onChange={() => {
                        setRecaptcha(true);
                      }}
                    />
                  </Box>
                </Box>

                <AuthenticationButton
                  fullWidth
                  variant="contained"
                  disabled={!recaptcha}
                  loading={isSubmitting}
                  type="submit"
                  sx={{ mt: 1 }}
                >
                  Login
                </AuthenticationButton>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}
