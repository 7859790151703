// react and js
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import PropTypes from "prop-types";
import { initializePaddle } from "@paddle/paddle-js";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";
import NewPlanItemTickData from "../../../components/NewPlanItemTickData";
import { countries } from "../../../components/CountrySelect";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import { B3Tiny, ModalActionsLoadingButton } from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  paymentMethod,
  paddleCheckoutOpen,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");
    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            if (paymentMethod.toLowerCase() === "paypal") {
              setSuccess(true);
              combineReturnMessage(contentFromBackend, setReturnMessage);
              postRequestUpdate(750);

              const h = 720;
              const w = 800;

              const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
              const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
              const openedWindow = window.open(
                contentFromBackend.approval_url,
                "checkout",
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
              );
              if (openedWindow !== null) {
                openedWindow.focus();
              }
            } else if (paymentMethod.toLowerCase() === "stripe") {
              setSuccess(true);
              combineReturnMessage(contentFromBackend, setReturnMessage);
              postRequestUpdate(750);

              const h = 720;
              const w = 1000;

              const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
              const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
              const openedWindow = window.open(
                contentFromBackend.approval_url,
                "checkout",
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
              );
              if (openedWindow !== null) {
                openedWindow.focus();
              }
            } else if (paymentMethod.toLowerCase() === "paddle") {
              combineReturnMessage(contentFromBackend, setReturnMessage);
              postRequestUpdate(250);

              paddleCheckoutOpen(
                contentFromBackend.paddle_customer_id,
                contentFromBackend.paddle_address_id,
                contentFromBackend.paddle_price_id,
                contentFromBackend.paddle_discount_id
              );
            }
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }

        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

function PaddleCustomerSync(
  dashboardAccessToken,
  dashboardId,
  pspPaddleAddressId,
  paymentInfoPostalCode,
  paymentInfoCountry
) {
  const sendData = {
    request_type: "dashboard",
    route_info: "subscriptions",
    operation: "paddle_customer_sync",
    authentication: {
      dashboard_access_token: dashboardAccessToken,
      dashboard_id: dashboardId,
    },
    payment_info: {
      psp_paddle_address_id: pspPaddleAddressId,
      postal_code: paymentInfoPostalCode,
      country: paymentInfoCountry,
    },
  };

  backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData);
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleSubscriptionsReactivateMt5TickDataSubscription({
  distributionPlans,
  currentPlanId,
  subscriptionId,
  categories,
  prorataDiscount,
  paymentMethod,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleSubscriptionsReactivateMt5TickDataSubscription.propTypes = {
    distributionPlans: PropTypes.any.isRequired,
    currentPlanId: PropTypes.any.isRequired,
    subscriptionId: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    prorataDiscount: PropTypes.any.isRequired,
    paymentMethod: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateSubscriptionsSubscriptions: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let deactivatedNewPlan = 0;

  let FullDeactivatedPlanStructure = null;

  distributionPlans.forEach((distrubutionPlan) => {
    if (distrubutionPlan.plan_id === currentPlanId) {
      deactivatedNewPlan = currentPlanId;
    }
  });

  distributionPlans.forEach((distrubutionPlan) => {
    if (distrubutionPlan.plan_id === deactivatedNewPlan) {
      FullDeactivatedPlanStructure = distrubutionPlan;
    }
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({});

  const dashboardId =
    stateStorage.userInformation.account_settings.config_data.config_data_client
      .dashboard_id;

  const [paddle, setPaddle] = useState(null);
  const [paddleClose, setPaddleClose] = useState(false);

  // Action mode
  let myLocalAction = sessionStorage.getItem("myLocalAction");
  if (myLocalAction != null) {
    myLocalAction = JSON.parse(myLocalAction);
    if (
      myLocalAction.actionType === "reactivate_mt5_tick_data_subscription" &&
      myLocalAction.distributionLinkingToken === subscriptionId
    ) {
      // Check if action is required
      setOpen(true);
      sessionStorage.removeItem("myLocalAction");
    }
  }

  const postPaddleSyncUpdate = (syncData) => {
    const newUserInformation = JSON.parse(
      localStorage.getItem("myLocalStateStorage")
    );

    // Update locally stored paddle address id, updated postal code and country
    newUserInformation.account_settings.config_data.config_data_client.psp_paddle_address_id =
      syncData.psp_paddle_address_id;
    newUserInformation.account_settings.config_data.config_data_client.postal_code =
      syncData.postal_code;
    newUserInformation.account_settings.config_data.config_data_client.country =
      syncData.country;

    localStorage.setItem(
      "myLocalStateStorage",
      JSON.stringify(newUserInformation)
    );

    setStateStorage((previousState) => ({
      ...previousState,
      userInformation: newUserInformation,
    }));
  };

  // Event callback for paddle checkout
  const paddleEventCallback = (EventData) => {
    // checkout.customer.created OR checkout.customer.updated -> Update paddle data if address id is not null
    if (EventData.name === "checkout.customer.updated") {
      // Sync paddle customer
      if (EventData.data.customer.address !== null) {
        // Read and convert country and postal from paddle data chunk and sync with checkout
        let countryLabel = null;
        countries.forEach((countryIterate) => {
          if (
            countryIterate.code === EventData.data.customer.address.country_code
          ) {
            countryLabel = countryIterate.label;
          }
        });

        PaddleCustomerSync(
          EventData.data.custom_data.dashboard_access_token,
          EventData.data.custom_data.dashboard_id,
          EventData.data.customer.address.id,
          EventData.data.customer.address.postal_code,
          countryLabel
        );

        const syncData = {
          dashboard_access_token:
            EventData.data.custom_data.dashboard_access_token,
          dashboard_id: EventData.data.custom_data.dashboard_id,
          psp_paddle_address_id: EventData.data.customer.address.id,
          postal_code: EventData.data.customer.address.postal_code,
          country: countryLabel,
        };
        postPaddleSyncUpdate(syncData);
      }
    }

    if (EventData.name === "checkout.completed") {
      // Close paddle checkout dialogue
      setPaddleClose(true);
    }
  };

  // Download and initialize Paddle instance from CDN
  if (stateStorage.userInformation !== "" && paddle === null) {
    if (
      stateStorage.userInformation.account_settings.retailer.psp_mode ===
      "sandbox"
    ) {
      initializePaddle({
        environment: "sandbox",
        token:
          stateStorage.userInformation.account_settings.retailer
            .paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }

    if (
      stateStorage.userInformation.account_settings.retailer.psp_mode === "live"
    ) {
      initializePaddle({
        environment: "production",
        token:
          stateStorage.userInformation.account_settings.retailer
            .paddle_client_sided_token,
        eventCallback: paddleEventCallback,
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }
  }

  // Method to open paddle checkout
  function paddleCheckoutOpen(
    paddleCustomerId,
    paddleAddressId,
    paddlePriceId,
    paddleDiscountId
  ) {
    paddle.Checkout.open({
      items: [{ priceId: paddlePriceId }],
      settings: {
        showAddTaxId: false,
        allowLogout: false,
      },
      customer: {
        id: paddleCustomerId,
        address: {
          id: paddleAddressId,
        },
      },
      discountId: paddleDiscountId,
      customData: {
        operation: "reactivate",
        dashboard_id:
          stateStorage.userInformation.account_settings.config_data
            .config_data_client.dashboard_id,
        distribution_linking_token: subscriptionId,
        dashboard_access_token:
          stateStorage.userInformation.answer.dashboard_access_token,
        postal_code:
          stateStorage.userInformation.account_settings.config_data
            .config_data_client.postal_code,
        country:
          stateStorage.userInformation.account_settings.config_data
            .config_data_client.country,
      },
    });
  }

  // Method to close paddle checkout
  if (paddleClose === true) {
    paddle.Checkout.close();
  }

  const formik = useFormik({
    initialValues: {},
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "subscriptions",
        operation: "reactivate_mt5_tick_data_subscription",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        permission: {
          return_url: `${process.env.REACT_APP_URL_API_EVENT_LISTENER}?request_type=reactivate&dashboard_id=${dashboardId}&distribution_linking_token=${subscriptionId}`,
          cancel_url: `${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/subscriptions`,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        paymentMethod,
        paddleCheckoutOpen,
        SubmitRequestContent
      );
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  let discountShown = "";
  if (paymentMethod.toLowerCase() === "paddle") {
    discountShown = `${(
      (parseFloat(prorataDiscount) /
        parseFloat(FullDeactivatedPlanStructure.plan_fixed_price_value)) *
      100
    ).toFixed(1)} % net price discount`;
  } else {
    discountShown = `${prorataDiscount} discount`;
  }

  return (
    <>
      <B3Tiny
        fullWidth
        variant="outlined"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 1 }}
      >
        Reactivate tick data plan
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Reactivate tick data plan
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 1 }}>
                  <Typography variant="contentMiddle">
                    This subscription is canceled and will be deactivated by the
                    end of the current billing cycle. However, you can
                    reactivate it using this dialogue.
                  </Typography>

                  <Box sx={{ pt: 1 }}>
                    <NewPlanItemTickData
                      planInformation={FullDeactivatedPlanStructure}
                      categories={categories}
                      sponsored={Boolean(false)}
                      reviseAction="reactivate"
                      isStopPossible={Boolean(false)}
                    />
                  </Box>

                  <Typography variant="contentSmall">
                    <sup>*</sup> {discountShown} discount on the first payment
                    due to an already paid cycle. Reactivation will initiate a
                    new billing cycle starting from today.
                  </Typography>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Reactivate tick data plan
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
