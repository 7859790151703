// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Typography,
  Collapse,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function FetchTable({ tableArray, refreshLoading, isAdministrator }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    isAdministrator: PropTypes.any.isRequired,
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> ID </Typography>
              </TableCell>
              <TableCell
                width="15%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Forename </Typography>
              </TableCell>
              <TableCell
                width="15%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Surname </Typography>
              </TableCell>
              <TableCell
                width="45%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Email </Typography>
              </TableCell>
              <TableCell
                width="15%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[0]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {isAdministrator ? row[8] : row[8]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {isAdministrator ? row[9] : row[9]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {isAdministrator ? row[5] : row[5]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <B3Tiny
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        window.scrollBy(0, 0);
                        navigate(`/distributor-portal/tick-data/${row[0]}`);
                      }}
                    >
                      Manage
                    </B3Tiny>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> ID </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[0]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Forename </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {isAdministrator ? row[8] : row[8]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Surname </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {isAdministrator ? row[9] : row[9]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Email </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {isAdministrator ? row[5] : row[5]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      window.scrollBy(0, 0);
                      navigate(`/distributor-portal/tick-data/${row[0]}`);
                    }}
                  >
                    Manage
                  </B3Tiny>
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterForename,
  setFilterForename,
  filterSurname,
  setFilterSurname,
  filterEmail,
  setFilterEmail,
  filterCountry,
  setFilterCountry,
  mobileBackOpen,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterForename: PropTypes.any.isRequired,
    setFilterForename: PropTypes.any.isRequired,
    filterSurname: PropTypes.any.isRequired,
    setFilterSurname: PropTypes.any.isRequired,
    filterEmail: PropTypes.any.isRequired,
    setFilterEmail: PropTypes.any.isRequired,
    filterCountry: PropTypes.any.isRequired,
    setFilterCountry: PropTypes.any.isRequired,
    mobileBackOpen: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  let distributorPortalTickDataCustomersTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs58ba9f5b3dcba6b8 !== null &&
      myLocalSettingStorage.obfs58ba9f5b3dcba6b8 !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterCollapseInitValue =
        myLocalSettingStorage.obfs58ba9f5b3dcba6b8;
    }
  }
  const [collapse, setCollapse] = useState(
    distributorPortalTickDataCustomersTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterForename !== "" ||
      filterSurname !== "" ||
      filterEmail !== "" ||
      filterCountry !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let distributorPortalTickDataCustomersTableFilterItemsPerPageInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsf5da3a01c268f44f !== null &&
      myLocalSettingStorage.obfsf5da3a01c268f44f !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfsf5da3a01c268f44f;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    distributorPortalTickDataCustomersTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterForename, setLocalFilterForename] =
    useState(filterForename);
  const [localFilterSurname, setLocalFilterSurname] = useState(filterSurname);
  const [localFilterEmail, setLocalFilterEmail] = useState(filterEmail);
  const [localFilterCountry, setLocalFilterCountry] = useState(filterCountry);

  const contentFiltered = [];
  if (content.t_mt5_tick_data !== undefined) {
    content.t_mt5_tick_data.data.forEach((account, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(account);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterForename(localFilterForename);
    setFilterSurname(localFilterSurname);
    setFilterEmail(localFilterEmail);
    setFilterCountry(localFilterCountry);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateDistributorPortalTickDataDistributionCustomers: true,
    }));
  }

  let isAdministrator = false;
  if (
    stateStorage.userInformation.account_settings.config_data
      .config_data_administrator !== undefined
  ) {
    isAdministrator = true;
  }

  const [mobileCollapse, setMobileCollapse] = useState(mobileBackOpen);
  useEffect(() => {
    if (mobileBackOpen === false) {
      setMobileCollapse(isDesktop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Tick Data Distribution Customers"
          subheader="Here, you can access the tick data customer management tool. A compact widget provides an overview, and you can adjust further settings by selecting a specific tick data customer."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            content.message.includes("no tick data distribution") ? null : (
              <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
                {isDesktop === false ? (
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setMobileCollapse(!mobileCollapse);
                    }}
                  >
                    {mobileCollapse ? "Close" : "Open"}
                  </B3Tiny>
                ) : null}

                <Collapse
                  sx={{ mx: -1, px: 1 }}
                  in={mobileCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setCollapse(!collapse);

                      const newLocalSettingStorage = JSON.parse(
                        localStorage.getItem("myLocalSettingStorage")
                      );

                      newLocalSettingStorage.obfs58ba9f5b3dcba6b8 = !collapse;
                      localStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                  >
                    {collapse ? "Hide filters" : "Show filters"}
                  </B3Tiny>

                  <Collapse
                    sx={{ mx: -1 }}
                    in={collapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                      <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">ID</Typography>
                          <FetchTableTextField
                            value={localFilterId}
                            onChange={(event) => {
                              setLocalFilterId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfsdef96eac893350d4 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Forename
                          </Typography>
                          <FetchTableTextField
                            value={localFilterForename}
                            onChange={(event) => {
                              setLocalFilterForename(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfsb527ce61f771e6bd =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Surname
                          </Typography>
                          <FetchTableTextField
                            value={localFilterSurname}
                            onChange={(event) => {
                              setLocalFilterSurname(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs02749bbdae439090 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Email</Typography>
                          <FetchTableTextField
                            value={localFilterEmail}
                            onChange={(event) => {
                              setLocalFilterEmail(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs45d87d82ab47e8bd =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Country
                          </Typography>
                          <FetchTableTextField
                            value={localFilterCountry}
                            onChange={(event) => {
                              setLocalFilterCountry(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs18894a63ab319423 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Items per page
                          </Typography>
                          <Box sx={{ px: 1 }}>
                            <FetchTableSlider
                              size="small"
                              min={1}
                              max={100}
                              align="right"
                              value={itemsPerPages}
                              onChange={(event, newValue) => {
                                setItemsPerPages(newValue);
                                setTotalPages(
                                  Math.ceil(content.num_rows / newValue)
                                );
                                setCurrentPage(1);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfsf5da3a01c268f44f =
                                  newValue;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <B1Tiny
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setFilters();
                        }}
                      >
                        Set filters and refresh output
                      </B1Tiny>
                    </Box>
                  </Collapse>

                  <Box sx={{ mt: 0, mb: 1 }} />

                  <FetchTable
                    tableArray={contentFiltered}
                    refreshLoading={refreshLoading}
                    isAdministrator={isAdministrator}
                  />

                  <Stack sx={{ mt: 3 }} alignItems="center">
                    <FetchTablePagination
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                      }}
                    />
                  </Stack>
                </Collapse>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  distributor,
  loadingWidgetTickDataDistributionCustomers,
  setLoadingWidgetTickDataDistributionCustomers,
  mobileBackOpen,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    distributor: PropTypes.any.isRequired,
    loadingWidgetTickDataDistributionCustomers: PropTypes.any.isRequired,
    setLoadingWidgetTickDataDistributionCustomers: PropTypes.any.isRequired,
    mobileBackOpen: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let distributorPortalTickDataCustomersTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsdef96eac893350d4 !== null &&
      myLocalSettingStorage.obfsdef96eac893350d4 !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterIdInitValue =
        myLocalSettingStorage.obfsdef96eac893350d4;
    }
  }
  const [filterId, setFilterId] = useState(
    distributorPortalTickDataCustomersTableFilterIdInitValue
  );

  // Filter
  let distributorPortalTickDataCustomersTableFilterForenameInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsb527ce61f771e6bd !== null &&
      myLocalSettingStorage.obfsb527ce61f771e6bd !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterForenameInitValue =
        myLocalSettingStorage.obfsb527ce61f771e6bd;
    }
  }
  const [filterForename, setFilterForename] = useState(
    distributorPortalTickDataCustomersTableFilterForenameInitValue
  );

  // Filter
  let distributorPortalTickDataCustomersTableFilterSurnameInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs02749bbdae439090 !== null &&
      myLocalSettingStorage.obfs02749bbdae439090 !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterSurnameInitValue =
        myLocalSettingStorage.obfs02749bbdae439090;
    }
  }
  const [filterSurname, setFilterSurname] = useState(
    distributorPortalTickDataCustomersTableFilterSurnameInitValue
  );

  // Filter
  let distributorPortalTickDataCustomersTableFilterEmailInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs45d87d82ab47e8bd !== null &&
      myLocalSettingStorage.obfs45d87d82ab47e8bd !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterEmailInitValue =
        myLocalSettingStorage.obfs45d87d82ab47e8bd;
    }
  }
  const [filterEmail, setFilterEmail] = useState(
    distributorPortalTickDataCustomersTableFilterEmailInitValue
  );

  // Filter
  let distributorPortalTickDataCustomersTableFilterCountryInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs18894a63ab319423 !== null &&
      myLocalSettingStorage.obfs18894a63ab319423 !== undefined
    ) {
      distributorPortalTickDataCustomersTableFilterCountryInitValue =
        myLocalSettingStorage.obfs18894a63ab319423;
    }
  }
  const [filterCountry, setFilterCountry] = useState(
    distributorPortalTickDataCustomersTableFilterCountryInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalTickDataDistributionCustomers: false,
          }));

          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "mt5_tick_data_customers",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "desc",
            page_order_by: "dashboard_id",
          },
          filters: {
            dashboard_id: filterId,
            forename: filterForename,
            surname: filterSurname,
            email: filterEmail,
            country: filterCountry,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetTickDataDistributionCustomers(false);
                setWarning(false);
                setSuccess(false);
                setRefreshLoading(false);
                setContent(contentFromBackend);
                setReturnMessage("");
              } else if (
                contentFromBackend.message.includes("no tick data distribution")
              ) {
                setSuccess(true);
                setWarning(false);
                setContent(contentFromBackend);
                setLoading(false);
                setLoadingWidgetTickDataDistributionCustomers(false);
                setRefreshLoading(false);
                setReturnMessage(
                  "Tick data distribution customers have not been found yet! Wait for them, or add a few …"
                );
              } else if (contentFromBackend.message === "token invalid") {
                setSuccess(false);
                setWarning(true);
                setError(false);
                setLoading(false);
                setLoadingWidgetTickDataDistributionCustomers(false);
                setRefreshLoading(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setSuccess(false);
                setWarning(false);
                setError(true);
                setLoading(false);
                setLoadingWidgetTickDataDistributionCustomers(false);
                setRefreshLoading(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setSuccess(false);
              setWarning(false);
              setError(true);
              setLoading(false);
              setLoadingWidgetTickDataDistributionCustomers(false);
              setRefreshLoading(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setSuccess(false);
              setWarning(false);
              setLoading(false);
              setLoadingWidgetTickDataDistributionCustomers(false);
              setRefreshLoading(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (
      !stateStorage.widgetUpdateDistributorPortalTickDataDistributionCustomers
    )
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalTickDataDistributionCustomers]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalTickDataDistributionCustomers);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <Box sx={{ mt: 3 }}>
      <MissingContent message="Current Tick Data Distribution Customers" />
    </Box>
  );

  if (loading || loadingWidgetTickDataDistributionCustomers) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <LoadingAnimation title="Current Tick Data Distribution Customers" />
      </Box>
    );
  } else if (error) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <MissingContent message="Current Tick Data Distribution Customers" />
      </Box>
    );
  } else if (content) {
    if (content.hide_widget === false) {
      WidgetOutlet = (
        <Box sx={{ mt: 3 }}>
          <WidgetMainContentProcessed
            content={content}
            error={error}
            warning={warning}
            success={success}
            returnMessage={returnMessage}
            refreshLoading={refreshLoading}
            setRefreshLoading={setRefreshLoading}
            filterId={filterId}
            setFilterId={setFilterId}
            filterForename={filterForename}
            setFilterForename={setFilterForename}
            filterSurname={filterSurname}
            setFilterSurname={setFilterSurname}
            filterEmail={filterEmail}
            setFilterEmail={setFilterEmail}
            filterCountry={filterCountry}
            setFilterCountry={setFilterCountry}
            mobileBackOpen={mobileBackOpen}
          />
        </Box>
      );
    } else {
      WidgetOutlet = null;
    }
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
