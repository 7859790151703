// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  Box,
  Typography,
  Select,
  NativeSelect,
  Stack,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { CardOutlinedInput, CardMenuItem, B3Tiny } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function FetchTable({ tableArray, referenceTradingCurrency }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="20%"
            >
              <Typography variant="contentSmallBold">Application</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="10%"
            >
              <Typography variant="contentSmallBold">
                Promotion Months
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="10%"
            >
              <Typography variant="contentSmallBold"> Plattform </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="15%"
            >
              <Typography variant="contentSmallBold">Min. equity</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="15%"
            >
              <Typography variant="contentSmallBold">Max. equity</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="15%"
            >
              <Typography variant="contentSmallBold">Min. leverage</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="15%"
            >
              <Typography variant="contentSmallBold">Max. leverage</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableArray.data.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall">{row[0]}</Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">{row[7]}</Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">{row[2]}</Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[3] === null
                    ? "-"
                    : `${numberWithCommas(row[3])} ${referenceTradingCurrency}`}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[4] === null
                    ? "-"
                    : `${numberWithCommas(row[4])} ${referenceTradingCurrency}`}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[5] === null ? "-" : row[5]}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[6] === null ? "-" : row[6]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.data.map((row, index) => (
        <Stack
          key={index}
          spacing="3px"
          sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Application </Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">{row[0]}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold">
                Promotion Months
              </Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">{row[7]}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Plattform </Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">{row[2]}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Min. equity </Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[3] === null
                  ? "-"
                  : `${numberWithCommas(row[3])} ${referenceTradingCurrency}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Max. equity </Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[4] === null
                  ? "-"
                  : `${numberWithCommas(row[4])} ${referenceTradingCurrency}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold">Min. leverage</Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[5] === null ? "-" : row[5]}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", pr: 1 }}>
              <Typography variant="contentSmallBold">Max. leverage</Typography>
            </Box>
            <Box sx={{ width: "55%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[6] === null ? "-" : row[6]}
              </Typography>
            </Box>
          </Box>
        </Stack>
      ))}
    </>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const keys = Object.keys(content.t_promotion_limitations);
  const brokers = [];

  for (let index = 0; index < keys.length; index += 1) {
    brokers.push(keys[index]);
  }

  let promoterPortalPromotionBrokerLimitationsInitValue = brokers[0];

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs56a28e49b19186d0 !== null &&
      myLocalSettingStorage.obfs56a28e49b19186d0 !== undefined
    ) {
      if (typeof myLocalSettingStorage.obfs56a28e49b19186d0 === "number") {
        if (
          Number(myLocalSettingStorage.obfs56a28e49b19186d0) < brokers.length
        ) {
          promoterPortalPromotionBrokerLimitationsInitValue =
            brokers[myLocalSettingStorage.obfs56a28e49b19186d0];
        }
      } else {
        promoterPortalPromotionBrokerLimitationsInitValue =
          myLocalSettingStorage.obfs56a28e49b19186d0;
      }
    }
  }

  const [selectedBroker, setSelectedBroker] = useState(
    promoterPortalPromotionBrokerLimitationsInitValue
  );

  if(content.t_promotion_limitations[selectedBroker] === undefined)
  {
    setSelectedBroker(brokers[0]);
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Broker Limitations"
      subheader="While we grant permissions to clients for free, we earn commission from our partners. Therefore, a minimum equity or leverage is required to start some applications to ensure a certain minimum income per broker."
      mainOutlet={
        <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
          {isDesktop === false ? (
            <B3Tiny
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
              onClick={() => {
                setMobileCollapse(!mobileCollapse);
              }}
            >
              {mobileCollapse ? "Close" : "Open"}
            </B3Tiny>
          ) : null}

          <Collapse
            sx={{ mx: -1, px: 1 }}
            in={mobileCollapse}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={1} sx={{ p: 0 }}>
              {brokers.length > 1 ? (
                <Grid item xs={12} md={12} lg={12} sx={{ mb: 4 }}>
                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Promotion broker
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={selectedBroker}
                          onChange={(event) => {
                            setSelectedBroker(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs56a28e49b19186d0 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          input={<CardOutlinedInput />}
                        >
                          {brokers.map((broker, index) => (
                            <CardMenuItem key={index} value={broker}>
                              {broker.replace("_", ".")}
                            </CardMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Promotion broker
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={selectedBroker}
                          onChange={(event) => {
                            setSelectedBroker(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs56a28e49b19186d0 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          input={<CardOutlinedInput />}
                        >
                          {brokers.map((broker, index) => (
                            <option key={index} value={broker}>
                              {broker.replace("_", ".")}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              ) : null}

              <Grid item xs={12} md={12} lg={12}>
                {content.t_promotion_limitations !== undefined ? (
                  <FetchTable
                    referenceTradingCurrency={content.reference_trading_currency.replace(
                      "EUR",
                      "€"
                    )}
                    tableArray={content.t_promotion_limitations[selectedBroker]}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  promoter,
  loadingWidgetBrokerLimitations,
  setLoadingWidgetBrokerLimitations,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    promoter: PropTypes.any.isRequired,
    loadingWidgetBrokerLimitations: PropTypes.any.isRequired,
    setLoadingWidgetBrokerLimitations: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (promoter !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdatePromoterPortalBrokerLimitations: false,
          }));

          setError(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "promoter_portal",
          widget: "broker_limitations",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_promoter_id: promoter,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "",
            page_order_by: "",
          },
          filters: {},
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetBrokerLimitations(false);
                setContent(contentFromBackend);
              } else if (
                contentFromBackend.message.includes("no promotion clients")
              ) {
                setSuccess(true);
                setContent(contentFromBackend);
                setLoading(false);
                setLoadingWidgetBrokerLimitations(false);
                setReturnMessage(
                  "No promotions clients found yet! Wait for them or add a few ..."
                );
              } else if (contentFromBackend.message === "token invalid") { 
                setWarning(true);
                setLoading(false);
                setLoadingWidgetBrokerLimitations(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setLoadingWidgetBrokerLimitations(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetBrokerLimitations(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetBrokerLimitations(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh is promoter changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoter]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdatePromoterPortalBrokerLimitations) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdatePromoterPortalBrokerLimitations]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalPromoterPortalBrokerLimitations);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Broker Limitations" />;
  if (loading || loadingWidgetBrokerLimitations) {
    WidgetOutlet = <LoadingAnimation title="Broker Limitations" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Broker Limitations" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed content={content} promoter={promoter} />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
