// react and js
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";

// mui
import {
  Stack,
  Box,
  InputAdornment,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";
import { stateStorageInit } from "../../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  AuthenticationTextField,
  ModalContentSwitch,
  ModalActionsLoadingButton,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  localLogout,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("answer");

        if (checkKey === true) {
          if (contentFromBackend.answer.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend.answer, setReturnMessage);
            postRequestUpdate(3750);

            localLogout();
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend.answer, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend.answer, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleAccountSettingsDeleteAccount() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const [formConfirm1, setFormConfirm1] = useState(false);
  const [formConfirm2, setFormConfirm2] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateAccountSettingPersonalInformation: true,
        widgetUpdateAccountSettingPromoterInformation: true,
        widgetUpdateAccountSettingDistributorInformation: true,
        widgetUpdateAccountSettingAdministratorInformation: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  const localLogout = () => {
    setStateStorage(stateStorageInit);

    localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
    localStorage.removeItem("myLocalStateStorage");

    sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
    sessionStorage.removeItem("myLocalLastRoute");

    localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
    localStorage.removeItem("myLocalSettingStorage");

    localStorage.setItem("myReauthentication", JSON.stringify(""));
    localStorage.removeItem("myReauthentication");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email address must be a valid email address")
      .required("Email address is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "account_settings",
        operation: "delete_account",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        credentials: {
          email: values.email,
          password: values.password,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        localLogout,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);

    handleSubmit();
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B1Small fullWidth variant="contained" onClick={handleClickOpen("paper")}>
        Confirm with email and password
      </B1Small>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">Delete Account</Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Typography variant="contentMiddle">
                    Your account can only be deleted if there are no active paid
                    subscriptions and the action is confirmed with your
                    dashboard login. If you have any active paid subscriptions,
                    please cancel them now.
                    <br />
                    <br />
                    Once deleted, all your personal data will be instantly
                    removed. You will lose access to all FEA Trading
                    applications and the option to download tick data.
                    Furthermore, you will be automatically logged out.
                    <br />
                    <br />
                    Please input your dashboard login credentials below and
                    confirm with the switches:
                  </Typography>
                  <Typography
                    variant="contentSmall"
                    sx={{
                      color:
                        Boolean(touched.email && errors.email) === true
                          ? "red"
                          : "none",
                    }}
                  >
                    {Boolean(touched.email && errors.email) === true
                      ? errors.email
                      : "Email address"}
                  </Typography>

                  <AuthenticationTextField
                    autoComplete="new-password"
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    size="small"
                    type="email"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    sx={{ mt: "4px", mb: 1 }}
                    InputLabelProps={{ shrink: false }}
                  />

                  <Typography
                    variant="contentSmall"
                    sx={{
                      color:
                        Boolean(touched.password && errors.password) === true
                          ? "red"
                          : "none",
                    }}
                  >
                    {Boolean(touched.password && errors.password) === true
                      ? errors.password
                      : "Password"}
                  </Typography>

                  <AuthenticationTextField
                    autoComplete="new-password" // Avoids chrome warning
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    sx={{ mt: "4px", mb: 1 }}
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pt: 2,
                    }}
                  >
                    <Typography variant="contentMiddle">
                      I confirm hereby that I want my account to be deleted.
                    </Typography>
                    <ModalContentSwitch
                      checked={formConfirm1}
                      onChange={(event) => {
                        setFormConfirm1(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pt: 2,
                    }}
                  >
                    <Typography variant="contentMiddle">
                      Yes, I agree that all my data will be removed.
                    </Typography>
                    <ModalContentSwitch
                      checked={formConfirm2}
                      onChange={(event) => {
                        setFormConfirm2(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  disabled={
                    !formConfirm1 ||
                    !formConfirm2 ||
                    Object.keys(errors).length !== 0
                  }
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Initiate account deletion
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
