// react and js
import PropTypes from "prop-types";
import { React, useState, useContext } from "react";

// mui

// components
import backendRequest from "./BackendRequest";

// hooks
import appContext from "../hooks/appContext";

// layouts

// pages

// theme
import { B3Tiny } from "../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function DownloadPaddleInvoice({
  invoiceData,
  userRole,
  distributor,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  DownloadPaddleInvoice.propTypes = {
    invoiceData: PropTypes.any.isRequired,
    userRole: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const { stateStorage } = useContext(appContext);
  const [submitting, setSubmitting] = useState(false);

  let requestTypeSelected = "dashboard";
  let routeInfoSelected = "accounting";
  let operationSelected = "download_paddle_invoice";
  let adminSelected = {};

  if (userRole === "distributor") {
    requestTypeSelected = "dashboard";
    routeInfoSelected = "distributor_portal";
    operationSelected = "download_paddle_invoice";
    adminSelected = {
      selected_distributor_id: distributor,
    };
  }

  const RequestDownloadLink = () => {
    if (navigator.onLine === true) {
      setSubmitting(true);

      const sendData = {
        request_type: requestTypeSelected,
        route_info: routeInfoSelected,
        operation: operationSelected,
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: adminSelected,
        invoice: {
          billing_aggreement_id: invoiceData[4],
          transaction_id: invoiceData[5],
        },
      };

      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              const link = document.createElement("a");
              link.href = contentFromBackend.url;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }

          setSubmitting(false);
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setSubmitting(false);
          }
        });
    }
  };

  return (
    <B3Tiny
      fullWidth
      disabled={submitting}
      variant="outlined"
      onClick={() => {
        RequestDownloadLink();
      }}
    >
      Download invoice
    </B3Tiny>
  );
}
