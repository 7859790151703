// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Collapse,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import ModalDialog from "../../components/ModalDialog";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleEvaluationPortalWithdraw from "./actions/HandleEvaluationPortalWithdraw";
import HandleEvaluationPortalGrant from "./actions/HandleEvaluationPortalGrant";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function Details({ detailsData }) {
  Details.propTypes = {
    detailsData: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny fullWidth variant="outlined" onClick={handleClickOpen("paper")}>
        Details
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">Evaluation details</Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Typography variant="contentSmall">
                <ValueLine
                  parameter="ID"
                  value={detailsData[0]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="Account number"
                  value={detailsData[1]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="Trading server"
                  value={detailsData[2]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="Account name"
                  value={detailsData[3]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="Drive ID"
                  value={detailsData[4]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="Start dates GMT"
                  value={detailsData[5]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ValueLine
                  parameter="Start counters"
                  value={detailsData[6]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ValueLine
                  parameter="Evaluation start"
                  value={detailsData[7]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="Evaluation end"
                  value={detailsData[8]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User IP"
                  value={detailsData[9]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User city"
                  value={detailsData[10]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User state"
                  value={detailsData[11]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User country"
                  value={detailsData[12]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User country code"
                  value={detailsData[13]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User continent"
                  value={detailsData[14]}
                  widthLeft="40%"
                  widthRight="60%"
                />
                <ValueLine
                  parameter="User continent code"
                  value={detailsData[15]}
                  widthLeft="40%"
                  widthRight="60%"
                />
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  );
}

function FetchTable({ tableArray, refreshLoading }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> ID </Typography>
              </TableCell>
              <TableCell
                width="25%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">
                  Account Number
                </Typography>
              </TableCell>
              <TableCell
                width="25%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">
                  Trading Server
                </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[0]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {row[1]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {row[2]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="right"
                  >
                    {row[18] === false ? (
                      <HandleEvaluationPortalWithdraw evaluationId={row[0]}/>
                    ) : (
                      <HandleEvaluationPortalGrant evaluationId={row[0]} applicationEvaluationDays={row[17]}/>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="right"
                  >
                    <Details detailsData={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> ID </Typography>
                </Box>
                <Box sx={{ width: "50%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[0]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Account Number
                  </Typography>
                </Box>
                <Box sx={{ width: "50%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {row[1]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Trading Server
                  </Typography>
                </Box>
                <Box sx={{ width: "50%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {row[2]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  {row[18] === false ? (
                    <HandleEvaluationPortalWithdraw evaluationId={row[0]} />
                  ) : (
                    <HandleEvaluationPortalGrant evaluationId={row[0]} applicationEvaluationDays={row[17]}/>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <Details detailsData={row} />
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterAccountNumber,
  setFilterAccountNumber,
  filterTradingServer,
  setFilterTradingServer,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterAccountNumber: PropTypes.any.isRequired,
    setFilterAccountNumber: PropTypes.any.isRequired,
    filterTradingServer: PropTypes.any.isRequired,
    setFilterTradingServer: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  let evaluationPortalTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsc1f7fcfaa4896859 !== null &&
      myLocalSettingStorage.obfsc1f7fcfaa4896859 !== undefined
    ) {
      evaluationPortalTableFilterCollapseInitValue =
        myLocalSettingStorage.obfsc1f7fcfaa4896859;
    }
  }
  const [collapse, setCollapse] = useState(
    evaluationPortalTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterAccountNumber !== "" ||
      filterTradingServer !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let evaluationPortalTableFilterItemsPerPageInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfscbdd0930afb23b49 !== null &&
      myLocalSettingStorage.obfscbdd0930afb23b49 !== undefined
    ) {
      evaluationPortalTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfscbdd0930afb23b49;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    evaluationPortalTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterAccountNumber, setLocalFilterAccountNumber] =
    useState(filterAccountNumber);
  const [localFilterTradingServer, setLocalFilterTradingServer] =
    useState(filterTradingServer);

  const contentFiltered = [];
  if (content.t_evaluation !== undefined) {
    content.t_evaluation.data.forEach((account, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(account);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterAccountNumber(localFilterAccountNumber);
    setFilterTradingServer(localFilterTradingServer);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateEvaluationPortalEvaluationPortal: true,
    }));
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Evaluation Portal"
          subheader="Current free evaluation users for MetaTrader applications."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No evaluation") ? null : (
              <Box>
                <B3Tiny
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setCollapse(!collapse);

                    const newLocalSettingStorage = JSON.parse(
                      localStorage.getItem("myLocalSettingStorage")
                    );

                    newLocalSettingStorage.obfsc1f7fcfaa4896859 = !collapse;
                    localStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }}
                >
                  {collapse ? "Hide filters" : "Show filters"}
                </B3Tiny>

                <Collapse
                  sx={{ mx: -1 }}
                  in={collapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">ID</Typography>
                        <FetchTableTextField
                          value={localFilterId}
                          onChange={(event) => {
                            setLocalFilterId(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs21e0919001c115ad =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">
                          Account number
                        </Typography>
                        <FetchTableTextField
                          value={localFilterAccountNumber}
                          onChange={(event) => {
                            setLocalFilterAccountNumber(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfsf89d594e2078650c =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="contentSmall">
                          Trading server
                        </Typography>
                        <FetchTableTextField
                          value={localFilterTradingServer}
                          onChange={(event) => {
                            setLocalFilterTradingServer(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs545d251b417763e1 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="contentSmall">
                          Items per page
                        </Typography>
                        <Box sx={{ px: 1 }}>
                          <FetchTableSlider
                            size="small"
                            min={1}
                            max={100}
                            align="right"
                            value={itemsPerPages}
                            onChange={(event, newValue) => {
                              setItemsPerPages(newValue);
                              setTotalPages(
                                Math.ceil(content.num_rows / newValue)
                              );
                              setCurrentPage(1);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfscbdd0930afb23b49 =
                                newValue;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <B1Tiny
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        setFilters();
                      }}
                    >
                      Set filters and refresh output
                    </B1Tiny>
                  </Box>
                </Collapse>

                <Box sx={{ mt: 0, mb: 1 }} />

                <FetchTable
                  tableArray={contentFiltered}
                  refreshLoading={refreshLoading}
                />

                <Stack sx={{ mt: 3 }} alignItems="center">
                  <FetchTablePagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    onChange={(event, value) => {
                      setCurrentPage(value);
                    }}
                  />
                </Stack>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let evaluationPortalTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs21e0919001c115ad !== null &&
      myLocalSettingStorage.obfs21e0919001c115ad !== undefined
    ) {
      evaluationPortalTableFilterIdInitValue =
        myLocalSettingStorage.obfs21e0919001c115ad;
    }
  }
  const [filterId, setFilterId] = useState(
    evaluationPortalTableFilterIdInitValue
  );

  // Filter
  let evaluationPortalTableFilterAccountNumberInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsf89d594e2078650c !== null &&
      myLocalSettingStorage.obfsf89d594e2078650c !== undefined
    ) {
      evaluationPortalTableFilterAccountNumberInitValue =
        myLocalSettingStorage.obfsf89d594e2078650c;
    }
  }
  const [filterAccountNumber, setFilterAccountNumber] = useState(
    evaluationPortalTableFilterAccountNumberInitValue
  );

  // Filter
  let evaluationPortalTableFilterTradingServerInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs545d251b417763e1 !== null &&
      myLocalSettingStorage.obfs545d251b417763e1 !== undefined
    ) {
      evaluationPortalTableFilterTradingServerInitValue =
        myLocalSettingStorage.obfs545d251b417763e1;
    }
  }
  const [filterTradingServer, setFilterTradingServer] = useState(
    evaluationPortalTableFilterTradingServerInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateEvaluationPortalEvaluationPortal: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "evaluation_portal",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "desc",
          page_order_by: "evaluation_id",
        },
        filters: {
          evaluation_id: filterId,
          account_number: filterAccountNumber,
          account_server: filterTradingServer,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");
          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setRefreshLoading(false);
              setContent(contentFromBackend);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterAccountNumber === "" &&
                  filterTradingServer === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No evaluation users found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateEvaluationPortalEvaluationPortal) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateEvaluationPortalEvaluationPortal]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalEvaluationPortalEvaluationPortal);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Evaluation Pool" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Evaluation Pool" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Evaluation Pool" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterAccountNumber={filterAccountNumber}
        setFilterAccountNumber={setFilterAccountNumber}
        filterTradingServer={filterTradingServer}
        setFilterTradingServer={setFilterTradingServer}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return <> {WidgetOutlet} </>;
}
