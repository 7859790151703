// react and js
import { useLocation, useNavigate } from "react-router-dom";
import { React, useContext, useEffect, useMemo } from "react";

// mui
import { Grid, Container } from "@mui/material";

// components
import Page from "../../components/Page";

// hooks
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme

// utils

// widgets
import WidgetApplicationSettingsMine from "../../widgets/application_settings/ApplicationSettingsMine";
import WidgetApplicationSettingsAllUser from "../../widgets/application_settings/ApplicationSettingsAllUser";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function ApplicationSettings() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  const navigate = useNavigate();
  const location = useLocation();

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Outlet protection
  // ######################################################
  let isOutletAllowed = false;
  if (stateStorage.userInformation !== "") {
    if (
      stateStorage.userInformation.account_settings !== null &&
      stateStorage.userInformation.account_settings !== undefined
    ) {
      if (
        stateStorage.userInformation.account_settings.dashboard_features !==
          null &&
        stateStorage.userInformation.account_settings.dashboard_features !==
          undefined
      ) {
        if (
          stateStorage.userInformation.account_settings.dashboard_features
            .obfs0921d9fe7b9543ab !== null &&
          stateStorage.userInformation.account_settings.dashboard_features
            .obfs0921d9fe7b9543ab !== undefined
        ) {
          if (
            stateStorage.userInformation.account_settings.dashboard_features
              .obfs0921d9fe7b9543ab === "obfsc555894615b268f9"
          ) {
            isOutletAllowed = true;
          }
        }
      }
    }
  }

  useEffect(() => {
    if (isOutletAllowed === false) {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Main outlet
  // ######################################################

  // eslint-disable-next-line
  return useMemo(() => {
    return isOutletAllowed ? (
      <Page title="Application Settings">
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <WidgetApplicationSettingsMine />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <WidgetApplicationSettingsAllUser />
            </Grid>
          </Grid>
        </Container>
      </Page>
    ) : null;
  });
}
