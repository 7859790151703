// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Typography,
  Collapse,
  Stack,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import ModalDialog from "../../components/ModalDialog";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleEventListenersIgnore from "./actions/HandleEventListenersIgnore";
import HandleEventListenersApprove from "./actions/HandleEventListenersApprove";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function RequestDetails({
  mainDataOutlet,
  requestClientDetailsData,
  requestTradingAccountDetailsData,
}) {
  RequestDetails.propTypes = {
    mainDataOutlet: PropTypes.any.isRequired,
    requestClientDetailsData: PropTypes.any.isRequired,
    requestTradingAccountDetailsData: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny fullWidth variant="outlined" onClick={handleClickOpen("paper")}>
        Details
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Manual promotion approval events details
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                onClick={() => {
                  navigate(
                    `/promoter-portal/${requestClientDetailsData.dashboard_id}`
                  );
                }}
              >
                Manage
              </B3Tiny>
              <Typography variant="contentSmall">
                {mainDataOutlet}

                {Object.keys(requestTradingAccountDetailsData).map(
                  (parameter, index) => (
                    <ValueLine
                      key={index}
                      parameter={parameter}
                      value={requestTradingAccountDetailsData[parameter]}
                      widthLeft="40%"
                      widthRight="60%"
                    />
                  )
                )}

                {requestClientDetailsData.dashboard_id !== null ? (
                  <ValueLine
                    parameter="User ID (UID)"
                    value={requestClientDetailsData.dashboard_id}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.email !== null ? (
                  <ValueLine
                    parameter="User email"
                    value={requestClientDetailsData.email}
                    widthLeft="20%"
                    widthRight="80%"
                  />
                ) : null}
                {requestClientDetailsData.forename !== null ? (
                  <ValueLine
                    parameter="User forename"
                    value={requestClientDetailsData.forename}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.surname !== null ? (
                  <ValueLine
                    parameter="User surname"
                    value={requestClientDetailsData.surname}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.birth !== null ? (
                  <ValueLine
                    parameter="User birth"
                    value={requestClientDetailsData.birth}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.gender !== null ? (
                  <ValueLine
                    parameter="User gender"
                    value={requestClientDetailsData.gender}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.organization !== null ? (
                  <ValueLine
                    parameter="User organization"
                    value={requestClientDetailsData.organization}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.street !== null ? (
                  <ValueLine
                    parameter="User street"
                    value={requestClientDetailsData.street}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.optional_line !== null ? (
                  <ValueLine
                    parameter="User optional address line"
                    value={requestClientDetailsData.optional_line}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.postal_code !== null ? (
                  <ValueLine
                    parameter="User postal code"
                    value={requestClientDetailsData.postal_code}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.city !== null ? (
                  <ValueLine
                    parameter="User city"
                    value={requestClientDetailsData.city}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.province !== null ? (
                  <ValueLine
                    parameter="User province"
                    value={requestClientDetailsData.province}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.country !== null ? (
                  <ValueLine
                    parameter="User country"
                    value={requestClientDetailsData.country}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
                {requestClientDetailsData.phone !== null ? (
                  <ValueLine
                    parameter="User phone"
                    value={requestClientDetailsData.phone}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  );
}

function FetchTable({ tableArray, refreshLoading }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Event ID </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">
                  {" "}
                  Event date GMT
                </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">
                  Promoter ID (PID)
                </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">
                  Promoter Link ID
                </Typography>
              </TableCell>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[0]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[1]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[4]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[5]} </Typography>
                  </TableCell>

                  {!(row[6] === "1") ? (
                    <>
                      <TableCell
                        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                        align="left"
                      >
                        <HandleEventListenersIgnore eventNo={row[0]} />
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                        align="left"
                      >
                        <HandleEventListenersApprove eventNo={row[0]} />
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                        align="right"
                      >
                        <RequestDetails
                          mainDataOutlet={
                            <>
                              <ValueLine
                                parameter="Event ID"
                                value={row[0]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                              <ValueLine
                                parameter="Event date GMT"
                                value={row[1]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                              <ValueLine
                                parameter="Promoter ID (PID)"
                                value={row[4]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                              <ValueLine
                                parameter="Promoter link ID"
                                value={row[5]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                            </>
                          }
                          requestClientDetailsData={row[16]}
                          requestTradingAccountDetailsData={row[17]}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                        align="left"
                      >
                        -
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                        align="left"
                      >
                        -
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                        align="right"
                      >
                        <RequestDetails
                          mainDataOutlet={
                            <>
                              <ValueLine
                                parameter="Event ID"
                                value={row[0]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                              <ValueLine
                                parameter="Event date GMT"
                                value={row[1]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                              <ValueLine
                                parameter="Promoter ID (PID)"
                                value={row[4]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                              <ValueLine
                                parameter="Promoter link ID"
                                value={row[5]}
                                widthLeft="40%"
                                widthRight="60%"
                              />
                            </>
                          }
                          requestClientDetailsData={row[16]}
                          requestTradingAccountDetailsData={row[17]}
                        />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "40%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Event ID </Typography>
                </Box>
                <Box sx={{ width: "60%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[0]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "40%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    {" "}
                    Event date GMT
                  </Typography>
                </Box>
                <Box sx={{ width: "60%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[1]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "40%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Promoter ID (PID)
                  </Typography>
                </Box>
                <Box sx={{ width: "60%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[4]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "40%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Promoter Link ID
                  </Typography>
                </Box>
                <Box sx={{ width: "60%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[5]}</Typography>
                </Box>
              </Box>

              {!(row[6] === "1") ? (
                <>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100%" }}>
                      <HandleEventListenersIgnore eventNo={row[0]} />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100%" }}>
                      <HandleEventListenersApprove eventNo={row[0]} />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100%" }}>
                      <RequestDetails
                        mainDataOutlet={
                          <>
                            <ValueLine
                              parameter="Event ID"
                              value={row[0]}
                              widthLeft="40%"
                              widthRight="60%"
                            />
                            <ValueLine
                              parameter="Event date GMT"
                              value={row[1]}
                              widthLeft="40%"
                              widthRight="60%"
                            />
                            <ValueLine
                              parameter="Promoter ID (PID)"
                              value={row[4]}
                              widthLeft="40%"
                              widthRight="60%"
                            />
                            <ValueLine
                              parameter="Promoter link ID"
                              value={row[5]}
                              widthLeft="40%"
                              widthRight="60%"
                            />
                          </>
                        }
                        requestClientDetailsData={row[16]}
                        requestTradingAccountDetailsData={row[17]}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "100%" }}>
                    <RequestDetails
                      mainDataOutlet={
                        <>
                          <ValueLine
                            parameter="Event ID"
                            value={row[0]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                          <ValueLine
                            parameter="Event date GMT"
                            value={row[1]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                          <ValueLine
                            parameter="Promoter ID (PID)"
                            value={row[4]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                          <ValueLine
                            parameter="Promoter link ID"
                            value={row[5]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                        </>
                      }
                      requestClientDetailsData={row[16]}
                      requestTradingAccountDetailsData={row[17]}
                    />
                  </Box>
                </Box>
              )}
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterDate,
  setFilterDate,
  filterPromoterId,
  setFilterPromoterId,
  filterPromoterLinkId,
  setFilterPromoterLinkId,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterDate: PropTypes.any.isRequired,
    setFilterDate: PropTypes.any.isRequired,
    filterPromoterId: PropTypes.any.isRequired,
    setFilterPromoterId: PropTypes.any.isRequired,
    filterPromoterLinkId: PropTypes.any.isRequired,
    setFilterPromoterLinkId: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let eventListenersManualPromotionApprovalEventsTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs92fd163c7a26d982 !== null &&
      myLocalSettingStorage.obfs92fd163c7a26d982 !== undefined
    ) {
      eventListenersManualPromotionApprovalEventsTableFilterCollapseInitValue =
        myLocalSettingStorage.obfs92fd163c7a26d982;
    }
  }
  const [collapse, setCollapse] = useState(
    eventListenersManualPromotionApprovalEventsTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterDate !== "" ||
      filterPromoterId !== "" ||
      filterPromoterLinkId !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let eventListenersManualPromotionApprovalEventsTableFilterItemsPerPageInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs8e98259618dd0d5b !== null &&
      myLocalSettingStorage.obfs8e98259618dd0d5b !== undefined
    ) {
      eventListenersManualPromotionApprovalEventsTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfs8e98259618dd0d5b;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    eventListenersManualPromotionApprovalEventsTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterDate, setLocalFilterDate] = useState(filterDate);
  const [localFilterPromoterId, setLocalFilterPromoterId] =
    useState(filterPromoterId);
  const [localFilterPromoterLinkId, setLocalFilterPromoterLinkId] =
    useState(filterPromoterLinkId);

  const contentFiltered = [];
  if (content.t_promotion_approval_events !== undefined) {
    content.t_promotion_approval_events.data.forEach((account, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(account);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterDate(localFilterDate);
    setFilterPromoterId(localFilterPromoterId);
    setFilterPromoterLinkId(localFilterPromoterLinkId);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateEventListenersPromotionApprovalEvents: true,
    }));
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Manual Promotion Approval Events"
          subheader="Pending and processed manual promotion approval events for all promoters."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No events") ? null : (
              <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
                {isDesktop === false ? (
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setMobileCollapse(!mobileCollapse);
                    }}
                  >
                    {mobileCollapse ? "Close" : "Open"}
                  </B3Tiny>
                ) : null}

                <Collapse
                  sx={{ mx: -1, px: 1 }}
                  in={mobileCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setCollapse(!collapse);

                      const newLocalSettingStorage = JSON.parse(
                        localStorage.getItem("myLocalSettingStorage")
                      );

                      newLocalSettingStorage.obfs92fd163c7a26d982 = !collapse;
                      localStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                  >
                    {collapse ? "Hide filters" : "Show filters"}
                  </B3Tiny>

                  <Collapse
                    sx={{ mx: -1 }}
                    in={collapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                      <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">ID</Typography>
                          <FetchTableTextField
                            value={localFilterId}
                            onChange={(event) => {
                              setLocalFilterId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs94a70a601a908031 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Date</Typography>
                          <FetchTableTextField
                            value={localFilterDate}
                            onChange={(event) => {
                              setLocalFilterDate(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfsde1bc431985dd383 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Promoter ID (PID)
                          </Typography>
                          <FetchTableTextField
                            value={localFilterPromoterId}
                            onChange={(event) => {
                              setLocalFilterPromoterId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs2d7175299904c2b6 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Promoter link ID
                          </Typography>
                          <FetchTableTextField
                            value={localFilterPromoterLinkId}
                            onChange={(event) => {
                              setLocalFilterPromoterLinkId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfse823b305289bb791 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Items per page
                          </Typography>
                          <Box sx={{ px: 1 }}>
                            <FetchTableSlider
                              size="small"
                              min={1}
                              max={100}
                              align="right"
                              value={itemsPerPages}
                              onChange={(event, newValue) => {
                                setItemsPerPages(newValue);
                                setTotalPages(
                                  Math.ceil(content.num_rows / newValue)
                                );
                                setCurrentPage(1);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfs8e98259618dd0d5b =
                                  newValue;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <B1Tiny
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setFilters();
                        }}
                      >
                        Set filters and refresh output
                      </B1Tiny>
                    </Box>
                  </Collapse>

                  <Box sx={{ mt: 0, mb: 1 }} />

                  <FetchTable
                    tableArray={contentFiltered}
                    refreshLoading={refreshLoading}
                  />

                  <Stack sx={{ mt: 3 }} alignItems="center">
                    <FetchTablePagination
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                      }}
                    />
                  </Stack>
                </Collapse>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let eventListenersManualPromotionApprovalEventsTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs94a70a601a908031 !== null &&
      myLocalSettingStorage.obfs94a70a601a908031 !== undefined
    ) {
      eventListenersManualPromotionApprovalEventsTableFilterIdInitValue =
        myLocalSettingStorage.obfs94a70a601a908031;
    }
  }
  const [filterId, setFilterId] = useState(
    eventListenersManualPromotionApprovalEventsTableFilterIdInitValue
  );

  // Filter
  let eventListenersManualPromotionApprovalEventsTableFilterDateInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsde1bc431985dd383 !== null &&
      myLocalSettingStorage.obfsde1bc431985dd383 !== undefined
    ) {
      eventListenersManualPromotionApprovalEventsTableFilterDateInitValue =
        myLocalSettingStorage.obfsde1bc431985dd383;
    }
  }
  const [filterDate, setFilterDate] = useState(
    eventListenersManualPromotionApprovalEventsTableFilterDateInitValue
  );

  // Filter
  let eventListenersManualPromotionApprovalEventsTableFilterPromoterIdInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs2d7175299904c2b6 !== null &&
      myLocalSettingStorage.obfs2d7175299904c2b6 !== undefined
    ) {
      eventListenersManualPromotionApprovalEventsTableFilterPromoterIdInitValue =
        myLocalSettingStorage.obfs2d7175299904c2b6;
    }
  }
  const [filterPromoterId, setFilterPromoterId] = useState(
    eventListenersManualPromotionApprovalEventsTableFilterPromoterIdInitValue
  );

  // Filter
  let eventListenersManualPromotionApprovalEventsTableFilterPromoterLinkIdInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfse823b305289bb791 !== null &&
      myLocalSettingStorage.obfse823b305289bb791 !== undefined
    ) {
      eventListenersManualPromotionApprovalEventsTableFilterPromoterLinkIdInitValue =
        myLocalSettingStorage.obfse823b305289bb791;
    }
  }
  const [filterPromoterLinkId, setFilterPromoterLinkId] = useState(
    eventListenersManualPromotionApprovalEventsTableFilterPromoterLinkIdInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateEventListenersPromotionApprovalEvents: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "event_listeners",
        widget: "promotion_approval_events",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "desc",
          page_order_by: "event_no",
        },
        filters: {
          event_no: filterId,
          event_time: filterDate,
          event_promoter_id: filterPromoterId,
          event_promoter_link_id: filterPromoterLinkId,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setRefreshLoading(false);
              setContent(contentFromBackend);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterDate === "" &&
                  filterPromoterId === "" &&
                  filterPromoterLinkId === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No events found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateEventListenersPromotionApprovalEvents) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateEventListenersPromotionApprovalEvents]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalEventListenersPromotionApprovalEvents);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <MissingContent message="Manual Promotion Approval Events" />
  );
  if (loading) {
    WidgetOutlet = (
      <LoadingAnimation title="Manual Promotion Approval Events" />
    );
  } else if (error) {
    WidgetOutlet = (
      <MissingContent message="Manual Promotion Approval Events" />
    );
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterPromoterId={filterPromoterId}
        setFilterPromoterId={setFilterPromoterId}
        filterPromoterLinkId={filterPromoterLinkId}
        setFilterPromoterLinkId={setFilterPromoterLinkId}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return <> {WidgetOutlet} </>;
}
