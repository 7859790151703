// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import {} from "@mui/material";

// components
import CardOutlet from "../../components/CardOutlet";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({ title, subheader }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
  };

  return (
    <CardOutlet
      variant="description"
      backgroundProp="#F2F2F2"
      title={title}
      subheader={subheader}
    />
  );
}
