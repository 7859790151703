// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import { enCA } from "date-fns/locale";
import Autocomplete from "@mui/material/Autocomplete";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";
import CountrySelect from "../../../components/CountrySelect";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentSwitch,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalDatePicker,
  ModalDatePickerSlotProps,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandlePromoterPortalAddNewPromotionClient({
  promoter,
  promoterLinkId,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandlePromoterPortalAddNewPromotionClient.propTypes = {
    promoter: PropTypes.any.isRequired,
    promoterLinkId: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        layoutUpdateFeatures: true,
        widgetUpdatePromoterPortalPromotionClients: true,
        widgetUpdatePromoterPortalManagePromotionClient: true,
        widgetUpdatePromoterPortalClientAndClickContingent: true,
        widgetUpdatePromoterPortalPendingPromotionClients: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  const [formSendPasswordReset, setFormSendPasswordReset] = useState(true);
  const [formSendApprovalEmail, setFormSendApprovalEmail] = useState(false);

  const [formGender, setFormGender] = useState("");
  const [formMarketingShares, setFormMarketingShares] = useState(true);
  const [formTailorizedAdds, setFormTailorizedAdds] = useState(true);
  const [formEmailNewsletter, setFormEmailNewsletter] = useState(true);
  const [formBirth, setFormBirth] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const myValidationScheme = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your new password")
      .min(8, "Password must be 8 characters long")
      .max(64, "Password must be shorter than 64 characters")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter"),
    organization: Yup.string(),
    gender: Yup.string(),
    forename: Yup.string(),
    surname: Yup.string(),
    province: Yup.string(),
    city: Yup.string(),
    postal_code: Yup.string(),
    street: Yup.string(),
    optional_line: Yup.string(),
    phone: Yup.string().matches(phoneRegExp, "phone is not valid"),
    live_account: Yup.number().positive().integer(),
    live_server: Yup.string(),
    demo_account: Yup.number().positive().integer(),
    demo_server: Yup.string(),
  });

  const generatePassword = (
    length = 24,
    wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
  ) =>
    Array.from(crypto.getRandomValues(new Uint32Array(length)))
      .map((x) => wishlist[x % wishlist.length])
      .join("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: generatePassword(),
      organization: "",
      gender: "",
      forename: "",
      surname: "",
      birth: null,
      country: null,
      province: "",
      city: "",
      postal_code: "",
      street: "",
      optional_line: "",
      phone: "",
      live_account: "",
      live_server: "",
      demo_account: "",
      demo_server: "",
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      // Calculate accounts
      const calcAccounts = [];
      let accountCounter = 1;

      if (values.live_account !== "") {
        calcAccounts.push({
          operation_id: accountCounter,
          type: "live",
          account: values.live_account,
          server: values.live_server,
        });
        accountCounter += 1;
      }

      if (values.demo_server !== "") {
        calcAccounts.push({
          operation_id: accountCounter,
          type: "demo",
          account: values.demo_account,
          server: values.demo_server,
        });
        accountCounter += 1;
      }

      let birthConverted = "";
      if (formBirth !== null && formBirth !== "" && formBirth !== undefined) {
        birthConverted = `${formBirth.toLocaleDateString("en-CA")}`;
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "promoter_portal",
        widget: "client_and_account_management",
        operation: "add_new_promotion_client",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_promoter_id: promoter,
        },
        used_promoter_link_id: promoterLinkId,
        send_password_reset_email: formSendPasswordReset,
        send_approval_email: formSendApprovalEmail,
        dashboard: {
          email: values.email,
          password: values.password,
          organization: values.organization,
          gender: formGender,
          forename: values.forename,
          surname: values.surname,
          birth: birthConverted,
          marketing_share: formMarketingShares ? "1" : "0",
          tailorized_adds: formTailorizedAdds ? "1" : "0",
          email_notification_settings: `newsletter=${
            formEmailNewsletter ? "1" : "0"
          },`,
          country: values.country === null ? "" : values.country,
          province: values.province,
          city: values.city,
          postal_code: values.postal_code,
          street: values.street,
          optional_line: values.optional_line,
          phone: values.phone,
        },
        accounts: calcAccounts,
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny fullWidth variant="outlined" onClick={handleClickOpen("paper")}>
        Add new promotion client
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Add new promotion client
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.email && errors.email) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.email && errors.email) === true
                        ? "Invalid email"
                        : "Email"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.password && errors.password) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.password && errors.password) === true
                        ? errors.password
                        : "Password"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("password")}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      py: 2,
                    }}
                  >
                    <Typography variant="contentSmall">
                      Send password reset email
                    </Typography>
                    <ModalContentSwitch
                      checked={formSendPasswordReset}
                      onChange={(event) => {
                        setFormSendPasswordReset(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pb: 2,
                    }}
                  >
                    <Typography variant="contentSmall">
                      Send email approval email
                    </Typography>
                    <ModalContentSwitch
                      checked={formSendApprovalEmail}
                      onChange={(event) => {
                        setFormSendApprovalEmail(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.forename && errors.forename) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.forename && errors.forename) === true
                        ? "Forename is required"
                        : "Forename"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("forename")}
                      error={Boolean(touched.forename && errors.forename)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.surname && errors.surname) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.surname && errors.surname) === true
                        ? "Surname is required"
                        : "Surname"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("surname")}
                      error={Boolean(touched.surname && errors.surname)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.organization && errors.organization
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.organization && errors.organization) ===
                      true
                        ? "Invalid organization"
                        : "Organization"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("organization")}
                      error={Boolean(
                        touched.organization && errors.organization
                      )}
                    />
                  </Box>

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">Gender</Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formGender}
                          onChange={(event) => {
                            setFormGender(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="">
                            <em>Choose gender ...</em>
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="male">
                            Male
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="female">
                            Female
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="other">
                            Other
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">Gender</Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formGender}
                          onChange={(event) => {
                            setFormGender(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="">Choose gender ...</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box>
                    <Typography variant="contentSmall">Birth</Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enCA}
                    >
                      <ModalDatePicker
                        disableFuture
                        value={formBirth}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            const value = new Date(
                              `${newValue.toLocaleDateString("en-CA")}T00:00:00`
                            );
                            setFormBirth(value);
                          } else {
                            setFormBirth(null);
                          }
                        }}
                        slotProps={ModalDatePickerSlotProps}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box>
                    <Typography variant="contentSmall">Country</Typography>
                    <CountrySelect
                      error={Boolean(touched.country && errors.country)}
                      initCountry={null}
                      setFormCountry={(newValue) => {
                        values.country = newValue;
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.province && errors.province) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.province && errors.province) === true
                        ? "Province / State is required"
                        : "Province / State"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("province")}
                      error={Boolean(touched.province && errors.province)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.city && errors.city) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.city && errors.city) === true
                        ? "City is required"
                        : "City"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("city")}
                      error={Boolean(touched.city && errors.city)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.postal_code && errors.postal_code) ===
                          true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.postal_code && errors.postal_code) ===
                      true
                        ? "Postal code is required"
                        : "Postal code"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("postal_code")}
                      error={Boolean(touched.postal_code && errors.postal_code)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.street && errors.street) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.street && errors.street) === true
                        ? "Street is required"
                        : "Street"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("street")}
                      error={Boolean(touched.street && errors.street)}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.optional_line && errors.optional_line
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.optional_line && errors.optional_line
                      ) === true
                        ? "Invalid optional address line"
                        : "Optional address line"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("optional_line")}
                      error={Boolean(
                        touched.optional_line && errors.optional_line
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.phone && errors.phone) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.phone && errors.phone) === true
                        ? "Invalid phone"
                        : "Phone"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      py: 2,
                    }}
                  >
                    <Typography variant="contentSmall">
                      Use of personal data for marketing purposes
                    </Typography>
                    <ModalContentSwitch
                      checked={formMarketingShares}
                      onChange={(event) => {
                        setFormMarketingShares(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pb: 2,
                    }}
                  >
                    <Typography variant="contentSmall">
                      Use of personal data for tailorized adds and product
                      offers
                    </Typography>
                    <ModalContentSwitch
                      checked={formTailorizedAdds}
                      onChange={(event) => {
                        setFormTailorizedAdds(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      pb: 2,
                    }}
                  >
                    <Typography variant="contentSmall">Newsletter</Typography>
                    <ModalContentSwitch
                      checked={formEmailNewsletter}
                      onChange={(event) => {
                        setFormEmailNewsletter(event.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>

                  <Box sx={{ pt: 3 }}>
                    <Typography variant="contentMiddleBold">
                      Initial live account (at least one required, live, demo,
                      or both)
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.live_account && errors.live_account
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.live_account && errors.live_account) ===
                      true
                        ? "Invalid live account number"
                        : "Live account number"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, value) => {
                        values.live_account = value;
                      }}
                      id="combo-box-demo"
                      options={[]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps("live_account")}
                          error={Boolean(
                            touched.live_account && errors.live_account
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.live_server && errors.live_server) ===
                          true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.live_server && errors.live_server) ===
                      true
                        ? "Invalid live trading server"
                        : "Live trading server"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, value) => {
                        values.live_server = value;
                      }}
                      id="combo-box-demo"
                      options={[]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps("live_server")}
                          error={Boolean(
                            touched.live_server && errors.live_server
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box sx={{ pt: 3 }}>
                    <Typography variant="contentMiddleBold">
                      Initial demo account (at least one required, live, demo,
                      or both)
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.demo_account && errors.demo_account
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.demo_account && errors.demo_account) ===
                      true
                        ? "Invalid demo account number"
                        : "Demo account number"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, value) => {
                        values.demo_account = value;
                      }}
                      id="combo-box-demo"
                      options={[]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps("demo_account")}
                          error={Boolean(
                            touched.demo_account && errors.demo_account
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(touched.demo_server && errors.demo_server) ===
                          true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(touched.demo_server && errors.demo_server) ===
                      true
                        ? "Invalid demo trading server"
                        : "Demo trading server"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, value) => {
                        values.demo_server = value;
                      }}
                      id="combo-box-demo"
                      options={[]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps("demo_server")}
                          error={Boolean(
                            touched.demo_server && errors.demo_server
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Add
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
