// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// mui
import {
  Grid,
  Stack,
  Link,
  Typography,
  Box,
  FormControl,
  Select,
  NativeSelect,
} from "@mui/material";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  CardOutlinedInput,
  CardMenuItem,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value === "1") {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "0") {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "extended_client_data_missing") {
      valueOutlet = (
        <Typography variant="contentSmall" align="right">
          Missing
        </Typography>
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function Item({ header, subtitle, content }) {
  Item.propTypes = {
    header: PropTypes.any.isRequired,
    subtitle: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  let currentYearAvailable = true;
  if (content[1].installer_end_date === "1970-01-31") {
    currentYearAvailable = false;
  }

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: "0px",
          border: "1px solid #E0E0E0",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Stack>
          <Box
            sx={{ backgroundColor: "#F2F2F2" }}
            display="block"
            align="center"
          >
            <Typography
              variant="h5"
              display="block"
              align="center"
              sx={{ mt: "14px" }}
            >
              <Link
                variant="h5"
                underline="none"
                color="inherit"
                target="_blank"
                href={content[0].home_url}
              >
                {header}
              </Link>
            </Typography>

            <Typography
              variant="contentMiddle"
              display="block"
              align="center"
              sx={{ mb: "14px" }}
            >
              {subtitle}
            </Typography>
          </Box>

          <Box
            sx={{
              background: "white",
              p: 2,
            }}
          >
            <ValueLine
              parameter="Type"
              value="executable installer"
              widthLeft="50%"
              widthRight="50%"
            />

            <ValueLine
              parameter="File size - full years"
              value={formatBytes(content[0].file_size)}
              widthLeft="60%"
              widthRight="40%"
            />

            {currentYearAvailable === true ? (
              <ValueLine
                parameter="File size - current year"
                value={formatBytes(content[1].file_size)}
                widthLeft="60%"
                widthRight="40%"
              />
            ) : null}

            <ValueLine
              parameter="MT4 compactibility"
              value={content[0].mt4_compactibility}
              widthLeft="80%"
              widthRight="20%"
            />

            <ValueLine
              parameter="MT5 compactibility"
              value={content[0].mt5_compactibility}
              widthLeft="80%"
              widthRight="20%"
            />

            <ValueLine
              parameter="Tick data start"
              value={content[0].start_date}
              widthLeft="50%"
              widthRight="50%"
            />

            <Box sx={{ mt: 2 }} />

            {content[0].getting_started_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content[0].getting_started_url}
              >
                Getting started
              </B3Tiny>
            ) : null}

            {content[0].user_manual_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content[0].user_manual_url}
              >
                User manual
              </B3Tiny>
            ) : null}

            {content[0].tutorial_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content[0].tutorial_url}
              >
                Examples and tutorials
              </B3Tiny>
            ) : null}

            <B3Tiny
              fullWidth
              sx={{ mb: 1 }}
              variant="outlined"
              target="_blank"
              href={`${
                content[0].user_manual_url.replace("category/", "user-manuals/")
              }/quality-reports/${content[0].tag.replace("full_years_", "").replace("_spread_low", "").replace("_spread_medium", "").replace("_spread_high", "")}`}
            >
              Quality reports
            </B3Tiny>

            <B1Tiny
              sx={{ mb: currentYearAvailable === true ? 1 : 0 }}
              fullWidth
              variant="contained"
              href={`${process.env.REACT_APP_URL_API_DOWNLOAD}/?tag=${content[0].tag}&token=${stateStorage.userInformation.answer.dashboard_access_token}`}
            >
              Download full years
            </B1Tiny>

            {currentYearAvailable === true ? (
              <B1Tiny
                fullWidth
                variant="contained"
                href={`${process.env.REACT_APP_URL_API_DOWNLOAD}/?tag=${content[1].tag}&token=${stateStorage.userInformation.answer.dashboard_access_token}`}
              >
                Download current year
              </B1Tiny>
            ) : null}
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

function DownloadItem({ content, selection }) {
  DownloadItem.propTypes = {
    content: PropTypes.any.isRequired,
    selection: PropTypes.any.isRequired,
  };

  return (
    <>
      <Item
        header="LOW SPREAD TICK DATA"
        subtitle={selection}
        content={content.spread_low}
      />
      <Item
        header="MEDIUM SPREAD TICK DATA"
        subtitle={selection}
        content={content.spread_medium}
      />
      <Item
        header="HIGH SPREAD TICK DATA"
        subtitle={selection}
        content={content.spread_high}
      />
    </>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let keys = "";
  let checkKey = "";
  let tickDataSubscriptionsAvailable = false;

  if (content === undefined || content === null) {
    checkKey = false;
  } else {
    keys = Object.keys(content);
    checkKey = keys.includes("tick_data");
  }

  keys = Object.keys(content.tick_data);
  const symbols = [];

  for (let index = 0; index < keys.length; index += 1) {
    symbols.push(keys[index]);
  }

  if (symbols.length === 0) {
    checkKey = true; // false;
  } else {
    tickDataSubscriptionsAvailable = true;
  }

  let downloadTickDataSymbolInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs4935af76d5753ad6 !== null &&
      myLocalSettingStorage.obfs4935af76d5753ad6 !== undefined
    ) {
      if (Number(myLocalSettingStorage.obfs4935af76d5753ad6) < symbols.length) {
        downloadTickDataSymbolInitValue =
          myLocalSettingStorage.obfs4935af76d5753ad6;
      }
    }
  }

  const [selectedSymbol, setSelectedSymbol] = useState(
    downloadTickDataSymbolInitValue
  );

  if (selectedSymbol >= symbols.length && selectedSymbol !== symbols.length) {
    setSelectedSymbol(0);
  }

  return checkKey ? (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 5, mb: 0 }}>
          <CardOutlet
            variant={symbols.length > 1 ? "outlet" : "description"}
            backgroundProp="#F2F2F2"
            title="Tick Data Downloads"
            subheader="Tick data installers are categorized into six files per symbol. For each spread configuration, you can download an installer for previous full years (e.g., 2003-2022) and the current year (e.g., 2023/01 - 2023/05). If the year has just started, only one installer for the previous full years is available."
            mainOutlet={
              isDesktop ? (
                <Box>
                  <Typography variant="contentSmall">Symbol</Typography>
                  <FormControl fullWidth>
                    <Select
                      value={selectedSymbol}
                      onChange={(event) => {
                        setSelectedSymbol(event.target.value);

                        const newLocalSettingStorage = JSON.parse(
                          localStorage.getItem("myLocalSettingStorage")
                        );

                        newLocalSettingStorage.obfs4935af76d5753ad6 =
                          event.target.value;
                        localStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      input={<CardOutlinedInput />}
                    >
                      {symbols.map((symbol, index) => (
                        <CardMenuItem key={symbol} value={index}>
                          {symbol.replace("_", ".")}
                        </CardMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Box>
                  <Typography variant="contentSmall">Symbol</Typography>
                  <FormControl fullWidth>
                    <NativeSelect
                      value={selectedSymbol}
                      onChange={(event) => {
                        setSelectedSymbol(event.target.value);

                        const newLocalSettingStorage = JSON.parse(
                          localStorage.getItem("myLocalSettingStorage")
                        );

                        newLocalSettingStorage.obfs4935af76d5753ad6 =
                          event.target.value;
                        localStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      input={<CardOutlinedInput />}
                    >
                      {symbols.map((symbol, index) => (
                        <option key={symbol} value={index}>
                          {symbol.replace("_", ".")}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Box>
              )
            }
          />
        </Box>
      </Grid>

      {symbols[selectedSymbol] !== undefined ? (
        <DownloadItem
          content={content.tick_data[symbols[selectedSymbol]]}
          selection={symbols[selectedSymbol]}
        />
      ) : null}

      {!tickDataSubscriptionsAvailable ? (
        <Grid item xs={12} md={12} lg={12}>
          <Box
            display="block"
            align="center"
            sx={{
              borderRadius: "0px",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
              py: 1,
            }}
          >
            <Typography
              variant="contentBig"
              display="block"
              align="center"
              sx={{ m: "14px" }}
            >
              No tick data subscription active ...
              <Typography variant="contentBigBold">
                {/* eslint-disable-next-line */}
                <Link
                  href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution-tick-data`}
                  target="_blank"
                  underline="none"
                  variant="contentBigLink"
                  color="inherit"
                >
                  {` discover tick data plans`}
                </Link>
              </Typography>
              .
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <> </>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateDownloadsMt5TickData: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "downloads",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDownloadsMt5TickData) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDownloadsMt5TickData]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDownloadsMt5TickData);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = null;
  if (loading) {
    WidgetOutlet = null;
  } else if (error) {
    WidgetOutlet = null;
  } else if (content) {
    WidgetOutlet = <WidgetMainContentProcessed content={content} />;
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
