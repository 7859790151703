// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import { Grid, Typography, Box } from "@mui/material";

// components
import Iconify from "../../components/Iconify";
import CardOutlet from "../../components/CardOutlet";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value.includes("new_subscriber=1")) {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }
    if (value.includes("new_subscriber=0")) {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const PromoterId = content.config_data.config_data_promoter.promoter_id
    
    ;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <ValueLine
          parameter="Promoter ID (PID)"
          value={`${PromoterId.substring(0, 2)}${PromoterId.substring(2, 5)}`}
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Forename"
          value={content.config_data.config_data_promoter.promoter_forename
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Surname"
          value={content.config_data.config_data_promoter.promoter_surname
            
            }
          widthLeft="25%"
          widthRight="75%"
        />

        <ValueLine
          parameter="Notification email"
          value={content.config_data.config_data_promoter.notification_email
            
            }
          widthLeft="40%"
          widthRight="60%"
        />
        <ValueLine
          parameter="Client contact email"
          value={content.config_data.config_data_promoter.client_contact_email
            
            }
          widthLeft="40%"
          widthRight="60%"
        />
        <ValueLine
          parameter="Notifications for new clients"
          value={content.config_data.config_data_promoter.email_notification_settings
            
            }
          widthLeft="80%"
          widthRight="20%"
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({ title, subheader, content }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    title: PropTypes.any.isRequired,
    subheader: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title={title}
      subheader={subheader}
      mainOutlet={<WidgetMainContentProcessed content={content} />}
    />
  );
}
