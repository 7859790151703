// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Stack,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import { DownloadInvoice } from "../../components/AccountingDownloads/AccountingDownloads";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import DownloadPaddleInvoice from "../../components/DownloadPaddleInvoice";
import HandleBillingAddressUpdateInformation from "./actions/HandleBillingAddressUpdateInformation";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  B1Tiny,
  B3Tiny,
  B3Small,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
  ModalAlertWarning,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentMiddle" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value.includes("newsletter=1")) {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }
    if (value.includes("newsletter=0")) {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentMiddle" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentMiddle"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function numberWithCommas(x) {
  return parseFloat(x)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function FetchTable({ tableArray, refreshLoading }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="16%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> ID </Typography>
              </TableCell>
              <TableCell
                width="14%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Date </Typography>
              </TableCell>
              <TableCell
                width="40%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">Description</Typography>
              </TableCell>
              <TableCell
                width="14%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Amount </Typography>
              </TableCell>
              <TableCell
                width="16%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[35] !== "" ? (
                      <Typography variant="contentSmall">{row[35]}</Typography>
                    ) : (
                      <Typography variant="contentSmall">{row[0]}</Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall">
                      {row[2].substring(0, 10)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[7]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[10] !== "0" ? (
                      <Typography variant="contentSmall">
                        {numberWithCommas(
                          (Math.ceil(row[10] * 100) / 100).toFixed(2)
                        )}{" "}
                        {row[29].replace("EUR", "€")}
                      </Typography>
                    ) : (
                      <Typography variant="contentSmall">{row[11]}</Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[6].toLowerCase() !== "paddle" ? (
                      <>
                        <B3Tiny
                          fullWidth
                          variant="outlined"
                          onClick={() => {
                            DownloadInvoice(row);
                          }}
                        >
                          Download invoice
                        </B3Tiny>

                        {row[44] !== "" && row[3] == null ? (
                          <B1Tiny
                            sx={{ mt: 1 }}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              const h = 720;
                              const w = 1000;

                              const y =
                                window.top.outerHeight / 2 +
                                window.top.screenY -
                                h / 2;
                              const x =
                                window.top.outerWidth / 2 +
                                window.top.screenX -
                                w / 2;
                              const openedWindow = window.open(
                                `${process.env.REACT_APP_URL_API_EVENT_LISTENER}?request_type=pay_invoice_online&billing_id=${row[0]}&online_payable=${row[44]}`,
                                "checkout",
                                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
                              );
                              if (openedWindow !== null) {
                                openedWindow.focus();
                              }
                            }}
                          >
                            Pay online
                          </B1Tiny>
                        ) : null}

                        {row[44] === "" && row[3] == null ? (
                          <Box
                            sx={{
                              mt: 1,
                              bgcolor: "#ffe79a",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="contentTiny">
                              Invoice is unpaid!
                            </Typography>
                          </Box>
                        ) : null}
                      </>
                    ) : (
                      <DownloadPaddleInvoice
                        invoiceData={row}
                        userRole="client"
                        distributor=""
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> ID </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  {row[35] !== "" ? (
                    <Typography variant="contentSmall">{row[35]}</Typography>
                  ) : (
                    <Typography variant="contentSmall">{row[0]}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Date </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">
                    {row[2].substring(0, 10)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Description
                  </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[7]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Amount </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  {row[10] !== "0" ? (
                    <Typography variant="contentSmall">
                      {numberWithCommas(
                        (Math.ceil(row[10] * 100) / 100).toFixed(2)
                      )}{" "}
                      {row[29].replace("EUR", "€")}
                    </Typography>
                  ) : (
                    <Typography variant="contentSmall">{row[11]}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  {row[6].toLowerCase() !== "paddle" ? (
                    <>
                      <B3Tiny
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          DownloadInvoice(row);
                        }}
                      >
                        Download invoice
                      </B3Tiny>

                      {row[44] !== "" && row[3] == null ? (
                        <B1Tiny
                          sx={{ mt: 1 }}
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            const h = 720;
                            const w = 1000;

                            const y =
                              window.top.outerHeight / 2 +
                              window.top.screenY -
                              h / 2;
                            const x =
                              window.top.outerWidth / 2 +
                              window.top.screenX -
                              w / 2;
                            const openedWindow = window.open(
                              `${process.env.REACT_APP_URL_API_EVENT_LISTENER}?request_type=pay_invoice_online&billing_id=${row[0]}&online_payable=${row[44]}`,
                              "checkout",
                              `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h}, width=${w}, top=${y}, left=${x}`
                            );
                            if (openedWindow !== null) {
                              openedWindow.focus();
                            }
                          }}
                        >
                          Pay online
                        </B1Tiny>
                      ) : null}

                      {row[44] === "" && row[3] == null ? (
                        <Box
                          sx={{
                            mt: 1,
                            bgcolor: "#ffe79a",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="contentTiny">
                            Invoice is unpaid!
                          </Typography>
                        </Box>
                      ) : null}
                    </>
                  ) : (
                    <DownloadPaddleInvoice
                      invoiceData={row}
                      userRole="client"
                      distributor=""
                    />
                  )}
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterDate,
  setFilterDate,
  filterProduct,
  setFilterProduct,
  filterAmount,
  setFilterAmount,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterDate: PropTypes.any.isRequired,
    setFilterDate: PropTypes.any.isRequired,
    filterProduct: PropTypes.any.isRequired,
    setFilterProduct: PropTypes.any.isRequired,
    filterAmount: PropTypes.any.isRequired,
    setFilterAmount: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  let accountingInvoicesTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsa1d78ea6c9cbdd85 !== null &&
      myLocalSettingStorage.obfsa1d78ea6c9cbdd85 !== undefined
    ) {
      accountingInvoicesTableFilterCollapseInitValue =
        myLocalSettingStorage.obfsa1d78ea6c9cbdd85;
    }
  }
  const [collapse, setCollapse] = useState(
    accountingInvoicesTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterDate !== "" ||
      filterProduct !== "" ||
      filterAmount !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let accountingInvoicesTableFilterItemsPerPageInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs049620f0953ec14a !== null &&
      myLocalSettingStorage.obfs049620f0953ec14a !== undefined
    ) {
      accountingInvoicesTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfs049620f0953ec14a;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    accountingInvoicesTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterDate, setLocalFilterDate] = useState(filterDate);
  const [localFilterProduct, setLocalFilterProduct] = useState(filterProduct);
  const [localFilterAmount, setLocalFilterAmount] = useState(filterAmount);

  const contentFiltered = [];
  if (content.billing_data !== undefined) {
    content.billing_data.data.forEach((invoice, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(invoice);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterDate(localFilterDate);
    setFilterProduct(localFilterProduct);
    setFilterAmount(localFilterAmount);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateBillingInvoicesAndReceipes: true,
    }));
  }

  let missingDataNote = false;

  if (
    content.personal_information.forename === "" ||
    content.personal_information.surname === "" ||
    content.personal_information.street === "" ||
    content.personal_information.postal_code === "" ||
    content.personal_information.city === "" ||
    content.personal_information.province === "" ||
    content.personal_information.country === ""
  ) {
    missingDataNote = true;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mb: 3 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Billing Address"
            subheader="Below you can find your current billing address and the option to update it."
            returnMessage={returnMessage}
            mainOutlet={
              <Box>
                {missingDataNote ? (
                  <ModalAlertWarning
                    severity="warning"
                    sx={{ mb: 1, lineHeight: 1.5 }}
                  >
                    Essential billing address information is missing. For proper
                    invoicing please update your billing address.
                  </ModalAlertWarning>
                ) : null}

                <ValueLine
                  parameter="Forename"
                  value={content.personal_information.forename}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <ValueLine
                  parameter="Surname"
                  value={content.personal_information.surname}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <ValueLine
                  parameter="Street"
                  value={content.personal_information.street}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <ValueLine
                  parameter="Optional address line"
                  value={content.personal_information.optional_line}
                  widthLeft="45%"
                  widthRight="55%"
                />

                <ValueLine
                  parameter="Postal code"
                  value={content.personal_information.postal_code}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <ValueLine
                  parameter="City"
                  value={content.personal_information.city}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <ValueLine
                  parameter="Province / State"
                  value={content.personal_information.province}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <ValueLine
                  parameter="Country"
                  value={content.personal_information.country}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <HandleBillingAddressUpdateInformation
                  configDataClient={content.personal_information}
                />
              </Box>
            }
          />
        </Box>

        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Invoices"
          subheader="Use the navigation tools below to search, filter, and download individual invoices. Please note that invoices may require a few minutes to become visible here."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No invoices") ? null : (
              <Box>
                <B3Small
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setCollapse(!collapse);

                    const newLocalSettingStorage = JSON.parse(
                      localStorage.getItem("myLocalSettingStorage")
                    );

                    newLocalSettingStorage.obfsa1d78ea6c9cbdd85 = !collapse;
                    localStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }}
                >
                  {collapse ? "Hide filters" : "Show filters"}
                </B3Small>

                <Collapse
                  sx={{ mx: -1 }}
                  in={collapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">ID</Typography>
                        <FetchTableTextField
                          value={localFilterId}
                          onChange={(event) => {
                            setLocalFilterId(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs096db587116cc1b1 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">Date</Typography>
                        <FetchTableTextField
                          value={localFilterDate}
                          onChange={(event) => {
                            setLocalFilterDate(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfsb8477d8d7dabcef1 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">
                          Description
                        </Typography>
                        <FetchTableTextField
                          value={localFilterProduct}
                          onChange={(event) => {
                            setLocalFilterProduct(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs1842ca6643e81b58 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">Amount</Typography>
                        <FetchTableTextField
                          value={localFilterAmount}
                          onChange={(event) => {
                            setLocalFilterAmount(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs2601c6855dfd60a8 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="contentSmall">
                          Items per page
                        </Typography>
                        <Box sx={{ px: 1 }}>
                          <FetchTableSlider
                            size="small"
                            min={1}
                            max={100}
                            align="right"
                            value={itemsPerPages}
                            onChange={(event, newValue) => {
                              setItemsPerPages(newValue);
                              setTotalPages(
                                Math.ceil(content.num_rows / newValue)
                              );
                              setCurrentPage(1);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs049620f0953ec14a =
                                newValue;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <B1Small
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        setFilters();
                      }}
                    >
                      Set filters and refresh output
                    </B1Small>
                  </Box>
                </Collapse>

                <Box sx={{ mt: 0, mb: 1 }} />

                <FetchTable
                  tableArray={contentFiltered}
                  refreshLoading={refreshLoading}
                />

                <Stack sx={{ mt: 3 }} alignItems="center">
                  <FetchTablePagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    onChange={(event, value) => {
                      setCurrentPage(value);
                    }}
                  />
                </Stack>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let accountingInvoicesTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs096db587116cc1b1 !== null &&
      myLocalSettingStorage.obfs096db587116cc1b1 !== undefined
    ) {
      accountingInvoicesTableFilterIdInitValue =
        myLocalSettingStorage.obfs096db587116cc1b1;
    }
  }
  const [filterId, setFilterId] = useState(
    accountingInvoicesTableFilterIdInitValue
  );

  // Filter
  let accountingInvoicesTableFilterDateInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsb8477d8d7dabcef1 !== null &&
      myLocalSettingStorage.obfsb8477d8d7dabcef1 !== undefined
    ) {
      accountingInvoicesTableFilterDateInitValue =
        myLocalSettingStorage.obfsb8477d8d7dabcef1;
    }
  }
  const [filterDate, setFilterDate] = useState(
    accountingInvoicesTableFilterDateInitValue
  );

  // Filter
  let accountingInvoicesTableFilterProductInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs1842ca6643e81b58 !== null &&
      myLocalSettingStorage.obfs1842ca6643e81b58 !== undefined
    ) {
      accountingInvoicesTableFilterProductInitValue =
        myLocalSettingStorage.obfs1842ca6643e81b58;
    }
  }
  const [filterProduct, setFilterProduct] = useState(
    accountingInvoicesTableFilterProductInitValue
  );

  // Filter
  let accountingInvoicesTableFilterAmountInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs2601c6855dfd60a8 !== null &&
      myLocalSettingStorage.obfs2601c6855dfd60a8 !== undefined
    ) {
      accountingInvoicesTableFilterAmountInitValue =
        myLocalSettingStorage.obfs2601c6855dfd60a8;
    }
  }
  const [filterAmount, setFilterAmount] = useState(
    accountingInvoicesTableFilterAmountInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        // Reset update flag
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateBillingInvoicesAndReceipes: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "accounting",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "desc",
          page_order_by: "billing_id",
        },
        filters: {
          billing_id: filterId,
          invoice_date: filterDate,
          product: filterProduct,
          total_amount: filterAmount,
        },
      };

      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setRefreshLoading(false);
              setContent(contentFromBackend);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterDate === "" &&
                  filterProduct === "" &&
                  filterAmount === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No invoices have been found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateBillingInvoicesAndReceipes) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateBillingInvoicesAndReceipes]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalBillingInvoicesAndReceipes);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Invoices" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Invoices" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Invoices" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        filterAmount={filterAmount}
        setFilterAmount={setFilterAmount}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
