// react and js
import { React, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";

// components

// hooks
import defines from "../hooks/defines";
import appContext from "../hooks/appContext";

// layouts
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Public() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );
  const location = useLocation();

  return !(
    stateStorage.authenticated === true &&
    myLocalStateStorage !== null &&
    location.pathname !== "/new-password" &&
    location.pathname !== "/invoice-printer"
  ) ? (
    <>
      <Box
        component="img"
        src="/illustrations/background/my-public-background.jpg"
        sx={{
          zIndex: -1000,
          position: "absolute",
          top: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          left: 0,
          width: "100%",
          height: isDesktop
            ? `calc(100vh - ${defines.headerHeightDesktop}px)`
            : `calc(100vh - ${defines.headerHeightMobile}px)`,
          objectFit: "cover",
          overflow: "hidden",
        }}
      />

      <Box
        id="auto-scroll-container"
        style={{
          marginTop: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          maxHeight: isDesktop
            ? `calc(100vh - ${defines.headerHeightDesktop}px)`
            : `calc(100vh - ${defines.headerHeightMobile}px)`,
          overflow: "auto",
        }}
      >
        <PublicHeader />
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          sx={{
            minHeight: isDesktop
              ? `calc(100vh - ${defines.headerHeightDesktop}px)`
              : `calc(100vh - ${defines.headerHeightMobile}px)`,
          }}
        >
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ my: 6 }}>
            <Outlet />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <PublicFooter />
        </Stack>
      </Box>
    </>
  ) : null;
}
