// react and js
import { React, useMemo } from "react";

// mui

// components
import Page from "../../components/Page";

// hooks

// layouts

// pages

// theme

// utils

// widgets
import WidgetPasswordReset from "../../widgets/password_reset/PasswordReset";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PasswordReset() {
  // eslint-disable-next-line
  return useMemo(() => {
    return (
      <Page title="Password Reset">
        <WidgetPasswordReset />
      </Page>
    );
  });
}
