// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import { enCA } from "date-fns/locale";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalDatePicker,
  ModalDatePickerSlotProps,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalModifyPermissionLimitations({
  distributor,
  permissionId,
  permission,
  isSponsored,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalModifyPermissionLimitations.propTypes = {
    distributor: PropTypes.any.isRequired,
    permissionId: PropTypes.any.isRequired,
    permission: PropTypes.any.isRequired,
    isSponsored: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let formPermissionLimitsInitValue = "permission_default";
  let formMinApplicationEquityInitValue = "Permission default value";
  let formMaxApplicationEquityInitValue = "Permission default value";
  let formMinApplicationLeverageInitValue = "Permission default value";
  let formMaxApplicationLeverageInitValue = "Permission default value";

  if (permission.given_account_type === "live") {
    if (permission.permission_live_limits === null) {
      formPermissionLimitsInitValue = "permission_default";
    } else {
      formPermissionLimitsInitValue = permission.permission_live_limits;
    }
  } else if (permission.given_account_type === "demo") {
    if (permission.permission_demo_limits === null) {
      formPermissionLimitsInitValue = "permission_default";
    } else {
      formPermissionLimitsInitValue = permission.permission_demo_limits;
    }
  }

  if (permission.permission_min_equity === "") {
    formMinApplicationEquityInitValue = "Permission default value";
  } else if (permission.permission_min_equity === "-1") {
    formMinApplicationEquityInitValue = "Disabled";
  } else {
    formMinApplicationEquityInitValue = permission.permission_min_equity;
  }

  if (permission.permission_max_equity === "") {
    formMaxApplicationEquityInitValue = "Permission default value";
  } else if (permission.permission_max_equity === "-1") {
    formMaxApplicationEquityInitValue = "Disabled";
  } else {
    formMaxApplicationEquityInitValue = permission.permission_max_equity;
  }

  if (permission.permission_min_leverage === "") {
    formMinApplicationLeverageInitValue = "Permission default value";
  } else if (permission.permission_min_leverage === "-1") {
    formMinApplicationLeverageInitValue = "Disabled";
  } else {
    formMinApplicationLeverageInitValue = permission.permission_min_leverage;
  }

  if (permission.permission_max_leverage === "") {
    formMaxApplicationLeverageInitValue = "Permission default value";
  } else if (permission.permission_max_leverage === "-1") {
    formMaxApplicationLeverageInitValue = "Disabled";
  } else {
    formMaxApplicationLeverageInitValue = permission.permission_max_leverage;
  }

  const [formPermissionLimits, setFormPermissionLimits] = useState(
    formPermissionLimitsInitValue
  );
  const [formMinApplicationEquity, setFormMinApplicationEquity] = useState(
    formMinApplicationEquityInitValue
  );
  const [formMaxApplicationEquity, setFormMaxApplicationEquity] = useState(
    formMaxApplicationEquityInitValue
  );
  const [formMinApplicationLeverage, setFormMinApplicationLeverage] = useState(
    formMinApplicationLeverageInitValue
  );
  const [formMaxApplicationLeverage, setFormMaxApplicationLeverage] = useState(
    formMaxApplicationLeverageInitValue
  );

  let formPermissionInitValue = "";

  if (permission.end !== null) {
    formPermissionInitValue = new Date(
      `${permission.end.slice(0, 10)}T${permission.end.slice(11, 19)}`
    );
  }

  const [formPermissionEnd, setFormPermissionEnd] = useState(
    formPermissionInitValue
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const limitationRegExp = /^[0-9]|Permission default value|Disabled+$/;

  const myValidationScheme = Yup.object().shape({
    permission_min_application_equity: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_max_application_equity: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_min_application_leverage: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_max_application_leverage: Yup.string()
      .required()
      .matches(limitationRegExp),
  });

  const formik = useFormik({
    initialValues: {
      permission_min_application_equity: formMinApplicationEquityInitValue,
      permission_max_application_equity: formMaxApplicationEquityInitValue,
      permission_min_application_leverage: formMinApplicationLeverageInitValue,
      permission_max_application_leverage: formMaxApplicationLeverageInitValue,
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      let MinApplicationEquityConverted =
        values.permission_min_application_equity;
      if (MinApplicationEquityConverted === "Permission default value") {
        MinApplicationEquityConverted = "permission_default";
      } else if (MinApplicationEquityConverted === "Disabled") {
        MinApplicationEquityConverted = "disabled";
      }

      let MaxApplicationEquityConverted =
        values.permission_max_application_equity;
      if (MaxApplicationEquityConverted === "Permission default value") {
        MaxApplicationEquityConverted = "permission_default";
      } else if (MaxApplicationEquityConverted === "Disabled") {
        MaxApplicationEquityConverted = "disabled";
      }

      let MinApplicationLeverageConverted =
        values.permission_min_application_leverage;
      if (MinApplicationLeverageConverted === "Permission default value") {
        MinApplicationLeverageConverted = "permission_default";
      } else if (MinApplicationLeverageConverted === "Disabled") {
        MinApplicationLeverageConverted = "disabled";
      }

      let MaxApplicationLeverageConverted =
        values.permission_max_application_leverage;
      if (MaxApplicationLeverageConverted === "Permission default value") {
        MaxApplicationLeverageConverted = "permission_default";
      } else if (MaxApplicationLeverageConverted === "Disabled") {
        MaxApplicationLeverageConverted = "disabled";
      }

      let permissionEndConverted = "-";
      if (
        formPermissionEnd !== null &&
        formPermissionEnd !== "" &&
        formPermissionEnd !== undefined
      ) {
        if (
          permission.end === null ||
          permission.end === "" ||
          permission.end === undefined
        ) {
          permissionEndConverted = `${formPermissionEnd.toLocaleDateString(
            "en-CA"
          )} 23:59:59`;
        } else {
          permissionEndConverted = `${formPermissionEnd.toLocaleDateString(
            "en-CA"
          )} ${permission.end.slice(11, 19)}`;
        }
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_and_account_management",
        operation: "modify_permission_limitations",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        permission: {
          selected_permission_id: permissionId,
        },
        limits: {
          permission_end: permissionEndConverted,
          permission_limits: formPermissionLimits,
          permission_min_application_equity: MinApplicationEquityConverted,
          permission_max_application_equity: MaxApplicationEquityConverted,
          permission_min_application_leverage: MinApplicationLeverageConverted,
          permission_max_application_leverage: MaxApplicationLeverageConverted,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);

    formik.values.permission_min_application_equity =
      formMinApplicationEquityInitValue;
    formik.values.permission_max_application_equity =
      formMaxApplicationEquityInitValue;
    formik.values.permission_min_application_leverage =
      formMinApplicationLeverageInitValue;
    formik.values.permission_max_application_leverage =
      formMaxApplicationLeverageInitValue;

    setFormPermissionLimits(formPermissionLimitsInitValue);
    setFormMinApplicationEquity(formMinApplicationEquityInitValue);
    setFormMaxApplicationEquity(formMaxApplicationEquityInitValue);
    setFormMinApplicationLeverage(formMinApplicationLeverageInitValue);
    setFormMaxApplicationLeverage(formMaxApplicationLeverageInitValue);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const [errorMinApplicationEquity, setErrorMinApplicationEquity] =
    useState(false);
  const [errorMaxApplicationEquity, setErrorMaxApplicationEquity] =
    useState(false);
  const [errorMinApplicationLeverage, setErrorMinApplicationLeverage] =
    useState(false);
  const [errorMaxApplicationLeverage, setErrorMaxApplicationLeverage] =
    useState(false);

  return (
    <>
      <B3Tiny
        variant="outlined"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 1 }}
        fullWidth
      >
        Modify permission limitations
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Modify permission limitations
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="contentMiddle">
                      Use this dialog to modify your customer's permission
                      limitations. You can either set a specific number, use the
                      'Permission default value,' or set it to 'Disabled.'
                      <br /> <br />A modified value does not overwrite the
                      permission standard value but sets a new value with higher
                      priority. To restore the permission default value, use the
                      'Permission default value' option.
                    </Typography>
                  </Box>

                  {isSponsored === true ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Permission end date
                      </Typography>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={enCA}
                      >
                        <ModalDatePicker
                          disablePast
                          value={formPermissionEnd}
                          onChange={(newValue) => {
                            if (newValue !== null) {
                              const value = new Date(
                                `${newValue.toLocaleDateString(
                                  "en-CA"
                                )}T00:00:00`
                              );
                              setFormPermissionEnd(value);
                            } else {
                              setFormPermissionEnd(null);
                            }
                          }}
                          slotProps={ModalDatePickerSlotProps}
                        />
                      </LocalizationProvider>
                    </Box>
                  ) : null}

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Selectable stop loss and take profit limit
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formPermissionLimits}
                          onChange={(event) => {
                            setFormPermissionLimits(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="permission_default">
                            Permission default value
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="server">
                            Remote only
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="local">
                            Local only
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="local_server">
                            Remote and local
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Selectable stop loss and take profit limit for accounts
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formPermissionLimits}
                          onChange={(event) => {
                            setFormPermissionLimits(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="permission_default">
                            Permission default value
                          </option>
                          <option value="server">Remote only</option>
                          <option value="local">Local only</option>
                          <option value="local_server">Remote and local</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_min_application_equity &&
                              errors.permission_min_application_equity
                          ) === true || errorMinApplicationEquity === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_min_application_equity &&
                          errors.permission_min_application_equity
                      ) === true || errorMinApplicationEquity === true
                        ? "Invalid min. equity for account"
                        : "Min. equity for account"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formMinApplicationEquity}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorMinApplicationEquity(false);
                          values.permission_min_application_equity =
                            "Permission default value";
                          setFormMinApplicationEquity(
                            "Permission default value"
                          );
                        } else if (
                          value !== "Permission default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorMinApplicationEquity(true);
                            } else {
                              setErrorMinApplicationEquity(false);
                              values.permission_min_application_equity = value;
                              setFormMinApplicationEquity(value);
                            }
                          } else {
                            setErrorMinApplicationEquity(true);
                          }
                        } else {
                          setErrorMinApplicationEquity(false);
                          values.permission_min_application_equity = value;
                          setFormMinApplicationEquity(value);
                        }
                      }}
                      options={["Permission default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_min_application_equity"
                          )}
                          error={Boolean(
                            touched.permission_min_application_equity &&
                              errors.permission_min_application_equity
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_max_application_equity &&
                              errors.permission_max_application_equity
                          ) === true || errorMaxApplicationEquity === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_max_application_equity &&
                          errors.permission_max_application_equity
                      ) === true || errorMaxApplicationEquity === true
                        ? "Invalid max. equity for account"
                        : "Max. equity for account"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formMaxApplicationEquity}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorMaxApplicationEquity(false);
                          values.permission_max_application_equity =
                            "Permission default value";
                          setFormMaxApplicationEquity(
                            "Permission default value"
                          );
                        } else if (
                          value !== "Permission default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorMaxApplicationEquity(true);
                            } else {
                              setErrorMaxApplicationEquity(false);
                              values.permission_max_application_equity = value;
                              setFormMaxApplicationEquity(value);
                            }
                          } else {
                            setErrorMaxApplicationEquity(true);
                          }
                        } else {
                          setErrorMaxApplicationEquity(false);
                          values.permission_max_application_equity = value;
                          setFormMaxApplicationEquity(value);
                        }
                      }}
                      options={["Permission default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_max_application_equity"
                          )}
                          error={Boolean(
                            touched.permission_max_application_equity &&
                              errors.permission_max_application_equity
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_min_application_leverage &&
                              errors.permission_min_application_leverage
                          ) === true || errorMinApplicationLeverage === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_min_application_leverage &&
                          errors.permission_min_application_leverage
                      ) === true || errorMinApplicationLeverage === true
                        ? "Invalid min. leverage for account"
                        : "Min. leverage for account"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formMinApplicationLeverage}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorMinApplicationLeverage(false);
                          values.permission_min_application_leverage =
                            "Permission default value";
                          setFormMinApplicationLeverage(
                            "Permission default value"
                          );
                        } else if (
                          value !== "Permission default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorMinApplicationLeverage(true);
                            } else {
                              setErrorMinApplicationLeverage(false);
                              values.permission_min_application_leverage =
                                value;
                              setFormMinApplicationLeverage(value);
                            }
                          } else {
                            setErrorMinApplicationLeverage(true);
                          }
                        } else {
                          setErrorMinApplicationLeverage(false);
                          values.permission_min_application_leverage = value;
                          setFormMinApplicationLeverage(value);
                        }
                      }}
                      options={["Permission default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_min_application_leverage"
                          )}
                          error={Boolean(
                            touched.permission_min_application_leverage &&
                              errors.permission_min_application_leverage
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_max_application_leverage &&
                              errors.permission_max_application_leverage
                          ) === true || errorMaxApplicationLeverage === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_max_application_leverage &&
                          errors.permission_max_application_leverage
                      ) === true || errorMaxApplicationLeverage === true
                        ? "Invalid max. leverage for account"
                        : "Max. leverage for account"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formMaxApplicationLeverage}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorMaxApplicationLeverage(false);
                          values.permission_max_application_leverage =
                            "Permission default value";
                          setFormMaxApplicationLeverage(
                            "Permission default value"
                          );
                        } else if (
                          value !== "Permission default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorMaxApplicationLeverage(true);
                            } else {
                              setErrorMaxApplicationLeverage(false);
                              values.permission_max_application_leverage =
                                value;
                              setFormMaxApplicationLeverage(value);
                            }
                          } else {
                            setErrorMaxApplicationLeverage(true);
                          }
                        } else {
                          setErrorMaxApplicationLeverage(false);
                          values.permission_max_application_leverage = value;
                          setFormMaxApplicationLeverage(value);
                        }
                      }}
                      options={["Permission default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_max_application_leverage"
                          )}
                          error={Boolean(
                            touched.permission_max_application_leverage &&
                              errors.permission_max_application_leverage
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Modify
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
