// react and js
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { React, useContext, useEffect, useMemo } from "react";

// mui

// components
import Page from "../../components/Page";

// hooks
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme

// utils

// widgets
import WidgetPromotions from "../../widgets/promotion/Promotions";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Promotion() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  
  const navigate = useNavigate();
  const location = useLocation();

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Outlet protection
  // ######################################################
  let isOutletAllowed = false;
  if (stateStorage.userInformation !== "") 
  {
    if (
      stateStorage.userInformation.account_settings !== null &&
      stateStorage.userInformation.account_settings !== undefined
    ) {
      if (
        stateStorage.userInformation.account_settings.dashboard_features !== null &&
        stateStorage.userInformation.account_settings.dashboard_features !== undefined
      ) {
        if (
          stateStorage.userInformation.account_settings.dashboard_features.obfs382f0296a2a463bb !== null &&
          stateStorage.userInformation.account_settings.dashboard_features.obfs382f0296a2a463bb !== undefined
        ) 
        {
          if (stateStorage.userInformation.account_settings.dashboard_features.obfs382f0296a2a463bb === "obfsa0d3c920c36e2ad0") 
          {
            isOutletAllowed = true;
          }
        }
      }
    }
  }

  useEffect(() => {
    if(isOutletAllowed === false)
    {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Main outlet
  // ######################################################

  // eslint-disable-next-line
  return useMemo(() => {
    return isOutletAllowed ? (
      <Page title="Promotion">
        <WidgetPromotions
          title="Our Promotion Partners"
          subheader="Start with one of the broker below to get free promotion licenses for all of our products."
        />
      </Page>
    ) : null;
  });
}
