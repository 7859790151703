// react and js
import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// mui
import {
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  NativeSelect,
} from "@mui/material";

// components
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks

// layouts

// pages

// theme
import { CardOutlinedInput, CardMenuItem } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function WidgetMainContentProcessed({ content, distributor, onChange }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    onChange: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let checkKey = true;

  if (content === undefined || content === null) {
    checkKey = false;
  }

  const distributors = [];
  if (checkKey) {
    for (let index = 0; index < content.distributors.data.length; index += 1) {
      distributors.push([
        content.distributors.data[index][1],
        content.distributors.data[index][0],
      ]);
    }
  }
  if (distributors.length === 0) {
    checkKey = false;
  }

  return checkKey ? (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant={distributors.length > 1 ? "outlet" : "description"}
          backgroundProp="#F2F2F2"
          title={`Selected Distributor ID (DID): ${distributor}`}
          subheader="Select the distributor you wish to represent."
          mainOutlet={
            isDesktop ? (
              <Box>
                <Typography variant="contentSmall">
                  Distribution partner
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={distributor}
                    onChange={onChange}
                    id="distributor-partner-select"
                    input={<CardOutlinedInput />}
                  >
                    {distributors.map((currentDistributor, index) => (
                      <CardMenuItem key={index} value={currentDistributor[1]}>
                        {currentDistributor[0]}
                      </CardMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Box>
                <Typography variant="contentSmall">
                  Distribution partner
                </Typography>
                <FormControl fullWidth>
                  <NativeSelect
                    value={distributor}
                    onChange={onChange}
                    id="distributor-partner-select"
                    inputProps={{
                      name: "Distribution partner",
                    }}
                    input={<CardOutlinedInput />}
                  >
                    {distributors.map((currentDistributor, index) => (
                      <option key={index} value={currentDistributor[1]}>
                        {currentDistributor[0]}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  distributor,
  onChange,
  loading,
  error,
  content,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    distributor: PropTypes.any.isRequired,
    onChange: PropTypes.any.isRequired,
    loading: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  let WidgetOutlet = <MissingContent message="Distributor Portal" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Distributor Portal" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Distributor Portal" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        distributor={distributor}
        onChange={onChange}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
