// react and js
import { React, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";

// components

// hooks
import defines from "../../hooks/defines";

// layouts
import PrivateHeader from "./PrivateHeader";
import PrivateSidebar from "./PrivateSidebar";
import PrivateFooter from "./PrivateFooter";

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Private() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  let openInitValue = false;
  if (isDesktop === true) {
    openInitValue = true;
  }
  const [open, setOpen] = useState(openInitValue);

  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );
  const location = useLocation();

  return myLocalStateStorage === null &&
    location.pathname !== "/new-password" &&
    location.pathname !== "/invoice-printer" ? null : (
    <>
      <PrivateHeader onOpenSidebar={() => setOpen(true)} />
      <PrivateSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />

      <Box
        sx={{
          background: "white",
          position: "absolute",
          top: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          left: isDesktop ? defines.sidebarWidthDesktop : 0,
          width: isDesktop
            ? `calc(100% - ${defines.sidebarWidthDesktop}px)`
            : "100%",
          height: isDesktop
            ? `calc(100vh - ${defines.headerHeightDesktop}px)`
            : `calc(100vh - ${defines.headerHeightMobile}px)`,
        }}
      >
        <Box
          id="auto-scroll-container"
          style={{
            minHeight: isDesktop
              ? `calc(100vh - ${defines.headerHeightDesktop}px)`
              : `calc(100vh - ${defines.headerHeightMobile}px)`,
            maxHeight: isDesktop
              ? `calc(100vh - ${defines.headerHeightDesktop}px)`
              : `calc(100vh - ${defines.headerHeightMobile}px)`,
            overflowY: "scroll",
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
            sx={{
              pt: 3,
              minHeight: isDesktop
                ? `calc(100vh - ${defines.headerHeightDesktop}px)`
                : `calc(100vh - ${defines.headerHeightMobile}px)`,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Outlet />
            </Box>

            <Box sx={{ width: "100%" }}>
              <PrivateFooter />
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
