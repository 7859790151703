// react and js
import { React } from "react";

// mui

// components

// hooks

// layouts

// pages

// theme

// utils

// widgets
import WidgetInvoicePrinter from "../../widgets/invoice_printer/InvoicePrinter";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function InvoicePrinter() {
  // ######################################################
  // Main outlet
  // ######################################################
  return <WidgetInvoicePrinter />;
}
