// react and js
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { React, useRef, useState, useEffect, useContext } from "react";

// mui
import {
  Stack,
  Grid,
  Collapse,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import { DownloadInvoice } from "../../components/AccountingDownloads/AccountingDownloads";
import ModalDialogInternalNotes from "../../components/ModalDialogInternalNotes";
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleDistributorPortalResendApprovalEmail from "./actions/HandleDistributorPortalResendApprovalEmail";
import HandleDistributorPortalResendPasswordResetEmail from "./actions/HandleDistributorPortalResendPasswordResetEmail";
import HandleDistributorPortalUpdateCustomersPersonalInformation from "./actions/HandleDistributorPortalUpdateCustomersPersonalInformation";
//
import { SubscriptionItem } from "../../components/SubscriptionItem";
//
import DownloadPaddleInvoice from "../../components/DownloadPaddleInvoice";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B1Small,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function DistributionTickDataSubscriptionItem({
  content,
  distributor,
  distributionPlansTickData,
  categories,
}) {
  DistributionTickDataSubscriptionItem.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    distributionPlansTickData: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
          pb: 1,
        }}
      >
        <SubscriptionItem
          mode="distribution_tick_data"
          distributor={distributor}
          content={content}
          distributionPlansTickData={distributionPlansTickData}
          managed={Boolean(true)}
          referenceTradingCurrency="none"
          categories={categories}
        />
      </Box>
    </Grid>
  );
}

function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value === "1" || value.includes("newsletter=1")) {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "0" || value.includes("newsletter=0")) {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "extended_client_data_missing") {
      valueOutlet = (
        <Typography variant="contentSmall" align="right">
          Missing
        </Typography>
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function DashboardData({ content, distributor }) {
  DashboardData.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  let isBillingAddressRequired = false;
  if (
    content.dashboard_features !== null &&
    content.dashboard_features !== undefined
  ) {
    if (
      content.dashboard_features.obfsaa8911d51f968403 !== null &&
      content.dashboard_features.obfsaa8911d51f968403 !== undefined
    ) {
      if (
        content.dashboard_features.obfsaa8911d51f968403 ===
        "obfs5828c1b7bf8ebf20"
      ) {
        isBillingAddressRequired = true;
      }
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 0, mb: 3 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Customers's Personal Information"
            subheader="Please use the widgets below to manage your tick data distribution customers."
            mainOutlet={
              <>
                <HandleDistributorPortalUpdateCustomersPersonalInformation
                  distributor={distributor}
                  dashboardId={content.dashboard_data.dashboard_id}
                  configDataClient={content.dashboard_data}
                  isBillingAddressRequiredProp={isBillingAddressRequired}
                />
                <HandleDistributorPortalResendApprovalEmail
                  distributor={distributor}
                  dashboardId={content.dashboard_data.dashboard_id}
                />
                <HandleDistributorPortalResendPasswordResetEmail
                  distributor={distributor}
                  dashboardId={content.dashboard_data.dashboard_id}
                />

                <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
                  Visible by customer
                </Typography>

                <ValueLine
                  parameter="User ID (UID)"
                  value={`${content.dashboard_data.dashboard_id}`}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Email"
                  value={content.dashboard_data.email}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Forename"
                  value={content.dashboard_data.forename}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Surname"
                  value={content.dashboard_data.surname}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Birth"
                  value={content.dashboard_data.birth}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Gender"
                  value={
                    content.dashboard_data.gender !== null
                      ? content.dashboard_data.gender.charAt(0).toUpperCase() +
                        content.dashboard_data.gender.slice(1)
                      : ""
                  }
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Organization"
                  value={content.dashboard_data.organization}
                  widthLeft="35%"
                  widthRight="65%"
                />
                <ValueLine
                  parameter="Street"
                  value={content.dashboard_data.street}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Optional address line"
                  value={content.dashboard_data.optional_line}
                  widthLeft="45%"
                  widthRight="55%"
                />
                <ValueLine
                  parameter="Postal code"
                  value={content.dashboard_data.postal_code}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="City"
                  value={content.dashboard_data.city}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Province / State"
                  value={content.dashboard_data.province}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Country"
                  value={content.dashboard_data.country}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Phone"
                  value={content.dashboard_data.phone}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ValueLine
                  parameter="Newsletter"
                  value={content.dashboard_data.email_notification_settings}
                  widthLeft="25%"
                  widthRight="75%"
                />

                <Typography variant="h3" sx={{ mt: 4, mb: 1 }}>
                  Internal
                </Typography>

                <ValueLine
                  parameter="Date added"
                  value={`${content.dashboard_data.datetime_added} GMT`}
                  widthLeft="50%"
                  widthRight="50%"
                />

                {content.dashboard_data.account_state !== null &&
                content.dashboard_data.account_state !== undefined ? (
                  <ValueLine
                    parameter="Account state"
                    value={
                      content.dashboard_data.account_state
                        .charAt(0)
                        .toUpperCase() +
                      content.dashboard_data.account_state.slice(1)
                    }
                    widthLeft="45%"
                    widthRight="65%"
                  />
                ) : null}

                <ValueLine
                  parameter="Use personal data for marketing"
                  value={content.dashboard_data.marketing_share}
                  widthLeft="80%"
                  widthRight="20%"
                />
                <ValueLine
                  parameter="Use personal data for tailorized adds"
                  value={content.dashboard_data.tailorized_adds}
                  widthLeft="80%"
                  widthRight="20%"
                />
                <ValueLine
                  parameter="Last dashboard login"
                  value={
                    content.dashboard_data.last_login !== null
                      ? `${content.dashboard_data.last_login} GMT`
                      : ""
                  }
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ValueLine
                  parameter="Last application start"
                  value={content.dashboard_data.last_application_connection}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ValueLine
                  parameter="Last used application name"
                  value={content.dashboard_data.last_application_name}
                  widthLeft="55%"
                  widthRight="45%"
                />
                <B3Tiny
                  fullWidth
                  variant="outlined"
                  onClick={handleClickOpen("paper")}
                  sx={{ mt: 2 }}
                >
                  Account notes
                </B3Tiny>
                <ModalDialogInternalNotes
                  open={open}
                  handleClose={handleClose}
                  handleClickClose={handleClickClose}
                  scroll={scroll}
                  data={content.dashboard_data.internal_notes}
                  notesType={`Account notes for user ID (UID) ${content.dashboard_data.dashboard_id}`}
                />
              </>
            }
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 10, mb: 0 }}>
          <CardOutlet
            variant="description"
            backgroundProp="#F2F2F2"
            title="Tick Data Subscriptions"
            subheader="Below, you can find your active subscriptions. Use this page to add and remove trading accounts and modify your subscription."
          />
        </Box>
      </Grid>

      <DistributionTickDataSubscriptionItem
        content={content}
        distributor={distributor}
        distributionPlansTickData={
          content.distribution_mt5_tick_data_plans
            .distribution_mt5_tick_data_plans
        }
        categories={content.distribution_mt5_tick_data_plans.availability_cats}
      />
    </Grid>
  );
}

function FetchTable({ tableArray, refreshLoading, distributor }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="16%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> ID </Typography>
              </TableCell>
              <TableCell
                width="14%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Date </Typography>
              </TableCell>
              <TableCell
                width="40%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">Description</Typography>
              </TableCell>
              <TableCell
                width="14%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Amount </Typography>
              </TableCell>
              <TableCell
                width="16%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[35] !== "" ? (
                      <Typography variant="contentSmall">{row[35]}</Typography>
                    ) : (
                      <Typography variant="contentSmall">{row[0]}</Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall">
                      {row[2].substring(0, 10)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[7]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[10] !== "0" ? (
                      <Typography variant="contentSmall">
                        {numberWithCommas(
                          (Math.ceil(row[10] * 100) / 100).toFixed(2)
                        )}{" "}
                        {row[29].replace("EUR", "€")}
                      </Typography>
                    ) : (
                      <Typography variant="contentSmall">{row[11]}</Typography>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    {row[6].toLowerCase() !== "paddle" ? (
                      <B3Tiny
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          DownloadInvoice(row);
                        }}
                      >
                        Download invoice
                      </B3Tiny>
                    ) : (
                      <DownloadPaddleInvoice
                        invoiceData={row}
                        userRole="distributor"
                        distributor={distributor}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}> 
                  <Typography variant="contentSmallBold"> ID </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  {row[35] !== "" ? (
                    <Typography variant="contentSmall">{row[35]}</Typography>
                  ) : (
                    <Typography variant="contentSmall">{row[0]}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Date </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">
                    {row[2].substring(0, 10)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold">
                    Description
                  </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[7]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Amount </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  {row[10] !== "0" ? (
                    <Typography variant="contentSmall">
                      {numberWithCommas(
                        (Math.ceil(row[10] * 100) / 100).toFixed(2)
                      )}{" "}
                      {row[29].replace("EUR", "€")}
                    </Typography>
                  ) : (
                    <Typography variant="contentSmall">{row[11]}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  {row[6].toLowerCase() !== "paddle" ? (
                    <B3Tiny
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        DownloadInvoice(row);
                      }}
                    >
                      Download invoice
                    </B3Tiny>
                  ) : (
                    <DownloadPaddleInvoice
                      invoiceData={row}
                      userRole="distributor"
                      distributor={distributor}
                    />
                  )}
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetSubAccountingContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterDate,
  setFilterDate,
  filterProduct,
  setFilterProduct,
  filterAmount,
  setFilterAmount,
  distributor,
}) {
  WidgetSubAccountingContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterDate: PropTypes.any.isRequired,
    setFilterDate: PropTypes.any.isRequired,
    filterProduct: PropTypes.any.isRequired,
    setFilterProduct: PropTypes.any.isRequired,
    filterAmount: PropTypes.any.isRequired,
    setFilterAmount: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  let distributorPortalDistributionTickDataInvoicesTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs96854d3129b87ed0 !== null &&
      myLocalSettingStorage.obfs96854d3129b87ed0 !== undefined
    ) {
      distributorPortalDistributionTickDataInvoicesTableFilterCollapseInitValue =
        myLocalSettingStorage.obfs96854d3129b87ed0;
    }
  }
  const [collapse, setCollapse] = useState(
    distributorPortalDistributionTickDataInvoicesTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterDate !== "" ||
      filterProduct !== "" ||
      filterAmount !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let distributorPortalDistributionTickDataInvoicesTableFilterItemsPerPageInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs082651ab4c5d253a !== null &&
      myLocalSettingStorage.obfs082651ab4c5d253a !== undefined
    ) {
      distributorPortalDistributionTickDataInvoicesTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfs082651ab4c5d253a;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    distributorPortalDistributionTickDataInvoicesTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterDate, setLocalFilterDate] = useState(filterDate);
  const [localFilterProduct, setLocalFilterProduct] = useState(filterProduct);
  const [localFilterAmount, setLocalFilterAmount] = useState(filterAmount);

  const contentFiltered = [];
  if (content.billing_data !== undefined) {
    content.billing_data.data.forEach((invoice, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(invoice);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterDate(localFilterDate);
    setFilterProduct(localFilterProduct);
    setFilterAmount(localFilterAmount);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateDistributorPortalManageTickDataDistributionCustomerInvoices: true,
    }));
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Customer's Invoices"
          subheader="Use the navigation below to search, filter, and download individual invoices. Invoices and receipts may take a couple of minutes to appear here."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No invoices") ? null : (
              <Box>
                <B3Tiny
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setCollapse(!collapse);

                    const newLocalSettingStorage = JSON.parse(
                      localStorage.getItem("myLocalSettingStorage")
                    );

                    newLocalSettingStorage.obfs96854d3129b87ed0 = !collapse;
                    localStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }}
                >
                  {collapse ? "Hide filters" : "Show filters"}
                </B3Tiny>

                <Collapse
                  sx={{ mx: -1 }}
                  in={collapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">ID</Typography>
                        <FetchTableTextField
                          value={localFilterId}
                          onChange={(event) => {
                            setLocalFilterId(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs235a4a7bf7ebefb4 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">Date</Typography>
                        <FetchTableTextField
                          value={localFilterDate}
                          onChange={(event) => {
                            setLocalFilterDate(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfsa54285f5418f87d0 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">
                          Description
                        </Typography>
                        <FetchTableTextField
                          value={localFilterProduct}
                          onChange={(event) => {
                            setLocalFilterProduct(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfsd9d6a55cbb2ffc59 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Typography variant="contentSmall">Amount</Typography>
                        <FetchTableTextField
                          value={localFilterAmount}
                          onChange={(event) => {
                            setLocalFilterAmount(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs71166139d97e4623 =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") setFilters();
                          }}
                          fullWidth
                          size="small"
                          sx={{ input: { textAlign: "center" }, mb: "0px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="contentSmall">
                          Items per page
                        </Typography>
                        <Box sx={{ px: 1 }}>
                          <FetchTableSlider
                            size="small"
                            min={1}
                            max={100}
                            align="right"
                            value={itemsPerPages}
                            onChange={(event, newValue) => {
                              setItemsPerPages(newValue);
                              setTotalPages(
                                Math.ceil(content.num_rows / newValue)
                              );
                              setCurrentPage(1);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs082651ab4c5d253a =
                                newValue;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <B1Tiny
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        setFilters();
                      }}
                    >
                      Set filters and refresh output
                    </B1Tiny>
                  </Box>
                </Collapse>

                <Box sx={{ mt: 0, mb: 1 }} />

                <FetchTable
                  tableArray={contentFiltered}
                  refreshLoading={refreshLoading}
                  distributor={distributor}
                />

                <Stack sx={{ mt: 3 }} alignItems="center">
                  <FetchTablePagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    onChange={(event, value) => {
                      setCurrentPage(value);
                    }}
                  />
                </Stack>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

function Accounting({ distributor, dashboardId }) {
  Accounting.propTypes = {
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let distributorPortalDistributionTickDataInvoicesTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs235a4a7bf7ebefb4 !== null &&
      myLocalSettingStorage.obfs235a4a7bf7ebefb4 !== undefined
    ) {
      distributorPortalDistributionTickDataInvoicesTableFilterIdInitValue =
        myLocalSettingStorage.obfs235a4a7bf7ebefb4;
    }
  }
  const [filterId, setFilterId] = useState(
    distributorPortalDistributionTickDataInvoicesTableFilterIdInitValue
  );

  // Filter
  let distributorPortalDistributionTickDataInvoicesTableFilterDateInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsa54285f5418f87d0 !== null &&
      myLocalSettingStorage.obfsa54285f5418f87d0 !== undefined
    ) {
      distributorPortalDistributionTickDataInvoicesTableFilterDateInitValue =
        myLocalSettingStorage.obfsa54285f5418f87d0;
    }
  }
  const [filterDate, setFilterDate] = useState(
    distributorPortalDistributionTickDataInvoicesTableFilterDateInitValue
  );

  // Filter
  let distributorPortalDistributionTickDataInvoicesTableFilterProductInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsd9d6a55cbb2ffc59 !== null &&
      myLocalSettingStorage.obfsd9d6a55cbb2ffc59 !== undefined
    ) {
      distributorPortalDistributionTickDataInvoicesTableFilterProductInitValue =
        myLocalSettingStorage.obfsd9d6a55cbb2ffc59;
    }
  }
  const [filterProduct, setFilterProduct] = useState(
    distributorPortalDistributionTickDataInvoicesTableFilterProductInitValue
  );

  // Filter
  let distributorPortalDistributionTickDataInvoicesTableFilterAmountInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs71166139d97e4623 !== null &&
      myLocalSettingStorage.obfs71166139d97e4623 !== undefined
    ) {
      distributorPortalDistributionTickDataInvoicesTableFilterAmountInitValue =
        myLocalSettingStorage.obfs71166139d97e4623;
    }
  }
  const [filterAmount, setFilterAmount] = useState(
    distributorPortalDistributionTickDataInvoicesTableFilterAmountInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        // Reset update flag
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateDistributorPortalManageTickDataDistributionCustomerInvoices: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_invoices_tick_data",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        selection: {
          selected_dashboard_id: dashboardId,
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "desc",
          page_order_by: "billing_id",
        },
        filters: {
          billing_id: filterId,
          invoice_date: filterDate,
          product: filterProduct,
          total_amount: filterAmount,
        },
      };

      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setRefreshLoading(false);
              setContent(contentFromBackend);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterDate === "" &&
                  filterProduct === "" &&
                  filterAmount === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No invoices found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalManageDistributionCustomer)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalManageDistributionCustomer]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalDistributionCustomers);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Customer's Invoices" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Customer's Invoices" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Customer's Invoices" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetSubAccountingContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        filterAmount={filterAmount}
        setFilterAmount={setFilterAmount}
        distributor={distributor}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}

function WidgetMainContentProcessed({ content, distributor, dashboardId }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
  };

  let dashboardIdFound = true;

  if (
    content.message.includes(
      "this is not not your tick data distribution customer!"
    )
  ) {
    dashboardIdFound = false;
  }

  return dashboardIdFound ? (
    <Stack>
      <DashboardData content={content} distributor={distributor} />
      <Box sx={{ mt: 13, mb: 0 }}>
        <Accounting distributor={distributor} dashboardId={dashboardId} />
      </Box>
    </Stack>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  category,
  title,
  subheader,
  dashboardId,
  distributor,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    category: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    subheader: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalManageTickDataDistributionCustomer: false,
          }));

          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "manage_tick_data_customer",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            selected_dashboard_id: dashboardId,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setWarning(false);
                setSuccess(false);
                setContent(contentFromBackend);
                setReturnMessage("");
              } else if (
                contentFromBackend.message.includes(
                  "this is not not your tick data distribution customer!"
                )
              ) {
                setSuccess(false);
                setWarning(true);
                setLoading(false);
                setContent(contentFromBackend);
                setReturnMessage(
                  "Tick data distribution customer not found ..."
                );
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setSuccess(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setSuccess(false);
              setWarning(false);
              setReturnMessage("Invalid request!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setSuccess(false);
              setWarning(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (
      !stateStorage.widgetUpdateDistributorPortalManageTickDataDistributionCustomer
    )
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateStorage.widgetUpdateDistributorPortalManageTickDataDistributionCustomer,
  ]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalTickDataDistributionCustomers);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Customer" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Customer" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Customer" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        distributor={distributor}
        dashboardId={dashboardId}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return (
    <>
      <Box sx={{ mt: 0, mb: 13 }}>
        <CardOutlet
          category={category}
          title={title}
          subheader={subheader}
          variant="outlet"
          mainOutlet={
            <B1Small
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => {
                navigate("/distributor-portal");

                setStateStorage((previousState) => ({
                  ...previousState,
                  widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer: true,
                }));
              }}
            >
              Back to distributor portal
            </B1Small>
          }
          backgroundProp="#F2F2F2"
          loading={loading}
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
        />
      </Box>

      {WidgetOutlet}
    </>
  );
}
