// react and js
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect, useContext } from "react";

// mui
import {
  Stack,
  Grid,
  IconButton,
  Box,
  FormControl,
  DialogTitle,
  DialogContent,
  Typography,
  FormGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  NativeSelect,
  Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ModalDialog from "../../components/ModalDialog";
import ModalDialogInternalNotes from "../../components/ModalDialogInternalNotes";
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
import { ApplicationItem } from "../../components/InspectorItem";
//
import HandlePromoterPortalAddAccountsToClient from "./actions/HandlePromoterPortalAddAccountsToClient";
import HandlePromoterPortalRemovePromotionClient from "./actions/HandlePromoterPortalRemovePromotionClient";
import HandlePromoterPortalUpdateClientsPersonalInformation from "./actions/HandlePromoterPortalUpdateClientsPersonalInformation";
import HandlePromoterPortalResendApprovalEmail from "./actions/HandlePromoterPortalResendApprovalEmail";
import HandlePromoterPortalResendPasswordResetEmail from "./actions/HandlePromoterPortalResendPasswordResetEmail";
import HandlePromoterPortalAddNewTradingAccount from "./actions/HandlePromoterPortalAddNewTradingAccount";
import HandlePromoterPortalIgnorePendingPromotionClient from "./actions/HandlePromoterPortalIgnorePendingPromotionClient";
import HandlePromoterPortalApprovePendingPromotionClient from "./actions/HandlePromoterPortalApprovePendingPromotionClient";
//
import {
  SubscriptionItem,
  LimitationsItem,
  AccountItem,
} from "../../components/SubscriptionItem";

// hooks
import promotionPartnersHook from "../../hooks/promotionPartnersHook";
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import typography from "../../theme/typography";
import {
  B1Small,
  B3Tiny,
  ModalAlertWarning,
  CardSwitch,
  CardOutlinedInput,
  CardMenuItem,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ModalValueLine({ parameter, value, widthLeft, widthRight }) {
  ModalValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function FreeDownloadDetails({ freeDownloadDetailsData }) {
  FreeDownloadDetails.propTypes = {
    freeDownloadDetailsData: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  function FreeDownloadDetailsOutlet() {
    let FreeDownloadDetailsData = null;

    if (freeDownloadDetailsData.meta.length !== 0) {
      const freeDownloadDetails = JSON.parse(
        freeDownloadDetailsData.data[0][3].replaceAll("&quot;", '"')
      );

      FreeDownloadDetailsData = (
        <>
          <ModalValueLine
            parameter="Form country"
            value={JSON.stringify(
              freeDownloadDetails["form-country"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form email"
            value={JSON.stringify(
              freeDownloadDetails["form-email"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form equity"
            value={JSON.stringify(
              freeDownloadDetails["form-equity"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form message"
            value={JSON.stringify(
              freeDownloadDetails["form-message"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form name"
            value={JSON.stringify(
              freeDownloadDetails["form-name"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form newsletter"
            value={JSON.stringify(
              freeDownloadDetails["form-newsletter"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form orga"
            value={JSON.stringify(
              freeDownloadDetails["form-orga"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form phone"
            value={JSON.stringify(
              freeDownloadDetails["form-phone"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form product"
            value={JSON.stringify(
              freeDownloadDetails["form-product"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form referral"
            value={JSON.stringify(
              freeDownloadDetails["form-referral"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
          <ModalValueLine
            parameter="Form skill"
            value={JSON.stringify(
              freeDownloadDetails["form-skill"],
              null,
              2
            ).replaceAll('"', "")}
            widthLeft="30%"
            widthRight="70%"
          />
        </>
      );
    }

    return FreeDownloadDetailsData;
  }

  return freeDownloadDetailsData.meta.length !== 0 ? (
    <>
      <B1Small
        fullWidth
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 2 }}
      >
        Free download form details
      </B1Small>
      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Free download form details
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Typography variant="contentSmall">
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[0].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[0]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][0]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[1].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[1]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][1]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[2].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[2]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][2]}
                  widthLeft="30%"
                  widthRight="70%"
                />
                <ModalValueLine
                  parameter={
                    freeDownloadDetailsData.meta[4].charAt(0).toUpperCase() +
                    freeDownloadDetailsData.meta[4]
                      .slice(1)
                      .replaceAll("_", " ")
                  }
                  value={freeDownloadDetailsData.data[0][4]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <FreeDownloadDetailsOutlet />
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  ) : null;
}

function EvaluationDetailsOutlet({ evaluationDetailsData }) {
  EvaluationDetailsOutlet.propTypes = {
    evaluationDetailsData: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return evaluationDetailsData.meta.length !== 0 ? (
    <>
      <B1Small
        fullWidth
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 2 }}
      >
        Evaluation details
      </B1Small>
      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">Evaluation details</Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Typography variant="contentSmall">
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[0].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[0].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[0]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[1].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[1].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[1]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[2].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[2].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[2]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[3].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[3].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[3]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[4].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[4].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[4]}
                  widthLeft="25%"
                  widthRight="75%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[5].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[5].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[5]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[6].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[6].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[6]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[7].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[7].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[7]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[8].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[8].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[8]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[9].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[9].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[9]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[10].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[10].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[10]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[11].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[11].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[11]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[12].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[12].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[12]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[13].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[13].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[13]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[14].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[14].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[14]}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ModalValueLine
                  parameter={
                    evaluationDetailsData.meta[15].charAt(0).toUpperCase() +
                    evaluationDetailsData.meta[15].slice(1).replaceAll("_", " ")
                  }
                  value={evaluationDetailsData.data[15]}
                  widthLeft="50%"
                  widthRight="50%"
                />
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  ) : null;
}

function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value === "1" || value.includes("newsletter=1")) {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "0" || value.includes("newsletter=0")) {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "extended_client_data_missing") {
      valueOutlet = (
        <Typography variant="contentSmall" align="right">
          Missing
        </Typography>
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function DashboardData({ content, promoter }) {
  DashboardData.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  let evaluationDetails = null;

  content.promotion_data.forEach((promotionProduct) => {
    promotionProduct.subscriptions.forEach((promotionPermission) => {
      promotionPermission.accounts.forEach((promotionPermissionAccount) => {
        if (promotionPermissionAccount.evaluation_id !== "-1") {
          if (promotionPermissionAccount.evaluation_id.meta.length !== 0) {
            evaluationDetails = {
              meta: promotionPermissionAccount.evaluation_id.meta,
              data: promotionPermissionAccount.evaluation_id.data[0],
            };
          }
        }
      });
    });
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ mt: 0, mb: 3 }}>
      <CardOutlet
        variant="outlet"
        backgroundProp="#F2F2F2"
        title="Client's Personal Information"
        subheader="Please use the widgets below to manage your promotion client."
        mainOutlet={
          <>
            <HandlePromoterPortalUpdateClientsPersonalInformation
              promoter={promoter}
              dashboardId={content.dashboard_data.dashboard_id}
              configDataClient={content.dashboard_data}
            />
            <HandlePromoterPortalResendApprovalEmail
              promoter={promoter}
              dashboardId={content.dashboard_data.dashboard_id}
            />
            <HandlePromoterPortalResendPasswordResetEmail
              promoter={promoter}
              dashboardId={content.dashboard_data.dashboard_id}
            />

            {typeof content.dashboard_data.form_free_download_ticket_id ===
            "object" ? (
              <FreeDownloadDetails
                freeDownloadDetailsData={
                  content.dashboard_data.form_free_download_ticket_id
                }
              />
            ) : null}

            {evaluationDetails !== null ? (
              <EvaluationDetailsOutlet
                evaluationDetailsData={evaluationDetails}
              />
            ) : null}

            <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
              Visible by client
            </Typography>

            <ValueLine
              parameter="User ID (UID)"
              value={`${content.dashboard_data.dashboard_id}`}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Email"
              value={content.dashboard_data.email}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Forename"
              value={content.dashboard_data.forename}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Surname"
              value={content.dashboard_data.surname}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Birth"
              value={content.dashboard_data.birth}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Gender"
              value={
                content.dashboard_data.gender !== null
                  ? content.dashboard_data.gender.charAt(0).toUpperCase() +
                    content.dashboard_data.gender.slice(1)
                  : ""
              }
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Organization"
              value={content.dashboard_data.organization}
              widthLeft="35%"
              widthRight="65%"
            />
            <ValueLine
              parameter="Street"
              value={content.dashboard_data.street}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Optional address line"
              value={content.dashboard_data.optional_line}
              widthLeft="45%"
              widthRight="55%"
            />
            <ValueLine
              parameter="Postal code"
              value={content.dashboard_data.postal_code}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="City"
              value={content.dashboard_data.city}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Province / State"
              value={content.dashboard_data.province}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Country"
              value={content.dashboard_data.country}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Phone"
              value={content.dashboard_data.phone}
              widthLeft="25%"
              widthRight="75%"
            />
            <ValueLine
              parameter="Newsletter"
              value={content.dashboard_data.email_notification_settings}
              widthLeft="25%"
              widthRight="75%"
            />

            <Typography variant="h3" sx={{ mt: 4, mb: 1 }}>
              Internal
            </Typography>

            <ValueLine
              parameter="Date added"
              value={`${content.dashboard_data.datetime_added} GMT`}
              widthLeft="50%"
              widthRight="50%"
            />

            {content.dashboard_data.account_state !== null &&
            content.dashboard_data.account_state !== undefined ? (
              <ValueLine
                parameter="Account state"
                value={
                  content.dashboard_data.account_state.charAt(0).toUpperCase() +
                  content.dashboard_data.account_state.slice(1)
                }
                widthLeft="45%"
                widthRight="65%"
              />
            ) : null}

            <ValueLine
              parameter="Use personal data for marketing"
              value={content.dashboard_data.marketing_share}
              widthLeft="80%"
              widthRight="20%"
            />
            <ValueLine
              parameter="Use personal data for tailorized adds"
              value={content.dashboard_data.tailorized_adds}
              widthLeft="80%"
              widthRight="20%"
            />
            <ValueLine
              parameter="Last dashboard login"
              value={
                content.dashboard_data.last_login !== null
                  ? `${content.dashboard_data.last_login} GMT`
                  : ""
              }
              widthLeft="50%"
              widthRight="50%"
            />
            <ValueLine
              parameter="Last application start"
              value={content.dashboard_data.last_application_connection}
              widthLeft="50%"
              widthRight="50%"
            />
            <ValueLine
              parameter="Last used application name"
              value={content.dashboard_data.last_application_name}
              widthLeft="55%"
              widthRight="45%"
            />
            <B3Tiny
              fullWidth
              variant="outlined"
              onClick={handleClickOpen("paper")}
              sx={{ mt: 2 }}
            >
              Account notes
            </B3Tiny>
            <ModalDialogInternalNotes
              open={open}
              handleClose={handleClose}
              handleClickClose={handleClickClose}
              scroll={scroll}
              data={content.dashboard_data.internal_notes}
              notesType={`Account notes for user ID (UID) ${content.dashboard_data.dashboard_id}`}
            />
          </>
        }
      />
    </Box>
  );
}

function PromotionSubscriptionItem({
  content,
  promoter,
  promoterLinkId,
  dashboardId,
}) {
  PromotionSubscriptionItem.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
    promoterLinkId: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
  };

  let checkKey = false;
  let isOnlyOneAccountLeft = false;

  if (content !== undefined) {
    const keyOnly = Object.keys(content);

    if (keyOnly.includes("accounts") === true) {
      if (content.accounts.length !== 0) {
        checkKey = true;
      }
      if (content.accounts.length === 1) {
        isOnlyOneAccountLeft = true;
      }
    }
  }

  return content.product.length !== 0 &&
    content.plan.length !== 0 &&
    content.accounts.length !== 0 ? (
    <Grid item xs={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box>
          {content.product.product_tag !== "mt5_tick_data" ? (
            <>
              <SubscriptionItem
                sx={{ px: 0 }}
                mode="promotion"
                content={content}
                promoter={promoter}
                promoterLinkId={promoterLinkId}
                dashboardId={dashboardId}
                managed={Boolean(true)}
              />
              <LimitationsItem
                sx={{ px: 2 }}
                mode="promotion"
                content={content}
                managed={Boolean(true)}
              />
            </>
          ) : (
            <> </>
          )}
        </Box>
        {checkKey ? (
          <Stack>
            {content.accounts.map((currentAccount, index) => (
              <AccountItem
                mode="promotion"
                currentAccount={currentAccount}
                key={index}
                promoter={promoter}
                content={content}
                managed={Boolean(true)}
                isOnlyOneAccountLeft={isOnlyOneAccountLeft}
                isSponsored={Boolean(true)}
                plan={content.plan}
              />
            ))}
          </Stack>
        ) : (
          <> </>
        )}
      </Box>
    </Grid>
  ) : null;
}

function PromotionPartnerItem({
  promotionPartner,
  promoter,
  content,
  selectedPromotionPartner,
}) {
  PromotionPartnerItem.propTypes = {
    promotionPartner: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
    selectedPromotionPartner: PropTypes.any.isRequired,
  };

  let promotionPartnersHookIndex = 0;
  promotionPartnersHook.forEach((promotionPartnerHook, index) => {
    if (
      promotionPartnerHook.broker_id ===
      promotionPartner.promotion_broker_details.broker_id
    ) {
      promotionPartnersHookIndex = index;
    }
  });

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Stack>
          <Box
            sx={{ py: 2, backgroundColor: "#F2F2F2" }}
            display="block"
            align="center"
          >
            <Typography
              variant="h3"
              display="block"
              align="center"
              sx={{ lineHeight: 1 }}
            >
              {promotionPartner.promotion_broker_details.broker_name}
            </Typography>
          </Box>

          <Grid container spacing={0} sx={{ p: 3, background: "white" }}>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              xs={12}
              md={4}
              lg={4}
            >
              <a
                href={`${process.env.REACT_APP_URL_API_LINK_ROTATOR}?broker_id=${promotionPartner.promotion_broker_details.broker_id}`}
              >
                <Box
                  component="img"
                  src={
                    promotionPartnersHook[promotionPartnersHookIndex].imageSrc
                  }
                  sx={{
                    p: 3,
                    width: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </a>
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
              <Box display="block" align="center" sx={{ p: 3 }}>
                <Typography
                  variant="contentMiddle"
                  display="block"
                  align="center"
                >
                  {
                    promotionPartnersHook[promotionPartnersHookIndex]
                      .description
                  }
                </Typography>

                <Typography variant="contentMiddle">
                  <Stack spacing={1} sx={{ mt: 2 }}>
                    {promotionPartnersHook[
                      promotionPartnersHookIndex
                    ].features.map((feature, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Iconify
                          color="#61CE70"
                          icon="eva:checkmark-outline"
                          minWidth={25}
                          minHeight={25}
                          sx={{ mr: 1 }}
                        />
                        {feature}
                      </Box>
                    ))}
                  </Stack>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <HandlePromoterPortalAddAccountsToClient
                promoter={promoter}
                dashboardId={content.dashboard_data.dashboard_id}
                promoterLinkId={
                  promotionPartner.promotion_broker_details.promoter_link_id
                }
                promoterLink={
                  promotionPartner.promotion_broker_details.promoter_link
                }
                contingentRow={
                  promotionPartner.contingent[selectedPromotionPartner]
                }
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Grid>
  );
}

function PermissionData({ content, promoter }) {
  PermissionData.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let promotionPartnerInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs3827fc2cbf1e3dd5 !== null &&
      myLocalSettingStorage.obfs3827fc2cbf1e3dd5 !== undefined
    ) {
      if (
        Number(myLocalSettingStorage.obfs3827fc2cbf1e3dd5) <
        content.promotion_data.length
      ) {
        promotionPartnerInitValue = myLocalSettingStorage.obfs3827fc2cbf1e3dd5;
      }
    }
  }

  const isntZeros = [];

  content.promotion_data.forEach((partner, index) => {
    if (partner.subscriptions[0].accounts.length !== 0) {
      isntZeros.push(index);
    }
  });

  if (isntZeros.length === 1) {
    promotionPartnerInitValue = isntZeros[0];
  } else if (isntZeros.length > 1) {
    if (!isntZeros.includes(Number(promotionPartnerInitValue))) {
      promotionPartnerInitValue = isntZeros[0];
    }
  }

  const [selectedPromotionPartner, setSelectedPromotionPartner] = useState(
    promotionPartnerInitValue
  );

  let checkKey = true;
  if (content === undefined || content === null) {
    checkKey = false;
  }

  const brokers = [];
  if (checkKey) {
    for (let index = 0; index < content.promotion_data.length; index += 1) {
      brokers.push(
        content.promotion_data[index].promotion_broker_details.broker_name
      );
    }
  }
  if (brokers.length === 0) {
    checkKey = false;
  }

  let allZero = true;
  for (
    let index = 0;
    index <
    content.promotion_data[selectedPromotionPartner].subscriptions.length;
    index += 1
  ) {
    if (
      content.promotion_data[selectedPromotionPartner].subscriptions[index]
        .product.length !== 0
    )
      allZero = false;
    if (
      content.promotion_data[selectedPromotionPartner].subscriptions[index].plan
        .length !== 0
    )
      allZero = false;
    if (
      content.promotion_data[selectedPromotionPartner].subscriptions[index]
        .accounts.length !== 0
    )
      allZero = false;
  }

  const [timeCounterSlider, setTimeCounterSlider] = useState(0);
  const [sliderStatus, setSliderStatus] = useState(false);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setSliderStatus(true);
    }, 80);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterSlider]);

  return checkKey ? (
    <Grid container spacing={3} sx={{ p: 0, mt: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 7, mb: 0 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Client's Promotion Application Subscriptions"
            subheader="Please use the widgets below to manage your promotion client."
            mainOutlet={
              <>
                {isDesktop ? (
                  <Box>
                    <Typography variant="contentSmall">
                      Promotion partner
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        value={selectedPromotionPartner}
                        onChange={(event) => {
                          setSliderStatus(false);
                          setTimeCounterSlider(timeCounterSlider + 1);

                          setSelectedPromotionPartner(event.target.value);

                          const newLocalSettingStorage = JSON.parse(
                            localStorage.getItem("myLocalSettingStorage")
                          );

                          newLocalSettingStorage.obfs3827fc2cbf1e3dd5 =
                            event.target.value;
                          localStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                        input={<CardOutlinedInput />}
                      >
                        {brokers.map((broker, index) => (
                          <CardMenuItem key={index} value={index}>
                            {broker}
                          </CardMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="contentSmall">
                      Promotion partner
                    </Typography>
                    <FormControl fullWidth>
                      <NativeSelect
                        value={selectedPromotionPartner}
                        onChange={(event) => {
                          setSliderStatus(false);
                          setTimeCounterSlider(timeCounterSlider + 1);

                          setSelectedPromotionPartner(event.target.value);

                          const newLocalSettingStorage = JSON.parse(
                            localStorage.getItem("myLocalSettingStorage")
                          );

                          newLocalSettingStorage.obfs3827fc2cbf1e3dd5 =
                            event.target.value;
                          localStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                        input={<CardOutlinedInput />}
                      >
                        {brokers.map((broker, index) => (
                          <option key={index} value={index}>
                            {broker}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                )}

                {content.promotion_data[selectedPromotionPartner]
                  .managed_by_other === true ? (
                  <ModalAlertWarning
                    severity="warning"
                    sx={{
                      mt: 2,
                      lineHeight: 1.5,
                      fontSize: typography.contentMiddle.fontSize,
                    }}
                  >
                    This client already has used or is using the selected
                    promotion partner but is assigned to another promoter.
                    Therefore, you can not add it to your account or manage the
                    existing subscriptions. <br /> <br /> In case you want to modify these
                    promotion subscriptions, please reach out to the
                    administrator at {process.env.REACT_APP_EMAIL_PRIMARY_ADMINISTRATOR}.
                  </ModalAlertWarning>
                ) : null}

                {allZero === true ? null : (
                  <Box sx={{ mt: 2 }}>
                    <ValueLine
                      parameter="Partner"
                      value={
                        content.promotion_data[selectedPromotionPartner]
                          .promotion_broker_details.broker_name
                      }
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      parameter="Link"
                      value={
                        content.promotion_data[selectedPromotionPartner]
                          .promotion_broker_details.promoter_link
                      }
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    {Object.keys(
                      content.promotion_data[selectedPromotionPartner]
                        .promotion_broker_details.additional_information
                    ).map((parameter, index) => (
                      <Box key={index}>
                      {/* eslint-disable-line */}
                      {content.promotion_data[selectedPromotionPartner]
                            .promotion_broker_details.additional_information[
                            parameter] !== "unset" ? (<ValueLine
                              parameter={parameter}
                              value={
                                content.promotion_data[selectedPromotionPartner]
                                  .promotion_broker_details.additional_information[
                                  parameter
                                ]
                              }
                              widthLeft="50%"
                              widthRight="50%"
                            />) : null}
                      </Box>
                      
                    ))}

                    <HandlePromoterPortalAddNewTradingAccount
                      promoter={promoter}
                      promoterLinkId={
                        content.promotion_data[selectedPromotionPartner]
                          .promotion_broker_details.promoter_link_id
                      }
                      dashboardId={content.dashboard_data.dashboard_id}
                      KnownAccountServer={content.known_account_server}
                    />

                    <HandlePromoterPortalRemovePromotionClient
                      promoter={promoter}
                      dashboardId={content.dashboard_data.dashboard_id}
                      promoterLinkId={
                        content.promotion_data[selectedPromotionPartner]
                          .promotion_broker_details.promoter_link_id
                      }
                    />
                  </Box>
                )}
              </>
            }
          />
        </Box>
      </Grid>

      {allZero === true &&
      content.promotion_data[selectedPromotionPartner].managed_by_other ===
        false ? (
        <Slide direction="up" in={sliderStatus}>
          <Grid item xs={12} md={12} lg={12}>
            {sliderStatus ? (
              <PromotionPartnerItem
                promotionPartner={
                  content.promotion_data[selectedPromotionPartner]
                }
                promoter={promoter}
                content={content}
                selectedPromotionPartner={selectedPromotionPartner}
              />
            ) : null}
          </Grid>
        </Slide>
      ) : null}

      {content.promotion_data[selectedPromotionPartner].subscriptions.map(
        (currentPromotionSubscriptionItem, index) => (
          <PromotionSubscriptionItem
            key={index}
            content={currentPromotionSubscriptionItem}
            promoter={promoter}
            promoterLinkId={
              content.promotion_data[selectedPromotionPartner]
                .promotion_broker_details.promoter_link_id
            }
            dashboardId={content.dashboard_data.dashboard_id}
            managed={Boolean(true)}
          />
        )
      )}
    </Grid>
  ) : (
    <> </>
  );
}

function SessionData({ content, promoter }) {
  SessionData.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  let promoterPortalManageClientInspectorSimplifiedInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs134bb88b27d909cb !== null &&
      myLocalSettingStorage.obfs134bb88b27d909cb !== undefined
    ) {
      promoterPortalManageClientInspectorSimplifiedInitValue =
        myLocalSettingStorage.obfs134bb88b27d909cb;
    }
  }

  const [simplified, setSimplified] = useState(
    promoterPortalManageClientInspectorSimplifiedInitValue
  );

  const handleChange = (event) => {
    setSimplified(event.target.checked);

    const newLocalSettingStorage = JSON.parse(
      localStorage.getItem("myLocalSettingStorage")
    );
    newLocalSettingStorage.obfs134bb88b27d909cb = event.target.checked;
    localStorage.setItem(
      "myLocalSettingStorage",
      JSON.stringify(newLocalSettingStorage)
    );
  };

  return (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 13, mb: 0 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Client's Inspector Sessions"
            subheader="Please use the widgets below to manage your promotion client's inspector sessions."
            mainOutlet={
              <FormGroup>
                <Box
                  justifyContent="space-between"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="contentMiddle">
                    Simplified output
                  </Typography>
                  <Typography variant="contentMiddle" align="right">
                    <CardSwitch checked={simplified} onChange={handleChange} />
                  </Typography>
                </Box>
              </FormGroup>
            }
          />
        </Box>
      </Grid>

      {content.inspector_data.length !== 0 ? (
        content.inspector_data.map((currentApplication, index) => (
          <ApplicationItem
            key={index}
            content={currentApplication}
            simplified={simplified}
            managed={Boolean(true)}
            promoter={promoter}
          />
        ))
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <Box
            display="block"
            align="center"
            sx={{
              borderRadius: "0px",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
              py: 1,
            }}
          >
            <Typography
              variant="contentBig"
              display="block"
              align="center"
              sx={{ m: "14px" }}
            >
              No active applications found ...
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

function FetchTable({ tableArray, promoter }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              width="25%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Partner </Typography>
            </TableCell>
            <TableCell
              width="30%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Link </Typography>
            </TableCell>
            <TableCell
              width="25%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Email </Typography>
            </TableCell>
            <TableCell
              width="10%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            />
            <TableCell
              width="10%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {tableArray.map((row, index) => (
            <TableRow key={index} sx={{ mb: 1 }}>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall"> {row[12]} </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall"> {row[17].Link} </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography
                  variant="contentSmall"
                  sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {row[15]}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <HandlePromoterPortalIgnorePendingPromotionClient
                  promoter={promoter}
                  eventNo={row[0]}
                />
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <HandlePromoterPortalApprovePendingPromotionClient
                  promoter={promoter}
                  eventNo={row[0]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.map((row, index) => (
        <Stack
          key={index}
          spacing="3px"
          sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "30%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Partner </Typography>
            </Box>
            <Box sx={{ width: "70%", pl: 1 }}>
              <Typography variant="contentSmall">{row[12]}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "30%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Link </Typography>
            </Box>
            <Box sx={{ width: "70%", pl: 1 }}>
              <Typography variant="contentSmall">{row[17].Link}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "30%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Email </Typography>
            </Box>
            <Box sx={{ width: "70%", pl: 1 }}>
              <Typography
                variant="contentSmall"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {row[15]}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <HandlePromoterPortalIgnorePendingPromotionClient
                promoter={promoter}
                eventNo={row[0]}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <HandlePromoterPortalApprovePendingPromotionClient
                promoter={promoter}
                eventNo={row[0]}
              />
            </Box>
          </Box>
        </Stack>
      ))}
    </>
  );
}

function WidgetSubPendingContentProcessed({
  content,
  promoter,
  error,
  warning,
  success,
  returnMessage,
}) {
  WidgetSubPendingContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
  };

  const contentFiltered = [];
  if (content.t_promotion_approval_events !== undefined) {
    content.t_promotion_approval_events.data.forEach((approvalEvent) => {
      contentFiltered.push(approvalEvent);
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Client Promotion Approval Answer Required"
          subheader="This client is waiting for your answer regarding promotion approval. Please verify the personal and trading details below and approve or reject this client."
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            <Box sx={{ mt: 0, mb: 2 }}>
              <FetchTable tableArray={contentFiltered} promoter={promoter} />
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
}

function Pending({ contentProp, promoter }) {
  Pending.propTypes = {
    contentProp: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (promoter !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdatePromoterPortalPendingPromotionClients: false,
          }));

          setError(false);
          setWarning(false);
          setSuccess(false);
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "promoter_portal",
          widget: "pending_promotion_clients",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_promoter_id: promoter,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "desc",
            page_order_by: "event_no",
          },
          filters: {
            event_client_email: contentProp.dashboard_data.email,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");
            
            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setContent(contentFromBackend);

                if (contentFromBackend.num_rows === 0) {
                  setSuccess(true);
                  setReturnMessage("No pending promotions requests found ...");
                } else {
                  setSuccess(false);
                  setReturnMessage("");
                }
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setWarning(false);
                setSuccess(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setWarning(false);
              setSuccess(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setWarning(false);
              setSuccess(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Init refresh
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentProp]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <MissingContent message="Client Promotion Approval Answer Required" />
  );
  if (loading) {
    WidgetOutlet = (
      <LoadingAnimation title="Client Promotion Approval Answer Required" />
    );
  } else if (error) {
    WidgetOutlet = (
      <MissingContent message="Client Promotion Approval Answer Required" />
    );
  } else if (content) {
    WidgetOutlet = (
      <WidgetSubPendingContentProcessed
        content={content}
        promoter={promoter}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return returnMessage.includes("No pending promotions") ? null : (
    <Box sx={{ mt: 0, mb: 13 }}>{WidgetOutlet}</Box>
  );
}

function WidgetMainContentProcessed({ content, promoter }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  let dashboardIdFound = true;

  if (content.message.includes("this is not not your promotion client!")) {
    dashboardIdFound = false;
  }

  return dashboardIdFound ? (
    <Stack>
      <Pending contentProp={content} promoter={promoter} />
      <DashboardData content={content} promoter={promoter} />
      <PermissionData content={content} promoter={promoter} />
      <SessionData content={content} promoter={promoter} />
    </Stack>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  category,
  title,
  subheader,
  dashboardId,
  promoter,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    category: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    subheader: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (promoter !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdatePromoterPortalManagePromotionClient: false,
          }));

          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "promoter_portal",
          widget: "manage_client",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_promoter_id: promoter,
          },
          selection: {
            selected_dashboard_id: dashboardId,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setWarning(false);
                setSuccess(false);
                setContent(contentFromBackend);
                setReturnMessage("");
              } else if (
                contentFromBackend.message.includes(
                  "this is not not your promotion client!"
                )
              ) {
                setSuccess(false);
                setContent(contentFromBackend);
                setWarning(true);
                setLoading(false);
                setReturnMessage("Promotion client not found ...");
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setSuccess(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setSuccess(false);
                setWarning(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setSuccess(false);
              setWarning(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setSuccess(false);
              setWarning(false);
              setLoading(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Refresh is promoter changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoter]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdatePromoterPortalManagePromotionClient) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdatePromoterPortalManagePromotionClient]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalPromoterPortalPromotionClients);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Client" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Client" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Client" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed content={content} promoter={promoter} />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return (
    <>
      <Box sx={{ mt: 0, mb: 13 }}>
        <CardOutlet
          category={category}
          title={title}
          subheader={subheader}
          variant="outlet"
          mainOutlet={
            <B1Small
              fullWidth
              sx={{ mt: 1 }}
              variant="contained"
              onClick={() => {
                navigate("/promoter-portal");

                setStateStorage((previousState) => ({
                  ...previousState,
                  widgetPerformBackScrollPromoterPortalManagePromotionClient: true,
                }));
              }}
            >
              Back to promoter portal
            </B1Small>
          }
          backgroundProp="#F2F2F2"
          loading={loading}
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
        />
      </Box>

      {WidgetOutlet}
    </>
  );
}
