// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import Autocomplete from "@mui/material/Autocomplete";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  Typography,
  NativeSelect,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B1Small,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(15000);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalAddToExistingDistributionCustomerApplication({
  distributor,
  dashboardId,
  distributionPlans,
  KnownAccountServer,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalAddToExistingDistributionCustomerApplication.propTypes =
    {
      distributor: PropTypes.any.isRequired,
      dashboardId: PropTypes.any.isRequired,
      distributionPlans: PropTypes.any.isRequired,
      KnownAccountServer: PropTypes.any.isRequired,
    };
  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  const [formNewPlan, setFormNewPlan] = useState("1");
  let selectedPlan = {
    distribution_live_included_accounts: "0",
    distribution_demo_included_accounts: "0",
  };

  const plans = [];

  const keyOnly = Object.keys(distributionPlans);

  keyOnly.forEach((distrubutionProduct) => {
    distributionPlans[distrubutionProduct].forEach((distrubutionPlan) => {
      plans.push({
        name: `${distrubutionPlan.product_name} - ${distrubutionPlan.plan_description}`,
        id: distrubutionPlan.plan_id,
        distribution_live_included_accounts:
          distrubutionPlan.distribution_live_included_accounts,
        distribution_demo_included_accounts:
          distrubutionPlan.distribution_demo_included_accounts,
      });

      if (distrubutionPlan.plan_id === formNewPlan) {
        selectedPlan = distrubutionPlan;
      }
    });
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({
    live_account: Yup.number().positive().integer(),
    live_server: Yup.string(),
    demo_account: Yup.number().positive().integer(),
    demo_server: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      live_account: "",
      live_server: "",
      demo_account: "",
      demo_server: "",
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      // Calculate accounts
      const calcAccounts = [];
      let accountCounter = 1;

      if (values.live_account !== "") {
        calcAccounts.push({
          operation_id: accountCounter,
          type: "live",
          account: values.live_account,
          server: values.live_server,
        });
        accountCounter += 1;
      }

      if (values.demo_server !== "") {
        calcAccounts.push({
          operation_id: accountCounter,
          type: "demo",
          account: values.demo_account,
          server: values.demo_server,
        });
        accountCounter += 1;
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_and_account_management",
        operation: "add_to_existing_distribution_customer",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        used_plan_id: formNewPlan,
        dashboard: {
          selected_dashboard_id: dashboardId,
        },
        accounts: calcAccounts,
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const KnownAccountServerLiveShown = {
    known_account: KnownAccountServer.known_account,
    known_server: [],
  };
  const KnownAccountServerDemoShown = {
    known_account: KnownAccountServer.known_account,
    known_server: [],
  };

  KnownAccountServer.known_server.forEach((server) => {
    if (!server.toUpperCase().includes("DEMO")) {
      KnownAccountServerLiveShown.known_server.push(server);
    }
    if (!server.toUpperCase().includes("LIVE")) {
      KnownAccountServerDemoShown.known_server.push(server);
    }
  });

  return (
    <>
      <B1Small onClick={handleClickOpen("paper")} fullWidth>
        Add new subscription to customer
      </B1Small>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Add new subscription to customer
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ pb: 2 }}>
                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">New plan</Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formNewPlan}
                          onChange={(event) => {
                            setFormNewPlan(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          {plans.map((currentPlan, index) => (
                            <ModalContentMenuItem
                              key={index}
                              value={currentPlan.id}
                            >
                              {currentPlan.name}
                            </ModalContentMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">New plan</Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formNewPlan}
                          onChange={(event) => {
                            setFormNewPlan(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          {plans.map((currentPlan, index) => (
                            <option key={index} value={currentPlan.id}>
                              {currentPlan.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  {selectedPlan.distribution_live_included_accounts !== "0" &&
                  selectedPlan.distribution_demo_included_accounts !== "0" ? (
                    <Box sx={{ pt: 3 }}>
                      <Typography variant="contentMiddleBold">
                      Please provide at least one initial account!
                      </Typography>
                    </Box>
                  ) : null}

                  {selectedPlan.distribution_live_included_accounts !== "0" ? (
                    <>
                      <Box sx={{ pt: 1 }}>
                        <Typography variant="contentMiddleBold">
                          Initial live account
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          variant="contentSmall"
                          sx={{
                            color:
                              Boolean(
                                touched.live_account && errors.live_account
                              ) === true
                                ? "red"
                                : "none",
                          }}
                        >
                          {Boolean(
                            touched.live_account && errors.live_account
                          ) === true
                            ? "Invalid live account number"
                            : "Live account number"}
                        </Typography>
                        <Autocomplete
                          fullWidth
                          freeSolo
                          size="small"
                          onChange={(event, value) => {
                            values.live_account = value;
                          }}
                          id="combo-box-demo"
                          options={KnownAccountServerLiveShown.known_account}
                          renderOption={(props, option) => (
                            <AutocompleteOption {...props} key={option}>
                              {option}
                            </AutocompleteOption>
                          )}
                          renderInput={(params) => (
                            <ModalContentTextField
                              {...params}
                              type="text"
                              {...getFieldProps("live_account")}
                              error={Boolean(
                                touched.live_account && errors.live_account
                              )}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Box>

                      <Box>
                        <Typography
                          variant="contentSmall"
                          sx={{
                            color:
                              Boolean(
                                touched.live_server && errors.live_server
                              ) === true
                                ? "red"
                                : "none",
                          }}
                        >
                          {Boolean(
                            touched.live_server && errors.live_server
                          ) === true
                            ? "Invalid live trading server"
                            : "Live trading server"}
                        </Typography>
                        <Autocomplete
                          fullWidth
                          freeSolo
                          size="small"
                          onChange={(event, value) => {
                            values.live_server = value;
                          }}
                          id="combo-box-demo"
                          options={KnownAccountServerLiveShown.known_server}
                          renderOption={(props, option) => (
                            <AutocompleteOption {...props} key={option}>
                              {option}
                            </AutocompleteOption>
                          )}
                          renderInput={(params) => (
                            <ModalContentTextField
                              {...params}
                              type="text"
                              {...getFieldProps("live_server")}
                              error={Boolean(
                                touched.live_server && errors.live_server
                              )}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Box>
                    </>
                  ) : null}

                  {selectedPlan.distribution_demo_included_accounts !== "0" ? (
                    <>
                      <Box sx={{ pt: 1 }}>
                        <Typography variant="contentMiddleBold">
                          Initial demo account
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          variant="contentSmall"
                          sx={{
                            color:
                              Boolean(
                                touched.demo_account && errors.demo_account
                              ) === true
                                ? "red"
                                : "none",
                          }}
                        >
                          {Boolean(
                            touched.demo_account && errors.demo_account
                          ) === true
                            ? "Invalid demo account number"
                            : "Demo account number"}
                        </Typography>
                        <Autocomplete
                          fullWidth
                          freeSolo
                          size="small"
                          onChange={(event, value) => {
                            values.demo_account = value;
                          }}
                          id="combo-box-demo"
                          options={KnownAccountServerDemoShown.known_account}
                          renderOption={(props, option) => (
                            <AutocompleteOption {...props} key={option}>
                              {option}
                            </AutocompleteOption>
                          )}
                          renderInput={(params) => (
                            <ModalContentTextField
                              {...params}
                              type="text"
                              {...getFieldProps("demo_account")}
                              error={Boolean(
                                touched.demo_account && errors.demo_account
                              )}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Box>

                      <Box>
                        <Typography
                          variant="contentSmall"
                          sx={{
                            color:
                              Boolean(
                                touched.demo_server && errors.demo_server
                              ) === true
                                ? "red"
                                : "none",
                          }}
                        >
                          {Boolean(
                            touched.demo_server && errors.demo_server
                          ) === true
                            ? "Invalid demo trading server"
                            : "Demo trading server"}
                        </Typography>
                        <Autocomplete
                          fullWidth
                          freeSolo
                          size="small"
                          onChange={(event, value) => {
                            values.demo_server = value;
                          }}
                          id="combo-box-demo"
                          options={KnownAccountServerDemoShown.known_server}
                          renderOption={(props, option) => (
                            <AutocompleteOption {...props} key={option}>
                              {option}
                            </AutocompleteOption>
                          )}
                          renderInput={(params) => (
                            <ModalContentTextField
                              {...params}
                              type="text"
                              {...getFieldProps("demo_server")}
                              error={Boolean(
                                touched.demo_server && errors.demo_server
                              )}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Box>
                    </>
                  ) : null}
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Add
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
