// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  Typography,
  Box,
  Collapse,
  Select,
  NativeSelect,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleDistributorPortalAddNewDistributionCustomerTickData from "./actions/HandleDistributorPortalAddNewDistributionCustomerTickData";
import HandleDistributorPortalAddToExistingDistributionCustomerTickData from "./actions/HandleDistributorPortalAddToExistingDistributionCustomerTickData";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B3Small,
  CardOutlinedInput,
  CardMenuItem,
  B3Tiny,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function FetchTable({ tableArray }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}>
              <Typography variant="contentSmallBold"> Symbol </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Type </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Start date </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Low spread </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">Medium spread</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">High spread</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableArray.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall">
                  {row.symbol_name}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row.symbol_group}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">{row.start_date}</Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row.availability_spread_low === "1" ? (
                    <Iconify
                      color="#61CE70"
                      icon="eva:checkmark-outline"
                      minWidth={22}
                      minHeight={22}
                    />
                  ) : null}

                  {row.availability_spread_low === "0" ? (
                    <Iconify
                      color="#C85439"
                      icon="eva:close-outline"
                      minWidth={22}
                      minHeight={22}
                    />
                  ) : null}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row.availability_spread_medium === "1" ? (
                    <Iconify
                      color="#61CE70"
                      icon="eva:checkmark-outline"
                      minWidth={22}
                      minHeight={22}
                    />
                  ) : null}

                  {row.availability_spread_medium === "0" ? (
                    <Iconify
                      color="#C85439"
                      icon="eva:close-outline"
                      minWidth={22}
                      minHeight={22}
                    />
                  ) : null}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row.availability_spread_high === "1" ? (
                    <Iconify
                      color="#61CE70"
                      icon="eva:checkmark-outline"
                      minWidth={22}
                      minHeight={22}
                    />
                  ) : null}

                  {row.availability_spread_high === "0" ? (
                    <Iconify
                      color="#C85439"
                      icon="eva:close-outline"
                      minWidth={22}
                      minHeight={22}
                    />
                  ) : null}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.map((row, index) => (
        <Stack
          key={index}
          spacing="3px"
          sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Symbol </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">{row.symbol_name}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Type </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">{row.symbol_group}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Start date </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">{row.start_date}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Low spread </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row.availability_spread_low === "1" ? (
                  <Iconify
                    color="#61CE70"
                    icon="eva:checkmark-outline"
                    minWidth={22}
                    minHeight={22}
                  />
                ) : null}

                {row.availability_spread_low === "0" ? (
                  <Iconify
                    color="#C85439"
                    icon="eva:close-outline"
                    minWidth={22}
                    minHeight={22}
                  />
                ) : null}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold">Medium spread</Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row.availability_spread_medium === "1" ? (
                  <Iconify
                    color="#61CE70"
                    icon="eva:checkmark-outline"
                    minWidth={22}
                    minHeight={22}
                  />
                ) : null}

                {row.availability_spread_medium === "0" ? (
                  <Iconify
                    color="#C85439"
                    icon="eva:close-outline"
                    minWidth={22}
                    minHeight={22}
                  />
                ) : null}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> High spread </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row.availability_spread_high === "1" ? (
                  <Iconify
                    color="#61CE70"
                    icon="eva:checkmark-outline"
                    minWidth={22}
                    minHeight={22}
                  />
                ) : null}

                {row.availability_spread_high === "0" ? (
                  <Iconify
                    color="#C85439"
                    icon="eva:close-outline"
                    minWidth={22}
                    minHeight={22}
                  />
                ) : null}
              </Typography>
            </Box>
          </Box>
        </Stack>
      ))}
    </>
  );
}

function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function WidgetMainContentProcessed({ content, distributor }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const plans = [];

  for (
    let index = 0;
    index <
    content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans
      .length;
    index += 1
  ) {
    plans.push(
      content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
        index
      ]
    );
  }

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  let distributorPortalAvailableTickDataPlansCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfscc67442277b56b01 !== null &&
      myLocalSettingStorage.obfscc67442277b56b01 !== undefined
    ) {
      distributorPortalAvailableTickDataPlansCollapseInitValue =
        myLocalSettingStorage.obfscc67442277b56b01;
    }
  }
  const [collapse, setCollapse] = useState(
    distributorPortalAvailableTickDataPlansCollapseInitValue
  );

  let distributorPortalAvailableTickDataPlansInitValue = 0;

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs67750d1b034e81c6 !== null &&
      myLocalSettingStorage.obfs67750d1b034e81c6 !== undefined
    ) {
      if (Number(myLocalSettingStorage.obfs67750d1b034e81c6) < plans.length) {
        distributorPortalAvailableTickDataPlansInitValue =
          myLocalSettingStorage.obfs67750d1b034e81c6;
      }
    }
  }

  let renderComponent = false;

  let applicationCategoryInitValue = null;

  if (plans.length > 0) {
    applicationCategoryInitValue =
      plans[distributorPortalAvailableTickDataPlansInitValue]
        .t_tick_data_linking;
    renderComponent = true;
  }

  const [applicationIndex, setApplicationIndex] = useState(
    distributorPortalAvailableTickDataPlansInitValue
  );
  const [applicationCategory, setApplicationCategory] = useState(
    applicationCategoryInitValue
  );

  let paymentTenure = "loading";

  if (
    content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
      applicationIndex
    ] !== undefined
  ) {
    if (
      content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
        applicationIndex
      ].plan_interval_unit === "DAY"
    ) {
      paymentTenure = "daily";
    } else if (
      content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
        applicationIndex
      ].plan_interval_unit === "WEEK"
    ) {
      paymentTenure = "weekly";
    } else if (
      content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
        applicationIndex
      ].plan_interval_unit === "MONTH"
    ) {
      paymentTenure = "monthly";
    } else if (
      content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
        applicationIndex
      ].plan_interval_unit === "YEAR"
    ) {
      paymentTenure = "yearly";
    }
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return renderComponent ? (
    <Grid item xs={12} md={12} lg={12} sx={{mt: 3}}>
      <CardOutlet
        variant="outlet"
        backgroundProp="#F2F2F2"
        title="Available Tick Data Plans"
        subheader="For your convenience, the latest available tick data plans are listed below."
        mainOutlet={
          <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
            {isDesktop === false ? (
              <B3Tiny
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                onClick={() => {
                  setMobileCollapse(!mobileCollapse);
                }}
              >
                {mobileCollapse ? "Close" : "Open"}
              </B3Tiny>
            ) : null}

            <Collapse
              sx={{ mx: -1, px: 1 }}
              in={mobileCollapse}
              timeout="auto"
              unmountOnExit
            >
              <Grid container spacing={1} sx={{ p: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  {plans.length > 1 ? (
                    <Box sx={{ mb: 3 }}>
                      {isDesktop ? (
                        <Box>
                          <Typography variant="contentSmall">
                            Tick data plan
                          </Typography>
                          <FormControl fullWidth>
                            <Select
                              value={applicationIndex}
                              onChange={(event) => {
                                setApplicationIndex(event.target.value);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfs67750d1b034e81c6 =
                                  event.target.value;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );

                                setApplicationCategory(
                                  plans[event.target.value].t_tick_data_linking
                                );
                              }}
                              input={<CardOutlinedInput />}
                            >
                              {plans.map((currentPlan, index) => (
                                <CardMenuItem key={index} value={index}>
                                  {currentPlan.plan_name}
                                </CardMenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        <Box>
                          <Typography variant="contentSmall">
                            Tick data plan
                          </Typography>
                          <FormControl fullWidth>
                            <NativeSelect
                              value={applicationIndex}
                              onChange={(event) => {
                                setApplicationIndex(event.target.value);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfs67750d1b034e81c6 =
                                  event.target.value;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );

                                setApplicationCategory(
                                  plans[event.target.value].t_tick_data_linking
                                );
                              }}
                              input={<CardOutlinedInput />}
                            >
                              {plans.map((currentPlan, index) => (
                                <option key={index} value={index}>
                                  {currentPlan.plan_name}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      )}
                    </Box>
                  ) : null}

                  <Box sx={{ mb: 4 }}>
                    <ValueLine
                      parameter="Payment fee"
                      value={`${numberWithCommas(
                        (
                          Math.floor(
                            content.distribution_mt5_tick_data_plans
                              .distribution_mt5_tick_data_plans[
                              applicationIndex
                            ].plan_fixed_price_value * 100
                          ) / 100
                        ).toFixed(2)
                      )} ${content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[
                        applicationIndex
                      ].plan_fixed_price_currency_code.replace("EUR", "€")}`}
                      widthLeft="50%"
                      widthRight="50%"
                    />
                    <ValueLine
                      parameter="Payment tenure"
                      value={paymentTenure}
                      widthLeft="50%"
                      widthRight="50%"
                    />
                  </Box>

                  <Box sx={{ mt: 1 }}>
                    <HandleDistributorPortalAddNewDistributionCustomerTickData
                      distributor={distributor}
                      planId={
                        content.distribution_mt5_tick_data_plans
                          .distribution_mt5_tick_data_plans[applicationIndex]
                          .plan_id
                      }
                    />
                  </Box>

                  <Box sx={{ mt: 1 }}>
                    <HandleDistributorPortalAddToExistingDistributionCustomerTickData
                      distributor={distributor}
                      planId={
                        content.distribution_mt5_tick_data_plans
                          .distribution_mt5_tick_data_plans[applicationIndex]
                          .plan_id
                      }
                    />
                  </Box>

                  <Box sx={{ mt: 1 }}>
                    <B3Small
                      fullWidth
                      variant="outlined"
                      target="_blank"
                      href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution-tick-data?plan_id=${content.distribution_mt5_tick_data_plans.distribution_mt5_tick_data_plans[applicationIndex].plan_id}&hidden_tag=fbcd6ad7c7bee06a77661dd7eb02d334`}
                    >
                      Tick data checkout
                    </B3Small>
                  </Box>

                  <B3Small
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1 }}
                    onClick={() => {
                      setCollapse(!collapse);

                      const newLocalSettingStorage = JSON.parse(
                        localStorage.getItem("myLocalSettingStorage")
                      );

                      newLocalSettingStorage.obfscc67442277b56b01 = !collapse;
                      localStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                  >
                    {collapse
                      ? "Hide included symbols"
                      : "Show included symbols"}
                  </B3Small>

                  <Collapse in={collapse} timeout="auto" unmountOnExit>
                    <Grid item xs={12} md={12} lg={12}>
                      {content.distribution_mt5_tick_data_plans !==
                      undefined ? (
                        <>
                          <Box sx={{ mt: 2, mb: 2 }} dir="ltr">
                            <Typography variant="contentMiddle">
                              Following symbol are included in this tick data
                              subscription plan.
                            </Typography>
                          </Box>
                          <FetchTable
                            tableArray={
                              content.distribution_mt5_tick_data_plans
                                .availability_cats[applicationCategory]
                            }
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        }
      />
    </Grid>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  distributor,
  loadingWidgetAvailableTickDataPlans,
  setLoadingWidgetAvailableTickDataPlans,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    distributor: PropTypes.any.isRequired,
    loadingWidgetAvailableTickDataPlans: PropTypes.any.isRequired,
    setLoadingWidgetAvailableTickDataPlans: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalAvailableTickDataPlans: false,
          }));

          setLoading(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "mt5_tick_data_plans",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "",
            page_order_by: "",
          },
          filters: {
            // account_state: "",
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetAvailableTickDataPlans(false);
                setContent(contentFromBackend);
              } else if (
                contentFromBackend.message.includes("no application distributi")
              ) {
                setSuccess(true);
                setContent(null);
                setLoading(false);
                setLoadingWidgetAvailableTickDataPlans(false);
                setReturnMessage(
                  "No application distribution dustomers found yet! Wait for them or add a few ..."
                );
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setLoadingWidgetAvailableTickDataPlans(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setLoadingWidgetAvailableTickDataPlans(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetAvailableTickDataPlans(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetAvailableTickDataPlans(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalAvailableTickDataPlans)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalAvailableTickDataPlans]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalAvailableTickDataPlans);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <Box sx={{ mt: 3 }}>
      <MissingContent message="Available Tick Data Plans" />
    </Box>
  );
  if (loading || loadingWidgetAvailableTickDataPlans) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <LoadingAnimation title="Available Tick Data Plans" />
      </Box>
    );
  } else if (error) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <MissingContent message="Available Tick Data Plans" />
      </Box>
    );
  } else if (content) {
    if (content.hide_widget === false) {
      WidgetOutlet = (
          <WidgetMainContentProcessed
            content={content}
            distributor={distributor}
          />
      );
    } else {
      WidgetOutlet = null;
    }
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
