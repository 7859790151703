// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "./Iconify";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function ModalValueLine({ parameter, value, widthLeft, widthRight }) {
  ModalValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function ModalDialogInternalNotes({
  data,
  open,
  handleClose,
  handleClickClose,
  scroll,
  notesType,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  ModalDialogInternalNotes.propTypes = {
    data: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    handleClose: PropTypes.any.isRequired,
    handleClickClose: PropTypes.any.isRequired,
    scroll: PropTypes.any.isRequired,
    notesType: PropTypes.any.isRequired,
  };

  const dataSplitted = [];
  data.split(";").forEach((entry) => {
    if (entry !== "") {
      dataSplitted.push({
        datetime: entry.slice(0, 19),
        entry: entry.slice(21),
      });
    }
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            margin: 0,
            maxHeight: {
              xs: "calc(100% - 20px)",
              sm: "calc(100% - 40px)",
              md: "calc(100% - 60px)",
              lg: "calc(100% - 60px)",
              xl: "calc(100% - 60px)",
            },
            minWidth: {
              xs: "calc(100% - 20px)",
              sm: "calc(100% - 40px)",
              md: "calc(95vw)",
              lg: "calc(90vw)",
              xl: "calc(80vw)",
            },
            maxWidth: {
              xs: "calc(100% - 20px)",
              sm: "calc(100% - 40px)",
              md: "calc(95vw)",
              lg: "calc(90vw)",
              xl: "calc(80vw)",
            },
          },
          "& .MuiAlert-root": {
            margin: 1,
            width: "unset",
            height: "unset",
            minHeight: "unset",
            minWidth: "unset",
            maxHeight: "unset",
            maxWidth: "unset",
          },
        },
      }}
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          background: "#F2F2F2",
          textTransform: "none",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          py: 1,
          pr: 1,
        }}
      >
        <Typography variant="contentBig">
          {notesType}
        </Typography>

        <IconButton onClick={handleClickClose} sx={{ borderRadius: "0px" }}>
          <Iconify
            icon="eva:close-outline"
            minWidth={25}
            minHeight={25}
            color="#474A4C"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          background: "#F2F2F2",
          borderTop: "1px solid #C8C8C8",
          borderBottom: "1px solid #C8C8C8",
        }}
      >
        {dataSplitted.map((dataLine, index) => (
          <ModalValueLine
            key={index}
            parameter={`${dataLine.datetime} GMT`}
            value={dataLine.entry}
            widthLeft={isDesktop ? "20%" : "30%"} 
            widthRight={isDesktop ? "80%" : "70%"}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default ModalDialogInternalNotes;
