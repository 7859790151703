// react and js
import PropTypes from "prop-types";
import { React, useState } from "react";
import dayjs from "dayjs";

// mui
import {
  Grid,
  Stack,
  Box,
  Typography,
  FormControl,
  Link,
  Select,
  NativeSelect,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { useTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import HandlePromoterPortalEndSession from "../widgets/promoter_portal/actions/HandlePromoterPortalEndSession";
//
import HandleDistributorPortalEndSession from "../widgets/distributor_portal/actions/HandleDistributorPortalEndSession";

// hooks

// layouts

// pages

// theme
import { ItemOutlinedInput, ItemMenuItem } from "../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
  },
});

function ValueLine({
  parameter,
  value,
  sumUp,
  tooltipLeft,
  widthLeft,
  widthRight,
}) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    sumUp: PropTypes.any,
    tooltipLeft: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        {tooltipLeft !== undefined ? (
          <CustomWidthTooltip
            title={tooltipLeft}
            followCursor
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            enterDelay={200}
            leaveDelay={0}
          >
            <Typography
              variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
              align="left"
            >
              {parameter}
            </Typography>
          </CustomWidthTooltip>
        ) : (
          <Typography
            variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
            align="left"
          >
            {parameter}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
          variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
          align="right"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function PerformanceLine({
  sumUp,
  parameter,
  valueAbsolute,
  valueRelative,
  unitAbs,
  unitRel,
  tooltipLeft,
  colorLeft,
  colorMiddle,
  colorRight,
}) {
  PerformanceLine.propTypes = {
    sumUp: PropTypes.any,
    parameter: PropTypes.any.isRequired,
    valueAbsolute: PropTypes.any,
    valueRelative: PropTypes.any,
    unitAbs: PropTypes.any,
    unitRel: PropTypes.any,
    tooltipLeft: PropTypes.any,
    colorLeft: PropTypes.any,
    colorMiddle: PropTypes.any,
    colorRight: PropTypes.any,
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
        mb: "5px",
        p: "5px 0px 0px 0px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {tooltipLeft !== undefined ? (
            <CustomWidthTooltip
              title={tooltipLeft}
              followCursor
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={200}
              leaveDelay={0}
            >
              <Typography
                variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
                display="block"
                align="left"
                sx={{ color: colorLeft !== undefined ? colorLeft : "none" }}
              >
                {parameter}
              </Typography>
            </CustomWidthTooltip>
          ) : (
            <Typography
              variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
              display="block"
              align="left"
            >
              {parameter}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
            display="block"
            align="right"
            sx={{ color: colorMiddle !== undefined ? colorMiddle : "none" }}
          >
            {valueAbsolute !== "-" ? (
              <>
                {(Math.floor(valueAbsolute * 100) / 100).toFixed(2)} {unitAbs}
              </>
            ) : (
              "-"
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
            display="block"
            align="right"
            sx={{ color: colorRight !== undefined ? colorRight : "none" }}
          >
            {valueRelative !== "-" ? (
              <>
                {(Math.floor(valueRelative * 100) / 100).toFixed(2)} {unitRel}
              </>
            ) : (
              "-"
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function InspectorPerformance({ inspectorData }) {
  InspectorPerformance.propTypes = {
    inspectorData: PropTypes.any.isRequired,
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h4" display="block" align="left">
            Performance
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" display="block" align="right">
            Absolute
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" display="block" align="right">
            Relative
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Day
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Day
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Day
          </Typography>
        </Grid>
      </Grid>

      <PerformanceLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_day_abs_max) !== 0
            ? inspectorData.performance_day_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_day_rel_max) !== 0
            ? inspectorData.performance_day_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed performance per day for application/module. If performance exceeds this limit, the application/module will shut down for the day."
        colorMiddle={
          Number(inspectorData.performance_day_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_day_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        parameter="Minimum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_day_abs_min) !== 0
            ? inspectorData.performance_day_abs_min
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_day_rel_min) !== 0
            ? inspectorData.performance_day_rel_min
            : "-"
        }
        tooltipLeft="Minimum allowed performance per day for application/module. If performance exceeds this limit, the application/module will shut down for the day."
        colorMiddle={
          Number(inspectorData.performance_day_abs_min) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_day_rel_min) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        // sumUp={Boolean(true)}
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        parameter="Current"
        valueAbsolute={inspectorData.performance_day_abs_current_all}
        valueRelative={inspectorData.performance_day_rel_current}
        tooltipLeft="Current application/module performance of the day. Each application/module's performance is inspected individually and in real-time. Suppose the application/module reaches one of the defined minimum or maximum values. In that case, the inspector turned off the trading module for the day. This value will be reset once a day, and in the same turn, the application/module will be allowed to open trades again for a further day."
        colorMiddle={
          Number(inspectorData.performance_day_abs_max_state) === 1 ||
          Number(inspectorData.performance_day_abs_min_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_day_rel_max_state) === 1 ||
          Number(inspectorData.performance_day_rel_min_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Week
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Week
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Week
          </Typography>
        </Grid>
      </Grid>

      <PerformanceLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_week_abs_max) !== 0
            ? inspectorData.performance_week_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_week_rel_max) !== 0
            ? inspectorData.performance_week_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed performance per week for application/module. If performance exceeds this limit, the application/module will shut down for the week."
        colorMiddle={
          Number(inspectorData.performance_week_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_week_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        parameter="Minimum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_week_abs_min) !== 0
            ? inspectorData.performance_week_abs_min
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_week_rel_min) !== 0
            ? inspectorData.performance_week_rel_min
            : "-"
        }
        tooltipLeft="Minimum allowed performance per week for application/module. If performance exceeds this limit, the application/module will shut down for the week."
        colorMiddle={
          Number(inspectorData.performance_week_abs_min) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_week_rel_min) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        // sumUp={Boolean(true)}
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        parameter="Current"
        valueAbsolute={inspectorData.performance_week_abs_current_all}
        valueRelative={inspectorData.performance_week_rel_current}
        tooltipLeft="Current application/module performance of the week. For each application/module, the performance is inspected individually and in real-time. Suppose the application/module reaches one of the defined minimum or maximum values. In that case, the inspector takes care to turn off the trading module for the week. This value will be reset once a week, and in the same turn, the application/module will be allowed to open trades again for a further week."
        colorMiddle={
          Number(inspectorData.performance_week_abs_max_state) === 1 ||
          Number(inspectorData.performance_week_abs_min_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_week_rel_max_state) === 1 ||
          Number(inspectorData.performance_week_rel_min_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Month
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Month
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Month
          </Typography>
        </Grid>
      </Grid>

      <PerformanceLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_month_abs_max) !== 0
            ? inspectorData.performance_month_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_month_rel_max) !== 0
            ? inspectorData.performance_month_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed performance per month for application/module. If performance exceeds this limit, the application/module will shut down for the month."
        colorMiddle={
          Number(inspectorData.performance_month_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_month_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        parameter="Minimum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_month_abs_min) !== 0
            ? inspectorData.performance_month_abs_min
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_month_rel_min) !== 0
            ? inspectorData.performance_month_rel_min
            : "-"
        }
        tooltipLeft="Minimum allowed performance per month for application/module. If performance exceeds this limit, the application/module will shut down for the month."
        colorMiddle={
          Number(inspectorData.performance_month_abs_min) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_month_rel_min) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.performance_month_abs_current_all}
        valueRelative={inspectorData.performance_month_rel_current}
        tooltipLeft="Current application/module performance of the month. For each application/module, the performance is inspected individually and in real-time. Suppose the application/module reaches one of the defined minimum or maximum values. In that case, the inspector turned off the trading module for the month. This value will be reset once a month, and in the same turn, the application/module will be allowed to open trades again for a further month."
        colorMiddle={
          Number(inspectorData.performance_month_abs_max_state) === 1 ||
          Number(inspectorData.performance_month_abs_min_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_month_rel_max_state) === 1 ||
          Number(inspectorData.performance_month_rel_min_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Year
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Year
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Year
          </Typography>
        </Grid>
      </Grid>

      <PerformanceLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_year_abs_max) !== 0
            ? inspectorData.performance_year_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_year_rel_max) !== 0
            ? inspectorData.performance_year_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed performance per year for application/module. If performance exceeds this limit, the application/module will shut down for the year."
        colorMiddle={
          Number(inspectorData.performance_year_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_year_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        parameter="Minimum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_year_abs_min) !== 0
            ? inspectorData.performance_year_abs_min
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_year_rel_min) !== 0
            ? inspectorData.performance_year_rel_min
            : "-"
        }
        tooltipLeft="Minimum allowed performance per year for application/module. If performance exceeds this limit, the application/module will shut down for the year."
        colorMiddle={
          Number(inspectorData.performance_year_abs_min) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_year_rel_min) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.performance_year_abs_current_all}
        valueRelative={inspectorData.performance_year_rel_current}
        tooltipLeft="Current application/module performance of the year. For each application/module, the performance is inspected individually and in real-time. Suppose the application/module reaches one of the defined minimum or maximum values. In that case, the inspector takes care to turn off the trading module for the year. This value will be reset once a year, and in the same turn, the application/module will be allowed to open trades again for a further year."
        colorMiddle={
          Number(inspectorData.performance_year_abs_max_state) === 1 ||
          Number(inspectorData.performance_year_abs_min_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_year_rel_max_state) === 1 ||
          Number(inspectorData.performance_year_rel_min_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Since start
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Since start
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Since start
          </Typography>
        </Grid>
      </Grid>

      <PerformanceLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_since_start_abs_max) !== 0
            ? inspectorData.performance_since_start_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_since_start_rel_max) !== 0
            ? inspectorData.performance_since_start_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed performance since the start of the application/module. The application/module will shut down if performance exceeds this limit."
        colorMiddle={
          Number(inspectorData.performance_since_start_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_since_start_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        parameter="Minimum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.performance_since_start_abs_min) !== 0
            ? inspectorData.performance_since_start_abs_min
            : "-"
        }
        valueRelative={
          Number(inspectorData.performance_since_start_rel_min) !== 0
            ? inspectorData.performance_since_start_rel_min
            : "-"
        }
        tooltipLeft="Minimum allowed performance since the start of the application/module. The application/module will shut down if performance exceeds this limit."
        colorMiddle={
          Number(inspectorData.performance_since_start_abs_min) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_since_start_rel_min) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <PerformanceLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.performance_since_start_abs_current_all}
        valueRelative={inspectorData.performance_since_start_rel_current}
        tooltipLeft="Current application/module performance since the start. For each application/module, the performance is inspected individually and in real-time. Suppose the application/module reaches one of the defined minimum or maximum values. In that case, the inspector turns off the trading module. This value won't be reset!"
        colorMiddle={
          Number(inspectorData.performance_since_start_abs_max_state) === 1 ||
          Number(inspectorData.performance_since_start_abs_min_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.performance_since_start_rel_max_state) === 1 ||
          Number(inspectorData.performance_since_start_rel_min_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 2 }} />
    </Box>
  );
}

function DrawdownLine({
  sumUp,
  parameter,
  valueAbsolute,
  valueRelative,
  unitAbs,
  unitRel,
  tooltipLeft,
  colorLeft,
  colorMiddle,
  colorRight,
}) {
  DrawdownLine.propTypes = {
    sumUp: PropTypes.any,
    parameter: PropTypes.any.isRequired,
    valueAbsolute: PropTypes.any,
    valueRelative: PropTypes.any,
    unitAbs: PropTypes.any,
    unitRel: PropTypes.any,
    tooltipLeft: PropTypes.any,
    colorLeft: PropTypes.any,
    colorMiddle: PropTypes.any,
    colorRight: PropTypes.any,
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
        mb: "5px",
        p: "5px 0px 0px 0px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {tooltipLeft !== undefined ? (
            <CustomWidthTooltip
              title={tooltipLeft}
              followCursor
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={200}
              leaveDelay={0}
            >
              <Typography
                variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
                display="block"
                align="left"
                sx={{ color: colorLeft !== undefined ? colorLeft : "none" }}
              >
                {parameter}
              </Typography>
            </CustomWidthTooltip>
          ) : (
            <Typography
              variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
              display="block"
              align="left"
            >
              {parameter}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
            display="block"
            align="right"
            sx={{ color: colorMiddle !== undefined ? colorMiddle : "none" }}
          >
            {valueAbsolute !== "-" ? (
              <>
                {(Math.floor(valueAbsolute * 100) / 100).toFixed(2)} {unitAbs}
              </>
            ) : (
              "-"
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
            display="block"
            align="right"
            sx={{ color: colorRight !== undefined ? colorRight : "none" }}
          >
            {valueRelative !== "-" ? (
              <>
                {(Math.floor(valueRelative * 100) / 100).toFixed(2)} {unitRel}
              </>
            ) : (
              "-"
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function InspectorDrawdown({ inspectorData }) {
  InspectorDrawdown.propTypes = {
    inspectorData: PropTypes.any.isRequired,
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h4" display="block" align="left">
            Drawdown
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" display="block" align="right">
            Absolute
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" display="block" align="right">
            Relative
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Day
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Day
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Day
          </Typography>
        </Grid>
      </Grid>

      <DrawdownLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.drawdown_day_abs_max) !== 0
            ? inspectorData.drawdown_day_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.drawdown_day_rel_max) !== 0
            ? inspectorData.drawdown_day_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed drawdown per day for application/module. If the drawdown exceeds this limit, the application/module will shut down for the day."
        colorMiddle={
          Number(inspectorData.drawdown_day_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_day_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <DrawdownLine
        parameter="Worst"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_day_abs_worst}
        valueRelative={inspectorData.drawdown_day_rel_worst}
        tooltipLeft="Worst recorded drawdown of a day for the application/module."
      />

      <DrawdownLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_day_abs_current}
        valueRelative={inspectorData.drawdown_day_rel_current}
        tooltipLeft="Current application/module drawdown of the day. For each application/module, the drawdown is inspected individually and in real-time. Suppose the application/module reaches the maximum value. In that case, the inspector takes care to turn off the trading module for the day. This value will be reset once a day, and in the same turn, the application/module will be allowed to open trades again for a further day."
        colorMiddle={
          Number(inspectorData.drawdown_day_abs_max_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_day_rel_max_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Week
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Week
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Week
          </Typography>
        </Grid>
      </Grid>

      <DrawdownLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.drawdown_week_abs_max) !== 0
            ? inspectorData.drawdown_week_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.drawdown_week_rel_max) !== 0
            ? inspectorData.drawdown_week_rel_max
            : "-"
        }
        tooltipLeft="The maximum allowed drawdown per week for the application/module is. If the drawdown exceeds this limit, the application/module will shut down for the week."
        colorMiddle={
          Number(inspectorData.drawdown_week_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_week_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <DrawdownLine
        parameter="Worst"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_week_abs_worst}
        valueRelative={inspectorData.drawdown_week_rel_worst}
        tooltipLeft="The worst recorded drawdown of a week was for the application/module."
      />

      <DrawdownLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_week_abs_current}
        valueRelative={inspectorData.drawdown_week_rel_current}
        tooltipLeft="Current application/module drawdown of the week. For each application/module, the drawdown is inspected individually and in real-time. Suppose the application/module reaches the maximum value. In that case, the inspector takes care to turn off the trading module for the week. This value will be reset once a week, and in the same turn, the application/module will be allowed to open trades again for a further week."
        colorMiddle={
          Number(inspectorData.drawdown_week_abs_max_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_week_rel_max_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Month
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Month
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Month
          </Typography>
        </Grid>
      </Grid>

      <DrawdownLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.drawdown_month_abs_max) !== 0
            ? inspectorData.drawdown_month_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.drawdown_month_rel_max) !== 0
            ? inspectorData.drawdown_month_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed drawdown per month for application/module. If the drawdown exceeds this limit, the application/module will shut down for the month."
        colorMiddle={
          Number(inspectorData.drawdown_month_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_month_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <DrawdownLine
        parameter="Worst"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_month_abs_worst}
        valueRelative={inspectorData.drawdown_month_rel_worst}
        tooltipLeft="Worst recorded drawdown of a month for the application/module."
      />

      <DrawdownLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_month_abs_current}
        valueRelative={inspectorData.drawdown_month_rel_current}
        tooltipLeft="Current application/module drawdown of the month. For each application/module, the drawdown is inspected individually and in real-time. Suppose the application/module reaches the maximum value. In that case, the inspector takes care to turn off the trading module for the month. This value will be reset once a month, and in the same turn, the application/module will be allowed to open trades again for a further month."
        colorMiddle={
          Number(inspectorData.drawdown_month_abs_max_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_month_rel_max_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Year
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Year
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Year
          </Typography>
        </Grid>
      </Grid>

      <DrawdownLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.drawdown_year_abs_max) !== 0
            ? inspectorData.drawdown_year_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.drawdown_year_rel_max) !== 0
            ? inspectorData.drawdown_year_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed drawdown per year for application/module. If the drawdown exceeds this limit, the application/module will shut down for the year."
        colorMiddle={
          Number(inspectorData.drawdown_year_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_year_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <DrawdownLine
        parameter="Worst"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_year_abs_worst}
        valueRelative={inspectorData.drawdown_year_rel_worst}
        tooltipLeft="Worst recorded drawdown of a year for application/module."
      />

      <DrawdownLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_year_abs_current}
        valueRelative={inspectorData.drawdown_year_rel_current}
        tooltipLeft="Current application/module drawdown of the year. For each application/module, the drawdown is inspected individually and in real-time. Suppose the application/module reaches the maximum value. In that case, the inspector takes care to turn off the trading module for the year. This value will be reset once a year, and in the same turn, the application/module will be allowed to open trades again for a further year."
        colorMiddle={
          Number(inspectorData.drawdown_year_abs_max_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_year_rel_max_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 4 }} />

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="left">
            Since start
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Since start
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" display="block" align="right">
            Since start
          </Typography>
        </Grid>
      </Grid>

      <DrawdownLine
        parameter="Maximum"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={
          Number(inspectorData.drawdown_since_start_abs_max) !== 0
            ? inspectorData.drawdown_since_start_abs_max
            : "-"
        }
        valueRelative={
          Number(inspectorData.drawdown_since_start_rel_max) !== 0
            ? inspectorData.drawdown_since_start_rel_max
            : "-"
        }
        tooltipLeft="Maximum allowed drawdown since the start of the application/module. The application/module will shut down if the drawdown exceeds this limit."
        colorMiddle={
          Number(inspectorData.drawdown_since_start_abs_max) !== 0
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_since_start_rel_max) !== 0
            ? "#C85439"
            : undefined
        }
      />

      <DrawdownLine
        parameter="Worst"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_since_start_abs_worst}
        valueRelative={inspectorData.drawdown_since_start_rel_worst}
        tooltipLeft="It was the worst recorded drawdown since the application/module started."
      />

      <DrawdownLine
        // sumUp={Boolean(true)}
        parameter="Current"
        unitAbs={inspectorData.account_currency}
        unitRel="%"
        valueAbsolute={inspectorData.drawdown_since_start_abs_current}
        valueRelative={inspectorData.drawdown_since_start_rel_current}
        tooltipLeft="The current application/module drawdown has occurred since the start. For each application/module, the drawdown is inspected individually and in real-time. If the application/module reaches the maximum value, the inspector turns off the trading module. This value won't be reset!"
        colorMiddle={
          Number(inspectorData.drawdown_since_start_abs_max_state) === 1
            ? "#C85439"
            : undefined
        }
        colorRight={
          Number(inspectorData.drawdown_since_start_rel_max_state) === 1
            ? "#C85439"
            : undefined
        }
      />

      <Box sx={{ my: 2 }} />
    </Box>
  );
}

function StatisticLine({
  sumUp,
  unit,
  digits,
  parameter,
  valueLong,
  valueShort,
  valueAll,
  tooltipLeft,
}) {
  StatisticLine.propTypes = {
    sumUp: PropTypes.any,
    unit: PropTypes.any,
    digits: PropTypes.any,
    parameter: PropTypes.any,
    valueLong: PropTypes.any,
    valueShort: PropTypes.any,
    valueAll: PropTypes.any,
    tooltipLeft: PropTypes.any,
  };

  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
        mb: "5px",
        p: "5px 0px 0px 0px",
      }}
    >
      <Box
        sx={{
          width: "31%",
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        {tooltipLeft !== undefined ? (
          <CustomWidthTooltip
            title={tooltipLeft}
            followCursor
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            enterDelay={200}
            leaveDelay={0}
          >
            <Typography
              variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
              display="block"
              align="left"
            >
              {parameter}
            </Typography>
          </CustomWidthTooltip>
        ) : (
          <Typography
            variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
            display="block"
            align="left"
          >
            {parameter}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "23%",
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
          display="block"
          align="right"
        >
          {`${(Math.floor(valueLong * 10 ** digits) / 10 ** digits).toFixed(
            digits
          )} ${unit}`}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "23%",
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
          display="block"
          align="right"
        >
          {`${(Math.floor(valueShort * 10 ** digits) / 10 ** digits).toFixed(
            digits
          )} ${unit}`}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "23%",
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant={sumUp === true ? "contentTinyBold" : "contentTiny"}
          display="block"
          align="right"
        >
          {`${(Math.floor(valueAll * 10 ** digits) / 10 ** digits).toFixed(
            digits
          )} ${unit}`}
        </Typography>
      </Box>
    </Box>
  );
}

function InspectorStatistic({ inspectorData }) {
  InspectorStatistic.propTypes = {
    inspectorData: PropTypes.any.isRequired,
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",

          borderBottom: "1px solid #C8C8C8",
          borderColor: "#C8C8C8",
          mb: 1,
          p: "5px 0px 1px 0px",
        }}
      >
        <Box
          sx={{
            width: "31%",
            pr: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h4" display="block" align="left">
            Statistsic
          </Typography>
        </Box>
        <Box
          sx={{
            width: "23%",
            pl: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h4" display="block" align="right">
            Long
          </Typography>
        </Box>
        <Box
          sx={{
            width: "23%",
            pl: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h4" display="block" align="right">
            Short
          </Typography>
        </Box>
        <Box
          sx={{
            width: "23%",
            pl: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h4" display="block" align="right">
            All
          </Typography>
        </Box>
      </Box>

      <StatisticLine
        parameter="Won trades (abs)"
        unit=""
        digits={0}
        valueLong={inspectorData.statistic_long_abs_good}
        valueShort={inspectorData.statistic_short_abs_good}
        valueAll={inspectorData.statistic_all_abs_good}
        tooltipLeft="Absolute count of won trades for application/module."
      />

      <StatisticLine
        parameter="Loss trades (abs)"
        unit=""
        digits={0}
        valueLong={inspectorData.statistic_long_abs_bad}
        valueShort={inspectorData.statistic_short_abs_bad}
        valueAll={inspectorData.statistic_all_abs_bad}
        tooltipLeft="Absolute count of loss trades for application/module."
      />

      <StatisticLine
        parameter="Won trades (%)"
        unit="%"
        digits={2}
        valueLong={inspectorData.statistic_long_rel_good}
        valueShort={inspectorData.statistic_short_rel_good}
        valueAll={inspectorData.statistic_all_rel_good}
        tooltipLeft="Relative count of won trades for application/module."
      />

      <StatisticLine
        parameter="Loss trades (%)"
        unit="%"
        digits={2}
        valueLong={inspectorData.statistic_long_rel_bad}
        valueShort={inspectorData.statistic_short_rel_bad}
        valueAll={inspectorData.statistic_all_rel_bad}
        tooltipLeft="Relative count of loss trades for application/module."
      />

      <StatisticLine
        parameter="Total profit"
        unit={inspectorData.account_currency}
        digits={2}
        valueLong={inspectorData.statistic_long_profit}
        valueShort={inspectorData.statistic_short_profit}
        valueAll={inspectorData.statistic_all_profit}
        tooltipLeft="Total profit of this trading module"
      />

      <StatisticLine
        parameter="Average profit per closed trade"
        unit={inspectorData.account_currency}
        digits={2}
        valueLong={inspectorData.statistic_long_profit_per_trade}
        valueShort={inspectorData.statistic_short_profit_per_trade}
        valueAll={inspectorData.statistic_all_profit_per_trade}
        tooltipLeft="Average profit per closed trade. Currently, open trades are not included in this value!"
      />

      <StatisticLine
        parameter="Closed trades"
        unit=""
        digits={0}
        valueLong={inspectorData.statistic_long_number_of}
        valueShort={inspectorData.statistic_short_number_of}
        valueAll={inspectorData.statistic_all_number_of}
        tooltipLeft="Total closed trades by this application/module."
      />
    </Box>
  );
}

export function CalcRunTime(startTime, lastConnection) {
  CalcRunTime.propTypes = {
    startTime: PropTypes.any.isRequired,
    lastConnection: PropTypes.any.isRequired,
  };

  const date1 = dayjs(lastConnection);
  const date2 = dayjs(startTime);

  let years = date1.diff(date2, "year");
  let months = date1.diff(date2, "month") - years * 12;
  let days = date1.diff(date2.add(years, "year").add(months, "month"), "day");
  years = String(years).padStart(2, "0");
  months = String(months).padStart(2, "0");
  days = String(days).padStart(2, "0");

  const d1 = new Date(startTime);
  const d2 = new Date(lastConnection);
  const deltaSeconds = Math.abs(d2 - d1) / 1000;

  let seconds = deltaSeconds % 60;
  seconds = String(seconds).padStart(2, "0");

  let minutes = Math.floor(deltaSeconds / 60) % 60;
  minutes = String(minutes).padStart(2, "0");

  let hours = Math.floor(deltaSeconds / 3600) % 24;
  hours = String(hours).padStart(2, "0");

  const runtime = `${years}-${months}-${days} ${hours}:${minutes}:${seconds}`;

  return runtime;
}

export function ApplicationItem({
  content,
  simplified,
  managed,
  promoter,
  distributor,
}) {
  ApplicationItem.propTypes = {
    content: PropTypes.any.isRequired,
    simplified: PropTypes.any.isRequired,
    managed: PropTypes.any,
    promoter: PropTypes.any,
    distributor: PropTypes.any,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const inspectorOptions = [];
  content.forEach((Inspector, index) => {
    if (Inspector[1].module_name === "Inspector") {
      inspectorOptions.push({
        moduleIndex: index,
        moduleName: "All combined",
      });
    } else {
      inspectorOptions.push({
        moduleIndex: index,
        moduleName: Inspector[1].module_name,
      });
    }
  });

  const [selectedInspector, setSelectedInspector] = useState(0);

  let selectedInspectorVar = selectedInspector;

  if (
    selectedInspector >= inspectorOptions.length ||
    selectedInspectorVar >= inspectorOptions.length
  ) {
    setSelectedInspector(0);
    selectedInspectorVar = 0;
  }

  return (
    <Grid
      item
      xs={12}
      md={simplified === true ? 6 : 12}
      lg={simplified === true ? 4 : 12}
    >
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Stack>
          <Box
            sx={{ pb: "10px", px: 2, mb: 0, backgroundColor: "#F2F2F2" }}
            display="block"
            align="center"
          >
            <Typography
              variant="h5"
              display="block"
              align="center"
              sx={{ mt: "14px" }}
            >
              <Link
                variant="h5"
                underline="none"
                color="inherit"
                target="_blank"
                href={content[0][1].home_url}
              >
                {content[0][1].application_name}
              </Link>
            </Typography>

            <Typography variant="contentMiddle">
              {content[0][1].chart_symbol} - {content[0][1].chart_timeframe}
            </Typography>
          </Box>

          {content[0][0].split("|")[4] !== "" ? (
            <Box
              sx={{
                background: "white",
                pt: 2,
                px: 2,
              }}
            >
              {isDesktop ? (
                <Box>
                  <Typography variant="contentTiny">
                    Selected trading module
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={selectedInspector}
                      onChange={(event) => {
                        setSelectedInspector(event.target.value);

                        selectedInspectorVar = event.target.value;
                      }}
                      input={<ItemOutlinedInput />}
                    >
                      {inspectorOptions.map((currentInspector, index) => (
                        <ItemMenuItem key={index} value={index}>
                          {currentInspector.moduleName}
                        </ItemMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Box>
                  <Typography variant="contentTiny">
                    Selected trading module
                  </Typography>
                  <FormControl fullWidth>
                    <NativeSelect
                      value={selectedInspector}
                      onChange={(event) => {
                        setSelectedInspector(event.target.value);

                        selectedInspectorVar = event.target.value;
                      }}
                      input={<ItemOutlinedInput />}
                    >
                      {inspectorOptions.map((currentInspector, index) => (
                        <option key={index} value={index}>
                          {currentInspector.moduleName}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Box>
              )}
            </Box>
          ) : null}

          <Box sx={{ px: 2, pt: 2, pb: 1 }}>
            {simplified === true ? null : (
              <>
                {promoter !== undefined ? (
                  <HandlePromoterPortalEndSession
                    promoter={promoter}
                    sessionId={content[0][1].session_id}
                  />
                ) : null}

                {distributor !== undefined ? (
                  <HandleDistributorPortalEndSession
                    distributor={distributor}
                    sessionId={content[0][1].session_id}
                  />
                ) : null}
              </>
            )}

            <ValueLine
              parameter="Server"
              value={content[0][0].split("|")[1]}
              tooltipLeft="Your MetaTrader trading server."
              widthLeft="50%"
              widthRight="50%"
            />

            <ValueLine
              parameter="Account"
              value={content[0][0].split("|")[2]}
              tooltipLeft="Your MetaTrader account number."
              widthLeft="50%"
              widthRight="50%"
            />

            {content[0][1].traded_volume !== null ? (
              <ValueLine
                parameter="Equity"
                value={`${content[0][1].account_equity} ${content[0][1].account_currency}`}
                tooltipLeft="Latest known account equity."
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {simplified === true ||
            content[0][1].traded_volume === null ? null : (
              <>
                <ValueLine
                  parameter="Balance"
                  value={`${content[0][1].account_balance} ${content[0][1].account_currency}`}
                  tooltipLeft="Latest known account balance."
                  widthLeft="50%"
                  widthRight="50%"
                />

                <ValueLine
                  parameter="Margin"
                  value={`${content[0][1].account_margin} ${content[0][1].account_currency}`}
                  tooltipLeft="Latest known account margin."
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ValueLine
                  parameter="Free margin"
                  value={`${content[0][1].account_free_margin} ${content[0][1].account_currency}`}
                  tooltipLeft="Latest known free account margin."
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ValueLine
                  parameter="Account profit"
                  value={`${content[0][1].account_profit} ${content[0][1].account_currency}`}
                  tooltipLeft="Latest known account profit."
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ValueLine
                  parameter="Traded volume"
                  value={`${Number(
                    content[selectedInspectorVar][1].traded_volume
                  ).toFixed(2)} Lots`}
                  tooltipLeft="The number of lots this application has been traded since the start."
                  widthLeft="50%"
                  widthRight="50%"
                />
              </>
            )}

            {content[0][1].traded_volume !== null ? (
              <ValueLine
                parameter="Last update"
                value={`${content[selectedInspectorVar][1].last_connection} GMT`}
                widthLeft="50%"
                widthRight="50%"
              />
            ) : null}

            {content[selectedInspectorVar][1].start_time_all !== null ? (
              <>
                <ValueLine
                  parameter="Start time"
                  value={`${content[selectedInspectorVar][1].start_time_all} GMT`}
                  widthLeft="50%"
                  widthRight="50%"
                />
                <ValueLine
                  parameter="Run time"
                  value={CalcRunTime(
                    content[selectedInspectorVar][1].start_time_all,
                    content[selectedInspectorVar][1].last_connection
                  )}
                  widthLeft="50%"
                  widthRight="50%"
                />
              </>
            ) : null}

            {content[0][0].split("|")[4] !== "" ? (
              <>
                <ValueLine
                  parameter="Local folder"
                  value={content[0][0].split("|")[4]}
                  tooltipLeft="Input/output folder for trading module configurations and journal outputs."
                  widthLeft="50%"
                  widthRight="50%"
                />

                {content[selectedInspectorVar][1].start_time_module !== null ? (
                  <>
                    <ValueLine
                      parameter="Module start time"
                      value={`${content[selectedInspectorVar][1].start_time_module} GMT`}
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      parameter="Module run time"
                      value={CalcRunTime(
                        content[selectedInspectorVar][1].start_time_module,
                        content[selectedInspectorVar][1].last_connection
                      )}
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      parameter="Module description"
                      value={
                        content[selectedInspectorVar][1].module_description
                      }
                      widthLeft="50%"
                      widthRight="50%"
                    />
                  </>
                ) : null}
              </>
            ) : null}

            {simplified === false && managed === true ? (
              <>
                <ValueLine
                  parameter="Session ID"
                  value={content[selectedInspectorVar][1].session_id}
                  widthLeft="50%"
                  widthRight="50%"
                />
                {content[selectedInspectorVar][1].session_key !== null ? (
                  <ValueLine
                    parameter="Session Key"
                    value={content[selectedInspectorVar][1].session_key}
                    tooltipLeft="Never share this key!"
                    widthLeft="50%"
                    widthRight="50%"
                  />
                ) : null}
              </>
            ) : null}
          </Box>

          {content[selectedInspectorVar][1].start_time_all !== null ? (
            // eslint-disable-next-line
            <>
              {simplified === true ? (
                <>
                  <Box
                    sx={{
                      border: "2px solid #D6D6D6",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      px: 2,
                      pt: 2,
                      pb: 1,
                    }}
                  >
                    {Number(
                      content[selectedInspectorVar][1]
                        .performance_since_start_abs_max
                    ) !== 0 ? (
                      <ValueLine
                        parameter="Maximum performance"
                        value={`${(
                          Math.floor(
                            content[selectedInspectorVar][1]
                              .performance_since_start_abs_max * 100
                          ) / 100
                        ).toFixed(2)} ${content[0][1].account_currency}`}
                        tooltipLeft="Maximum allowed performance since the start of the application/module. The application/module will shut down if performance exceeds this limit."
                        widthLeft="50%"
                        widthRight="50%"
                      />
                    ) : (
                      <ValueLine
                        parameter="Maximum performance"
                        value="-"
                        tooltipLeft="Maximum allowed performance since the start of the application/module. The application/module will shut down if performance exceeds this limit."
                        widthLeft="50%"
                        widthRight="50%"
                      />
                    )}

                    {Number(
                      content[selectedInspectorVar][1]
                        .performance_since_start_abs_min
                    ) !== 0 ? (
                      <ValueLine
                        parameter="Minimum performance"
                        value={`${(
                          Math.floor(
                            content[selectedInspectorVar][1]
                              .performance_since_start_abs_min * 100
                          ) / 100
                        ).toFixed(2)} ${content[0][1].account_currency}`}
                        tooltipLeft="Minimum allowed performance since the start of the application/module. The application/module will shut down if performance exceeds this limit."
                        widthLeft="50%"
                        widthRight="50%"
                      />
                    ) : (
                      <ValueLine
                        parameter="Minimum performance"
                        value="-"
                        tooltipLeft="Minimum allowed performance since the start of the application/module. The application/module will shut down if performance exceeds this limit."
                        widthLeft="50%"
                        widthRight="50%"
                      />
                    )}

                    <ValueLine
                      // sumUp={Boolean(true)}
                      parameter="Current performance"
                      value={`${(
                        Math.floor(
                          content[selectedInspectorVar][1]
                            .performance_since_start_abs_current_all * 100
                        ) / 100
                      ).toFixed(2)} ${content[0][1].account_currency}`}
                      tooltipLeft="Current application/module performance. For each application/module, the performance is inspected individually and in real-time. Suppose the application/module reaches one of the defined minimum or maximum values. In that case, the inspector turns off the trading module."
                      widthLeft="50%"
                      widthRight="50%"
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "2px solid #D6D6D6",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      px: 2,
                      pt: 2,
                      pb: 1,
                    }}
                  >
                    {Number(
                      content[selectedInspectorVar][1]
                        .drawdown_since_start_rel_max
                    ) !== 0 ? (
                      <ValueLine
                        parameter="Maximum drawdown"
                        value={`${(
                          Math.floor(
                            content[selectedInspectorVar][1]
                              .drawdown_since_start_rel_max * 100
                          ) / 100
                        ).toFixed(2)} %`}
                        tooltipLeft="Maximum allowed drawdown since the start of the application/module. The application/module will shut down if the drawdown exceeds this limit."
                        widthLeft="50%"
                        widthRight="50%"
                      />
                    ) : (
                      <ValueLine
                        parameter="Maximum drawdown"
                        value="-"
                        tooltipLeft="Maximum allowed drawdown since the start of the application/module. The application/module will shut down if the drawdown exceeds this limit."
                        widthLeft="50%"
                        widthRight="50%"
                      />
                    )}

                    <ValueLine
                      parameter="Worst drawdown"
                      value={`${(
                        Math.floor(
                          content[selectedInspectorVar][1]
                            .drawdown_since_start_rel_worst * 100
                        ) / 100
                      ).toFixed(2)} %`}
                      tooltipLeft="The worst recorded drawdown since the application/module started. This value is the most significant indicator of the quality of the application/module."
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      // sumUp={Boolean(true)}
                      parameter="Current drawdown"
                      value={`${(
                        Math.floor(
                          content[selectedInspectorVar][1]
                            .drawdown_since_start_rel_current * 100
                        ) / 100
                      ).toFixed(2)} %`}
                      tooltipLeft="Current application/module drawdown. For each application/module, the drawdown is inspected individually and in real-time. Suppose the application/module reaches one of the defined maximum values. In that case, the inspector turns off the trading module."
                      widthLeft="50%"
                      widthRight="50%"
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "2px solid #D6D6D6",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      px: 2,
                      pt: 2,
                      pb: 1,
                    }}
                  >
                    <ValueLine
                      parameter="Closed trades"
                      value={
                        content[selectedInspectorVar][1].statistic_all_number_of
                      }
                      tooltipLeft="Total closed trades by this application/module."
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      parameter="Win trades"
                      value={`${(
                        Math.floor(
                          content[selectedInspectorVar][1]
                            .statistic_all_rel_good * 100
                        ) / 100
                      ).toFixed(2)} %`}
                      tooltipLeft="Percentage of closed trades with positive profit."
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      parameter="Loss trades"
                      value={`${(
                        Math.floor(
                          content[selectedInspectorVar][1]
                            .statistic_all_rel_bad * 100
                        ) / 100
                      ).toFixed(2)} %`}
                      tooltipLeft="Percentage of closed trades with negative profit."
                      widthLeft="50%"
                      widthRight="50%"
                    />

                    <ValueLine
                      // sumUp={Boolean(true)}
                      parameter="Average profit per closed trade"
                      value={`${(
                        Math.floor(
                          content[selectedInspectorVar][1]
                            .statistic_all_profit_per_trade * 100
                        ) / 100
                      ).toFixed(2)} ${content[0][1].account_currency}`}
                      widthLeft="65%"
                      widthRight="35%"
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      border: "2px solid #D6D6D6",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      px: 2,
                      pt: 2,
                      pb: 1,
                    }}
                  >
                    <InspectorPerformance
                      inspectorData={content[selectedInspectorVar][1]}
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "2px solid #D6D6D6",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      px: 2,
                      pt: 2,
                      pb: 1,
                    }}
                  >
                    <InspectorDrawdown
                      inspectorData={content[selectedInspectorVar][1]}
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "2px solid #D6D6D6",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      px: 2,
                      pt: 2,
                      pb: 1,
                    }}
                  >
                    <InspectorStatistic
                      inspectorData={content[selectedInspectorVar][1]}
                    />
                  </Box>
                </>
              )}
            </>
          ) : null}
        </Stack>
      </Box>
    </Grid>
  );
}
