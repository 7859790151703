// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";
import NewPlanItemTickData from "../../../components/NewPlanItemTickData";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalActionsLoadingButton,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalReviseMt5TickDataSubscription({
  distributor,
  dashboardId,
  distributionPlans,
  currentPlanId,
  currentPlanProp,
  categories,
  sponsored,
  prorataDiscount,
  reviseInformation,
  paymentMethod,
  currentEntirePlan,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalReviseMt5TickDataSubscription.propTypes = {
    distributor: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    distributionPlans: PropTypes.any.isRequired,
    currentPlanId: PropTypes.any.isRequired,
    currentPlanProp: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    sponsored: PropTypes.any.isRequired,
    prorataDiscount: PropTypes.any.isRequired,
    reviseInformation: PropTypes.any.isRequired,
    paymentMethod: PropTypes.any.isRequired,
    currentEntirePlan: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageTickDataDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let tempNewPlan = 0;
  const plans = [];

  let FullCurrentPlanStructure = null;
  let FullNewPlanStructure = null;

  distributionPlans.forEach((distrubutionPlan) => {
    plans.push({
      name: distrubutionPlan.plan_name,
      id: distrubutionPlan.plan_id,
    });

    if (distrubutionPlan.plan_id === currentPlanId) {
      tempNewPlan = currentPlanId;
      FullCurrentPlanStructure = distrubutionPlan;
    }
  });

  if (tempNewPlan === 0) {
    tempNewPlan = plans[0].id;
  }

  const [formNewPlan, setFormNewPlan] = useState(tempNewPlan);

  distributionPlans.forEach((distrubutionPlan) => {
    if (distrubutionPlan.plan_id === formNewPlan) {
      FullNewPlanStructure = distrubutionPlan;
    }
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({});

  // Determine if it's the same plan, and upgrade or downgrade
  let reviseAction = "unchanged";
  let isStopPossible = false;

  if (
    parseFloat(FullNewPlanStructure.plan_fixed_price_value) >
    parseFloat(FullCurrentPlanStructure.plan_fixed_price_value)
  ) {
    reviseAction = "upgrade";
  } else if (
    parseFloat(FullNewPlanStructure.plan_fixed_price_value) <
    parseFloat(FullCurrentPlanStructure.plan_fixed_price_value)
  ) {
    reviseAction = "downgrade";
  } else {
    FullCurrentPlanStructure.plan_fixed_price_value =
      currentEntirePlan.fee.replace(/[^0-9.-]+/g, "");
  }

  if (FullCurrentPlanStructure.plan_id === formNewPlan) {
    isStopPossible = true;
  }

  const formik = useFormik({
    initialValues: {},
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "mt5_tick_data_customers",
        operation: "revise_mt5_tick_data_subscription",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        permission: {
          selected_dashboard_id: dashboardId,
          new_plan_id: formNewPlan,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  let discountShown = "";
  if (paymentMethod.toLowerCase() === "paddle") {
    discountShown = `${(
      (parseFloat(prorataDiscount) /
        parseFloat(FullNewPlanStructure.plan_fixed_price_value)) *
      100
    ).toFixed(1)} % net price discount`;
  } else {
    discountShown = `${prorataDiscount} discount`;
  }

  return plans.length > 1 ? (
    <>
      <B3Tiny
        variant="outlined"
        onClick={handleClickOpen("paper")}
        fullWidth
        sx={{ mb: 1 }}
      >
        Change tick data plan
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Change tick data plan
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 1 }}>
                  {currentPlanProp.payment_method !== "sponsored" ? (
                    <Typography variant="contentMiddle">
                      Use this form to downgrade or upgrade your customer's
                      subscription plan. Your customer will receive an email
                      requesting their approval for the plan change.
                    </Typography>
                  ) : null}

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        New tick data plan
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formNewPlan}
                          onChange={(event) => {
                            setFormNewPlan(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          {plans.map((currentPlan, index) => (
                            <ModalContentMenuItem
                              key={index}
                              value={currentPlan.id}
                            >
                              {currentPlan.name}
                            </ModalContentMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        New tick data plan
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formNewPlan}
                          onChange={(event) => {
                            setFormNewPlan(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          {plans.map((currentPlan, index) => (
                            <option key={index} value={currentPlan.id}>
                              {currentPlan.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ pt: 1 }}>
                    <NewPlanItemTickData
                      planInformation={FullNewPlanStructure}
                      categories={categories}
                      sponsored={sponsored}
                      reviseAction={reviseAction}
                      isStopPossible={isStopPossible}
                    />
                  </Box>

                  {!sponsored ? (
                    <>
                      {reviseAction === "downgrade" ? (
                        <Typography variant="contentSmall">
                          <sup>*</sup> The plan change will take effect at the
                          start of the next billing period.
                        </Typography>
                      ) : null}

                      {reviseAction === "upgrade" &&
                      isStopPossible === false ? (
                        <Typography variant="contentSmall">
                          <sup>*</sup> {discountShown} discount on the first
                          payment due to an already paid cycle.
                        </Typography>
                      ) : null}
                    </>
                  ) : null}
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                {reviseInformation === "none" ? (
                  <ModalActionsLoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={
                      reviseAction === "unchanged"
                        ? Boolean(true)
                        : Boolean(false)
                    }
                  >
                    {currentPlanProp.payment_method !== "sponsored" ? (
                      <>
                        {reviseAction === "downgrade"
                          ? "Request customer's approval"
                          : null}
                        {reviseAction === "unchanged" ? "Current plan" : null}
                        {reviseAction === "upgrade"
                          ? "Request customer's approval"
                          : null}
                      </>
                    ) : (
                      <>
                        {reviseAction === "downgrade" ? "Downgrade" : null}
                        {reviseAction === "unchanged" ? "Current plan" : null}
                        {reviseAction === "upgrade" ? "Upgrade" : null}
                      </>
                    )}
                  </ModalActionsLoadingButton>
                ) : (
                  <ModalActionsLoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    {reviseAction === "downgrade" &&
                    reviseInformation.revise_plan_id !== formNewPlan
                      ? "Request customer's approval"
                      : null}
                    {reviseAction === "downgrade" &&
                    reviseInformation.revise_plan_id === formNewPlan
                      ? "Resend approval email to customer"
                      : null}

                    {isStopPossible === true
                      ? "Request customer's approval"
                      : null}

                    {reviseAction === "upgrade" &&
                    reviseInformation.revise_plan_id !== formNewPlan
                      ? "Request customer's approval"
                      : null}
                    {reviseAction === "upgrade" &&
                    reviseInformation.revise_plan_id === formNewPlan
                      ? "Resend approval email to customer"
                      : null}
                  </ModalActionsLoadingButton>
                )}
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  ) : null;
}
