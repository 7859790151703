// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import { enCA } from "date-fns/locale";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalDatePicker,
  ModalPromoterPortalDatePickerSlotProps,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandlePromoterPortalModifyPermissionLimitations({
  promoter,
  dashboardId,
  promoterLinkId,
  productId,
  content,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandlePromoterPortalModifyPermissionLimitations.propTypes = {
    promoter: PropTypes.any.isRequired,
    dashboardId: PropTypes.any.isRequired,
    promoterLinkId: PropTypes.any.isRequired,
    productId: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdatePromoterPortalManagePromotionClient: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  let formPermissionLiveLimitsInitValue = "product_default";
  let formPermissionDemoLimitsInitValue = "product_default";

  let formLiveMinApplicationEquityInitValue = "Product default value";
  let formLiveMaxApplicationEquityInitValue = "Product default value";
  let formLiveMinApplicationLeverageInitValue = "Product default value";
  let formLiveMaxApplicationLeverageInitValue = "Product default value";
  let formDemoMinApplicationEquityInitValue = "Product default value";
  let formDemoMaxApplicationEquityInitValue = "Product default value";
  let formDemoMinApplicationLeverageInitValue = "Product default value";
  let formDemoMaxApplicationLeverageInitValue = "Product default value";

  let initFormPermissionEndLive = null;
  let initFormPermissionEndDemo = null;

  content.accounts.forEach((account) => {
    if (account.given_account_type === "live") {
      initFormPermissionEndLive = new Date(
        `${content.accounts[0].end.slice(0, 10)}T23:59:59`
      );

      if (content.accounts[0].live_limits === null) {
        formPermissionLiveLimitsInitValue = "permission_default";
      } else {
        formPermissionLiveLimitsInitValue = content.accounts[0].live_limits;
      }

      if (content.accounts[0].permission_min_equity === "") {
        formLiveMinApplicationEquityInitValue = "Product default value";
      } else if (content.accounts[0].permission_min_equity === "-1") {
        formLiveMinApplicationEquityInitValue = "Disabled";
      } else {
        formLiveMinApplicationEquityInitValue =
          content.accounts[0].permission_min_equity;
      }

      if (content.accounts[0].permission_max_equity === "") {
        formLiveMaxApplicationEquityInitValue = "Product default value";
      } else if (content.accounts[0].permission_max_equity === "-1") {
        formLiveMaxApplicationEquityInitValue = "Disabled";
      } else {
        formLiveMaxApplicationEquityInitValue =
          content.accounts[0].permission_max_equity;
      }

      if (content.accounts[0].permission_min_leverage === "") {
        formLiveMinApplicationLeverageInitValue = "Product default value";
      } else if (content.accounts[0].permission_min_leverage === "-1") {
        formLiveMinApplicationLeverageInitValue = "Disabled";
      } else {
        formLiveMinApplicationLeverageInitValue =
          content.accounts[0].permission_min_leverage;
      }

      if (content.accounts[0].permission_max_leverage === "") {
        formLiveMaxApplicationLeverageInitValue = "Product default value";
      } else if (content.accounts[0].permission_max_leverage === "-1") {
        formLiveMaxApplicationLeverageInitValue = "Disabled";
      } else {
        formLiveMaxApplicationLeverageInitValue =
          content.accounts[0].permission_max_leverage;
      }
    } else if (account.given_account_type === "demo") {
      initFormPermissionEndDemo = new Date(
        `${content.accounts[0].end.slice(0, 10)}T23:59:59`
      );

      if (content.accounts[0].demo_limits === null) {
        formPermissionDemoLimitsInitValue = "permission_default";
      } else {
        formPermissionDemoLimitsInitValue = content.accounts[0].demo_limits;
      }

      if (content.accounts[0].permission_min_equity === "") {
        formDemoMinApplicationEquityInitValue = "Product default value";
      } else if (content.accounts[0].permission_min_equity === "-1") {
        formDemoMinApplicationEquityInitValue = "Disabled";
      } else {
        formDemoMinApplicationEquityInitValue =
          content.accounts[0].permission_min_equity;
      }

      if (content.accounts[0].permission_max_equity === "") {
        formDemoMaxApplicationEquityInitValue = "Product default value";
      } else if (content.accounts[0].permission_max_equity === "-1") {
        formDemoMaxApplicationEquityInitValue = "Disabled";
      } else {
        formDemoMaxApplicationEquityInitValue =
          content.accounts[0].permission_max_equity;
      }

      if (content.accounts[0].permission_min_leverage === "") {
        formDemoMinApplicationLeverageInitValue = "Product default value";
      } else if (content.accounts[0].permission_min_leverage === "-1") {
        formDemoMinApplicationLeverageInitValue = "Disabled";
      } else {
        formDemoMinApplicationLeverageInitValue =
          content.accounts[0].permission_min_leverage;
      }

      if (content.accounts[0].permission_max_leverage === "") {
        formDemoMaxApplicationLeverageInitValue = "Product default value";
      } else if (content.accounts[0].permission_max_leverage === "-1") {
        formDemoMaxApplicationLeverageInitValue = "Disabled";
      } else {
        formDemoMaxApplicationLeverageInitValue =
          content.accounts[0].permission_max_leverage;
      }
    }

    if (
      initFormPermissionEndLive === null &&
      initFormPermissionEndDemo !== null
    ) {
      initFormPermissionEndLive = initFormPermissionEndDemo;

      formPermissionLiveLimitsInitValue = formPermissionDemoLimitsInitValue;
      formLiveMinApplicationEquityInitValue =
        formDemoMinApplicationEquityInitValue;
      formLiveMaxApplicationEquityInitValue =
        formDemoMaxApplicationEquityInitValue;
      formLiveMinApplicationLeverageInitValue =
        formDemoMinApplicationLeverageInitValue;
      formLiveMaxApplicationLeverageInitValue =
        formDemoMaxApplicationLeverageInitValue;
    } else if (
      initFormPermissionEndLive !== null &&
      initFormPermissionEndDemo === null
    ) {
      initFormPermissionEndDemo = initFormPermissionEndLive;
      formPermissionDemoLimitsInitValue = formPermissionLiveLimitsInitValue;
      formDemoMinApplicationEquityInitValue =
        formLiveMinApplicationEquityInitValue;
      formDemoMaxApplicationEquityInitValue =
        formLiveMaxApplicationEquityInitValue;
      formDemoMinApplicationLeverageInitValue =
        formLiveMinApplicationLeverageInitValue;
      formDemoMaxApplicationLeverageInitValue =
        formLiveMaxApplicationLeverageInitValue;
    }
  });

  const [formPermissionEndLive, setFormPermissionEndLive] = useState(
    initFormPermissionEndLive
  );
  const [formPermissionEndDemo, setFormPermissionEndDemo] = useState(
    initFormPermissionEndDemo
  );

  const [formPermissionLiveLimits, setFormPermissionLiveLimits] = useState(
    formPermissionLiveLimitsInitValue
  );
  const [formPermissionDemoLimits, setFormPermissionDemoLimits] = useState(
    formPermissionDemoLimitsInitValue
  );

  const [formLiveMinApplicationEquity, setFormLiveMinApplicationEquity] =
    useState(formLiveMinApplicationEquityInitValue);
  const [formLiveMaxApplicationEquity, setFormLiveMaxApplicationEquity] =
    useState(formLiveMaxApplicationEquityInitValue);
  const [formLiveMinApplicationLeverage, setFormLiveMinApplicationLeverage] =
    useState(formLiveMinApplicationLeverageInitValue);
  const [formLiveMaxApplicationLeverage, setFormLiveMaxApplicationLeverage] =
    useState(formLiveMaxApplicationLeverageInitValue);
  const [formDemoMinApplicationEquity, setDemoLiveMinApplicationEquity] =
    useState(formDemoMinApplicationEquityInitValue);
  const [formDemoMaxApplicationEquity, setDemoLiveMaxApplicationEquity] =
    useState(formDemoMaxApplicationEquityInitValue);
  const [formDemoMinApplicationLeverage, setDemoLiveMinApplicationLeverage] =
    useState(formDemoMinApplicationLeverageInitValue);
  const [formDemoMaxApplicationLeverage, setDemoLiveMaxApplicationLeverage] =
    useState(formDemoMaxApplicationLeverageInitValue);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const limitationRegExp = /^[0-9]|Product default value|Disabled+$/;

  const myValidationScheme = Yup.object().shape({
    permission_live_min_application_equity: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_live_max_application_equity: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_live_min_application_leverage: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_live_max_application_leverage: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_demo_min_application_equity: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_demo_max_application_equity: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_demo_min_application_leverage: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_demo_max_application_leverage: Yup.string()
      .required()
      .matches(limitationRegExp),
  });

  const formik = useFormik({
    initialValues: {
      permission_live_min_application_equity:
        formLiveMinApplicationEquityInitValue,
      permission_live_max_application_equity:
        formLiveMaxApplicationEquityInitValue,
      permission_live_min_application_leverage:
        formLiveMinApplicationLeverageInitValue,
      permission_live_max_application_leverage:
        formLiveMaxApplicationLeverageInitValue,
      permission_demo_min_application_equity:
        formDemoMinApplicationEquityInitValue,
      permission_demo_max_application_equity:
        formDemoMaxApplicationEquityInitValue,
      permission_demo_min_application_leverage:
        formDemoMinApplicationLeverageInitValue,
      permission_demo_max_application_leverage:
        formDemoMaxApplicationLeverageInitValue,
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      let LiveMinApplicationEquityConverted =
        values.permission_live_min_application_equity;
      if (LiveMinApplicationEquityConverted === "Product default value") {
        LiveMinApplicationEquityConverted = "product_default";
      } else if (LiveMinApplicationEquityConverted === "Disabled") {
        LiveMinApplicationEquityConverted = "disabled";
      }

      let LiveMaxApplicationEquityConverted =
        values.permission_live_max_application_equity;
      if (LiveMaxApplicationEquityConverted === "Product default value") {
        LiveMaxApplicationEquityConverted = "product_default";
      } else if (LiveMaxApplicationEquityConverted === "Disabled") {
        LiveMaxApplicationEquityConverted = "disabled";
      }

      let LiveMinApplicationLeverageConverted =
        values.permission_live_min_application_leverage;
      if (LiveMinApplicationLeverageConverted === "Product default value") {
        LiveMinApplicationLeverageConverted = "product_default";
      } else if (LiveMinApplicationLeverageConverted === "Disabled") {
        LiveMinApplicationLeverageConverted = "disabled";
      }

      let LiveMaxApplicationLeverageConverted =
        values.permission_live_max_application_leverage;
      if (LiveMaxApplicationLeverageConverted === "Product default value") {
        LiveMaxApplicationLeverageConverted = "product_default";
      } else if (LiveMaxApplicationLeverageConverted === "Disabled") {
        LiveMaxApplicationLeverageConverted = "disabled";
      }

      let DemoMinApplicationEquityConverted =
        values.permission_demo_min_application_equity;
      if (DemoMinApplicationEquityConverted === "Product default value") {
        DemoMinApplicationEquityConverted = "product_default";
      } else if (DemoMinApplicationEquityConverted === "Disabled") {
        DemoMinApplicationEquityConverted = "disabled";
      }

      let DemoMaxApplicationEquityConverted =
        values.permission_demo_max_application_equity;
      if (DemoMaxApplicationEquityConverted === "Product default value") {
        DemoMaxApplicationEquityConverted = "product_default";
      } else if (DemoMaxApplicationEquityConverted === "Disabled") {
        DemoMaxApplicationEquityConverted = "disabled";
      }

      let DemoMinApplicationLeverageConverted =
        values.permission_demo_min_application_leverage;
      if (DemoMinApplicationLeverageConverted === "Product default value") {
        DemoMinApplicationLeverageConverted = "product_default";
      } else if (DemoMinApplicationLeverageConverted === "Disabled") {
        DemoMinApplicationLeverageConverted = "disabled";
      }

      let DemoMaxApplicationLeverageConverted =
        values.permission_demo_max_application_leverage;
      if (DemoMaxApplicationLeverageConverted === "Product default value") {
        DemoMaxApplicationLeverageConverted = "product_default";
      } else if (DemoMaxApplicationLeverageConverted === "Disabled") {
        DemoMaxApplicationLeverageConverted = "disabled";
      }

      let permissionEndLiveConverted = "-";
      if (
        formPermissionEndLive !== "" &&
        formPermissionEndLive !== null &&
        formPermissionEndLive !== undefined
      ) {
        permissionEndLiveConverted = `${formPermissionEndLive.toLocaleDateString(
          "en-CA"
        )} 23:59:59`;
      }

      let permissionEndDemoConverted = "-";
      if (
        formPermissionEndDemo !== "" &&
        formPermissionEndDemo !== null &&
        formPermissionEndDemo !== undefined
      ) {
        permissionEndDemoConverted = `${formPermissionEndDemo.toLocaleDateString(
          "en-CA"
        )} 23:59:59`;
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "promoter_portal",
        widget: "client_and_account_management",
        operation: "modify_permission_limitations",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_promoter_id: promoter,
        },

        dashboard: {
          selected_dashboard_id: dashboardId,
          selected_promoter_link_id: promoterLinkId,
          selected_product_id: productId,
        },

        limits: {
          permission_end_live: permissionEndLiveConverted,
          permission_live_limits: formPermissionLiveLimits,
          permission_live_min_application_equity:
            LiveMinApplicationEquityConverted,
          permission_live_max_application_equity:
            LiveMaxApplicationEquityConverted,
          permission_live_min_application_leverage:
            LiveMinApplicationLeverageConverted,
          permission_live_max_application_leverage:
            LiveMaxApplicationLeverageConverted,
          permission_end_demo: permissionEndDemoConverted,
          permission_demo_limits: formPermissionDemoLimits,
          permission_demo_min_application_equity:
            DemoMinApplicationEquityConverted,
          permission_demo_max_application_equity:
            DemoMaxApplicationEquityConverted,
          permission_demo_min_application_leverage:
            DemoMinApplicationLeverageConverted,
          permission_demo_max_application_leverage:
            DemoMaxApplicationLeverageConverted,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);

    formik.values.permission_live_min_application_equity =
      formLiveMinApplicationEquityInitValue;
    formik.values.permission_live_max_application_equity =
      formLiveMaxApplicationEquityInitValue;
    formik.values.permission_live_min_application_leverage =
      formLiveMinApplicationLeverageInitValue;
    formik.values.permission_live_max_application_leverage =
      formLiveMaxApplicationLeverageInitValue;
    formik.values.permission_demo_min_application_equity =
      formDemoMinApplicationEquityInitValue;
    formik.values.permission_demo_max_application_equity =
      formDemoMaxApplicationEquityInitValue;
    formik.values.permission_demo_min_application_leverage =
      formDemoMinApplicationLeverageInitValue;
    formik.values.permission_demo_max_application_leverage =
      formDemoMaxApplicationLeverageInitValue;

    setFormLiveMinApplicationEquity(formLiveMinApplicationEquityInitValue);
    setFormLiveMaxApplicationEquity(formLiveMaxApplicationEquityInitValue);
    setFormLiveMinApplicationLeverage(formLiveMinApplicationLeverageInitValue);
    setFormLiveMaxApplicationLeverage(formLiveMaxApplicationLeverageInitValue);
    setDemoLiveMinApplicationEquity(formDemoMinApplicationEquityInitValue);
    setDemoLiveMaxApplicationEquity(formDemoMaxApplicationEquityInitValue);
    setDemoLiveMinApplicationLeverage(formDemoMinApplicationLeverageInitValue);
    setDemoLiveMaxApplicationLeverage(formDemoMaxApplicationLeverageInitValue);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const [errorLiveMinApplicationEquity, setErrorLiveMinApplicationEquity] =
    useState(false);
  const [errorLiveMaxApplicationEquity, setErrorLiveMaxApplicationEquity] =
    useState(false);
  const [errorLiveMinApplicationLeverage, setErrorLiveMinApplicationLeverage] =
    useState(false);
  const [errorLiveMaxApplicationLeverage, setErrorLiveMaxApplicationLeverage] =
    useState(false);
  const [errorDemoMinApplicationEquity, setErrorDemoMinApplicationEquity] =
    useState(false);
  const [errorDemoMaxApplicationEquity, setErrorDemoMaxApplicationEquity] =
    useState(false);
  const [errorDemoMinApplicationLeverage, setErrorDemoMinApplicationLeverage] =
    useState(false);
  const [errorDemoMaxApplicationLeverage, setErrorDemoMaxApplicationLeverage] =
    useState(false);

  return (
    <>
      <B3Tiny
        fullWidth
        variant="outlined"
        sx={{ mb: 1 }}
        onClick={handleClickOpen("paper")}
      >
        Modify product limitations
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Modify product limitations
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="contentMiddle">
                      Use this dialog to modify your client's product
                      limitations. You can either set a specific number, use the
                      'Product default value,' or set it to 'Disabled.'
                      <br /> <br />A modified value does not overwrite the
                      product standard value; it just sets a new value with
                      higher priority. Use the option 'Product default value' to
                      restore the product default value.'
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="contentMiddleBold">
                      Live accounts
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="contentSmall">
                      Permission end date for all live accounts
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enCA}
                    >
                      <ModalDatePicker
                        disablePast
                        value={formPermissionEndLive}
                        onChange={(newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const value = new Date(
                              `${newValue.toLocaleDateString("en-CA")}T00:00:00`
                            );
                            setFormPermissionEndLive(value);
                          } else {
                            setFormPermissionEndLive(initFormPermissionEndLive);
                          }
                        }}
                        slotProps={ModalPromoterPortalDatePickerSlotProps}
                      />
                    </LocalizationProvider>
                  </Box>

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Selectable stop loss and take profit limit for all live
                        accounts
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formPermissionLiveLimits}
                          onChange={(event) => {
                            setFormPermissionLiveLimits(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="product_default">
                            Product default value
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="server">
                            Remote only
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="local">
                            Local only
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="local_server">
                            Remote and local
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Selectable stop loss and take profit limit for live all
                        accounts
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formPermissionLiveLimits}
                          onChange={(event) => {
                            setFormPermissionLiveLimits(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="product_default">
                            Product default value
                          </option>
                          <option value="server">Remote only</option>
                          <option value="local">Local only</option>
                          <option value="local_server">Remote and local</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_live_min_application_equity &&
                              errors.permission_live_min_application_equity
                          ) === true || errorLiveMinApplicationEquity === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_live_min_application_equity &&
                          errors.permission_live_min_application_equity
                      ) === true || errorLiveMinApplicationEquity === true
                        ? "Invalid min. equity for all live accounts"
                        : "Min. equity for all live accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formLiveMinApplicationEquity}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorLiveMinApplicationEquity(false);
                          values.permission_live_min_application_equity =
                            "Product default value";
                          setFormLiveMinApplicationEquity(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorLiveMinApplicationEquity(true);
                            } else {
                              setErrorLiveMinApplicationEquity(false);
                              values.permission_live_min_application_equity =
                                value;
                              setFormLiveMinApplicationEquity(value);
                            }
                          } else {
                            setErrorLiveMinApplicationEquity(true);
                          }
                        } else {
                          setErrorLiveMinApplicationEquity(false);
                          values.permission_live_min_application_equity = value;
                          setFormLiveMinApplicationEquity(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_live_min_application_equity"
                          )}
                          error={Boolean(
                            touched.permission_live_min_application_equity &&
                              errors.permission_live_min_application_equity
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_live_max_application_equity &&
                              errors.permission_live_max_application_equity
                          ) === true || errorLiveMaxApplicationEquity === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_live_max_application_equity &&
                          errors.permission_live_max_application_equity
                      ) === true || errorLiveMaxApplicationEquity === true
                        ? "Invalid max. equity for all live accounts"
                        : "Max. equity for all live accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formLiveMaxApplicationEquity}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorLiveMaxApplicationEquity(false);
                          values.permission_live_max_application_equity =
                            "Product default value";
                          setFormLiveMaxApplicationEquity(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorLiveMaxApplicationEquity(true);
                            } else {
                              setErrorLiveMaxApplicationEquity(false);
                              values.permission_live_max_application_equity =
                                value;
                              setFormLiveMaxApplicationEquity(value);
                            }
                          } else {
                            setErrorLiveMaxApplicationEquity(true);
                          }
                        } else {
                          setErrorLiveMaxApplicationEquity(false);
                          values.permission_live_max_application_equity = value;
                          setFormLiveMaxApplicationEquity(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_live_max_application_equity"
                          )}
                          error={Boolean(
                            touched.permission_live_max_application_equity &&
                              errors.permission_live_max_application_equity
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_live_min_application_leverage &&
                              errors.permission_live_min_application_leverage
                          ) === true || errorLiveMinApplicationLeverage === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_live_min_application_leverage &&
                          errors.permission_live_min_application_leverage
                      ) === true || errorLiveMinApplicationLeverage === true
                        ? "Invalid min. leverage for all live accounts"
                        : "Min. leverage for all live accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formLiveMinApplicationLeverage}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorLiveMinApplicationLeverage(false);
                          values.permission_live_min_application_leverage =
                            "Product default value";
                          setFormLiveMinApplicationLeverage(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorLiveMinApplicationLeverage(true);
                            } else {
                              setErrorLiveMinApplicationLeverage(false);
                              values.permission_live_min_application_leverage =
                                value;
                              setFormLiveMinApplicationLeverage(value);
                            }
                          } else {
                            setErrorLiveMinApplicationLeverage(true);
                          }
                        } else {
                          setErrorLiveMinApplicationLeverage(false);
                          values.permission_live_min_application_leverage =
                            value;
                          setFormLiveMinApplicationLeverage(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_live_min_application_leverage"
                          )}
                          error={Boolean(
                            touched.permission_live_min_application_leverage &&
                              errors.permission_live_min_application_leverage
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_live_max_application_leverage &&
                              errors.permission_live_max_application_leverage
                          ) === true || errorLiveMaxApplicationLeverage === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_live_max_application_leverage &&
                          errors.permission_live_max_application_leverage
                      ) === true || errorLiveMaxApplicationLeverage === true
                        ? "Invalid max. leverage for all live accounts"
                        : "Max. leverage for all live accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formLiveMaxApplicationLeverage}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorLiveMaxApplicationLeverage(false);
                          values.permission_live_max_application_leverage =
                            "Product default value";
                          setFormLiveMaxApplicationLeverage(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorLiveMaxApplicationLeverage(true);
                            } else {
                              setErrorLiveMaxApplicationLeverage(false);
                              values.permission_live_max_application_leverage =
                                value;
                              setFormLiveMaxApplicationLeverage(value);
                            }
                          } else {
                            setErrorLiveMaxApplicationLeverage(true);
                          }
                        } else {
                          setErrorLiveMaxApplicationLeverage(false);
                          values.permission_live_max_application_leverage =
                            value;
                          setFormLiveMaxApplicationLeverage(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_live_max_application_leverage"
                          )}
                          error={Boolean(
                            touched.permission_live_max_application_leverage &&
                              errors.permission_live_max_application_leverage
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box sx={{ pt: 3 }}>
                    <Typography variant="contentMiddleBold">
                      Demo accounts
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="contentSmall">
                      Permission end date for all demo accounts
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enCA}
                    >
                      <ModalDatePicker
                        disablePast
                        value={formPermissionEndDemo}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            const value = new Date(
                              `${newValue.toLocaleDateString("en-CA")}T00:00:00`
                            );
                            setFormPermissionEndDemo(value);
                          } else {
                            setFormPermissionEndDemo(initFormPermissionEndDemo);
                          }
                        }}
                        slotProps={ModalPromoterPortalDatePickerSlotProps}
                      />
                    </LocalizationProvider>
                  </Box>

                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Selectable stop loss and take profit limit for all demo
                        accounts
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={formPermissionDemoLimits}
                          onChange={(event) => {
                            setFormPermissionDemoLimits(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <ModalContentMenuItem value="product_default">
                            Product default value
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="server">
                            Remote only
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="local">
                            Local only
                          </ModalContentMenuItem>
                          <ModalContentMenuItem value="local_server">
                            Remote and local
                          </ModalContentMenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Selectable stop loss and take profit limit for demo all
                        accounts
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={formPermissionDemoLimits}
                          onChange={(event) => {
                            setFormPermissionDemoLimits(event.target.value);
                          }}
                          input={<ModalContentOutlinedInput />}
                        >
                          <option value="product_default">
                            Product default value
                          </option>
                          <option value="server">Remote only</option>
                          <option value="local">Local only</option>
                          <option value="local_server">Remote and local</option>
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_demo_min_application_equity &&
                              errors.permission_demo_min_application_equity
                          ) === true || errorDemoMinApplicationEquity === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_demo_min_application_equity &&
                          errors.permission_demo_min_application_equity
                      ) === true || errorDemoMinApplicationEquity === true
                        ? "Invalid min. equity for all demo accounts"
                        : "Min. equity for all demo accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formDemoMinApplicationEquity}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorDemoMinApplicationEquity(false);
                          values.permission_demo_min_application_equity =
                            "Product default value";
                          setDemoLiveMinApplicationEquity(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorDemoMinApplicationEquity(true);
                            } else {
                              setErrorDemoMinApplicationEquity(false);
                              values.permission_demo_min_application_equity =
                                value;
                              setDemoLiveMinApplicationEquity(value);
                            }
                          } else {
                            setErrorDemoMinApplicationEquity(true);
                          }
                        } else {
                          setErrorDemoMinApplicationEquity(false);
                          values.permission_demo_min_application_equity = value;
                          setDemoLiveMinApplicationEquity(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_demo_min_application_equity"
                          )}
                          error={Boolean(
                            touched.permission_demo_min_application_equity &&
                              errors.permission_demo_min_application_equity
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_demo_max_application_equity &&
                              errors.permission_demo_max_application_equity
                          ) === true || errorDemoMaxApplicationEquity === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_demo_max_application_equity &&
                          errors.permission_demo_max_application_equity
                      ) === true || errorDemoMaxApplicationEquity === true
                        ? "Invalid max. equity for all demo accounts"
                        : "Max. equity for all demo accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formDemoMaxApplicationEquity}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorDemoMaxApplicationEquity(false);
                          values.permission_demo_max_application_equity =
                            "Product default value";
                          setDemoLiveMaxApplicationEquity(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorDemoMaxApplicationEquity(true);
                            } else {
                              setErrorDemoMaxApplicationEquity(false);
                              values.permission_demo_max_application_equity =
                                value;
                              setDemoLiveMaxApplicationEquity(value);
                            }
                          } else {
                            setErrorDemoMaxApplicationEquity(true);
                          }
                        } else {
                          setErrorDemoMaxApplicationEquity(false);
                          values.permission_demo_max_application_equity = value;
                          setDemoLiveMaxApplicationEquity(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_demo_max_application_equity"
                          )}
                          error={Boolean(
                            touched.permission_demo_max_application_equity &&
                              errors.permission_demo_max_application_equity
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_demo_min_application_leverage &&
                              errors.permission_demo_min_application_leverage
                          ) === true || errorDemoMinApplicationLeverage === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_demo_min_application_leverage &&
                          errors.permission_demo_min_application_leverage
                      ) === true || errorDemoMinApplicationLeverage === true
                        ? "Invalid min. leverage for all demo accounts"
                        : "Min. leverage for all demo accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formDemoMinApplicationLeverage}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorDemoMinApplicationLeverage(false);
                          values.permission_demo_min_application_leverage =
                            "Product default value";
                          setDemoLiveMinApplicationLeverage(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorDemoMinApplicationLeverage(true);
                            } else {
                              setErrorDemoMinApplicationLeverage(false);
                              values.permission_demo_min_application_leverage =
                                value;
                              setDemoLiveMinApplicationLeverage(value);
                            }
                          } else {
                            setErrorDemoMinApplicationLeverage(true);
                          }
                        } else {
                          setErrorDemoMinApplicationLeverage(false);
                          values.permission_demo_min_application_leverage =
                            value;
                          setDemoLiveMinApplicationLeverage(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_demo_min_application_leverage"
                          )}
                          error={Boolean(
                            touched.permission_demo_min_application_leverage &&
                              errors.permission_demo_min_application_leverage
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_demo_max_application_leverage &&
                              errors.permission_demo_max_application_leverage
                          ) === true || errorDemoMaxApplicationLeverage === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_demo_max_application_leverage &&
                          errors.permission_demo_max_application_leverage
                      ) === true || errorDemoMaxApplicationLeverage === true
                        ? "Invalid max. leverage for all demo accounts"
                        : "Max. leverage for all demo accounts"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formDemoMaxApplicationLeverage}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorDemoMaxApplicationLeverage(false);
                          values.permission_demo_max_application_leverage =
                            "Product default value";
                          setDemoLiveMaxApplicationLeverage(
                            "Product default value"
                          );
                        } else if (
                          value !== "Product default value" &&
                          value !== "Disabled"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorDemoMaxApplicationLeverage(true);
                            } else {
                              setErrorDemoMaxApplicationLeverage(false);
                              values.permission_demo_max_application_leverage =
                                value;
                              setDemoLiveMaxApplicationLeverage(value);
                            }
                          } else {
                            setErrorDemoMaxApplicationLeverage(true);
                          }
                        } else {
                          setErrorDemoMaxApplicationLeverage(false);
                          values.permission_demo_max_application_leverage =
                            value;
                          setDemoLiveMaxApplicationLeverage(value);
                        }
                      }}
                      options={["Product default value", "Disabled"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_demo_max_application_leverage"
                          )}
                          error={Boolean(
                            touched.permission_demo_max_application_leverage &&
                              errors.permission_demo_max_application_leverage
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Modify
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
