// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import { Box, Typography, Stack, Link } from "@mui/material";

// components
import Iconify from "./Iconify";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function PlanItemFeature({ feature }) {
  PlanItemFeature.propTypes = {
    feature: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <Iconify
        color="#61CE70"
        icon="eva:checkmark-outline"
        minWidth={25}
        minHeight={25}
        sx={{ mr: 1, mt: "1px" }}
      />
      {feature}
    </Box>
  );
}

function NewPlanItemApplications({
  planInformation,
  referenceTradingCurrency,
  sponsored,
  reviseAction,
  isStopPossible,
}) {
  NewPlanItemApplications.propTypes = {
    planInformation: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
    sponsored: PropTypes.any.isRequired,
    reviseAction: PropTypes.any.isRequired,
    isStopPossible: PropTypes.any.isRequired,
  };

  const priceBeforeDot = planInformation.plan_fixed_price_value.substring(
    0,
    planInformation.plan_fixed_price_value.length - 3
  );
  const priceAfterDot = planInformation.plan_fixed_price_value.substring(
    planInformation.plan_fixed_price_value.length - 2,
    planInformation.plan_fixed_price_value.length - 0
  );

  let paymentTenure = "";
  if (planInformation.plan_interval_unit === "DAY") {
    paymentTenure = "Daily";
  } else if (planInformation.plan_interval_unit === "WEEK") {
    paymentTenure = "Weekly";
  } else if (planInformation.plan_interval_unit === "MONTH") {
    paymentTenure = "Monthly";
  } else if (planInformation.plan_interval_unit === "YEAR") {
    paymentTenure = "Yearly";
  }

  let paymentCurrency = planInformation.plan_fixed_price_currency_code;
  if (planInformation.plan_fixed_price_currency_code === "EUR") {
    paymentCurrency = "€";
  }

  let tradingCurrency = referenceTradingCurrency;
  if (referenceTradingCurrency === "EUR") {
    tradingCurrency = "€";
  }

  let liveLimitsValue = planInformation.plan_fixed_price_currency_code;
  if (planInformation.distribution_live_limits === "server") {
    liveLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Remote `}</Typography>
        SL and TP with all live accounts usable
      </Typography>
    );
  } else if (planInformation.distribution_live_limits === "local") {
    liveLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Local `}</Typography>
        SL and TP with all live accounts usable
      </Typography>
    );
  } else if (planInformation.distribution_live_limits === "local_server") {
    liveLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`Local and remote `}
        </Typography>
        SL and TP with all live accounts usable
      </Typography>
    );
  }

  let liveIncludedAccounts = "";
  if (planInformation.distribution_live_included_accounts !== "-1") {
    if (planInformation.distribution_live_included_accounts === "1") {
      liveIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${planInformation.distribution_live_included_accounts} live `}
          </Typography>
          trading account included {" "}
          {reviseAction === "downgrade" && sponsored === false ? <sup>2</sup> : null}
        </Typography>
      );
    } else {
      liveIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${planInformation.distribution_live_included_accounts} live `}
          </Typography>
          trading accounts included {" "}
          {reviseAction === "downgrade" && sponsored === false  ? <sup>2</sup> : null}
        </Typography>
      );
    }
  } else {
    liveIncludedAccounts = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Unlimited `}</Typography>
        number of live trading accounts included
      </Typography>
    );
  }

  let liveIncludedAccountChanges = "";
  if (planInformation.distribution_demo_included_account_changes !== "-1") {
    liveIncludedAccountChanges = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`${planInformation.distribution_demo_included_account_changes} `}
        </Typography>
        included live trading account changes
      </Typography>
    );
  }

  let demoLimitsValue = planInformation.plan_fixed_price_currency_code;
  if (planInformation.distribution_demo_limits === "server") {
    demoLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Remote `}</Typography>
        SL and TP with all demo accounts usable
      </Typography>
    );
  } else if (planInformation.distribution_demo_limits === "local") {
    demoLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Local `}</Typography>
        SL and TP with all demo accounts usable
      </Typography>
    );
  } else if (planInformation.distribution_demo_limits === "local_server") {
    demoLimitsValue = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`Local and remote `}
        </Typography>
        SL and TP with all demo accounts usable
      </Typography>
    );
  }

  let demoIncludedAccounts = "";
  if (planInformation.distribution_demo_included_accounts !== "-1") {
    if (planInformation.distribution_demo_included_accounts === "1") {
      demoIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${planInformation.distribution_demo_included_accounts} demo `}
          </Typography>
          trading account included {" "}
          {reviseAction === "downgrade" && sponsored === false  ? <sup>2</sup> : null}
        </Typography>
      );
    } else {
      demoIncludedAccounts = (
        <Typography variant="contentMiddle">
          <Typography variant="contentMiddleBold">
            {`${planInformation.distribution_demo_included_accounts} demo `}
          </Typography>
          trading accounts included {" "}
          {reviseAction === "downgrade" && sponsored === false  ? <sup>2</sup> : null}
        </Typography>
      );
    }
  } else {
    demoIncludedAccounts = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">{`Unlimited `}</Typography>
        number of demo trading accounts included
      </Typography>
    );
  }

  let demoIncludedAccountChanges = "";
  if (planInformation.distribution_demo_included_account_changes !== "-1") {
    demoIncludedAccountChanges = (
      <Typography variant="contentMiddle">
        <Typography variant="contentMiddleBold">
          {`${planInformation.distribution_demo_included_account_changes} `}
        </Typography>
        included demo trading account changes
      </Typography>
    );
  }

  const minApplicationEquity = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Min. ${numberWithCommas(
          Number(planInformation.distribution_min_application_equity)
        )} ${tradingCurrency} equity`}{" "}
        {reviseAction === "downgrade" && sponsored === false  ? <sup>1</sup> : null}
        {reviseAction === "upgrade" && sponsored === false  ? <sup>2</sup> : null}
        {" "}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  const maxApplicationEquity = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Max. ${numberWithCommas(
          Number(planInformation.distribution_max_application_equity)
        )} ${tradingCurrency} equity`}{" "}
        {reviseAction === "downgrade" && sponsored === false  ? <sup>1</sup> : null}
        {reviseAction === "upgrade" && sponsored === false  ? <sup>2</sup> : null}
        {" "}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  const minApplicationLeverage = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Min. ${numberWithCommas(
          Number(planInformation.distribution_min_application_leverage)
        )} leverage `}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  const maxApplicationLeverage = (
    <Typography variant="contentMiddle">
      <Typography variant="contentMiddleBold">
        {`Max. ${numberWithCommas(
          Number(planInformation.distribution_max_application_leverage)
        )} leverage `}
      </Typography>
      per live and demo trading account
    </Typography>
  );

  return (
    <Stack>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
        }}
      >
        <Box
          sx={{ pb: "0px", px: 2, mb: 1, backgroundColor: "#F2F2F2" }}
          display="block"
          align="center"
        >
          <Typography
            variant="h5"
            display="block"
            align="center"
            sx={{ mt: "14px" }}
          >
            <Link
              variant="h5"
              underline="none"
              color="inherit"
              target="_blank"
              href={planInformation.home_url}
            >
              {planInformation.product_name}
            </Link>
          </Typography>

          <Typography variant="contentHuge">
            {planInformation.plan_description}
          </Typography>
        </Box>

        <Box sx={{ px: 2, pb: 2, backgroundColor: "#FFFFFF" }}>
          {sponsored === false ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 3,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    lineHeight: 1,
                    display: "flex",
                    alignItems: "end",
                    mb: "4px",
                  }}
                >
                  {paymentCurrency}
                </Typography>

                <Typography variant="h1" sx={{ lineHeight: 1 }}>
                  {priceBeforeDot}
                </Typography>

                <Typography variant="h3" sx={{ lineHeight: 1, mt: "7px" }}>
                  {priceAfterDot}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pb: 3,
                }}
              >
                <Typography variant="contentMiddle" sx={{ lineHeight: 1 }}>
                  {paymentTenure}{" "}{reviseAction === "upgrade" && isStopPossible === false ? <sup>1</sup> : null}
                  {reviseAction === "reactivate" && isStopPossible === false ? <sup>*</sup> : null}
                </Typography>
              </Box>
            </>
          ) : (
            <Box sx={{ pt: 3 }} />
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              {planInformation.distribution_min_application_equity !== null ? (
                <PlanItemFeature feature={minApplicationEquity} />
              ) : null}
              {planInformation.distribution_max_application_equity !== null ? (
                <PlanItemFeature feature={maxApplicationEquity} />
              ) : null}
              {planInformation.distribution_min_application_leverage !==
              null ? (
                <PlanItemFeature feature={minApplicationLeverage} />
              ) : null}
              {planInformation.distribution_max_application_leverage !==
              null ? (
                <PlanItemFeature feature={maxApplicationLeverage} />
              ) : null}

              {planInformation.distribution_min_application_equity !== null ||
              planInformation.distribution_max_application_equity !== null ||
              planInformation.distribution_min_application_leverage !== null ||
              planInformation.distribution_max_application_leverage !== null ? (
                <Box sx={{ mt: 2 }} />
              ) : null}

              {liveIncludedAccounts !== "" ? (
                <PlanItemFeature feature={liveIncludedAccounts} />
              ) : null}
              {liveIncludedAccountChanges !== "" ? (
                <PlanItemFeature feature={liveIncludedAccountChanges} />
              ) : null}
              {liveLimitsValue !== "" ? (
                <PlanItemFeature feature={liveLimitsValue} />
              ) : null}

              {liveIncludedAccounts !== "" ||
              liveIncludedAccountChanges !== "" ||
              liveLimitsValue !== "" ? (
                <Box sx={{ mt: 2 }} />
              ) : null}

              {demoIncludedAccounts !== "" ? (
                <PlanItemFeature feature={demoIncludedAccounts} />
              ) : null}
              {demoIncludedAccountChanges !== "" ? (
                <PlanItemFeature feature={demoIncludedAccountChanges} />
              ) : null}
              {demoLimitsValue !== "" ? (
                <PlanItemFeature feature={demoLimitsValue} />
              ) : null}

              {demoIncludedAccounts !== "" ||
              demoIncludedAccountChanges !== "" ||
              demoLimitsValue !== "" ? (
                <Box sx={{ mt: 2 }} />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}

export default NewPlanItemApplications;
