// react and js
import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// mui
import {
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  NativeSelect,
} from "@mui/material";

// components
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks

// layouts

// pages

// theme
import { CardOutlinedInput, CardMenuItem } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function WidgetMainContentProcessed({ content, promoter, onChange }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
    onChange: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let checkKey = true;

  if (content === undefined || content === null) {
    checkKey = false;
  }

  const promoters = [];
  if (checkKey) {
    for (let index = 0; index < content.promoters.data.length; index += 1) {
      promoters.push([
        `${content.promoters.data[index][1]} ${content.promoters.data[index][2]}`,
        content.promoters.data[index][0],
      ]);
    }
  }
  if (promoters.length === 0) {
    checkKey = false;
  }

  return checkKey ? (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant={promoters.length > 1 ? "outlet" : "description"}
          backgroundProp="#F2F2F2"
          title={`Selected Promoter ID (PID): ${promoter}`}
          subheader="Select the promoter you wish to represent."
          mainOutlet={
            isDesktop ? (
              <Box>
                <Typography variant="contentSmall">
                  Promotion partner
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={promoter}
                    onChange={onChange}
                    input={<CardOutlinedInput />}
                  >
                    {promoters.map((currentPromoter, index) => (
                      <CardMenuItem key={index} value={currentPromoter[1]}>
                        {currentPromoter[0]}
                      </CardMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Box>
                <Typography variant="contentSmall">
                  Promotion partner
                </Typography>
                <FormControl fullWidth>
                  <NativeSelect
                    value={promoter}
                    onChange={onChange}
                    input={<CardOutlinedInput />}
                  >
                    {promoters.map((currentPromoter, index) => (
                      <option key={index} value={currentPromoter[1]}>
                        {currentPromoter[0]}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  ) : null;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  promoter,
  onChange,
  loading,
  error,
  content,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    promoter: PropTypes.any.isRequired,
    onChange: PropTypes.any.isRequired,
    loading: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  let WidgetOutlet = <MissingContent message="Promoter Portal" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Promoter Portal" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Promoter Portal" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        promoter={promoter}
        onChange={onChange}
      />
    );
  }

  return WidgetOutlet;
}
