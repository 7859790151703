// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext, useRef } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  Typography,
  IconButton,
  Stack,
  Popover,
  Box,
  Link,
  Collapse,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//
// import { Chart } from "react-google-charts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Pie } from "react-chartjs-2";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandlePromoterPortalAddNewPromotionClient from "./actions/HandlePromoterPortalAddNewPromotionClient";
import HandlePromoterPortalAddToExistingPromotionClient from "./actions/HandlePromoterPortalAddToExistingPromotionClient";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { B3Tiny } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ActionPopover({ row, promoter }) {
  ActionPopover.propTypes = {
    row: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  let clientsMaxReached = false;
  if (row.contingent_clients_max !== "unlimited") {
    if (
      Number(row.contingent_clients_current) ===
      Number(row.contingent_clients_max)
    ) {
      clientsMaxReached = true;
    }
  }

  return clientsMaxReached === false ? (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          color: "#474A4C",
          borderRadius: "0px",
        }}
      >
        <AddCircleOutlineIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            p: 1,
            border: "0px solid #C8C8C8",
            boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 50%)",
          },
        }}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          <HandlePromoterPortalAddNewPromotionClient
            promoter={promoter}
            promoterLinkId={row.promoter_link_id}
          />

          <HandlePromoterPortalAddToExistingPromotionClient
            promoter={promoter}
            promoterLinkId={row.promoter_link_id}
          />
        </Stack>
      </Popover>
    </>
  ) : (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          color: "#474A4C",
          borderRadius: "0px",
        }}
      >
        <AddCircleOutlineIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            p: 1,
            border: "0px solid #C8C8C8",
            boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 50%)",
            width: "300px",
          },
        }}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          <Typography variant="contentSmallBold">
            No contingent available for this partner!
            <br />
            <br />
            To update, please contact the administrator via email at{" "}
            {process.env.REACT_APP_EMAIL_PRIMARY_ADMINISTRATOR}.
          </Typography>
        </Stack>
      </Popover>
    </>
  );
}

function FetchTableContingentCheck(row) {
  let clientsMaxReached = false;
  if (row.contingent_clients_max !== "unlimited") {
    if (
      Number(row.contingent_clients_current) ===
      Number(row.contingent_clients_max)
    ) {
      clientsMaxReached = true;
    }
  }
  return clientsMaxReached;
}

function FetchTable({ tableArray, promoter }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              width="5%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            />
            <TableCell
              width="10%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Partner </Typography>
              <br />
            </TableCell>
            <TableCell
              width="35%"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Link Rotator Link </Typography>
            </TableCell>

            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="10%"
            >
              <Typography variant="contentSmallBold">
                {" "}
                Website <br /> Shares{" "}
              </Typography>
            </TableCell>

            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="10%"
            >
              <Typography variant="contentSmallBold">
                {" "}
                Checkout <br /> Shares{" "}
              </Typography>
            </TableCell>

            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="15%"
            >
              <Typography variant="contentSmallBold"> Link Rotator Clicks </Typography>
              <br />
              <Typography variant="contentSmall">current / max</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              width="15%"
            >
              <Typography variant="contentSmallBold"> Assigned Clients </Typography>
              <br />
              <Typography variant="contentSmall">current / max</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableArray.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
                width="5%"
              >
                <ActionPopover row={row} promoter={promoter} />
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall">
                  {row.broker_name}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography
                  variant="contentSmall"
                  sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {row.link}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography
                  variant="contentSmall"
                  sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {(
                    Math.floor(
                      (row.website_promotion_shares /
                        row.sum_website_promotion_shares) *
                        100 *
                        100
                    ) / 100
                  ).toFixed(0)}{" "}
                  %
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography
                  variant="contentSmall"
                  sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                >
                  {(
                    Math.floor(
                      (row.checkout_promotion_shares /
                        row.sum_checkout_promotion_shares) *
                        100 *
                        100
                    ) / 100
                  ).toFixed(0)}{" "}
                  %
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {`${row.contingent_clicks_current} / ${row.contingent_clicks_max}`}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {`${row.contingent_clients_current} / ${row.contingent_clients_max}`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.map((row, index) => (
        <Stack
          key={index}
          spacing="3px"
          sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Partner </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">{row.broker_name}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Link Rotator Link </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography
                variant="contentSmall"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {row.link}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold">
                {" "}
                Website Shares{" "}
              </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography
                variant="contentSmall"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {(
                  Math.floor(
                    (row.website_promotion_shares /
                      row.sum_website_promotion_shares) *
                      100 *
                      100
                  ) / 100
                ).toFixed(0)}{" "}
                %
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold">
                {" "}
                Checkout Shares{" "}
              </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography
                variant="contentSmall"
                sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
              >
                {(
                  Math.floor(
                    (row.checkout_promotion_shares /
                      row.sum_checkout_promotion_shares) *
                      100 *
                      100
                  ) / 100
                ).toFixed(0)}{" "}
                %
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Link Rotator Clicks </Typography>
              <br />
              <Typography variant="contentSmall">current / max</Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <br />
              <Typography variant="contentSmall">
                {`${row.contingent_clicks_current} / ${row.contingent_clicks_max}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Assigned Clients </Typography>
              <br />
              <Typography variant="contentSmall">current / max</Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <br />
              <Typography variant="contentSmall">
                {`${row.contingent_clients_current} / ${row.contingent_clients_max}`}
              </Typography>
            </Box>
          </Box>

          {FetchTableContingentCheck(row) === false ? (
            <>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <HandlePromoterPortalAddNewPromotionClient
                    promoter={promoter}
                    promoterLinkId={row.promoter_link_id}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <HandlePromoterPortalAddToExistingPromotionClient
                    promoter={promoter}
                    promoterLinkId={row.promoter_link_id}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Typography variant="contentSmallBold">
                No contingent available for this partner!
                <br />
                To update, please contact the administrator via email at{" "}
                {process.env.REACT_APP_EMAIL_PRIMARY_ADMINISTRATOR}.
              </Typography>
            </Box>
          )}
        </Stack>
      ))}
    </>
  );
}

/*
function GooglePieChart({ sharesValue }) {
  GooglePieChart.propTypes = {
    sharesValue: PropTypes.any.isRequired,
  };

  const options = {
    pieHole: 0.0,
    enableInteractivity: false,
    legend: "none",
    pieSliceText: "none",
    pieStartAngle: 180,
    chartArea: { width: "100%", height: "100%" },
    slices: {
      0: { offset: 0.0, color: "#474A4C" },
      1: { offset: 0.0, color: "#FFC000" },
    },
  };

  const data = [
    ["Group", "Shares"],
    ["Yours", sharesValue],
    ["Others", 100 - sharesValue],
  ];

  return (
    <Box>
      <Chart
        height="150px"
        width="150px"
        chartType="PieChart"
        options={options}
        data={data}

        // https://www.w3schools.com/js/js_graphics_chartjs.asp
        
      />
    </Box>
  );
}
*/

/*
ChartJS.register(ArcElement, Tooltip, Legend);

function ReactPieChart({ sharesValue }) {
  ReactPieChart.propTypes = {
    sharesValue: PropTypes.any.isRequired,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    animation: false,
    hover: false,
    layout: {
      padding: 1,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const data = {
    labels: ["Yours", "Others"],
    datasets: [
      {
        data: [sharesValue, 100 - sharesValue],
        backgroundColor: ["#474A4C", "#FFC000"],
        borderColor: ["#474A4C", "#FFC000"],
        borderWidth: 0,
        rotation: 180,
      },
    ],
  };

  return (
    <Box sx={{ width: "151px", height: "151px" }}>
      <Pie
        margin="10px 10px 10px 10px"
        height={150}
        width={150}
        options={options}
        data={data}
      />
    </Box>
  );
}
*/

function WidgetMainContentProcessed({ content, promoter }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    promoter: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  /*
  const websiteLinkRotatorShares =
    (content.shares.data[0][3] / content.sum_website_promotion_shares) * 100;
  const checkoutLinkRotatorShares =
    (content.shares.data[0][4] / content.sum_checkout_promotion_shares) * 100;
  */

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Client And Click Contingent"
      subheader="Your allocated contingents are listed below. These values can be renegotiated at any time!"
      mainOutlet={
        <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
          {isDesktop === false ? (
            <B3Tiny
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
              onClick={() => {
                setMobileCollapse(!mobileCollapse);
              }}
            >
              {mobileCollapse ? "Close" : "Open"}
            </B3Tiny>
          ) : null}

          <Collapse
            sx={{ mx: -1, px: 1 }}
            in={mobileCollapse}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Card
                  sx={{
                    background: "white",
                    border: 0,
                    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
                    pb: 2,
                  }}
                >
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h3" sx={{ mt: 0, mb: 1 }}>
                      Website Shares
                    </Typography>

                    <Box sx={{ mt: 1, mb: 0 }} dir="ltr">
                      <Typography variant="contentMiddle">
                        This value represents the number of clients redirected
                        by the link rotator to your promotion links. It's
                        applicable for each link rotator link on
                      </Typography>

                      <Link
                        variant="contentMiddleLink"
                        underline="none"
                        color="inherit"
                        target="_blank"
                        href={process.env.REACT_APP_URL_FRONTEND_WEBSITE}
                      >
                        {` ${process.env.REACT_APP_URL_FRONTEND_WEBSITE}.`}
                      </Link>
                    </Box>

                    {/*
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h1" sx={{ lineHeight: 1 }}>
                          {(
                            Math.floor(websiteLinkRotatorShares * 100) / 100
                          ).toFixed(1)}
                          %
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <ReactPieChart sharesValue={websiteLinkRotatorShares} />
                        // <GooglePieChart sharesValue={websiteLinkRotatorShares} /> 
                      </Grid>
                    </Grid>
                    */}
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Card
                  sx={{
                    background: "white",
                    border: 0,
                    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
                    pb: 2,
                  }}
                >
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h3" sx={{ mt: 0, mb: 1 }}>
                      Checkout Shares
                    </Typography>

                    <Box sx={{ mt: 1, mb: 0 }} dir="ltr">
                      <Typography variant="contentMiddle">
                        This value represents the number of clients redirected
                        by the link rotator to your promotion links. It's
                        applicable for each link rotator link on
                      </Typography>

                      <Link
                        variant="contentMiddleLink"
                        underline="none"
                        color="inherit"
                        target="_blank"
                        href={process.env.REACT_APP_URL_FRONTEND_CHECKOUT}
                      >
                        {` ${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}.`}
                      </Link>
                    </Box>

                    {/*
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h1" sx={{ lineHeight: 1 }}>
                          {(
                            Math.floor(checkoutLinkRotatorShares * 100) / 100
                          ).toFixed(1)}
                          %
                        </Typography>
                      </Grid>

                      
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <ReactPieChart
                          sharesValue={checkoutLinkRotatorShares}
                        />
                        // <PieChart sharesValue={checkoutLinkRotatorShares} /> 
                      </Grid>
                    </Grid>
                    */}
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FetchTable
                  tableArray={content.contingent}
                  promoter={promoter}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  promoter,
  loadingWidgetClientAndClickContingent,
  setLoadingWidgetClientAndClickContingent,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    promoter: PropTypes.any.isRequired,
    loadingWidgetClientAndClickContingent: PropTypes.any.isRequired,
    setLoadingWidgetClientAndClickContingent: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (promoter !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdatePromoterPortalClientAndClickContingent: false,
          }));

          setError(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "promoter_portal",
          widget: "client_and_click_contingent",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_promoter_id: promoter,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "",
            page_order_by: "",
          },
          filters: {},
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetClientAndClickContingent(false);
                setContent(contentFromBackend);
              } else if (
                contentFromBackend.message.includes("no promotion clients")
              ) {
                setSuccess(true);
                setContent(contentFromBackend);
                setLoading(false);
                setLoadingWidgetClientAndClickContingent(false);
                setReturnMessage(
                  "No promotions clients found yet! Wait for them or add a few ..."
                );
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setLoadingWidgetClientAndClickContingent(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setLoadingWidgetClientAndClickContingent(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetClientAndClickContingent(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetClientAndClickContingent(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh is promoter changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoter]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdatePromoterPortalClientAndClickContingent)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdatePromoterPortalClientAndClickContingent]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalPromoterPortalClientAndClickContingent);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Client And Click Contingent" />;
  if (loading || loadingWidgetClientAndClickContingent) {
    WidgetOutlet = <LoadingAnimation title="Client And Click Contingent" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Client And Click Contingent" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed content={content} promoter={promoter} />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
