// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";
import Autocomplete from "@mui/material/Autocomplete";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentTextField,
  ModalActionsLoadingButton,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalAddToExistingDistributionCustomerApplication({
  distributor,
  planId,
  plan,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalAddToExistingDistributionCustomerApplication.propTypes =
    {
      distributor: PropTypes.any.isRequired,
      planId: PropTypes.any.isRequired,
      plan: PropTypes.any,
    };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        layoutUpdateFeatures: true,
        widgetUpdateDistributorPortalTickDataDistributionCustomers: true,
        widgetUpdateDistributorPortalManageTickDataDistributionCustomer: true,
        widgetUpdateDistributorPortalContingents: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({
    selected_dashboard_id: Yup.number()
      .required("User ID (UID) is required")
      .positive()
      .integer(),
    live_account: Yup.number().positive().integer(),
    live_server: Yup.string(),
    demo_account: Yup.number().positive().integer(),
    demo_server: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      selected_dashboard_id: "",
      live_account: "",
      live_server: "",
      demo_account: "",
      demo_server: "",
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      // Calculate accounts
      const calcAccounts = [];
      let accountCounter = 1;

      if (values.live_account !== "") {
        calcAccounts.push({
          operation_id: accountCounter,
          type: "live",
          account: values.live_account,
          server: values.live_server,
        });
        accountCounter += 1;
      }

      if (values.demo_server !== "") {
        calcAccounts.push({
          operation_id: accountCounter,
          type: "demo",
          account: values.demo_account,
          server: values.demo_server,
        });
        accountCounter += 1;
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_and_account_management",
        operation: "add_to_existing_distribution_customer",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        used_plan_id: planId,
        dashboard: {
          selected_dashboard_id: values.selected_dashboard_id,
        },
        accounts: calcAccounts,
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny fullWidth variant="outlined" onClick={handleClickOpen("paper")}>
        Add to existing distribution customer
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Add to existing distribution customer
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.selected_dashboard_id &&
                              errors.selected_dashboard_id
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.selected_dashboard_id &&
                          errors.selected_dashboard_id
                      ) === true
                        ? "User ID (UID) must be a positive number!"
                        : "User ID (UID)"}
                    </Typography>
                    <ModalContentTextField
                      fullWidth
                      size="small"
                      type="text"
                      {...getFieldProps("selected_dashboard_id")}
                      error={Boolean(
                        touched.selected_dashboard_id &&
                          errors.selected_dashboard_id
                      )}
                    />
                  </Box>

                  {plan !== undefined ? (
                    <>
                      {plan.distribution_live_included_accounts !== "0" &&
                      plan.distribution_demo_included_accounts !== "0" ? (
                        <Box sx={{ pt: 3 }}>
                          <Typography variant="contentMiddleBold">
                          Please provide at least one initial account!
                          </Typography>
                        </Box>
                      ) : null}

                      {plan.distribution_live_included_accounts !== "0" ? (
                        <>
                          <Box sx={{ pt: 1 }}>
                            <Typography variant="contentMiddleBold">
                              Initial live account
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant="contentSmall"
                              sx={{
                                color:
                                  Boolean(
                                    touched.live_account && errors.live_account
                                  ) === true
                                    ? "red"
                                    : "none",
                              }}
                            >
                              {Boolean(
                                touched.live_account && errors.live_account
                              ) === true
                                ? "Live account number must be a positive number!"
                                : "Live account number"}
                            </Typography>
                            <Autocomplete
                              fullWidth
                              freeSolo
                              size="small"
                              onChange={(event, value) => {
                                values.live_account = value;
                              }}
                              id="combo-box-demo"
                              options={[]}
                              renderOption={(props, option) => (
                                <AutocompleteOption {...props} key={option}>
                                  {option}
                                </AutocompleteOption>
                              )}
                              renderInput={(params) => (
                                <ModalContentTextField
                                  {...params}
                                  type="text"
                                  {...getFieldProps("live_account")}
                                  error={Boolean(
                                    touched.live_account && errors.live_account
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Box>

                          <Box>
                            <Typography
                              variant="contentSmall"
                              sx={{
                                color:
                                  Boolean(
                                    touched.live_server && errors.live_server
                                  ) === true
                                    ? "red"
                                    : "none",
                              }}
                            >
                              {Boolean(
                                touched.live_server && errors.live_server
                              ) === true
                                ? "Live trading server must be a text!"
                                : "Live trading server"}
                            </Typography>
                            <Autocomplete
                              fullWidth
                              freeSolo
                              size="small"
                              onChange={(event, value) => {
                                values.live_server = value;
                              }}
                              id="combo-box-demo"
                              options={[]}
                              renderOption={(props, option) => (
                                <AutocompleteOption {...props} key={option}>
                                  {option}
                                </AutocompleteOption>
                              )}
                              renderInput={(params) => (
                                <ModalContentTextField
                                  {...params}
                                  type="text"
                                  {...getFieldProps("live_server")}
                                  error={Boolean(
                                    touched.live_server && errors.live_server
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Box>
                        </>
                      ) : null}

                      {plan.distribution_demo_included_accounts !== "0" ? (
                        <>
                          <Box sx={{ pt: 1 }}>
                            <Typography variant="contentMiddleBold">
                              Initial demo account
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant="contentSmall"
                              sx={{
                                color:
                                  Boolean(
                                    touched.demo_account && errors.demo_account
                                  ) === true
                                    ? "red"
                                    : "none",
                              }}
                            >
                              {Boolean(
                                touched.demo_account && errors.demo_account
                              ) === true
                                ? "Demo account number must be a positive number!"
                                : "Demo account number"}
                            </Typography>
                            <Autocomplete
                              fullWidth
                              freeSolo
                              size="small"
                              onChange={(event, value) => {
                                values.demo_account = value;
                              }}
                              id="combo-box-demo"
                              options={[]}
                              renderOption={(props, option) => (
                                <AutocompleteOption {...props} key={option}>
                                  {option}
                                </AutocompleteOption>
                              )}
                              renderInput={(params) => (
                                <ModalContentTextField
                                  {...params}
                                  type="text"
                                  {...getFieldProps("demo_account")}
                                  error={Boolean(
                                    touched.demo_account && errors.demo_account
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Box>

                          <Box>
                            <Typography
                              variant="contentSmall"
                              sx={{
                                color:
                                  Boolean(
                                    touched.demo_server && errors.demo_server
                                  ) === true
                                    ? "red"
                                    : "none",
                              }}
                            >
                              {Boolean(
                                touched.demo_server && errors.demo_server
                              ) === true
                                ? "Demo trading server must be a text!"
                                : "Demo trading server"}
                            </Typography>
                            <Autocomplete
                              fullWidth
                              freeSolo
                              size="small"
                              onChange={(event, value) => {
                                values.demo_server = value;
                              }}
                              id="combo-box-demo"
                              options={[]}
                              renderOption={(props, option) => (
                                <AutocompleteOption {...props} key={option}>
                                  {option}
                                </AutocompleteOption>
                              )}
                              renderInput={(params) => (
                                <ModalContentTextField
                                  {...params}
                                  type="text"
                                  {...getFieldProps("demo_server")}
                                  error={Boolean(
                                    touched.demo_server && errors.demo_server
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Box>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Add
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
