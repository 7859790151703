// react and js
import { React, useMemo } from "react";

// mui

// components
import Page from "../../components/Page";

// hooks

// layouts

// pages

// theme

// utils

// widgets
import WidgetLogin from "../../widgets/login/Login";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Login() {
  // eslint-disable-next-line
  return useMemo(() => {
    return (
      <Page title="Dashboard Login">
        <WidgetLogin />
      </Page>
    );
  });
}
