// react and js
import PropTypes from "prop-types";
import React from "react";

// mui
import { Card, Box, Collapse, Stack, Typography } from "@mui/material";

// components

// hooks

// layouts

// pages

// theme
import {
  CardAlertWarning,
  CardAlertError,
  CardAlertSuccess,
} from "../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function CardOutlet({
  variant,
  backgroundProp,
  category,
  title,
  subheader,
  mainOutlet,
  error,
  warning,
  success,
  returnMessage,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  CardOutlet.propTypes = {
    variant: PropTypes.any,
    backgroundProp: PropTypes.any,
    category: PropTypes.any,
    title: PropTypes.any,
    subheader: PropTypes.any,
    mainOutlet: PropTypes.any,
    error: PropTypes.any,
    warning: PropTypes.any,
    success: PropTypes.any,
    returnMessage: PropTypes.any,
  };

  return (
    <Card
      sx={{
        background: backgroundProp,
        border: variant === "outlined" ? 1 : 0,
        borderColor: "#6D6E70",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          py: {
            xs: 3,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          },

          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 4,
            xl: 4,
          },
        }}
        dir="ltr"
      >
        {category !== undefined ? (
          <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
            {category}
          </Typography>
        ) : null}

        {title !== undefined ? (
          <Typography
            variant="h2"
            sx={{
              mt: 0,
              mb: 0,
              pl: 2,
              borderLeft: 1,
              borderWidth: "3px",
              borderColor: "#FF9600",
              borderRadius: "0px 0px 0px 0px",
              boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
            }}
          >
            {title}
          </Typography>
        ) : null}

        {subheader !== undefined ? (
          <Box sx={{ mt: 2, mb: 0 }} dir="ltr">
            <Typography variant="contentMiddle"> {subheader} </Typography>
          </Box>
        ) : null}

        {variant !== "description" ? (
          <>
            <Stack justifyContent="space-between" sx={{ my: 1 }}>
              <Collapse in={error} timeout="auto" unmountOnExit>
                <CardAlertError severity="error" sx={{ mt: 1, mb: 0 }}>
                  {returnMessage}
                </CardAlertError>
              </Collapse>
              <Collapse in={warning} timeout="auto" unmountOnExit>
                <CardAlertWarning severity="warning" sx={{ mt: 1, mb: 0 }}>
                  {returnMessage}
                </CardAlertWarning>
              </Collapse>
              <Collapse in={success} timeout="auto" unmountOnExit>
                <CardAlertSuccess severity="success" sx={{ mt: 1, mb: 0 }}>
                  {returnMessage}
                </CardAlertSuccess>
              </Collapse>
            </Stack>
            {mainOutlet}
          </>
        ) : null}
      </Box>
    </Card>
  );
}

export default CardOutlet;
