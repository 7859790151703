// react and js
import * as Yup from "yup";
import { React, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

// mui
import { Link, Collapse, Card, Box, Typography, Grid } from "@mui/material";

// components
import backendRequest from "../../components/BackendRequest";

// hooks

// layouts

// pages

// theme
import {
  AuthenticationButton,
  AuthenticationTextField,
  AuthenticationAlertError,
  AuthenticationAlertSuccess,
} from "../../theme/styled";

// utils
import { combineReturnMessage } from "../../utils/common";

// widgets

// app

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const handleClick = () => {
    setError(false);
  };
  document.addEventListener("mousedown", handleClick, {
    passive: false,
    capture: false,
  });

  const [recaptcha, setRecaptcha] = useState(false);

  const PasswordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email address is required"),
  });

  const handlePasswordReset = (values, setSubmitting) => {
    if (navigator.onLine === true) {
      setError(false);
      setSuccess(false);

      const sendData = {
        request_type: "dashboard",
        route_info: "authentication",
        client: {
          mode: "password_reset",
          email: values.email,
          return_url: `${process.env.REACT_APP_URL_FRONTEND_DASHBOARD}/new-password`,
          // return_url: "http://localhost:3000/new-password",
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("answer");

          if (checkKey === true) {
            if (contentFromBackend.answer.message === "success") {
              setReturnMessage("Reset email sent!");
              setSuccess(true);
              setError(false);
            } else {
              setReturnMessage("Please check email address again!");
              setError(true);
            }
          } else {
            combineReturnMessage(contentFromBackend, setReturnMessage);
            setError(true);
          }
          setSubmitting(false);
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setReturnMessage("Unknown error!");
            setError(true);
            setSubmitting(false);
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: PasswordResetSchema,
    onSubmit: (values, { setSubmitting }) => {
      handlePasswordReset(values, setSubmitting);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "70vw",
            md: "50vw",
            lg: "40vw",
            xl: "35vw",
          },
        }}
      >
        <Card
          sx={{
            background: "#F2F2F2",
            borderColor: "#6D6E70",
            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
          }}
        >
          <Box sx={{ p: 4 }} dir="ltr">
            <Typography
              variant="h2"
              sx={{
                mt: 0,
                mb: 0,
                pl: 2,
                borderLeft: 1,
                borderWidth: "3px",
                borderColor: "#FF9600",
                borderRadius: "0px 0px 0px 0px",
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
              }}
            >
              Password Reset
            </Typography>

            <Box sx={{ mt: 1, mb: 2 }} dir="ltr">
              <Typography
                variant="contentSmall"
                sx={{ mt: 1, textAlign: "center" }}
              >
                Please enter your email address to receive a password reset email with further instructions.
              </Typography>
            </Box>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.email && errors.email) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.email && errors.email) === true
                    ? errors.email
                    : "Email address"}
                </Typography>

                <AuthenticationTextField
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                />

                <Collapse
                  ref={wrapperRef}
                  in={error}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertError
                    severity="error"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertError>
                </Collapse>

                <Collapse
                  ref={wrapperRef}
                  in={success}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertSuccess
                    severity="success"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertSuccess>
                </Collapse>

                <Box sx={{ textAlign: "center", mt: 1, mb: 1 }}>
                  <Typography variant="contentSmall">
                    <Link
                      component={RouterLink}
                      to="/login"
                      underline="none"
                      variant="contentSmallLink"
                      color="inherit"
                    >
                      Back to login
                    </Link>
                  </Typography>
                </Box>

                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                      onErrored={() => {
                        setRecaptcha(false);
                      }}
                      onExpired={() => {
                        setRecaptcha(false);
                      }}
                      onChange={() => {
                        setRecaptcha(true);
                      }}
                    />
                  </Box>
                </Box>

                <AuthenticationButton
                  fullWidth
                  variant="contained"
                  disabled={!recaptcha}
                  loading={isSubmitting}
                  type="submit"
                  sx={{ mt: 1 }}
                >
                  Send reset email
                </AuthenticationButton>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}
