// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import { Grid, Box, Typography, FormGroup } from "@mui/material";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import { ApplicationItem } from "../../components/InspectorItem";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { CardSwitch } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  let inspectorSimplifiedInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsb07ce3792cd977cc !== null &&
      myLocalSettingStorage.obfsb07ce3792cd977cc !== undefined
    ) {
      inspectorSimplifiedInitValue = myLocalSettingStorage.obfsb07ce3792cd977cc;
    }
  }

  const [simplified, setSimplified] = useState(inspectorSimplifiedInitValue);

  const handleChange = (event) => {
    setSimplified(event.target.checked);

    const newLocalSettingStorage = JSON.parse(
      localStorage.getItem("myLocalSettingStorage")
    );
    newLocalSettingStorage.obfsb07ce3792cd977cc = event.target.checked;
    localStorage.setItem(
      "myLocalSettingStorage",
      JSON.stringify(newLocalSettingStorage)
    );
  };

  return (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 0, mb: 0 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Inspector"
            subheader="Stay informed about your active applications' performance, drawdown, and statistics. A widget displaying the latest data set will appear below for any application with a built-in inspector. If an application lacks the inspector engine, it will appear below without performance, drawdown, and statistics information."
            mainOutlet={
              <FormGroup>
                <Box
                  justifyContent="space-between"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="contentMiddle">
                    Simplified output
                  </Typography>
                  <Typography variant="contentMiddle" align="right">
                    <CardSwitch checked={simplified} onChange={handleChange} />
                  </Typography>
                </Box>
              </FormGroup>
            }
          />
        </Box>
      </Grid>

      {content.inspector_data.length !== 0 ? (
        content.inspector_data.map((currentApplication, index) => (
          <ApplicationItem
            key={index}
            content={currentApplication}
            simplified={simplified}
            managed={Boolean(false)}
          />
        ))
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <Box
            display="block"
            align="center"
            sx={{
              borderRadius: "0px",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
              py: 1,
            }}
          >
            <Typography
              variant="contentBig"
              display="block"
              align="center"
              sx={{ m: "14px" }}
            >
              No active applications found ...
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateInspectorInspector: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "inspector",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateInspectorInspector) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateInspectorInspector]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalInspectorInspector);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Inspector" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Inspector" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Inspector" />;
  } else if (content) {
    WidgetOutlet = <WidgetMainContentProcessed content={content} />;
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
