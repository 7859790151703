// react and js
import { React } from "react";

// mui

// components

// hooks

// layouts

// pages
import Subscriptions from "../pages/subscriptions/Subscriptions";
import Promotion from "../pages/promotion/Promotion";
import Downloads from "../pages/downloads/Downloads";
import Inspector from "../pages/inspector/Inspector";
import Accounting from "../pages/accounting/Accounting";
import PromoterPortal from "../pages/promoter_portal/PromoterPortal";
import DistributorPortal from "../pages/distributor_portal/DistributorPortal";
import EvaluationPortal from "../pages/evaluation_portal/EvaluationPortal";
import FormManager from "../pages/form_manager/FormManager";
import EventListeners from "../pages/event_listeners/EventListeners";
import ApplicationSettings from "../pages/application_settings/ApplicationSettings";

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
const features = [
  {
    title: "promotion",
    path: "/promotion",
    icon: "eva:award-fill",
    backendLinking: "obfs382f0296a2a463bb", // Clear: dashboard_feature_promotion
    trueObfs: "obfsa0d3c920c36e2ad0",
    route: { path: "promotion", element: <Promotion /> },
  },
  {
    title: "subscriptions",
    path: "/subscriptions",
    icon: "eva:radio-outline",
    backendLinking: "obfs348d78fb69464a00", // Clear: dashboard_feature_subscriptions
    trueObfs: "obfs2f1c786d5ef25c55",
    route: { path: "subscriptions", element: <Subscriptions /> },
  },
  {
    title: "billing",
    path: "/accounting",
    icon: "eva:email-outline",
    backendLinking: "obfsaa8911d51f968403", // Clear: dashboard_feature_accounting
    trueObfs: "obfs5828c1b7bf8ebf20",
    route: { path: "accounting", element: <Accounting /> },
  },
  {
    title: "downloads",
    path: "/downloads",
    icon: "eva:cloud-download-fill",
    backendLinking: "obfs998de9826cbc26b1", // Clear: dashboard_feature_downloads
    trueObfs: "obfs7c64df1a627d2987",
    route: { path: "downloads", element: <Downloads /> },
  },
  {
    title: "inspector",
    path: "/inspector",
    icon: "eva:activity-fill",
    backendLinking: "obfse1ba76479c823d4c", // Clear: dashboard_feature_inspector
    trueObfs: "obfs06cedb24269b130b",
    route: { path: "inspector", element: <Inspector /> },
  },
  {
    title: "promoter portal",
    path: "/promoter-portal",
    icon: "eva:briefcase-outline",
    backendLinking: "obfsefb710ed690a6335", // Clear: dashboard_feature_promoter_portal
    trueObfs: "obfsf2c9be0f5184f441",
    route: { path: "promoter-portal", element: <PromoterPortal /> },
  },
  {
    title: "distributor portal",
    path: "/distributor-portal",
    icon: "eva:briefcase-fill",
    backendLinking: "obfs103d6174421f3079", // Clear: dashboard_feature_distributor_portal
    trueObfs: "obfsdf2b6301086ec5eb",
    route: { path: "distributor-portal", element: <DistributorPortal /> },
  },
  {
    title: "evaluation portal",
    path: "/evaluation-portal",
    icon: "eva:people-fill",
    backendLinking: "obfs6ba90c02fea078eb", // Clear: dashboard_feature_evaluation_portal
    trueObfs: "obfs415152af3dd89e65",
    route: { path: "evaluation-portal", element: <EvaluationPortal /> },
  },
  {
    title: "form manager",
    path: "form-manager",
    icon: "eva:file-text-outline",
    backendLinking: "obfse24461ff3dff5db0", // Clear: dashboard_feature_form_manager
    trueObfs: "obfsb37c553ac6d139a7",
    route: { path: "form-manager", element: <FormManager /> },
  },
  {
    title: "event listeners",
    path: "event-listeners",
    icon: "eva:play-circle-fill",
    backendLinking: "obfs2155c98142291399", // Clear: dashboard_feature_event_listener
    trueObfs: "obfs726cb1e1a87b1c05",
    route: { path: "event-listeners", element: <EventListeners /> },
  },
  {
    title: "application settings",
    path: "application-settings",
    icon: "eva:options-2-fill",
    backendLinking: "obfs0921d9fe7b9543ab", // Clear: dashboard_feature_application_settings
    trueObfs: "obfsc555894615b268f9",
    route: { path: "application-settings", element: <ApplicationSettings /> },
  },
];

export default features;
