// react and js
import PropTypes from "prop-types";
import { React, useRef, useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Divider,
  Typography,
  Stack,
  IconButton,
  Popover,
} from "@mui/material";

// components
import backendRequest from "../../components/BackendRequest";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

import { B1Tiny } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function Roles({ configData }) {
  Roles.propTypes = {
    configData: PropTypes.any.isRequired,
  };

  let keyOnly = null;

  keyOnly = Object.keys(configData);
  const checkKeyClient = keyOnly.includes("config_data_client");

  keyOnly = Object.keys(configData);
  const checkKeyPromoter = keyOnly.includes("config_data_promoter");

  keyOnly = Object.keys(configData);
  const checkKeyDistributor = keyOnly.includes("config_data_distributor");

  keyOnly = Object.keys(configData);
  const checkKeyAdministrator = keyOnly.includes("config_data_administrator");

  return (
    <>
      {checkKeyClient ? (
        <Typography variant="contentSmall">
          Client <br />
        </Typography>
      ) : null}

      {checkKeyPromoter ? (
        <Typography variant="contentSmall">
          Promoter <br />
        </Typography>
      ) : null}

      {checkKeyDistributor ? (
        <Typography variant="contentSmall">
          Distributor <br />
        </Typography>
      ) : null}

      {checkKeyAdministrator ? (
        <Typography variant="contentSmall">
          Administrator <br />
        </Typography>
      ) : null}
    </>
  );
}

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PrivateAccount() {
  const { stateStorage, setStateStorage } = useContext(appContext);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const handleLogout = () => {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      localStorage.setItem("myReauthentication", true);

      let dashboardAccessTokenCopy = null;
      if (stateStorage.userInformation !== "") {
        dashboardAccessTokenCopy =
          stateStorage.userInformation.answer.dashboard_access_token;
      }

      setStateStorage(stateStorageInit);

      localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
      localStorage.removeItem("myLocalStateStorage");

      sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
      sessionStorage.removeItem("myLocalLastRoute");

      localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
      localStorage.removeItem("myLocalSettingStorage");

      localStorage.setItem("myReauthentication", JSON.stringify(""));
      localStorage.removeItem("myReauthentication");

      setOpen(false);

      if (dashboardAccessTokenCopy !== null) {
        const sendData = {
          request_type: "dashboard",
          route_info: "authentication",
          client: {
            mode: "logout",
            dashboard_access_token: dashboardAccessTokenCopy,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData);
      }
    }
  };

  let Forename = "";
  let Surname = "";
  let Email = "";
  let DashboardId = "";

  if (stateStorage.userInformation !== "") {
    Forename =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.forename;
    Surname =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.surname;
    Email =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.email;
    DashboardId =
      stateStorage.userInformation.account_settings.config_data
        .config_data_client.dashboard_id;
  }

  return stateStorage.userInformation !== "" ? (
    <>
      <IconButton
        ref={anchorRef}
        onClick={(event) => {
          setOpen(event.currentTarget);
        }}
        sx={{
          marginRight: isDesktop ? "13px" : "10px",
          color: "#474A4C",
          borderRadius: "0px",
        }}
      >
        <PersonIcon sx={{ fontSize: 30 }} />
        <ArrowDropDownIcon sx={{ fontSize: 30 }} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            width: "auto",
            minWidth: 250,
            border: "0px solid #C8C8C8",
            boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 50%)",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          {Forename !== "" || Surname !== "" ? (
            <>
              {Forename !== "" && Surname !== "" ? (
                <Typography variant="h3" sx={{ mt: 0, mb: 1 }}>
                  {Forename} {Surname}
                </Typography>
              ) : null}

              {Forename === "" && Surname !== "" ? (
                <Typography variant="h3" sx={{ mt: 0, mb: 1 }}>
                  User
                </Typography>
              ) : null}

              {Forename !== "" && Surname === "" ? (
                <Typography variant="h3" sx={{ mt: 0, mb: 1 }}>
                  {Forename}
                </Typography>
              ) : null}
            </>
          ) : (
            <Typography variant="h3" sx={{ mt: 0, mb: 1 }}>
              User
            </Typography>
          )}

          <Typography variant="contentSmall">
            {Email} <br />
          </Typography>

          <Typography variant="contentSmall">
            User ID (UID): {DashboardId} <br />
          </Typography>

          <Roles
            configData={
              stateStorage.userInformation.account_settings.config_data
            }
          />
        </Box>

        <Divider sx={{ borderStyle: "solid", borderColor: "#C8C8C8" }} />

        <Stack sx={{ p: 2 }}>
          <B1Tiny
            onClick={() => {
              setOpen(false);
            }}
            fullWidth
            variant="contained"
            sx={{ mb: 1 }}
            to="/account-settings"
            component={RouterLink}
          >
            Settings
          </B1Tiny>

          <B1Tiny
            onClick={handleLogout}
            fullWidth
            variant="contained"
            to="/"
            component={RouterLink}
          >
            Logout
          </B1Tiny>
        </Stack>
      </Popover>
    </>
  ) : null;
}
