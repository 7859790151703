// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
} from "@mui/material";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B3Tiny,
  ModalContentTextField,
  ModalActionsLoadingButton,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalModifyPlanLimitations({
  distributor,
  permissionId,
  plan,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalModifyPlanLimitations.propTypes = {
    distributor: PropTypes.any.isRequired,
    permissionId: PropTypes.any.isRequired,
    plan: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const limitationRegExp = /^[0-9]|Plan default value|Unlimited+$/;

  const myValidationScheme = Yup.object().shape({
    permission_live_included_accounts: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_live_included_account_changes: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_demo_included_accounts: Yup.string()
      .required()
      .matches(limitationRegExp),
    permission_demo_included_account_changes: Yup.string()
      .required()
      .matches(limitationRegExp),
  });

  let formLiveIncludedAccountsInitValue = "Plan default value";
  if (plan.live_permission_included_accounts === null) {
    formLiveIncludedAccountsInitValue = "Plan default value";
  } else if (plan.live_permission_included_accounts === "-1") {
    formLiveIncludedAccountsInitValue = "Unlimited";
  } else {
    formLiveIncludedAccountsInitValue = plan.live_permission_included_accounts;
  }

  let formLiveIncludedAccountChangesInitValue = "Plan default value";
  if (plan.live_permission_included_account_changes === null) {
    formLiveIncludedAccountChangesInitValue = "Plan default value";
  } else if (plan.live_permission_included_account_changes === "-1") {
    formLiveIncludedAccountChangesInitValue = "Unlimited";
  } else {
    formLiveIncludedAccountChangesInitValue =
      plan.live_permission_included_account_changes;
  }

  let formDemoIncludedAccountsInitValue = "Plan default value";
  if (plan.demo_permission_included_accounts === null) {
    formDemoIncludedAccountsInitValue = "Plan default value";
  } else if (plan.demo_permission_included_accounts === "-1") {
    formDemoIncludedAccountsInitValue = "Unlimited";
  } else {
    formDemoIncludedAccountsInitValue = plan.demo_permission_included_accounts;
  }

  let formDemoIncludedAccountChangesInitValue = "Plan default value";
  if (plan.demo_permission_included_account_changes === null) {
    formDemoIncludedAccountChangesInitValue = "Plan default value";
  } else if (plan.demo_permission_included_account_changes === "-1") {
    formDemoIncludedAccountChangesInitValue = "Unlimited";
  } else {
    formDemoIncludedAccountChangesInitValue =
      plan.demo_permission_included_account_changes;
  }

  const [formLiveIncludedAccounts, setFormLiveIncludedAccounts] = useState(
    formLiveIncludedAccountsInitValue
  );
  const [formLiveIncludedAccountChanges, setFormLiveIncludedAccountChanges] =
    useState(formLiveIncludedAccountChangesInitValue);
  const [formDemoIncludedAccounts, setFormDemoIncludedAccounts] = useState(
    formDemoIncludedAccountsInitValue
  );
  const [formDemoIncludedAccountChanges, setFormDemoIncludedAccountChanges] =
    useState(formDemoIncludedAccountChangesInitValue);

  const formik = useFormik({
    initialValues: {
      permission_live_included_accounts: formLiveIncludedAccountsInitValue,
      permission_live_included_account_changes:
        formLiveIncludedAccountChangesInitValue,
      permission_demo_included_accounts: formDemoIncludedAccountsInitValue,
      permission_demo_included_account_changes:
        formDemoIncludedAccountChangesInitValue,
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      let permissionLiveIncludedAccountsProcessedConverted =
        values.permission_live_included_accounts;
      if (
        permissionLiveIncludedAccountsProcessedConverted ===
        "Plan default value"
      ) {
        permissionLiveIncludedAccountsProcessedConverted = "plan_default";
      } else if (
        permissionLiveIncludedAccountsProcessedConverted === "Unlimited"
      ) {
        permissionLiveIncludedAccountsProcessedConverted = "unlimited";
      }

      let permissionLiveIncludedAccountChangesConverted =
        values.permission_live_included_account_changes;
      if (
        permissionLiveIncludedAccountChangesConverted === "Plan default value"
      ) {
        permissionLiveIncludedAccountChangesConverted = "plan_default";
      } else if (
        permissionLiveIncludedAccountChangesConverted === "Unlimited"
      ) {
        permissionLiveIncludedAccountChangesConverted = "unlimited";
      }

      let permissionDemoIncludedAccountsConverted =
        values.permission_demo_included_accounts;
      if (permissionDemoIncludedAccountsConverted === "Plan default value") {
        permissionDemoIncludedAccountsConverted = "plan_default";
      } else if (permissionDemoIncludedAccountsConverted === "Unlimited") {
        permissionDemoIncludedAccountsConverted = "unlimited";
      }

      let permissionDemoIncludedAccountChangesConverted =
        values.permission_demo_included_account_changes;
      if (
        permissionDemoIncludedAccountChangesConverted === "Plan default value"
      ) {
        permissionDemoIncludedAccountChangesConverted = "plan_default";
      } else if (
        permissionDemoIncludedAccountChangesConverted === "Unlimited"
      ) {
        permissionDemoIncludedAccountChangesConverted = "unlimited";
      }

      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_and_account_management",
        operation: "modify_plan_limitations",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        permission: {
          selected_permission_id: permissionId,
        },
        limits: {
          permission_live_included_accounts:
            permissionLiveIncludedAccountsProcessedConverted,
          permission_live_included_account_changes:
            permissionLiveIncludedAccountChangesConverted,
          permission_demo_included_accounts:
            permissionDemoIncludedAccountsConverted,
          permission_demo_included_account_changes:
            permissionDemoIncludedAccountChangesConverted,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);

    formik.values.permission_live_included_accounts =
      formLiveIncludedAccountsInitValue;
    formik.values.permission_live_included_account_changes =
      formLiveIncludedAccountChangesInitValue;
    formik.values.permission_demo_included_accounts =
      formDemoIncludedAccountsInitValue;
    formik.values.permission_demo_included_account_changes =
      formDemoIncludedAccountChangesInitValue;

    setFormLiveIncludedAccounts(formLiveIncludedAccountsInitValue);
    setFormLiveIncludedAccountChanges(formLiveIncludedAccountChangesInitValue);
    setFormDemoIncludedAccounts(formDemoIncludedAccountsInitValue);
    setFormDemoIncludedAccountChanges(formDemoIncludedAccountChangesInitValue);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const [errorLiveIncludedAccounts, setErrorLiveIncludedAccounts] =
    useState(false);
  const [errorLiveIncludedAccountChanges, setErrorLiveIncludedAccountChanges] =
    useState(false);
  const [errorDemoIncludedAccounts, setErrorDemoIncludedAccounts] =
    useState(false);
  const [errorDemoIncludedAccountChanges, setErrorDemoIncludedAccountChanges] =
    useState(false);

  return (
    <>
      <B3Tiny
        variant="outlined"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 1 }}
        fullWidth
      >
        Modify plan limitations
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Modify plan limitations
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  <Box>
                    <Typography variant="contentMiddle">
                      Use this dialog to modify your customer's plan
                      limitations. You can either set a specific number, use the
                      'Plan default value,' or set it to 'Unlimited.'
                      <br />
                      <br />A modified value does not overwrite the plan
                      standard value; it just sets a new value with higher
                      priority. To restore the Plan default value, use the
                      option 'Plan default value.'
                    </Typography>
                  </Box>

                  <Box sx={{ pt: 2 }}>
                    <Typography variant="contentMiddleBold">
                      Live accounts
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_live_included_accounts &&
                              errors.permission_live_included_accounts
                          ) === true || errorLiveIncludedAccounts === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_live_included_accounts &&
                          errors.permission_live_included_accounts
                      ) === true || errorLiveIncludedAccounts === true
                        ? "Invalid included live trading accounts"
                        : "Included live trading accounts"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formLiveIncludedAccounts}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorLiveIncludedAccounts(false);
                          values.permission_live_included_accounts =
                            "Plan default value";
                          setFormLiveIncludedAccounts("Plan default value");
                        } else if (
                          value !== "Plan default value" &&
                          value !== "Unlimited"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorLiveIncludedAccounts(true);
                            } else {
                              setErrorLiveIncludedAccounts(false);
                              values.permission_live_included_accounts = value;
                              setFormLiveIncludedAccounts(value);
                            }
                          } else {
                            setErrorLiveIncludedAccounts(true);
                          }
                        } else {
                          setErrorLiveIncludedAccounts(false);
                          values.permission_live_included_accounts = value;
                          setFormLiveIncludedAccounts(value);
                        }
                      }}
                      options={["Plan default value", "Unlimited"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_live_included_accounts"
                          )}
                          error={Boolean(
                            touched.permission_live_included_accounts &&
                              errors.permission_live_included_accounts
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_live_included_account_changes &&
                              errors.permission_live_included_account_changes
                          ) === true || errorLiveIncludedAccountChanges === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_live_included_account_changes &&
                          errors.permission_live_included_account_changes
                      ) === true || errorLiveIncludedAccountChanges === true
                        ? "Invalid included live trading account changes"
                        : "Included live trading account changes"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formLiveIncludedAccountChanges}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorLiveIncludedAccountChanges(false);
                          values.permission_live_included_account_changes =
                            "Plan default value";
                          setFormLiveIncludedAccountChanges(
                            "Plan default value"
                          );
                        } else if (
                          value !== "Plan default value" &&
                          value !== "Unlimited"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorLiveIncludedAccountChanges(true);
                            } else {
                              setErrorLiveIncludedAccountChanges(false);
                              values.permission_live_included_account_changes =
                                value;
                              setFormLiveIncludedAccountChanges(value);
                            }
                          } else {
                            setErrorLiveIncludedAccountChanges(true);
                          }
                        } else {
                          setErrorLiveIncludedAccountChanges(false);
                          values.permission_live_included_account_changes =
                            value;
                          setFormLiveIncludedAccountChanges(value);
                        }
                      }}
                      options={["Plan default value", "Unlimited"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_live_included_account_changes"
                          )}
                          error={Boolean(
                            touched.permission_live_included_account_changes &&
                              errors.permission_live_included_account_changes
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box sx={{ pt: 2 }}>
                    <Typography variant="contentMiddleBold">
                      Demo accounts
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_demo_included_accounts &&
                              errors.permission_demo_included_accounts
                          ) === true || errorDemoIncludedAccounts === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_demo_included_accounts &&
                          errors.permission_demo_included_accounts
                      ) === true || errorDemoIncludedAccounts === true
                        ? "Invalid included demo trading accounts"
                        : "Included demo trading accounts"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formDemoIncludedAccounts}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorDemoIncludedAccounts(false);
                          values.permission_demo_included_accounts =
                            "Plan default value";
                          setFormDemoIncludedAccounts("Plan default value");
                        } else if (
                          value !== "Plan default value" &&
                          value !== "Unlimited"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorDemoIncludedAccounts(true);
                            } else {
                              setErrorDemoIncludedAccounts(false);
                              values.permission_demo_included_accounts = value;
                              setFormDemoIncludedAccounts(value);
                            }
                          } else {
                            setErrorDemoIncludedAccounts(true);
                          }
                        } else {
                          setErrorDemoIncludedAccounts(false);
                          values.permission_demo_included_accounts = value;
                          setFormDemoIncludedAccounts(value);
                        }
                      }}
                      options={["Plan default value", "Unlimited"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_demo_included_accounts"
                          )}
                          error={Boolean(
                            touched.permission_demo_included_accounts &&
                              errors.permission_demo_included_accounts
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.permission_demo_included_account_changes &&
                              errors.permission_demo_included_account_changes
                          ) === true || errorDemoIncludedAccountChanges === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.permission_demo_included_account_changes &&
                          errors.permission_demo_included_account_changes
                      ) === true || errorDemoIncludedAccountChanges === true
                        ? "Invalid included demo trading account changes"
                        : "Included demo trading account changes"}
                    </Typography>

                    <Autocomplete
                      fullWidth
                      freeSolo
                      value={formDemoIncludedAccountChanges}
                      size="small"
                      onInputChange={(event, value) => {
                        if (value === null) {
                          setErrorDemoIncludedAccountChanges(false);
                          values.permission_demo_included_account_changes =
                            "Plan default value";
                          setFormDemoIncludedAccountChanges(
                            "Plan default value"
                          );
                        } else if (
                          value !== "Plan default value" &&
                          value !== "Unlimited"
                        ) {
                          const isNumber = /^\d+$/.test(value);
                          if (isNumber === true) {
                            if (Number(value) < 0) {
                              setErrorDemoIncludedAccountChanges(true);
                            } else {
                              setErrorDemoIncludedAccountChanges(false);
                              values.permission_demo_included_account_changes =
                                value;
                              setFormDemoIncludedAccountChanges(value);
                            }
                          } else {
                            setErrorDemoIncludedAccountChanges(true);
                          }
                        } else {
                          setErrorDemoIncludedAccountChanges(false);
                          values.permission_demo_included_account_changes =
                            value;
                          setFormDemoIncludedAccountChanges(value);
                        }
                      }}
                      options={["Plan default value", "Unlimited"]}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps(
                            "permission_demo_included_account_changes"
                          )}
                          error={Boolean(
                            touched.permission_demo_included_account_changes &&
                              errors.permission_demo_included_account_changes
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Modify
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
