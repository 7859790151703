// react and js
import * as Yup from "yup";
import { React, useContext, useState, useRef, useEffect } from "react";
import {
  Link as RouterLink,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

import { useFormik, Form, FormikProvider } from "formik";

// mui
import {
  Link,
  IconButton,
  InputAdornment,
  Collapse,
  Card,
  Box,
  Typography,
  Grid,
} from "@mui/material";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";

// hooks
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme
import {
  AuthenticationButton,
  AuthenticationTextField,
  AuthenticationAlertError,
  AuthenticationAlertSuccess,
} from "../../theme/styled";

// utils

// widgets

// app

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { stateStorage } = useContext(appContext);
  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );

  const wrapperRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const [timeNoDataCheck, setTimeNoDataCheck] = useState(0);

  const handleClick = () => {
    setError(false);
    setSuccess(false);
  };
  document.addEventListener("mousedown", handleClick, {
    passive: false,
    capture: false,
  });

  const NewPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("New password is required")
      .min(8, "Password must be 8 characters long")
      .max(64, "Password must be shorter than 64 characters")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter"),
    // .matches(/[^\w]/, 'Password requires a symbol')
    passwordRepeat: Yup.string()
      .required("Please repeat password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const [ModeChecked] = useState(
    searchParams.get("5c88da93a09cd871e902ea3daf8927d6")
  );
  const [DashboardIdChecked] = useState(
    searchParams.get("a96194a965bec98ffc3aeba420ff8e2f")
  );
  const [DashboardPasswordResetTokenChecked] = useState(
    searchParams.get("ccc8c5d2386123252eaaae2b47f6738f")
  );

  useEffect(() => {
    const timerID = setTimeout(() => {
      setTimeNoDataCheck(timeNoDataCheck + 1);
    }, 50);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeNoDataCheck]);

  useEffect(() => {
    if (ModeChecked === null) {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeNoDataCheck]);

  const handleNewPassword = (values, setSubmitting) => {
    if (navigator.onLine === true) {
      setError(false);
      setSuccess(false);

      if (ModeChecked !== undefined && ModeChecked !== null) {
        const sendData = {
          request_type: "dashboard",
          route_info: "authentication",
          client: {
            mode: "new_password",
            password: values.password,
            dashboard_id: DashboardIdChecked,
            dashboard_password_reset_token: DashboardPasswordResetTokenChecked,
          },
        };

        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("answer");

            if (checkKey === true) {
              if (contentFromBackend.answer.message === "success") {
                setReturnMessage("New password was set successfully!");
                setSuccess(true);
                setError(false);
              } else {
                setReturnMessage("Invalid request!");
                setError(true);
              }
            } else {
              setReturnMessage("Invalid request!");
              setError(true);
            }
            setSubmitting(false);
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setReturnMessage("Unknown error!");
              setError(true);
              setSubmitting(false);
            }
          });
      } else {
        setReturnMessage("Invalid request!");
        setError(true);
        setSubmitting(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordRepeat: "",
    },
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleNewPassword(values, setSubmitting);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        direction="column"
        alignItems="center"
        sx={{
          width: {
            xs: "95vw",
            sm: "70vw",
            md: "50vw",
            lg: "40vw",
            xl: "35vw",
          },
        }}
      >
        <Card
          sx={{
            background: "#F2F2F2",
            borderColor: "#6D6E70",
            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
          }}
        >
          <Box sx={{ p: 4 }} dir="ltr">
            <Typography
              variant="h2"
              sx={{
                mt: 0,
                mb: 0,
                pl: 2,
                borderLeft: 1,
                borderWidth: "3px",
                borderColor: "#FF9600",
                borderRadius: "0px 0px 0px 0px",
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)",
              }}
            >
              New Password
            </Typography>

            <Box sx={{ mt: 1, mb: 2 }} dir="ltr">
              <Typography
                variant="contentSmall"
                sx={{ mt: 1, textAlign: "center" }}
              >
                Please enter your new password below.
              </Typography>
            </Box>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(touched.password && errors.password) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.password && errors.password) === true
                    ? errors.password
                    : "New password"}
                </Typography>

                <AuthenticationTextField
                  autoComplete="new-password" // Avoids chrome warning
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography
                  variant="contentSmall"
                  sx={{
                    color:
                      Boolean(
                        touched.passwordRepeat && errors.passwordRepeat
                      ) === true
                        ? "red"
                        : "none",
                  }}
                >
                  {Boolean(touched.passwordRepeat && errors.passwordRepeat) ===
                  true
                    ? errors.passwordRepeat
                    : "Repeat new password"}
                </Typography>

                <AuthenticationTextField
                  autoComplete="new-password" // Avoids chrome warning
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("passwordRepeat")}
                  error={Boolean(
                    touched.passwordRepeat && errors.passwordRepeat
                  )}
                  sx={{ mt: "4px", mb: 1 }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Collapse
                  ref={wrapperRef}
                  in={error}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertError
                    severity="error"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertError>
                </Collapse>

                <Collapse
                  ref={wrapperRef}
                  in={success}
                  timeout="auto"
                  unmountOnExit
                >
                  <AuthenticationAlertSuccess
                    severity="success"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {returnMessage}
                  </AuthenticationAlertSuccess>
                </Collapse>

                <Box sx={{ textAlign: "center", mt: 1, mb: 1 }}>
                  {!(
                    stateStorage.authenticated === true &&
                    myLocalStateStorage !== null
                  ) ? (
                    <Typography variant="contentSmall">
                      <Link
                        component={RouterLink}
                        to="/login"
                        underline="none"
                        variant="contentSmallLink"
                        color="inherit"
                      >
                        Back to login
                      </Link>
                    </Typography>
                  ) : null}

                  {stateStorage.authenticated === true &&
                  myLocalStateStorage !== null ? (
                    <Typography variant="contentSmall">
                      <Link
                        component={RouterLink}
                        to="/"
                        underline="none"
                        variant="contentSmallLink"
                        color="inherit"
                      >
                        Back to dashboard
                      </Link>
                    </Typography>
                  ) : null}
                </Box>

                <AuthenticationButton
                  fullWidth
                  variant="contained"
                  loading={isSubmitting}
                  type="submit"
                  sx={{ mt: 1 }}
                >
                  Set new password
                </AuthenticationButton>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}
