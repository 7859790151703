// react and js
import { useEffect } from "react";

// mui

// components
import { DownloadInvoice } from "../../components/AccountingDownloads/AccountingDownloads";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  
  useEffect(() => {
    // Action mode
    let myLocalAction = sessionStorage.getItem("myLocalAction");
    if (myLocalAction != null) {
      myLocalAction = JSON.parse(myLocalAction);
      if (myLocalAction.actionType === "invoice_data") {
        sessionStorage.removeItem("myLocalAction");

        let dataError = false;

        if (myLocalAction.billingId === null) {
          dataError = true;
        }

        if (myLocalAction.passPhrase1 === null) {
          dataError = true;
        } else if (
          myLocalAction.passPhrase1 !== "2146560ef5d518512c53ecb5caebe289"
        ) {
          dataError = true;
        }

        if (myLocalAction.passPhrase2 === null) {
          dataError = true;
        } else if (
          myLocalAction.passPhrase2 !== "75db178026ae7ce09f00b2ce6a99a62c"
        ) {
          dataError = true;
        }

        if (myLocalAction.passPhrase3 === null) {
          dataError = true;
        } else if (
          myLocalAction.passPhrase3 !== "9b948cde05822ee0eb7b5922976ed9b2"
        ) {
          dataError = true;
        }

        if (myLocalAction.passPhrase4 === null) {
          dataError = true;
        } else if (
          myLocalAction.passPhrase4 !== "9f2867705fdf152dccfeb0eaa1f18609"
        ) {
          dataError = true;
        }

        if (myLocalAction.passPhrase5 === null) {
          dataError = true;
        } else if (
          myLocalAction.passPhrase5 !== "639d199155d5022ae44227e31f3aa6d2"
        ) {
          dataError = true;
        }

        if (dataError === false) {

          const data = [
            myLocalAction.billingId,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            myLocalAction.passPhrase1,
            myLocalAction.passPhrase2,
            myLocalAction.passPhrase3,
            myLocalAction.passPhrase4,
            myLocalAction.passPhrase5,
          ];

          DownloadInvoice(data);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Outlet
  // ######################################################
  return null;
}
