// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// mui
import {
  Grid,
  Box,
  FormControl,
  Typography,
  Stack,
  Link,
  Select,
  NativeSelect,
} from "@mui/material";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  CardOutlinedInput,
  CardMenuItem,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value === "1") {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "0") {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }

    if (value === "extended_client_data_missing") {
      valueOutlet = (
        <Typography variant="contentSmall" align="right">
          Missing
        </Typography>
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function DownloadItem({ content }) {
  DownloadItem.propTypes = {
    content: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: "0px",
          border: "1px solid #E0E0E0",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Stack>
          <Box
            sx={{ backgroundColor: "#F2F2F2" }}
            display="block"
            align="center"
          >
            <Typography
              variant="h5"
              display="block"
              align="center"
              sx={{ my: "14px" }}
            >
              <Link
                variant="h5"
                underline="none"
                color="inherit"
                target="_blank"
                href={content.home_url}
              >
                {content.name}
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              background: "white",
              p: 2,
            }}
          >
            <ValueLine
              parameter="Type"
              value="executable installer"
              widthLeft="50%"
              widthRight="50%"
            />

            <ValueLine
              parameter="File size"
              value={formatBytes(content.file_size)}
              widthLeft="50%"
              widthRight="50%"
            />

            <ValueLine
              parameter="MT4 compactibility"
              value={content.mt4_compactibility}
              widthLeft="80%"
              widthRight="20%"
            />

            <ValueLine
              parameter="MT5 compactibility"
              value={content.mt5_compactibility}
              widthLeft="80%"
              widthRight="20%"
            />

            {content.password !== "none" ? (
              <ValueLine
                parameter="Password"
                value={content.password}
                widthLeft="25%"
                widthRight="75%"
              />
            ) : null}

            <Box sx={{ mt: 2 }} />

            {content.getting_started_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content.getting_started_url}
              >
                Getting started
              </B3Tiny>
            ) : null}

            {content.user_manual_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content.user_manual_url}
              >
                User manual
              </B3Tiny>
            ) : null}

            {content.tutorial_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content.tutorial_url}
              >
                Examples and tutorials
              </B3Tiny>
            ) : null}

            {content.historical_performance_url !== null ? (
              <B3Tiny
                fullWidth
                sx={{ mb: 1 }}
                variant="outlined"
                target="_blank"
                href={content.historical_performance_url}
              >
                Historical performance
              </B3Tiny>
            ) : null}

            <B1Tiny
              fullWidth
              variant="contained"
              href={`${process.env.REACT_APP_URL_API_DOWNLOAD}/?tag=${content.tag}&token=${stateStorage.userInformation.answer.dashboard_access_token}`}
            >
              Download
            </B1Tiny>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let keys = "";
  let checkKey = "";

  if (content === undefined || content === null) {
    checkKey = false;
  } else {
    keys = Object.keys(content);
    checkKey = keys.includes("applications");
  }

  keys = Object.keys(content.applications);
  const versions = [];

  for (let index = 0; index < keys.length; index += 1) {
    versions.push(keys[index]);
  }

  if (versions.length === 0) {
    checkKey = false;
  }

  let downloadApplicationVersionInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs203515b7ac68d72a !== null &&
      myLocalSettingStorage.obfs203515b7ac68d72a !== undefined
    ) {
      if (
        Number(myLocalSettingStorage.obfs203515b7ac68d72a) < versions.length
      ) {
        downloadApplicationVersionInitValue =
          myLocalSettingStorage.obfs203515b7ac68d72a;
      }
    }
  }

  const [selectedVersion, setSelectedVersion] = useState(
    downloadApplicationVersionInitValue
  );

  if (
    selectedVersion >= versions.length &&
    selectedVersion !== versions.length
  ) {
    setSelectedVersion(0);
  }

  return checkKey ? (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 0, mb: 0 }}>
          <CardOutlet
            variant={versions.length > 1 ? "outlet" : "description"}
            backgroundProp="#F2F2F2"
            title="Applications Downloads"
            subheader="Below, you will find installers for all applications that you are currently authorized to use. Use the version selector to download the most appropriate version. We always recommend using the latest version."
            mainOutlet={
              isDesktop ? (
                <Box>
                  <Typography variant="contentSmall">Version</Typography>
                  <FormControl fullWidth>
                    <Select
                      value={selectedVersion}
                      onChange={(event) => {
                        setSelectedVersion(event.target.value);

                        const newLocalSettingStorage = JSON.parse(
                          localStorage.getItem("myLocalSettingStorage")
                        );

                        newLocalSettingStorage.obfs203515b7ac68d72a =
                          event.target.value;
                        localStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      input={<CardOutlinedInput />}
                    >
                      {versions.map((currentVersion, index) => (
                        <CardMenuItem key={currentVersion} value={index}>
                          {currentVersion.replace("_", ".")}
                        </CardMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Box>
                  <Typography variant="contentSmall">Version</Typography>
                  <FormControl fullWidth>
                    <NativeSelect
                      value={selectedVersion}
                      onChange={(event) => {
                        setSelectedVersion(event.target.value);

                        const newLocalSettingStorage = JSON.parse(
                          localStorage.getItem("myLocalSettingStorage")
                        );

                        newLocalSettingStorage.obfs203515b7ac68d72a =
                          event.target.value;
                        localStorage.setItem(
                          "myLocalSettingStorage",
                          JSON.stringify(newLocalSettingStorage)
                        );
                      }}
                      input={<CardOutlinedInput />}
                    >
                      {versions.map((currentVersion, index) => (
                        <option key={currentVersion} value={index}>
                          {currentVersion.replace("_", ".")}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Box>
              )
            }
          />
        </Box>
      </Grid>

      {versions[selectedVersion] !== undefined
        ? content.applications[versions[selectedVersion]].map(
            (currentDownloadItem) => (
              <DownloadItem
                key={currentDownloadItem.tag}
                content={currentDownloadItem}
              />
            )
          )
        : null}
    </Grid>
  ) : (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 0, mb: 0 }}>
          <CardOutlet
            variant="description"
            backgroundProp="#F2F2F2"
            title="Downloads"
            subheader="Below you can find all installers for your subscribed applications."
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 0, mb: 0 }}>
          <Box
            display="block"
            align="center"
            sx={{
              borderRadius: "0px",
              border: "1px solid #E0E0E0",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
            }}
          >
                <Typography
                  variant="contentBig"
                  display="block"
                  align="center"
                  sx={{ m: "14px" }}
                >
                  No application subscription active ...
                  <Typography variant="contentBigBold">
                    {/* eslint-disable-next-line */}
                    <Link
                      href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
                      target="_blank"
                      underline="none"
                      variant="contentBigLink"
                      color="inherit"
                    >
                      {` discover application plans`}
                    </Link>
                  </Typography>
                  .
                </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateDownloadsApplications: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "downloads",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        // eslint-disable-next-line no-console
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDownloadsApplications) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDownloadsApplications]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDownloadsApplications);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Downloads" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Downloads" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Downloads" />;
  } else if (content) {
    WidgetOutlet = <WidgetMainContentProcessed content={content} />;
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
