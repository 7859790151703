// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Grid,
  Container,
  Box,
  FormControl,
  Typography,
  Stack,
  Select,
  NativeSelect,
  Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
import CardOutlet from "../../components/CardOutlet";
//
import {
  SubscriptionItem,
  LimitationsItem,
  AccountItem,
} from "../../components/SubscriptionItem";

// hooks
import promotionPartnersHook from "../../hooks/promotionPartnersHook";
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Small,
  CardOutlinedInput,
  CardMenuItem,
  ModalAlertWarning,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function PromotionPartnerItem({ promotionPartner }) {
  PromotionPartnerItem.propTypes = {
    promotionPartner: PropTypes.any.isRequired,
  };

  let promotionPartnersHookIndex = 0;
  promotionPartnersHook.forEach((promotionPartnerHook, index) => {
    if (
      promotionPartnerHook.broker_id ===
      promotionPartner.promotion_broker_details.broker_id
    ) {
      promotionPartnersHookIndex = index;
    }
  });

  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Stack>
          <Box
            sx={{ py: 2, backgroundColor: "#F2F2F2" }}
            display="block"
            align="center"
          >
            <Typography
              variant="h3"
              display="block"
              align="center"
              sx={{ lineHeight: 1 }}
            >
              {promotionPartner.promotion_broker_details.broker_name}
            </Typography>
          </Box>

          <Grid container spacing={0} sx={{ p: 3, background: "white" }}>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              xs={12}
              md={4}
              lg={4}
            >
              <a
                href={`${process.env.REACT_APP_URL_API_LINK_ROTATOR}?broker_id=${promotionPartner.promotion_broker_details.broker_id}`}
                rel="noreferrer"
                target="_blank"
              >
                <Box
                  component="img"
                  src={
                    promotionPartnersHook[promotionPartnersHookIndex].imageSrc
                  }
                  sx={{
                    p: 3,
                    width: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </a>
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ p: 3, height: "100%" }}
              >
                <Typography
                  variant="contentMiddle"
                  display="block"
                  align="center"
                >
                  {
                    promotionPartnersHook[promotionPartnersHookIndex]
                      .description
                  }
                </Typography>

                {promotionPartnersHook[promotionPartnersHookIndex].features
                  .length !== 0 ? (
                  <Typography variant="contentMiddle">
                    <Stack spacing={1} sx={{ mt: 2 }}>
                      {promotionPartnersHook[
                        promotionPartnersHookIndex
                      ].features.map((feature, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Iconify
                            color="#61CE70"
                            icon="eva:checkmark-outline"
                            minWidth={25}
                            minHeight={25}
                            sx={{ mr: 1 }}
                          />
                          {feature}
                        </Box>
                      ))}
                    </Stack>
                  </Typography>
                ) : null}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: 0 }}>
              <B1Small
                fullWidth
                variant="contained"
                href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/promotion?broker_id=${promotionPartner.promotion_broker_details.broker_id}&email=${myLocalStateStorage.account_settings.config_data.config_data_client.email}&dashboard_id=${myLocalStateStorage.account_settings.config_data.config_data_client.dashboard_id}&dashboard_access_token=${myLocalStateStorage.answer.dashboard_access_token}&dashboard_access_token_expire=${myLocalStateStorage.answer.dashboard_access_token_expire}`}
                target="_blank"
              >
                Start with promotion partner
              </B1Small>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Grid>
  );
}

function PromotionSubscriptionItem({ content }) {
  PromotionSubscriptionItem.propTypes = {
    content: PropTypes.any.isRequired,
  };

  let checkKey = false;
  let isOnlyOneAccountLeft = false;

  if (content !== undefined) {
    const keyOnly = Object.keys(content);

    if (keyOnly.includes("accounts") === true) {
      if (content.accounts.length !== 0) {
        checkKey = true;
      }
      if (content.accounts.length === 1) {
        isOnlyOneAccountLeft = true;
      }
    }
  }

  return content.product.length !== 0 &&
    content.plan.length !== 0 &&
    content.accounts.length !== 0 ? (
    <Grid item xs={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box>
          {content.product.product_tag !== "mt5_tick_data" ? (
            <>
              <SubscriptionItem
                sx={{ px: 0 }}
                mode="promotion"
                content={content}
                managed={Boolean(false)}
              />
              <LimitationsItem
                sx={{ px: 2 }}
                mode="promotion"
                content={content}
                managed={Boolean(false)}
              />
            </>
          ) : (
            <> </>
          )}
        </Box>
        {checkKey ? (
          <Stack>
            {content.accounts.map((currentAccount, index) => (
              <AccountItem
                mode="promotion"
                currentAccount={currentAccount}
                key={index}
                content={content}
                managed={Boolean(false)}
                isOnlyOneAccountLeft={isOnlyOneAccountLeft}
                isSponsored={Boolean(false)}
                plan={content.plan}
              />
            ))}
          </Stack>
        ) : (
          <> </>
        )}
      </Box>
    </Grid>
  ) : null;
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let promotionPartnerInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsb16ea0b10c32f5c5 !== null &&
      myLocalSettingStorage.obfsb16ea0b10c32f5c5 !== undefined
    ) {
      if (
        Number(myLocalSettingStorage.obfsb16ea0b10c32f5c5) <
        content.promotion.length
      ) {
        promotionPartnerInitValue = myLocalSettingStorage.obfsb16ea0b10c32f5c5;
      }
    }
  }

  const isntZeros = [];

  content.promotion.forEach((partner, index) => {
    if (partner.subscriptions[0].accounts.length !== 0) {
      isntZeros.push(index);
    }
  });

  if (isntZeros.length === 1) {
    promotionPartnerInitValue = isntZeros[0];
  } else if (isntZeros.length > 1) {
    if (!isntZeros.includes(Number(promotionPartnerInitValue))) {
      promotionPartnerInitValue = isntZeros[0];
    }
  }

  const [selectedPromotionPartner, setSelectedPromotionPartner] = useState(
    promotionPartnerInitValue
  );

  let checkKey = true;
  if (content === undefined || content === null) {
    checkKey = false;
  }

  const promotionPartners = [];
  if (checkKey) {
    for (let index = 0; index < content.promotion.length; index += 1) {
      promotionPartners.push(
        content.promotion[index].promotion_broker_details.broker_name
      );
    }
  }
  if (promotionPartners.length === 0) {
    checkKey = false;
  }

  let allZero = true;
  let pendingApproval = false;
  for (
    let index = 0;
    index < content.promotion[selectedPromotionPartner].subscriptions.length;
    index += 1
  ) {
    if (
      content.promotion[selectedPromotionPartner].subscriptions[index].product
        .length !== 0
    )
      allZero = false;
    if (
      content.promotion[selectedPromotionPartner].subscriptions[index].plan
        .length !== 0
    )
      allZero = false;
    if (
      content.promotion[selectedPromotionPartner].subscriptions[index].accounts
        .length !== 0
    )
      allZero = false;

    for (
      let accountIndex = 0;
      accountIndex <
      content.promotion[selectedPromotionPartner].subscriptions[index].accounts
        .length;
      accountIndex += 1
    ) {
      if (
        content.promotion[selectedPromotionPartner].subscriptions[index]
          .accounts[accountIndex].payment_state === "promotion_approval_pending"
      ) {
        pendingApproval = true;
      }
    }
  }

  const [timeCounterSlider, setTimeCounterSlider] = useState(0);
  const [sliderStatus, setSliderStatus] = useState(false);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setSliderStatus(true);
    }, 80);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterSlider]);

  return checkKey ? (
    <Grid container spacing={3} sx={{ p: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ mt: 0, mb: 0 }}>
          <CardOutlet
            variant="outlet"
            backgroundProp="#F2F2F2"
            title="Available Promotion Partners"
            subheader="Start with a promotion partner from the selection below to acquire free promotion subscriptions for our products."
            mainOutlet={
              <>
                {isDesktop ? (
                  <Box>
                    <Typography variant="contentSmall">
                      Promotion partner
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        value={selectedPromotionPartner}
                        onChange={(event) => {
                          setSliderStatus(false);
                          setTimeCounterSlider(timeCounterSlider + 1);

                          setSelectedPromotionPartner(event.target.value);

                          const newLocalSettingStorage = JSON.parse(
                            localStorage.getItem("myLocalSettingStorage")
                          );

                          newLocalSettingStorage.obfsb16ea0b10c32f5c5 =
                            event.target.value;
                          localStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                        input={<CardOutlinedInput />}
                      >
                        {promotionPartners.map((promotionPartner, index) => (
                          <CardMenuItem key={index} value={index}>
                            {promotionPartner}
                          </CardMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="contentSmall">
                      Promotion partner
                    </Typography>
                    <FormControl fullWidth>
                      <NativeSelect
                        value={selectedPromotionPartner}
                        onChange={(event) => {
                          setSliderStatus(false);
                          setTimeCounterSlider(timeCounterSlider + 1);

                          setSelectedPromotionPartner(event.target.value);

                          const newLocalSettingStorage = JSON.parse(
                            localStorage.getItem("myLocalSettingStorage")
                          );

                          newLocalSettingStorage.obfsb16ea0b10c32f5c5 =
                            event.target.value;
                          localStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                        input={<CardOutlinedInput />}
                      >
                        {promotionPartners.map((promotionPartner, index) => (
                          <option key={index} value={index}>
                            {promotionPartner}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                )}

                {pendingApproval ? (
                  <ModalAlertWarning
                    severity="warning"
                    sx={{ mt: 3, lineHeight: 1.5 }}
                  >
                    The given trading account is not approved yet. Therefore the
                    applications won't be able to start. Be aware that the
                    approval may take up to 24 hours.
                  </ModalAlertWarning>
                ) : null}
              </>
            }
          />
        </Box>
      </Grid>
      {content.promotion[selectedPromotionPartner].subscriptions.map(
        (currentPromotionSubscriptionItem, index) => (
          <PromotionSubscriptionItem
            key={index}
            content={currentPromotionSubscriptionItem}
          />
        )
      )}

      {allZero ? (
        <Slide direction="up" in={sliderStatus}>
          <Grid item xs={12} md={12} lg={12}>
            {sliderStatus ? (
              <PromotionPartnerItem
                promotionPartner={content.promotion[selectedPromotionPartner]}
              />
            ) : null}
          </Grid>
        </Slide>
      ) : null}
    </Grid>
  ) : (
    <> </>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdatePromotionPromotions: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "promotion",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdatePromotionPromotions) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdatePromotionPromotions]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalPromotionPromotions);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Promotions" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Promotions" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Promotions" />;
  } else if (content) {
    WidgetOutlet = <WidgetMainContentProcessed content={content} />;
  }

  // ######################################################
  // Outlet
  // ######################################################
  return (
    <Container maxWidth="false">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          {WidgetOutlet}
        </Grid>
      </Grid>
    </Container>
  );
}
