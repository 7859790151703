// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Box,
  Stack,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { B3Tiny } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function FetchTable({ tableArray }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
  };

  const contentFiltered = [];
  let contentLastLine = null;

  tableArray.forEach((line, index) => {
    if (index < tableArray.length - 1) {
      contentFiltered.push(line);
    }
    if (index === tableArray.length - 1) {
      contentLastLine = line;
    }
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}>
              <Typography variant="contentSmallBold">Product</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Demo </Typography>
              <br />
              <Typography variant="contentSmall">
                current / assigned / max
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Live </Typography>
              <br />
              <Typography variant="contentSmall">
                current / assigned / max
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contentFiltered.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall">
                  {row.product_name}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {`${row.contingent_demo_current} / ${row.contingent_demo_assigned} / ${row.contingent_demo_max}`}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {`${row.contingent_live_current} / ${row.contingent_live_assigned} / ${row.contingent_live_max}`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              align="left"
            >
              <Typography variant="contentSmallBold">
                {contentLastLine.product_name}
              </Typography>
            </TableCell>

            <TableCell
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              align="center"
            >
              <Typography variant="contentSmallBold">
                {`${contentLastLine.contingent_demo_current} / ${contentLastLine.contingent_demo_assigned} / ${contentLastLine.contingent_demo_max}`}
              </Typography>
            </TableCell>

            <TableCell
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              align="center"
            >
              <Typography variant="contentSmallBold">
                {`${contentLastLine.contingent_live_current} / ${contentLastLine.contingent_live_assigned} / ${contentLastLine.contingent_live_max}`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.map((row, index) => (
        <Stack
          key={index}
          spacing="3px"
          sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "50%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Product </Typography>
            </Box>
            <Box sx={{ width: "50%", pl: 1 }}>
              <Typography variant="contentSmall">{row.product_name}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "50%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Live </Typography>
              <br />
              <Typography variant="contentSmall">
                current / assigned / max
              </Typography>
            </Box>
            <Box sx={{ width: "50%", pl: 1 }}>
              <br />
              <Typography variant="contentSmall">
                {`${row.contingent_demo_current} / ${row.contingent_demo_assigned} / ${row.contingent_demo_max}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "50%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Demo </Typography>
              <br />
              <Typography variant="contentSmall">
                current / assigned / max
              </Typography>
            </Box>
            <Box sx={{ width: "50%", pl: 1 }}>
              <br />
              <Typography variant="contentSmall">
                {`${row.contingent_live_current} / ${row.contingent_live_assigned} / ${row.contingent_live_max}`}
              </Typography>
            </Box>
          </Box>
        </Stack>
      ))}
    </>
  );
}

function WidgetMainContentProcessed({ content, distributor }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    distributor: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Contingent"
      subheader="Below is an overview of your allocated contingents."
      mainOutlet={
        <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
          {isDesktop === false ? (
            <B3Tiny
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
              onClick={() => {
                setMobileCollapse(!mobileCollapse);
              }}
            >
              {mobileCollapse ? "Close" : "Open"}
            </B3Tiny>
          ) : null}

          <Collapse
            sx={{ mx: -1, px: 1 }}
            in={mobileCollapse}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FetchTable
                  tableArray={content.contingent}
                  distributor={distributor}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  distributor,
  loadingWidgetContingents,
  setLoadingWidgetContingents,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    distributor: PropTypes.any.isRequired,
    loadingWidgetContingents: PropTypes.any.isRequired,
    setLoadingWidgetContingents: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalContingents: false,
          }));

          setLoading(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "contingent",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "",
            page_order_by: "",
          },
          filters: {},
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetContingents(false);
                setContent(contentFromBackend);
              } else if (
                contentFromBackend.message.includes("no application distributi")
              ) {
                setSuccess(true);
                setContent(null);
                setLoading(false);
                setLoadingWidgetContingents(false);
                setReturnMessage(
                  "No application distribution dustomers found yet! Wait for them or add a few ..."
                );
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setLoadingWidgetContingents(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify("")
                );
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setLoadingWidgetContingents(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetContingents(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetContingents(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalContingents) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalContingents]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalContingents);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <Box sx={{ mt: 3 }}>
      <MissingContent message="Contingent" />
    </Box>
  );
  if (loading || loadingWidgetContingents) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <LoadingAnimation title="Contingent" />
      </Box>
    );
  } else if (error) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <MissingContent message="Contingent" />
      </Box>
    );
  } else if (content) {
    WidgetOutlet = (
      <Box sx={{ mt: 3 }}>
        <WidgetMainContentProcessed
          content={content}
          distributor={distributor}
        />
      </Box>
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
