// react and js
import {
  Navigate,
  useRoutes,
  useLocation,
  useNavigate,
  useSearchParams,
  // useNavigationType,
} from "react-router-dom";

import { React, useContext, useEffect, useState } from "react";

// mui

// components
import backendRequest from "./components/BackendRequest";

// hooks
import features from "./hooks/features";
import appContext from "./hooks/appContext";
import { stateStorageInit } from "./hooks/stateStorageInit";

// layouts
import Private from "./layouts/private/Private";
import Public from "./layouts/Public";

// pages
import Login from "./pages/login/Login";
import PasswordReset from "./pages/password_reset/PasswordReset";
import NewPassword from "./pages/new_password/NewPassword";
import AccountSettings from "./pages/account_settings/AccountSettings";
import Promotion from "./pages/promotion/Promotion";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import PromoterPortal from "./pages/promoter_portal/PromoterPortal";
import DistributorPortal from "./pages/distributor_portal/DistributorPortal";
import InvoicePrinter from "./pages/invoice_printer/InvoicePrinter";

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
localStorage.setItem("myReauthentication", false);

function getFeaturesList(stateStorage) {
  const featureList = [];

  // Account settings
  featureList.push({ path: "account-settings", element: <AccountSettings /> });

  // Start page
  if (stateStorage.userInformation !== "") {
    if (stateStorage.userInformation.account_settings.promotion_mode === true) {
      featureList.push({ path: "/", element: <Promotion /> });
    } else {
      featureList.push({ path: "/", element: <Subscriptions /> });
    }
  } else {
    featureList.push({ path: "/", element: <Subscriptions /> });
  }

  featureList.push({
    path: "/promoter-portal/:dashboard_id",
    element: <PromoterPortal />,
  });
  featureList.push({
    path: "/distributor-portal/application/:dashboard_id",
    element: <DistributorPortal />,
  });
  featureList.push({
    path: "/distributor-portal/tick-data/:dashboard_id",
    element: <DistributorPortal />,
  });

  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );

  if (stateStorage.authenticated === true && myLocalStateStorage !== null) {
    featureList.push({
      path: "/new-password",
      element: <NewPassword />,
    });
  }

  if (stateStorage.authenticated === true && myLocalStateStorage !== null) {
    featureList.push({
      path: "/invoice-printer",
      element: <InvoicePrinter />,
    });
  }

  features.forEach((element) => {
    featureList.push(element.route);
  });

  return featureList;
}

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Router() {
  const { stateStorage, setStateStorage } = useContext(appContext);

  const [timeCounterRouterUpdate, setTimeCounterRouterUpdate] = useState(0);
  const [timeCounterLayoutUpdate, setTimeCounterLayoutUpdate] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // const navigationType = useNavigationType();

  // ######################################################
  // Resetting of last activity
  // ######################################################
  const onMouseMove = () => {
    const TimestampSecond = Math.round(Date.now() / 1000);
    localStorage.setItem("lastActivity", JSON.stringify(TimestampSecond));
  };
  window.addEventListener("mousemove", onMouseMove, {
    passive: false,
    capture: false,
  });

  // ######################################################
  // Browser navigation detection
  // ######################################################
  let isReloaded = false;

  useEffect(() => {
    // eslint-disable-next-line
    isReloaded = true;
    return () => {
      window.onbeforeunload = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // History broswer handling
  // ######################################################
  /*
  Idea: If browser reload, then preload myLocalLastRouteStack to
  keep back navigation of browser. As of 2023, BroswerRouter hasn't
  implemented any option to preload a history stack.

  useEffect(() => {
    const TimestampSecond = Math.round(Date.now() / 1000);
    localStorage.setItem("lastActivity", JSON.stringify(TimestampSecond));

    const myLocalLastRoute = JSON.parse(sessionStorage.getItem("myLocalLastRoute"));

    if (location.pathname !== "/login") {
      sessionStorage.setItem("myLocalLastRoute",JSON.stringify(location.pathname));
    }

    if(navigationType === "POP")
    {
      if (myLocalLastRoute === location.pathname) 
      {
        console.log("POP - BROWSER RELOAD BUTTON!");
        const currentMyLocalLastRouteStack = JSON.parse(sessionStorage.getItem("myLocalLastRouteStack"));
        console.log("currentMyLocalLastRouteStack = ", currentMyLocalLastRouteStack);
      }
    }

    // listen for mouse events
    window.addEventListener("mousemove", onMouseMove, {passive: false, capture: false});

  }, [location]);
  */

  // ######################################################
  // Properly determine start page
  // ######################################################
  const startPage = () => {
    const myLocalStateStorage = JSON.parse(
      localStorage.getItem("myLocalStateStorage")
    );

    if (myLocalStateStorage !== null) {
      if (myLocalStateStorage.account_settings !== undefined) {
        return myLocalStateStorage.account_settings.promotion_mode ? (
          <Navigate to="/promotion" />
        ) : (
          <Navigate to="/subscriptions" />
        );
      }
    }
    return null;
  };

  // ######################################################
  // Dashboard refresh method
  // ######################################################
  function refreshDashboardFeatures(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      const myLocalStateStorage = JSON.parse(
        localStorage.getItem("myLocalStateStorage")
      );

      if (periodic === false) {
        // Reset update flag
        setStateStorage((previousState) => ({
          ...previousState,
          layoutUpdateFeatures: false,
        }));
      }

      // Code
      if (myLocalStateStorage !== null) {
        if (myLocalStateStorage.answer !== undefined) {
          if (myLocalStateStorage.answer.dashboard_access_token !== undefined) {
            const sendData = {
              request_type: "dashboard",
              route_info: "account_settings",
              operation: "get_essentials",
              client: {
                dashboard_access_token:
                  myLocalStateStorage.answer.dashboard_access_token,
              },
            };
            backendRequest(
              process.env.REACT_APP_URL_API_DASHBOARD,
              sendData
            ).then((contentFromBackend) => {
              const myLocalStateStorageInner = JSON.parse(
                localStorage.getItem("myLocalStateStorage")
              );

              if (myLocalStateStorageInner !== null) {
                const keyOnly = Object.keys(contentFromBackend);
                const checkKey = keyOnly.includes("message");

                if (checkKey === true) {
                  const newUserInformation = JSON.parse(
                    localStorage.getItem("myLocalStateStorage")
                  );
                  newUserInformation.account_settings.promotion_mode =
                    contentFromBackend.promotion_mode;
                  newUserInformation.account_settings.dashboard_features =
                    contentFromBackend.dashboard_features;
                  localStorage.setItem(
                    "myLocalStateStorage",
                    JSON.stringify(newUserInformation)
                  );

                  setStateStorage((previousState) => ({
                    ...previousState,
                    userInformation: newUserInformation,
                  }));
                }
              }
            });
          }
        }
      }
    }
  }

  // ######################################################
  // Dashboard logout method
  // ######################################################
  const handleLogout = () => {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      let dashboardAccessTokenCopy = null;
      if (stateStorage.userInformation !== "") {
        dashboardAccessTokenCopy =
          stateStorage.userInformation.answer.dashboard_access_token;
      }

      setStateStorage(stateStorageInit);

      localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
      localStorage.removeItem("myLocalStateStorage");

      sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
      sessionStorage.removeItem("myLocalLastRoute");

      localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
      localStorage.removeItem("myLocalSettingStorage");

      localStorage.setItem("myReauthentication", JSON.stringify(""));
      localStorage.removeItem("myReauthentication");

      if (dashboardAccessTokenCopy !== null) {
        const sendData = {
          request_type: "dashboard",
          route_info: "authentication",
          client: {
            mode: "logout",
            dashboard_access_token: dashboardAccessTokenCopy,
          },
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData);
      }
    }
  };

  // ######################################################
  // Dashboard reathenticate method
  // ######################################################
  const handleReathenticate = (debug) => {
    const myLocalStateStorage = JSON.parse(
      localStorage.getItem("myLocalStateStorage")
    );

    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      localStorage.setItem("myReauthentication", true);

      if (myLocalStateStorage !== null) {
        if (
          myLocalStateStorage.answer !== null &&
          myLocalStateStorage.answer !== undefined
        ) {
          if (
            myLocalStateStorage.answer.dashboard_access_token !== null &&
            myLocalStateStorage.answer.dashboard_access_token !== undefined
          ) {
            const sendData = {
              request_type: "dashboard",
              route_info: "authentication",
              client: {
                mode: "reauthenticate",
                dashboard_access_token:
                  myLocalStateStorage.answer.dashboard_access_token,
              },
            };
            backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
              .then((contentFromBackend) => {
                const keyOnly = Object.keys(contentFromBackend);
                const checkKey = keyOnly.includes("answer");

                if (checkKey === true) {
                  if (contentFromBackend.answer.message === "success") {
                    const myLocalStateStorageInner = JSON.parse(
                      localStorage.getItem("myLocalStateStorage")
                    );

                    if (myLocalStateStorageInner !== null) {
                      if (
                        myLocalStateStorageInner.userInformation !== "" &&
                        myLocalStateStorageInner.answer !== null &&
                        myLocalStateStorageInner.answer !== undefined
                      ) {
                        const DashboardAccessTokenExpire =
                          myLocalStateStorageInner.answer
                            .dashboard_access_token_expire;

                        const TimestampSecond = Math.round(Date.now() / 1000);
                        const ExpiryDiff =
                          DashboardAccessTokenExpire - TimestampSecond;

                        if (ExpiryDiff > 0) {
                          let compValue = 0;
                          if (debug === true) {
                            compValue = 3588;
                          } else {
                            compValue = 120;
                          }
                          if (ExpiryDiff < compValue) {
                            const newUserInformation = JSON.parse(
                              localStorage.getItem("myLocalStateStorage")
                            );
                            newUserInformation.answer.dashboard_access_token =
                              contentFromBackend.answer.dashboard_access_token;
                            newUserInformation.answer.dashboard_access_token_expire =
                              contentFromBackend.answer.dashboard_access_token_expire;
                            newUserInformation.dashboard_features =
                              contentFromBackend.dashboard_features;
                            localStorage.setItem(
                              "myLocalStateStorage",
                              JSON.stringify(newUserInformation)
                            );

                            setStateStorage((previousState) => ({
                              ...previousState,
                              userInformation: newUserInformation,
                            }));
                          }
                        }
                      }
                    }
                  }
                }

                localStorage.setItem("myReauthentication", false);
              })
              .catch(() => {
                if (navigator.onLine === true) {
                  localStorage.setItem("forcedLoggedOut", true);

                  localStorage.setItem(
                    "myLocalStateStorage",
                    JSON.stringify("")
                  );
                  localStorage.removeItem("myLocalStateStorage");

                  sessionStorage.setItem(
                    "myLocalLastRoute",
                    JSON.stringify("")
                  );
                  sessionStorage.removeItem("myLocalLastRoute");

                  localStorage.setItem(
                    "myLocalSettingStorage",
                    JSON.stringify("")
                  );
                  localStorage.removeItem("myLocalSettingStorage");

                  localStorage.setItem(
                    "myReauthentication",
                    JSON.stringify("")
                  );
                  localStorage.removeItem("myReauthentication");

                  localStorage.setItem("myReauthentication", false);
                }
              });
          }
        }
      }
    }
  };

  // ######################################################
  // Specific action based on route
  // ######################################################
  const actionTypeParam = searchParams.get("action_type");
  const dashboardIdParam = searchParams.get("dashboard_id");
  const distributionLinkingTokenParam = searchParams.get(
    "distribution_linking_token"
  );
  const newPlanIdParam = searchParams.get("new_plan_id");

  if (actionTypeParam != null) {
    // Verify parametere for action "reactivate_subscription" and "reactivate_mt5_tick_data_subscription"
    if (
      actionTypeParam === "reactivate_subscription" ||
      actionTypeParam === "reactivate_mt5_tick_data_subscription"
    ) {
      if (dashboardIdParam !== null && distributionLinkingTokenParam !== null) {
        sessionStorage.setItem(
          "myLocalAction",
          JSON.stringify({
            actionType: actionTypeParam,
            dashboardId: dashboardIdParam,
            distributionLinkingToken: distributionLinkingTokenParam,
          })
        );

        const myLocalStateStorage = JSON.parse(
          localStorage.getItem("myLocalStateStorage")
        );

        if (myLocalStateStorage !== null) {
          if (
            dashboardIdParam !==
            myLocalStateStorage.account_settings.config_data.config_data_client
              .dashboard_id
          ) {
            handleLogout();
          }
        }
      }
    }

    // Verify parametere for action "revise_subscription" and "revise_mt5_tick_data_subscription"
    if (
      actionTypeParam === "revise_subscription" ||
      actionTypeParam === "revise_mt5_tick_data_subscription"
    ) {
      if (
        dashboardIdParam !== null &&
        distributionLinkingTokenParam !== null &&
        newPlanIdParam !== null
      ) {
        sessionStorage.setItem(
          "myLocalAction",
          JSON.stringify({
            actionType: actionTypeParam,
            dashboardId: dashboardIdParam,
            distributionLinkingToken: distributionLinkingTokenParam,
            newPlanId: newPlanIdParam,
          })
        );

        const myLocalStateStorage = JSON.parse(
          localStorage.getItem("myLocalStateStorage")
        );

        if (myLocalStateStorage !== null) {
          if (
            dashboardIdParam !==
            myLocalStateStorage.account_settings.config_data.config_data_client
              .dashboard_id
          ) {
            handleLogout();
          }
        }
      }
    }

    // Verify parametere for action "invoice_data"
    if (actionTypeParam === "invoice_data") {
      const billingIdParam = searchParams.get("billing_id");
      const passPhrase1Param = searchParams.get("4143715f3bcb43e435ff7dd99dbd3c2b");
      const passPhrase2Param = searchParams.get("98f8dc2616fa9a1ab1173b3c7b7ebc13");
      const passPhrase3Param = searchParams.get("d39d73cd600910f63df219675de6b227");
      const passPhrase4Param = searchParams.get("5acd1fb0bf632b3e736053a790649223");
      const passPhrase5Param = searchParams.get("1efc9e08677afd9c51ed4b37c484db87");

      sessionStorage.setItem(
        "myLocalAction",
        JSON.stringify({
          actionType: actionTypeParam,
          billingId: billingIdParam,
          passPhrase1: passPhrase1Param,
          passPhrase2: passPhrase2Param,
          passPhrase3: passPhrase3Param,
          passPhrase4: passPhrase4Param,
          passPhrase5: passPhrase5Param,
        })
      );
    }
  }

  // ######################################################
  // Periodic call of dashboard feature update method
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setTimeCounterLayoutUpdate(timeCounterLayoutUpdate + 1);
    }, stateStorage.layoutUpdateIntervalFeatures);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterLayoutUpdate]);

  useEffect(() => {
    refreshDashboardFeatures(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterLayoutUpdate]);

  // ######################################################
  // Manual call of dashboard feature update method
  // ######################################################
  useEffect(() => {
    if (!stateStorage.layoutUpdateFeatures) return;

    refreshDashboardFeatures(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.layoutUpdateFeatures]);

  // ######################################################
  // Route tracker in session storage for reloads
  // ######################################################
  useEffect(() => {
    if (sessionStorage.getItem("myLocalLastRoute") === null) {
      sessionStorage.setItem(
        "myLocalLastRoute",
        JSON.stringify(location.pathname)
      );
    } else if (
      location.pathname !==
      sessionStorage.getItem("myLocalLastRoute").replaceAll('"', "")
    ) {
      sessionStorage.setItem(
        "myLocalLastRoute",
        JSON.stringify(location.pathname)
      );
    }

    if (sessionStorage.getItem("myLocalLastRouteStack") === null) {
      sessionStorage.setItem(
        "myLocalLastRouteStack",
        JSON.stringify([location.pathname])
      );
    } else {
      const StackSize = JSON.parse(
        sessionStorage.getItem("myLocalLastRouteStack")
      ).length;
      if (
        location.pathname !==
        JSON.parse(sessionStorage.getItem("myLocalLastRouteStack"))[
          StackSize - 1
        ]
      ) {
        const currentMyLocalLastRouteStack = JSON.parse(
          sessionStorage.getItem("myLocalLastRouteStack")
        );
        currentMyLocalLastRouteStack.push(location.pathname);
        if (StackSize >= 20) {
          currentMyLocalLastRouteStack.shift();
        }
        sessionStorage.setItem(
          "myLocalLastRouteStack",
          JSON.stringify(currentMyLocalLastRouteStack)
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // ######################################################
  // Router
  // ######################################################
  useEffect(() => {
    const myLocalStateStorage = JSON.parse(
      localStorage.getItem("myLocalStateStorage")
    );

    if (myLocalStateStorage !== null) {
      if (
        myLocalStateStorage.userInformation !== "" &&
        myLocalStateStorage.answer !== null &&
        myLocalStateStorage.answer !== undefined
      ) {
        const DashboardAccessTokenExpire =
          myLocalStateStorage.answer.dashboard_access_token_expire;
        const TimestampSecond = Math.round(Date.now() / 1000);
        const ExpiryDiff = DashboardAccessTokenExpire - TimestampSecond;

        const myLocalLastRoute = JSON.parse(
          sessionStorage.getItem("myLocalLastRoute")
        );

        // if (ExpiryDiff > 12) {
        if (ExpiryDiff > 120) {
          setStateStorage((previousState) => ({
            ...previousState,
            authenticated: true,
            userInformation: myLocalStateStorage,
          }));

          if (myLocalLastRoute !== null) {
            if (myLocalLastRoute !== "/login") {
              refreshDashboardFeatures(false);

              if (isReloaded === true) {
                navigate(myLocalLastRoute);
              }

              if (isReloaded === false) {
                if (myLocalLastRoute !== location.pathname) {
                  navigate(myLocalLastRoute);
                }
              }
            }
          }
        }
      }
    } else {
      setStateStorage(stateStorageInit);

      localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
      localStorage.removeItem("myLocalStateStorage");

      sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
      sessionStorage.removeItem("myLocalLastRoute");

      localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
      localStorage.removeItem("myLocalSettingStorage");

      localStorage.setItem("myReauthentication", JSON.stringify(""));
      localStorage.removeItem("myReauthentication");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterRouterUpdate]);

  // ######################################################
  // For auto reauthenticate and keeping user logged in
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      const myLocalStateStorage = JSON.parse(
        localStorage.getItem("myLocalStateStorage")
      );
      const lastActivity = JSON.parse(localStorage.getItem("lastActivity"));

      if (myLocalStateStorage !== null) {
        if (
          myLocalStateStorage.userInformation !== "" &&
          myLocalStateStorage.answer !== null &&
          myLocalStateStorage.answer !== undefined
        ) {
          const DashboardAccessTokenExpire =
            myLocalStateStorage.answer.dashboard_access_token_expire;

          const TimestampSecond = Math.round(Date.now() / 1000);
          const ExpiryDiff = DashboardAccessTokenExpire - TimestampSecond;
          const lastActivityDiff = TimestampSecond - lastActivity;

          const debug = false;

          if (debug === true) {
            /* eslint-disable */
            console.log("ExpiryDiff = ", ExpiryDiff);
            console.log("lastActivityDiff = ", lastActivityDiff);
            console.log(myLocalStateStorage.answer.dashboard_access_token);
            if (stateStorage.userInformation !== "") {
              console.log(
                stateStorage.userInformation.account_settings.config_data
                  .config_data_client.forename
              );
              console.log(
                stateStorage.userInformation.account_settings.config_data
                  .config_data_client.psp_paddle_address_id
              );
              console.log(
                stateStorage.userInformation.account_settings.config_data
                  .config_data_client.postal_code
              );
            }
            /* eslint-enable */
          }

          if (ExpiryDiff > 0) {
            if (debug === true) {
              /* eslint-disable */
              if (ExpiryDiff < 3588) {
                if (lastActivityDiff <= 600000000) {
                  console.log("handleReathenticate");
                  handleReathenticate(debug);
                }
              }

              if (ExpiryDiff <= 3582) {
                console.log("handleLogout");
                handleLogout();
              }
              /* eslint-enable */
            } else {
              if (ExpiryDiff < 120) {
                if (lastActivityDiff <= 3420) {
                  handleReathenticate(debug);
                }
              }
              if (ExpiryDiff <= 60) {
                handleLogout();
              }
            }
          }
        }
      }

      setTimeCounterRouterUpdate(timeCounterRouterUpdate + 1);
    }, 1000);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounterRouterUpdate]);

  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );

  // ######################################################
  // Router
  // ######################################################
  return useRoutes([
    {
      path: "/",
      element:
        stateStorage.authenticated === true && myLocalStateStorage !== null ? (
          // eslint-disable-next-line
          <>
            {location.pathname !== "/new-password" &&
            location.pathname !== "/invoice-printer" ? (
              <Private />
            ) : (
              <Public />
            )}
          </>
        ) : (
          <Navigate to="/login" />
        ),
      children: getFeaturesList(stateStorage),
    },
    {
      path: "/",
      element:
        stateStorage.authenticated === true && myLocalStateStorage !== null ? (
          <Private />
        ) : (
          <Public />
        ),
      children: [
        {
          path: "/",
          element:
            stateStorage.authenticated === true &&
            myLocalStateStorage !== null ? (
              startPage()
            ) : (
              <Login />
            ),
        },
        {
          path: "login",
          element:
            stateStorage.authenticated === true &&
            myLocalStateStorage !== null ? (
              startPage()
            ) : (
              <Login />
            ),
        },
        {
          path: "password-reset",
          element:
            stateStorage.authenticated === true &&
            myLocalStateStorage !== null ? (
              startPage()
            ) : (
              <PasswordReset />
            ),
        },
        {
          path: "new-password",
          element: <NewPassword />,
        },
        {
          path: "invoice-printer",
          element: <InvoicePrinter />,
        },
      ],
    },
    // { path: "*", element: <Navigate to="/login" replace /> },
    {
      path: "*",
      element:
        stateStorage.authenticated === true && myLocalStateStorage !== null ? (
          <Navigate to="/subscriptions" replace />
        ) : (
          <Navigate to="/login" replace />
        ),
    },
  ]);
}
