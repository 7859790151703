// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui
import { Grid, Typography, Box } from "@mui/material";

// components
import Iconify from "../../components/Iconify";
import CardOutlet from "../../components/CardOutlet";
//
import HandleAccountSettingsUpdateInformation from "./actions/HandleAccountSettingsUpdateInformation";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  let valueOutlet = (
    <Typography variant="contentSmall" align="right">
      {value}
    </Typography>
  );

  if (value !== null && value !== undefined) {
    if (value.includes("newsletter=1")) {
      valueOutlet = (
        <Iconify
          color="#61CE70"
          icon="eva:checkmark-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }
    if (value.includes("newsletter=0")) {
      valueOutlet = (
        <Iconify
          color="#C85439"
          icon="eva:close-outline"
          minWidth={22}
          minHeight={22}
          sx={{ my: "-5px" }}
        />
      );
    }
  }

  return (
    <Box
      sx={{
        mb: "5px",
        p: "5px 0px 0px 0px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentSmall" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentSmall"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {valueOutlet}
        </Typography>
      </Box>
    </Box>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const DashboardId = content.config_data.config_data_client.dashboard_id
    
    ;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <ValueLine
          parameter="User ID (UID)"
          value={`${DashboardId}`}
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Email"
          value={content.config_data.config_data_client.email
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Forename"
          value={content.config_data.config_data_client.forename
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Surname"
          value={content.config_data.config_data_client.surname
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Birth"
          value={content.config_data.config_data_client.birth
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Gender"
          value={
            content.config_data.config_data_client.gender
              
              
              .charAt(0)
              .toUpperCase() +
            content.config_data.config_data_client.gender
              
              
              .slice(1)
          }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Organization"
          value={content.config_data.config_data_client.organization
            
            }
          widthLeft="35%"
          widthRight="65%"
        />
        <ValueLine
          parameter="Street"
          value={content.config_data.config_data_client.street
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Optional address line"
          value={content.config_data.config_data_client.optional_line
            
            }
          widthLeft="45%"
          widthRight="55%"
        />
        <ValueLine
          parameter="Postal code"
          value={content.config_data.config_data_client.postal_code
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="City"
          value={content.config_data.config_data_client.city
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Province / State"
          value={content.config_data.config_data_client.province
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Country"
          value={content.config_data.config_data_client.country
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Phone"
          value={content.config_data.config_data_client.phone
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <ValueLine
          parameter="Newsletter"
          value={content.config_data.config_data_client.email_notification_settings
            
            }
          widthLeft="25%"
          widthRight="75%"
        />
        <HandleAccountSettingsUpdateInformation
          configDataClient={content.config_data.config_data_client}
        />
        <br />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({ title, subheader, content }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    title: PropTypes.any.isRequired,
    subheader: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title={title}
      subheader={subheader}
      mainOutlet={<WidgetMainContentProcessed content={content} />}
    />
  );
}
