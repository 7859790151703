// react and js
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { React } from "react";

// mui
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, AppBar, IconButton, Typography, Grid } from "@mui/material";

// components
import Iconify from "../../components/Iconify";

// hooks
import defines from "../../hooks/defines";

// layouts
import AccountPopover from "./PrivateAccount";

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function PrivateHeader({ onOpenSidebar }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  PrivateHeader.propTypes = {
    onOpenSidebar: PropTypes.func,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <AppBar
      sx={{
        background: "#F2F2F2",
        boxShadow: "0px 0px 2px rgba(0,0,0,0.5)",
        minHeight: isDesktop
          ? defines.headerHeightDesktop
          : defines.headerHeightMobile,
        maxHeight: isDesktop
          ? defines.headerHeightDesktop
          : defines.headerHeightMobile,
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          justifyItems: "center",
          minHeight: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
          maxHeight: isDesktop
            ? defines.headerHeightDesktop
            : defines.headerHeightMobile,
        }}
      >
        <Box sx={{ display: "flex" }}>
          {!isDesktop && (
            <IconButton
              onClick={onOpenSidebar}
              sx={{
                marginLeft: "12px",
                borderRadius: "0px",
              }}
            >
              <Iconify
                icon="eva:menu-fill"
                minWidth={35}
                minHeight={35}
                color="#474A4C"
              />
            </IconButton>
          )}

          <RouterLink
            to={process.env.REACT_APP_URL_FRONTEND_WEBSITE}
            style={{ display: "flex" }}
          >
            {isDesktop ? (
              <Box
                component="img"
                src={process.env.REACT_APP_PATH_LOGO}
                sx={{
                  marginTop: "0px",
                  marginLeft: "22px",
                  width: "100px",
                }}
              />
            ) : (
              <Box
                component="img"
                src={process.env.REACT_APP_PATH_LOGO}
                sx={{
                  marginTop: "2px",
                  marginLeft: "12px",
                  height: "47px",
                  width: "100px",
                }}
              />
            )}
          </RouterLink>
        </Box>

        <RouterLink
          to={process.env.REACT_APP_URL_FRONTEND_DASHBOARD}
          style={{ textDecoration: "none", display: "flex" }}
        >
          <Typography
            variant="topbar"
            sx={{
              marginRight: "32px",
            }}
          >
            Dashboard
          </Typography>
        </RouterLink>

        <AccountPopover />
      </Grid>
    </AppBar>
  );
}
