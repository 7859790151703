// react and js
import PropTypes from "prop-types";
import { React } from "react";

// mui

// components
import CardOutlet from "../../components/CardOutlet";
//
import HandleAccountSettingsPasswordReset from "./actions/HandleAccountSettingsPasswordReset";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({ title, subheader, content }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    title: PropTypes.any.isRequired,
    subheader: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
  };

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title={title}
      subheader={subheader}
      mainOutlet={
        <HandleAccountSettingsPasswordReset
          emailParam={content.config_data.config_data_client.email
            
            }
        />
      }
    />
  );
}
