// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import { Grid, Stack, Box, Typography, Link } from "@mui/material";

// components
import backendRequest from "../../components/BackendRequest";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
import CardOutlet from "../../components/CardOutlet";
//
import {
  SubscriptionItem,
  LimitationsItem,
  AccountItem,
} from "../../components/SubscriptionItem";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ------------------------------------------------ ----------------------
function DistributionApplicationSubscriptionItem({
  content,
  distributionPlansApplication,
  KnownAccountServer,
  referenceTradingCurrency,
}) {
  DistributionApplicationSubscriptionItem.propTypes = {
    content: PropTypes.any.isRequired,
    distributionPlansApplication: PropTypes.any,
    KnownAccountServer: PropTypes.any,
    referenceTradingCurrency: PropTypes.any,
  };

  let checkKey = false;
  let isOnlyOneAccountLeft = false;

  if (content !== undefined) {
    const keyOnly = Object.keys(content);

    if (keyOnly.includes("accounts") === true) {
      if (content.accounts.length !== 0) {
        checkKey = true;
      }

      if (content.accounts.length === 1) {
        isOnlyOneAccountLeft = true;
      }
    }
  }

  return content.product.length !== 0 &&
    content.plan.length !== 0 &&
    content.accounts.length !== 0 ? (
    <Grid item xs={12} md={6} lg={4}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box>
          <>
            <SubscriptionItem
              sx={{ px: 0 }}
              mode="distribution_application"
              content={content}
              distributionPlansApplication={distributionPlansApplication}
              KnownAccountServer={KnownAccountServer}
              managed={Boolean(false)}
              referenceTradingCurrency={referenceTradingCurrency}
              categories="none"
            />
            <LimitationsItem
              sx={{ px: 2 }}
              mode="distribution_application"
              content={content}
              managed={Boolean(false)}
            />
          </>
        </Box>
        {checkKey ? (
          <Stack>
            {content.accounts.map((currentAccount, index) => (
              <AccountItem
                mode="distribution_application"
                currentAccount={currentAccount}
                key={index}
                managed={Boolean(false)}
                isOnlyOneAccountLeft={isOnlyOneAccountLeft}
                isSponsored={Boolean(false)}
                plan={content.plan}
              />
            ))}
          </Stack>
        ) : null}
      </Box>
    </Grid>
  ) : null;
}

function DistributionTickDataSubscriptionItem({
  content,
  distributionPlansTickData,
  KnownAccountServer,
  categories,
}) {
  DistributionTickDataSubscriptionItem.propTypes = {
    content: PropTypes.any.isRequired,
    distributionPlansTickData: PropTypes.any,
    KnownAccountServer: PropTypes.any,
    categories: PropTypes.any,
  };

  let checkKey = false;
  let isOnlyOneAccountLeft = false;

  if (content !== undefined) {
    const keyOnly = Object.keys(content);

    if (keyOnly.includes("accounts") === true) {
      if (content.accounts.length !== 0) {
        checkKey = true;
      }
      if (content.accounts.length === 1) {
        isOnlyOneAccountLeft = true;
      }
    }
  }

  return content.product.length !== 0 &&
    content.plan.length !== 0 &&
    content.accounts.length === 0 ? (
    <Grid item xs={12} md={12} lg={12}>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
          pb: 1,
        }}
      >
        <SubscriptionItem
          mode="distribution_tick_data"
          content={content}
          distributionPlansTickData={distributionPlansTickData}
          KnownAccountServer={KnownAccountServer}
          managed={Boolean(false)}
          categories={categories}
          referenceTradingCurrency="none"
        />

        {checkKey ? (
          <Stack>
            {content.accounts.map((currentAccount, index) => (
              <AccountItem
                mode="distribution_tick_data"
                currentAccount={currentAccount}
                key={index}
                content={content}
                managed={Boolean(false)}
                isOnlyOneAccountLeft={isOnlyOneAccountLeft}
                isSponsored={Boolean(false)}
                plan={content.plan}
              />
            ))}
          </Stack>
        ) : null}
      </Box>
    </Grid>
  ) : null;
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  let applicationSubscriptionsAvailable = false;
  let tickDataSubscriptionsAvailable = false;

  content.subscriptions.forEach((distrubutionProduct) => {
    if (distrubutionProduct.accounts.length !== 0) {
      applicationSubscriptionsAvailable = true;
    } else {
      tickDataSubscriptionsAvailable = true;
    }
  });

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Stack spacing={5}>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={{ mt: 0, mb: 0 }}>
                <CardOutlet
                  variant="description"
                  backgroundProp="#F2F2F2"
                  title="Application Subscriptions"
                  subheader="The section below displays your current application subscriptions. Utilize the widget in this section to manage your trading accounts and adjust your subscriptions."
                />
              </Box>
            </Grid>

            {content.subscriptions.map((currentSubscription) => (
              <DistributionApplicationSubscriptionItem
                key={currentSubscription.product.product_tag}
                content={currentSubscription}
                distributionPlansApplication={
                  content.distribution_plans[
                    currentSubscription.product.product_name
                  ]
                }
                KnownAccountServer={content.known_account_server}
                referenceTradingCurrency={content.reference_trading_currency}
              />
            ))}
          </>

          {!applicationSubscriptionsAvailable ? (
            <Grid item xs={12} md={12} lg={12}>
              <Box
                display="block"
                align="center"
                sx={{
                  borderRadius: "0px",
                  boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
                  py: 1,
                }}
              >
                <Typography
                  variant="contentBig"
                  display="block"
                  align="center"
                  sx={{ m: "14px" }}
                >
                  No application subscription active ...
                  <Typography variant="contentBigBold">
                    {/* eslint-disable-next-line */}
                    <Link
                      href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution`}
                      target="_blank"
                      underline="none"
                      variant="contentBigLink"
                      color="inherit"
                    >
                      {` discover application plans`}
                    </Link>
                  </Typography>
                  .
                </Typography>
              </Box>
            </Grid>
          ) : null}

          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ mt: 5, mb: 0 }}>
              <CardOutlet
                variant="description"
                backgroundProp="#F2F2F2"
                title="Tick Data Subscriptions"
                subheader="Below you can find your active tick data subscriptions. Use this page to modify your subscription."
              />
            </Box>
          </Grid>

          {content.subscriptions.map((currentSubscription) => (
            <DistributionTickDataSubscriptionItem
              key={currentSubscription.product.product_tag}
              content={currentSubscription}
              distributionPlansTickData={
                content.distribution_mt5_tick_data_plans
                  .distribution_mt5_tick_data_plans
              }
              KnownAccountServer={content.known_account_server}
              categories={
                content.distribution_mt5_tick_data_plans.availability_cats
              }
            />
          ))}

          {!tickDataSubscriptionsAvailable ? (
            <Grid item xs={12} md={12} lg={12}>
              <Box
                display="block"
                align="center"
                sx={{
                  borderRadius: "0px",
                  boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
                  py: 1,
                }}
              >
                <Typography
                  variant="contentBig"
                  display="block"
                  align="center"
                  sx={{ m: "14px" }}
                >
                  No tick data subscription active ...
                  <Typography variant="contentBigBold">
                    {/* eslint-disable-next-line */}
                    <Link
                      href={`${process.env.REACT_APP_URL_FRONTEND_CHECKOUT}/distribution-tick-data`}
                      target="_blank"
                      underline="none"
                      variant="contentBigLink"
                      color="inherit"
                    >
                      {` discover tick data plans`}
                    </Link>
                  </Typography>
                  .
                </Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Stack>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalStateStorage = JSON.parse(
    localStorage.getItem("myLocalStateStorage")
  );

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateSubscriptionsSubscriptions: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "subscriptions",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            myLocalStateStorage.answer.dashboard_access_token,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateSubscriptionsSubscriptions) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateSubscriptionsSubscriptions]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalSubscriptionsSubscriptions);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Subscriptions" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Subscriptions" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Subscriptions" />;
  } else if (content) {
    WidgetOutlet = <WidgetMainContentProcessed content={content} />;
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
