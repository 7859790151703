// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

// mui
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  Stack,
  Box,
} from "@mui/material";

// components
import ModalDialog from "./ModalDialog";
import Iconify from "./Iconify";

// hooks

// layouts

// pages

// theme
import { B1Small } from "../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function IncludedSymbols({ plan, details }) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  IncludedSymbols.propTypes = {
    plan: PropTypes.any.isRequired,
    details: PropTypes.any.isRequired,
  };

  // console.log(details);

  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography variant="contentSmallBold">
        {/* eslint-disable-next-line */}
        <Link
          component={RouterLink}
          onClick={handleClickOpen("paper")}
          underline="none"
          variant="contentSmallLink"
          color="inherit"
        >
          Included symbols
        </Link>
      </Typography>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Included symbols for {plan.plan_description.toLowerCase()}
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="contentMiddle">
                  For every symbol listed below you'll be able to download the
                  tick data as executable installer. Within minutes all data
                  will be available in a MetaTrader 5 installation of your
                  choice.
                </Typography>

                <Typography variant="contentMiddle">
                  <br />
                  <br />
                  Each symbol tick data is provided in{" "}
                  <Typography
                    variant="contentMiddle"
                    sx={{ textDecoration: "underline" }}
                  >
                    low, medium and high spread
                  </Typography>{" "}
                  configuration. Please take a look into the
                  <Typography variant="contentMiddleBold">
                    <Link
                      variant="contentMiddleLink"
                      underline="none"
                      target="_blank"
                      color="inherit"
                      href={plan.user_manual_url}
                    >
                      {` user manual `}
                    </Link>
                  </Typography>
                  to get more information about precise spread values.
                </Typography>
              </Box>

              {details.map((row, index) => (
                <Stack
                  key={index}
                  spacing="3px"
                  sx={{ background: "white", mb: 1, px: "10px", py: "5px" }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", pr: 1 }}>
                      <Typography variant="contentSmallBold">Symbol</Typography>
                    </Box>
                    <Box sx={{ width: "60%", pl: 1 }}>
                      <Typography variant="contentSmall">
                        {row.symbol_name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", pr: 1 }}>
                      <Typography variant="contentSmallBold"> Type </Typography>
                    </Box>
                    <Box sx={{ width: "60%", pl: 1 }}>
                      <Typography variant="contentSmall">
                        {row.symbol_group}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", pr: 1 }}>
                      <Typography variant="contentSmallBold">
                        Start date
                      </Typography>
                    </Box>
                    <Box sx={{ width: "60%", pl: 1 }}>
                      <Typography variant="contentSmall">
                        {row.start_date}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </DialogContent>

            <DialogActions
              sx={{
                background: "#F2F2F2",
                p: 0,

                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <B1Small
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleClose}
                sx={{ py: 1 }}
              >
                Close
              </B1Small>
            </DialogActions>
          </>
        }
      />
    </>
  );
}

function PlanItemFeature({ feature }) {
  PlanItemFeature.propTypes = {
    feature: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <Iconify
        color="#61CE70"
        icon="eva:checkmark-outline"
        minWidth={25}
        minHeight={25}
        sx={{ mr: 1, mt: "1px" }}
      />
      {feature}
    </Box>
  );
}

function NewPlanItemTickData({
  planInformation,
  categories,
  sponsored,
  reviseAction,
  isStopPossible,
}) {
  NewPlanItemTickData.propTypes = {
    planInformation: PropTypes.any.isRequired,
    categories: PropTypes.any.isRequired,
    sponsored: PropTypes.any.isRequired,
    reviseAction: PropTypes.any.isRequired,
    isStopPossible: PropTypes.any.isRequired,
  };

  const priceBeforeDot = planInformation.plan_fixed_price_value.substring(
    0,
    planInformation.plan_fixed_price_value.length - 3
  );
  const priceAfterDot = planInformation.plan_fixed_price_value.substring(
    planInformation.plan_fixed_price_value.length - 2,
    planInformation.plan_fixed_price_value.length - 0
  );

  let paymentTenure = "";
  if (planInformation.plan_interval_unit === "DAY") {
    paymentTenure = "Daily";
  } else if (planInformation.plan_interval_unit === "WEEK") {
    paymentTenure = "Weekly";
  } else if (planInformation.plan_interval_unit === "MONTH") {
    paymentTenure = "Monthly";
  } else if (planInformation.plan_interval_unit === "YEAR") {
    paymentTenure = "Yearly";
  }

  let paymentCurrency = planInformation.plan_fixed_price_currency_code;
  if (planInformation.plan_fixed_price_currency_code === "EUR") {
    paymentCurrency = "€";
  }

  const categorySymbolGroups = {};
  categories[planInformation.t_tick_data_linking].forEach((item) => {
    const keys = Object.keys(categorySymbolGroups);
    if (keys.includes(item.symbol_group) === true) {
      categorySymbolGroups[item.symbol_group] += 1;
    } else {
      categorySymbolGroups[item.symbol_group] = 1;
    }
  });

  return (
    <Stack>
      <Box
        sx={{
          borderRadius: "0px",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5);",
        }}
      >
        <Box
          sx={{ pb: "0px", px: 2, mb: 2, backgroundColor: "#F2F2F2" }}
          display="block"
          align="center"
        >
          <Typography
            variant="h5"
            display="block"
            align="center"
            sx={{ mt: "14px" }}
          >
            <Link
              variant="h5"
              underline="none"
              color="inherit"
              target="_blank"
              href={planInformation.home_url}
            >
              {planInformation.product_name}
            </Link>
          </Typography>

          <Typography variant="contentHuge">
            {planInformation.plan_description}
          </Typography>

          <br />
          <IncludedSymbols
            plan={planInformation}
            details={categories[planInformation.t_tick_data_linking]}
          />
        </Box>

        <Box sx={{ px: 2, pb: 2, backgroundColor: "#FFFFFF" }}>
          {sponsored === false ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 3,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    lineHeight: 1,
                    display: "flex",
                    alignItems: "end",
                    mb: "4px",
                  }}
                >
                  {paymentCurrency}
                </Typography>

                <Typography variant="h1" sx={{ lineHeight: 1 }}>
                  {priceBeforeDot}
                </Typography>

                <Typography variant="h3" sx={{ lineHeight: 1, mt: "7px" }}>
                  {priceAfterDot}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pb: 3,
                }}
              >
                <Typography variant="contentMiddle" sx={{ lineHeight: 1 }}>
                  {paymentTenure}{" "}
                  {reviseAction === "upgrade" && sponsored === false && isStopPossible === false ? (
                    <sup>*</sup>
                  ) : null}
                  {reviseAction === "reactivate" && sponsored === false && isStopPossible === false ? (
                    <sup>*</sup>
                  ) : null}
                </Typography>
              </Box>
            </>
          ) : (
            <Box sx={{ pt: 3 }} />
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              {/* eslint-disable-next-line */}
              {Object.keys(categorySymbolGroups).map((symbolGroup, index) => {
                return (
                  <PlanItemFeature
                    key={index}
                    feature={
                      <Typography variant="contentMiddle">
                        {categorySymbolGroups[symbolGroup] === 1
                          ? `${categorySymbolGroups[symbolGroup]} ${symbolGroup} symbol`
                          : `${categorySymbolGroups[symbolGroup]} ${symbolGroup} symbols`}{" "}
                        {reviseAction === "downgrade" && sponsored === false ? (
                          <sup>*</sup>
                        ) : null}
                      </Typography>
                    }
                  />
                );
              })}

              <Box sx={{ mt: 2 }} />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`Low spread `}</Typography>
                    data included
                  </Typography>
                }
              />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`Medium spread `}</Typography>
                    data included
                  </Typography>
                }
              />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`High spread `}</Typography>
                    data included
                  </Typography>
                }
              />

              <Box sx={{ mt: 2 }} />

              <PlanItemFeature
                feature={
                  <Typography variant="contentMiddle">
                    <Typography variant="contentMiddleBold">{`Executable installer `}</Typography>
                    Executable installer for MetaTrader 5
                  </Typography>
                }
              />

              <Box sx={{ mt: 2 }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}

export default NewPlanItemTickData;
