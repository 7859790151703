// react and js
import PropTypes from "prop-types";
import { React, useState, useRef, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Collapse,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import ModalDialog from "../../components/ModalDialog";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function ValueLine({ parameter, value, widthLeft, widthRight }) {
  ValueLine.propTypes = {
    parameter: PropTypes.any.isRequired,
    value: PropTypes.any,
    widthLeft: PropTypes.any.isRequired,
    widthRight: PropTypes.any.isRequired,
  };

  return (
    <Box
      sx={{
        mb: "4px",
        display: "flex",
        borderBottom: "1px solid #C8C8C8",
        borderColor: "#C8C8C8",
      }}
    >
      <Box
        sx={{
          width: widthLeft,
          pr: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Typography variant="contentTinyBold" display="block" align="left">
          {parameter}
        </Typography>
      </Box>

      <Box
        sx={{
          width: widthRight,
          pl: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="contentTiny"
          display="block"
          align="right"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function RequestDetails({ mainDataOutlet, requestDetailsData }) {
  RequestDetails.propTypes = {
    mainDataOutlet: PropTypes.any.isRequired,
    requestDetailsData: PropTypes.any.isRequired,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const parsedRequestDetailsData = JSON.parse(
    requestDetailsData.replaceAll("&quot;", '"')
  );

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B3Tiny fullWidth variant="outlined" onClick={handleClickOpen("paper")}>
        Details
      </B3Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Form free download details
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                background: "#F2F2F2",
                borderTop: "1px solid #C8C8C8",
                borderBottom: "1px solid #C8C8C8",
              }}
            >
              <Typography variant="contentSmall">
                {mainDataOutlet}

                {parsedRequestDetailsData["form-name"] !== undefined ? (
                  <ValueLine
                    parameter="Form name"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-name"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-email"] !== undefined ? (
                  <ValueLine
                    parameter="Form email"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-email"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-phone"] !== undefined ? (
                  <ValueLine
                    parameter="Form phone"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-phone"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-orga"] !== undefined ? (
                  <ValueLine
                    parameter="Form orga"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-orga"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-country"] !== undefined ? (
                  <ValueLine
                    parameter="Form country"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-country"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-product"] !== undefined ? (
                  <ValueLine
                    parameter="Form product"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-product"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-skill"] !== undefined ? (
                  <ValueLine
                    parameter="Form skill"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-skill"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-equity"] !== undefined ? (
                  <ValueLine
                    parameter="Form equity"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-equity"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-referral"] !== undefined ? (
                  <ValueLine
                    parameter="Form referral"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-referral"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-message"] !== undefined ? (
                  <ValueLine
                    parameter="Form message"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-message"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["form-newsletter"] !== undefined ? (
                  <ValueLine
                    parameter="Form newsletter"
                    value={JSON.stringify(
                      parsedRequestDetailsData["form-newsletter"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-client-ip"] !==
                undefined ? (
                  <ValueLine
                    parameter="Location client ip"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-client-ip"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-city"] !== undefined ? (
                  <ValueLine
                    parameter="Location city"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-city"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-state"] !== undefined ? (
                  <ValueLine
                    parameter="Location state"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-state"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-country"] !== undefined ? (
                  <ValueLine
                    parameter="Location country"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-country"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-country-code"] !==
                undefined ? (
                  <ValueLine
                    parameter="Location country code"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-country-code"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-continent"] !==
                undefined ? (
                  <ValueLine
                    parameter="Location continent"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-continent"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}

                {parsedRequestDetailsData["location-continent-code"] !==
                undefined ? (
                  <ValueLine
                    parameter="Location continent code"
                    value={JSON.stringify(
                      parsedRequestDetailsData["location-continent-code"],
                      null,
                      2
                    ).replaceAll('"', "").replaceAll('\\r\\n', "\r\n").replaceAll('\\n', "\n")}
                    widthLeft="40%"
                    widthRight="60%"
                  />
                ) : null}
              </Typography>
            </DialogContent>
          </>
        }
      />
    </>
  );
}

function FetchTable({ tableArray, refreshLoading }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">ID</Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Date GMT </Typography>
              </TableCell>
              <TableCell
                width="30%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Email </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Answer </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <TableRow key={index} sx={{ mb: 1 }}>
                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[0]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[1]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography
                      variant="contentSmall"
                      sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {row[2]}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="left"
                  >
                    <Typography variant="contentSmall"> {row[4]} </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                    align="right"
                  >
                    <RequestDetails
                      mainDataOutlet={
                        <>
                          <ValueLine
                            parameter="ID"
                            value={row[0]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                          <ValueLine
                            parameter="Date GMT"
                            value={row[1]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                          <ValueLine
                            parameter="Email"
                            value={row[2]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                          <ValueLine
                            parameter="Answer"
                            value={row[4]}
                            widthLeft="40%"
                            widthRight="60%"
                          />
                        </>
                      }
                      requestDetailsData={row[3]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <Stack
              key={index}
              spacing="3px"
              sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> ID </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[0]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Date GMT </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[1]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Email </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography
                    variant="contentSmall"
                    sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {row[2]}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%", pr: 1 }}>
                  <Typography variant="contentSmallBold"> Answer </Typography>
                </Box>
                <Box sx={{ width: "70%", pl: 1 }}>
                  <Typography variant="contentSmall">{row[4]}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "100%" }}>
                  <RequestDetails
                    mainDataOutlet={
                      <>
                        <ValueLine
                          parameter="ID"
                          value={row[0]}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                        <ValueLine
                          parameter="Date GMT"
                          value={row[1]}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                        <ValueLine
                          parameter="Email"
                          value={row[2]}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                        <ValueLine
                          parameter="Answer"
                          value={row[4]}
                          widthLeft="40%"
                          widthRight="60%"
                        />
                      </>
                    }
                    requestDetailsData={row[3]}
                  />
                </Box>
              </Box>
            </Stack>
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterDate,
  setFilterDate,
  filterEmail,
  setFilterEmail,
  filterAnswer,
  setFilterAnswer,
  filterDetails,
  setFilterDetails,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterDate: PropTypes.any.isRequired,
    setFilterDate: PropTypes.any.isRequired,
    filterEmail: PropTypes.any.isRequired,
    setFilterEmail: PropTypes.any.isRequired,
    filterAnswer: PropTypes.any.isRequired,
    setFilterAnswer: PropTypes.any.isRequired,
    filterDetails: PropTypes.any.isRequired,
    setFilterDetails: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let formManagerFreeDownloadTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsca50c326072310ba !== null &&
      myLocalSettingStorage.obfsca50c326072310ba !== undefined
    ) {
      formManagerFreeDownloadTableFilterCollapseInitValue =
        myLocalSettingStorage.obfsca50c326072310ba;
    }
  }
  const [collapse, setCollapse] = useState(
    formManagerFreeDownloadTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterDate !== "" ||
      filterEmail !== "" ||
      filterAnswer !== "" ||
      filterDetails !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let formManagerFreeDownloadTableFilterItemsPerPageInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs11b3a2911a9fbd3e !== null &&
      myLocalSettingStorage.obfs11b3a2911a9fbd3e !== undefined
    ) {
      formManagerFreeDownloadTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfs11b3a2911a9fbd3e;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    formManagerFreeDownloadTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterDate, setLocalFilterDate] = useState(filterDate);
  const [localFilterEmail, setLocalFilterEmail] = useState(filterEmail);
  const [localFilterAnswer, setLocalFilterAnswer] = useState(filterAnswer);
  const [localFilterDetails, setLocalFilterDetails] = useState(filterDetails);

  const contentFiltered = [];
  if (content.t_history !== undefined) {
    content.t_history.data.forEach((account, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(account);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterDate(localFilterDate);
    setFilterEmail(localFilterEmail);
    setFilterAnswer(localFilterAnswer);
    setFilterDetails(localFilterDetails);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateFormManagerFormFreeDownload: true,
    }));
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Form Free Download"
          subheader={`Source: ${process.env.REACT_APP_URL_FRONTEND_WEBSITE}/free-download/`}
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No form") ? null : (
              <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
                {isDesktop === false ? (
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setMobileCollapse(!mobileCollapse);
                    }}
                  >
                    {mobileCollapse ? "Close" : "Open"}
                  </B3Tiny>
                ) : null}

                <Collapse
                  sx={{ mx: -1, px: 1 }}
                  in={mobileCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setCollapse(!collapse);

                      const newLocalSettingStorage = JSON.parse(
                        localStorage.getItem("myLocalSettingStorage")
                      );

                      newLocalSettingStorage.obfsca50c326072310ba = !collapse;
                      localStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                  >
                    {collapse ? "Hide filters" : "Show filters"}
                  </B3Tiny>

                  <Collapse
                    sx={{ mx: -1 }}
                    in={collapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                      <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">ID</Typography>
                          <FetchTableTextField
                            value={localFilterId}
                            onChange={(event) => {
                              setLocalFilterId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfsff4c0adfc1d7ffc5 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Date</Typography>
                          <FetchTableTextField
                            value={localFilterDate}
                            onChange={(event) => {
                              setLocalFilterDate(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs5a8e1a90a7495ae0 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Email</Typography>
                          <FetchTableTextField
                            value={localFilterEmail}
                            onChange={(event) => {
                              setLocalFilterEmail(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs5bb3fb5d24ebcae7 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">Answer</Typography>
                          <FetchTableTextField
                            value={localFilterAnswer}
                            onChange={(event) => {
                              setLocalFilterAnswer(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfsc7b5f1b8062ec420 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Details
                          </Typography>
                          <FetchTableTextField
                            value={localFilterDetails}
                            onChange={(event) => {
                              setLocalFilterDetails(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs39abaf9f64c6351d =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Items per page
                          </Typography>
                          <Box sx={{ px: 1 }}>
                            <FetchTableSlider
                              size="small"
                              min={1}
                              max={100}
                              align="right"
                              value={itemsPerPages}
                              onChange={(event, newValue) => {
                                setItemsPerPages(newValue);
                                setTotalPages(
                                  Math.ceil(content.num_rows / newValue)
                                );
                                setCurrentPage(1);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfs11b3a2911a9fbd3e =
                                  newValue;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <B1Tiny
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setFilters();
                        }}
                      >
                        Set filters and refresh output
                      </B1Tiny>
                    </Box>
                  </Collapse>

                  <Box sx={{ mt: 0, mb: 1 }} />

                  <FetchTable
                    tableArray={contentFiltered}
                    refreshLoading={refreshLoading}
                  />

                  <Stack sx={{ mt: 3 }} alignItems="center">
                    <FetchTablePagination
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                      }}
                    />
                  </Stack>
                </Collapse>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let formManagerFreeDownloadTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsff4c0adfc1d7ffc5 !== null &&
      myLocalSettingStorage.obfsff4c0adfc1d7ffc5 !== undefined
    ) {
      formManagerFreeDownloadTableFilterIdInitValue =
        myLocalSettingStorage.obfsff4c0adfc1d7ffc5;
    }
  }
  const [filterId, setFilterId] = useState(
    formManagerFreeDownloadTableFilterIdInitValue
  );

  // Filter
  let formManagerFreeDownloadTableFilterDateInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs5a8e1a90a7495ae0 !== null &&
      myLocalSettingStorage.obfs5a8e1a90a7495ae0 !== undefined
    ) {
      formManagerFreeDownloadTableFilterDateInitValue =
        myLocalSettingStorage.obfs5a8e1a90a7495ae0;
    }
  }
  const [filterDate, setFilterDate] = useState(
    formManagerFreeDownloadTableFilterDateInitValue
  );

  // Filter
  let formManagerFreeDownloadTableFilterEmailInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs5bb3fb5d24ebcae7 !== null &&
      myLocalSettingStorage.obfs5bb3fb5d24ebcae7 !== undefined
    ) {
      formManagerFreeDownloadTableFilterEmailInitValue =
        myLocalSettingStorage.obfs5bb3fb5d24ebcae7;
    }
  }
  const [filterEmail, setFilterEmail] = useState(
    formManagerFreeDownloadTableFilterEmailInitValue
  );

  // Filter
  let formManagerFreeDownloadTableFilterAnswerInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsc7b5f1b8062ec420 !== null &&
      myLocalSettingStorage.obfsc7b5f1b8062ec420 !== undefined
    ) {
      formManagerFreeDownloadTableFilterAnswerInitValue =
        myLocalSettingStorage.obfsc7b5f1b8062ec420;
    }
  }
  const [filterAnswer, setFilterAnswer] = useState(
    formManagerFreeDownloadTableFilterAnswerInitValue
  );

  // Filter
  let formManagerFreeDownloadTableFilterDetailsInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs39abaf9f64c6351d !== null &&
      myLocalSettingStorage.obfs39abaf9f64c6351d !== undefined
    ) {
      formManagerFreeDownloadTableFilterDetailsInitValue =
        myLocalSettingStorage.obfs39abaf9f64c6351d;
    }
  }
  const [filterDetails, setFilterDetails] = useState(
    formManagerFreeDownloadTableFilterDetailsInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateFormManagerFormFreeDownload: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "form_manager",
        widget: "form_free_download",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "desc",
          page_order_by: "ticket_id",
        },
        filters: {
          ticket_id: filterId,
          time: filterDate,
          email: filterEmail,
          client_email_answer_state: filterAnswer,
          content: filterDetails,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setContent(contentFromBackend);
              setLoading(false);
              setRefreshLoading(false);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterDate === "" &&
                  filterEmail === "" &&
                  filterAnswer === "" &&
                  filterDetails === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No form requests found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateFormManagerFormFreeDownload) return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateFormManagerFormFreeDownload]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalFormManagerFormFreeDownload);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Form Free Download" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Form Free Download" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Form Free Download" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterEmail={filterEmail}
        setFilterEmail={setFilterEmail}
        filterAnswer={filterAnswer}
        setFilterAnswer={setFilterAnswer}
        filterDetails={filterDetails}
        setFilterDetails={setFilterDetails}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return <> {WidgetOutlet} </>;
}
