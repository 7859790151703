// react and js
import { React, useState, useMemo, useEffect } from "react";
import { BrowserRouter, } from "react-router-dom";
import Routing from "./routes";

// mui

// components

// hooks
import appContext from "./hooks/appContext";
import { myLocalSettingStorage } from "./hooks/myLocalSettingStorage";
import { stateStorageInit } from "./hooks/stateStorageInit";

// layouts

// pages

// theme
import ThemeProvider from "./theme/ThemeProvider";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function App() {
  // Important to surpress chrome preload warnings
  useEffect(() => {
    const preLoadWarningSurpressBackground = new Image();
    preLoadWarningSurpressBackground.src = `/illustrations/background/my-public-background.jpg`;

    const preLoadWarningSurpress1 = new Image();
    preLoadWarningSurpress1.src = "/logo/logo-512.png";

    const preLoadWarningSurpress2 = new Image();
    preLoadWarningSurpress2.src = "/icon/icon-192-192.png";

    const preLoadWarningSurpress3 = new Image();
    preLoadWarningSurpress3.src = "/icon/icon-256-256.png";

    const preLoadWarningSurpress4 = new Image();
    preLoadWarningSurpress4.src = "/icon/icon-512-512.png";

    const preLoadWarningSurpressP1 = new Image();
    preLoadWarningSurpressP1.src = "/illustrations/partners/partners-logo-ic-markets.png";

    const preLoadWarningSurpressP2 = new Image();
    preLoadWarningSurpressP2.src = "/illustrations/partners/partners-logo-ic-trading.png";

    const preLoadWarningSurpressP3 = new Image();
    preLoadWarningSurpressP3.src = "/illustrations/partners/partners-logo-admiral-markets.png";

    const preLoadWarningSurpressP4 = new Image();
    preLoadWarningSurpressP4.src = "/illustrations/partners/partners-logo-vantage.png";

    const preLoadWarningSurpressP5 = new Image();
    preLoadWarningSurpressP5.src = "/illustrations/partners/partners-logo-blackbull-markets.png";

    const preLoadWarningSurpressP6 = new Image();
    preLoadWarningSurpressP6.src = "/illustrations/partners/partners-logo-moneta-markets.png";

    const preLoadWarningSurpressP7 = new Image();
    preLoadWarningSurpressP7.src = "/illustrations/partners/partners-logo-robo-forex.png";

    const preLoadWarningSurpressP8 = new Image();
    preLoadWarningSurpressP8.src = "/illustrations/partners/partners-logo-pu-prime.png";
  }, []);

  const [stateStorage, setStateStorage] = useState(stateStorageInit);

  const myContext = useMemo(
    () => ({ stateStorage, setStateStorage }),
    [stateStorage, setStateStorage]
  );

  // ######################################################
  // Check for storage changes
  // ######################################################
  useEffect(() => {
    const currentLocalSettingStorage = JSON.parse(
      localStorage.getItem("myLocalSettingStorage")
    );
    if (currentLocalSettingStorage !== null) {
      const referenceLocalSettingStorage = myLocalSettingStorage;
      const referenceLocalSettingStorageKeys = Object.keys(
        referenceLocalSettingStorage
      );

      referenceLocalSettingStorageKeys.forEach(
        (referenceLocalSettingStorageKey) => {
          if (
            currentLocalSettingStorage[referenceLocalSettingStorageKey] ===
            undefined
          ) {
            currentLocalSettingStorage[referenceLocalSettingStorageKey] =
              referenceLocalSettingStorage[referenceLocalSettingStorageKey];
            localStorage.setItem(
              "myLocalSettingStorage",
              JSON.stringify(currentLocalSettingStorage)
            );
          }
        }
      );
    }
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <appContext.Provider value={myContext}>
          <Routing />
        </appContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
