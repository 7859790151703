// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Typography,
  Collapse,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Iconify from "../../components/Iconify";
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";
//
import HandleApplicationSettingsUpdate from "./actions/HandleApplicationSettingsUpdate";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import {
  B1Tiny,
  B3Tiny,
  FetchTableLinearProgress,
  FetchTablePagination,
  FetchTableTextField,
  FetchTableSlider,
} from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function FetchTableRowDesktop({ row }) {
  FetchTableRowDesktop.propTypes = {
    row: PropTypes.any.isRequired,
  };

  let valueOutlet = null;
  if (row[7].includes("NOT_IN_USE")) {
    valueOutlet = (
      <Iconify
        color="#E0E0E0"
        icon="eva:pause-circle-outline"
        minWidth={22}
        minHeight={22}
      />
    );
  }
  if (row[7].includes("INCLUDED_DISABLED")) {
    valueOutlet = (
      <Iconify
        color="#61CE70"
        icon="eva:stop-circle-outline"
        minWidth={22}
        minHeight={22}
      />
    );
  }
  if (row[7].includes("INCLUDED_DISABLED_WITH_CLOSING")) {
    valueOutlet = (
      <Iconify
        color="#C85439"
        icon="eva:close-circle-outline"
        minWidth={22}
        minHeight={22}
      />
    );
  }

  return (
    <TableRow sx={{ mb: 1 }}>
      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall"> {row[0]} </Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall"> {row[2]} </Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography
          variant="contentSmall"
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          {row[3]}
        </Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall"> {row[4]} </Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        <Typography variant="contentSmall"> {row[5]} </Typography>
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="left">
        {valueOutlet}
      </TableCell>

      <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }} align="right">
        <HandleApplicationSettingsUpdate
          conditionId={row[0]}
          currentValue={row[7]}
          conditionSelection={row[8]}
        />
      </TableCell>
    </TableRow>
  );
}

function FetchTableRowMobile({ row }) {
  FetchTableRowMobile.propTypes = {
    row: PropTypes.any.isRequired,
  };

  let valueOutlet = null;
  if (row[7].includes("NOT_IN_USE")) {
    valueOutlet = "not in use";
  }
  if (row[7].includes("INCLUDED_DISABLED")) {
    valueOutlet = "included disabled";
  }
  if (row[7].includes("INCLUDED_DISABLED_WITH_CLOSING")) {
    valueOutlet = "included disabled with closing";
  }

  return (
    <Stack
      spacing="3px"
      sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "40%", pr: 1 }}>
          <Typography variant="contentSmallBold"> ID </Typography>
        </Box>
        <Box sx={{ width: "60%", pl: 1 }}>
          <Typography variant="contentSmall">{row[0]}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "40%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Product ID </Typography>
        </Box>
        <Box sx={{ width: "60%", pl: 1 }}>
          <Typography variant="contentSmall">{row[2]}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "40%", pr: 1 }}>
          <Typography variant="contentSmallBold"> User Idendifier </Typography>
        </Box>
        <Box sx={{ width: "60%", pl: 1 }}>
          <Typography
            variant="contentSmall"
            sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
          >
            {row[3]}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "40%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Plan ID </Typography>
        </Box>
        <Box sx={{ width: "60%", pl: 1 }}>
          <Typography variant="contentSmall">{row[4]}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "40%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Broker ID </Typography>
        </Box>
        <Box sx={{ width: "60%", pl: 1 }}>
          <Typography variant="contentSmall">{row[5]}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "40%", pr: 1 }}>
          <Typography variant="contentSmallBold"> Value </Typography>
        </Box>
        <Box sx={{ width: "60%", pl: 1 }}>
          <Typography variant="contentSmall">{valueOutlet}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <HandleApplicationSettingsUpdate
            conditionId={row[0]}
            currentValue={row[7]}
            conditionSelection={row[8]}
          />
        </Box>
      </Box>
    </Stack>
  );
}

function FetchTable({ tableArray, refreshLoading }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <>
      <TableContainer component={Box} sx={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                width="5%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> ID </Typography>
              </TableCell>
              <TableCell
                width="20%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Product ID </Typography>
              </TableCell>
              <TableCell
                width="30%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold">
                  User Idendifier
                </Typography>
              </TableCell>
              <TableCell
                width="15%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Plan ID </Typography>
              </TableCell>
              <TableCell
                width="15%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Broker ID </Typography>
              </TableCell>
              <TableCell
                width="5%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              >
                <Typography variant="contentSmallBold"> Value </Typography>
              </TableCell>
              <TableCell
                width="10%"
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
              />
            </TableRow>
          </TableHead>
          {refreshLoading === false ? (
            <TableBody>
              {tableArray.map((row, index) => (
                <FetchTableRowDesktop key={index} row={row} />
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  ) : (
    <>
      {refreshLoading === false ? (
        <>
          {tableArray.map((row, index) => (
            <FetchTableRowMobile key={index} row={row} />
          ))}
        </>
      ) : null}

      {refreshLoading === true ? (
        <Box
          sx={{
            justifyContent: "center",
            px: 2,
            pt: "19px",
            pb: "18px",
            background: "white",
          }}
        >
          <FetchTableLinearProgress color="inherit" sx={{ width: "100%" }} />
        </Box>
      ) : null}

      {tableArray.length === 0 && refreshLoading === false ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            px: 2,
            pt: "9px",
            pb: "9px",
            background: "white",
          }}
        >
          <Typography variant="contentSmall">
            No data for given filters found!
          </Typography>
        </Box>
      ) : null}
    </>
  );
}

function WidgetMainContentProcessed({
  content,
  error,
  warning,
  success,
  returnMessage,
  refreshLoading,
  setRefreshLoading,
  filterId,
  setFilterId,
  filterProductId,
  setFilterProductId,
  filterPlanId,
  setFilterPlanId,
  filterBrokerId,
  setFilterBrokerId,
  filterValue,
  setFilterValue,
}) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    success: PropTypes.any.isRequired,
    returnMessage: PropTypes.any.isRequired,
    refreshLoading: PropTypes.any.isRequired,
    setRefreshLoading: PropTypes.any.isRequired,
    filterId: PropTypes.any.isRequired,
    setFilterId: PropTypes.any.isRequired,
    filterProductId: PropTypes.any.isRequired,
    setFilterProductId: PropTypes.any.isRequired,
    filterPlanId: PropTypes.any.isRequired,
    setFilterPlanId: PropTypes.any.isRequired,
    filterBrokerId: PropTypes.any.isRequired,
    setFilterBrokerId: PropTypes.any.isRequired,
    filterValue: PropTypes.any.isRequired,
    setFilterValue: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let applicationSettingsPublicApplicationSettingsTableFilterCollapseInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsaef18eb5e60a8c61 !== null &&
      myLocalSettingStorage.obfsaef18eb5e60a8c61 !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterCollapseInitValue =
        myLocalSettingStorage.obfsaef18eb5e60a8c61;
    }
  }
  const [collapse, setCollapse] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterCollapseInitValue
  );
  useEffect(() => {
    if (
      filterId !== "" ||
      filterProductId !== "" ||
      filterPlanId !== "" ||
      filterBrokerId !== "" ||
      filterValue !== ""
    ) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let applicationSettingsPublicApplicationSettingsTableFilterItemsPerPageInitValue = false;
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsb2f73d3a058209ea !== null &&
      myLocalSettingStorage.obfsb2f73d3a058209ea !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterItemsPerPageInitValue =
        myLocalSettingStorage.obfsb2f73d3a058209ea;
    }
  }
  const [itemsPerPages, setItemsPerPages] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterItemsPerPageInitValue
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (content.num_rows !== undefined) {
      setTotalPages(Math.ceil(content.num_rows / itemsPerPages));
      const initValueTotalPages = Math.ceil(content.num_rows / itemsPerPages);
      if (initValueTotalPages <= 0) {
        setTotalPages(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const [localFilterId, setLocalFilterId] = useState(filterId);
  const [localFilterProductId, setLocalFilterProductId] =
    useState(filterProductId);
  const [localFilterPlanId, setLocalFilterPlanId] = useState(filterPlanId);
  const [localFilterBrokerId, setLocalFilterBrokerId] =
    useState(filterBrokerId);
  const [localFilterValue, setLocalFilterValue] = useState(filterValue);

  const contentFiltered = [];
  if (content.t_condition !== undefined) {
    content.t_condition.data.forEach((account, index) => {
      if (
        index >= (currentPage - 1) * itemsPerPages &&
        index <= currentPage * itemsPerPages - 1
      ) {
        contentFiltered.push(account);
      }
    });
  }

  function setFilters() {
    setCurrentPage(1);

    setFilterId(localFilterId);
    setFilterProductId(localFilterProductId);
    setFilterPlanId(localFilterPlanId);
    setFilterBrokerId(localFilterBrokerId);
    setFilterValue(localFilterValue);

    setRefreshLoading(true);

    setStateStorage((previousState) => ({
      ...previousState,
      widgetUpdateApplicationSettingsApplicationSettingsAllUser: true,
    }));
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <CardOutlet
          variant="outlet"
          backgroundProp="#F2F2F2"
          title="Public Application Settings"
          subheader="Use wisely or never!"
          error={error}
          warning={warning}
          success={success}
          returnMessage={returnMessage}
          mainOutlet={
            returnMessage.includes("No form") ? null : (
              <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
                {isDesktop === false ? (
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setMobileCollapse(!mobileCollapse);
                    }}
                  >
                    {mobileCollapse ? "Close" : "Open"}
                  </B3Tiny>
                ) : null}

                <Collapse
                  sx={{ mx: -1, px: 1 }}
                  in={mobileCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <B3Tiny
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      setCollapse(!collapse);

                      const newLocalSettingStorage = JSON.parse(
                        localStorage.getItem("myLocalSettingStorage")
                      );

                      newLocalSettingStorage.obfsaef18eb5e60a8c61 = !collapse;
                      localStorage.setItem(
                        "myLocalSettingStorage",
                        JSON.stringify(newLocalSettingStorage)
                      );
                    }}
                  >
                    {collapse ? "Hide filters" : "Show filters"}
                  </B3Tiny>

                  <Collapse
                    sx={{ mx: -1 }}
                    in={collapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ flexGrow: 1, mx: 1, mb: 2 }}>
                      <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">ID</Typography>
                          <FetchTableTextField
                            value={localFilterId}
                            onChange={(event) => {
                              setLocalFilterId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs33628e6db9542c05 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Product ID
                          </Typography>
                          <FetchTableTextField
                            value={localFilterProductId}
                            onChange={(event) => {
                              setLocalFilterProductId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs6332e0ca833e5390 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Plan ID
                          </Typography>
                          <FetchTableTextField
                            value={localFilterPlanId}
                            onChange={(event) => {
                              setLocalFilterPlanId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs141fae1ceccdfc6d =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                          <Typography variant="contentSmall">
                            Broker ID
                          </Typography>
                          <FetchTableTextField
                            value={localFilterBrokerId}
                            onChange={(event) => {
                              setLocalFilterBrokerId(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfse44b33540c0d7849 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">Value</Typography>
                          <FetchTableTextField
                            value={localFilterValue}
                            onChange={(event) => {
                              setLocalFilterValue(event.target.value);

                              const newLocalSettingStorage = JSON.parse(
                                localStorage.getItem("myLocalSettingStorage")
                              );

                              newLocalSettingStorage.obfs7bbe3154cb531b61 =
                                event.target.value;
                              localStorage.setItem(
                                "myLocalSettingStorage",
                                JSON.stringify(newLocalSettingStorage)
                              );
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") setFilters();
                            }}
                            fullWidth
                            size="small"
                            sx={{ input: { textAlign: "center" }, mb: "0px" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="contentSmall">
                            Items per page
                          </Typography>
                          <Box sx={{ px: 1 }}>
                            <FetchTableSlider
                              size="small"
                              min={1}
                              max={100}
                              align="right"
                              value={itemsPerPages}
                              onChange={(event, newValue) => {
                                setItemsPerPages(newValue);
                                setTotalPages(
                                  Math.ceil(content.num_rows / newValue)
                                );
                                setCurrentPage(1);

                                const newLocalSettingStorage = JSON.parse(
                                  localStorage.getItem("myLocalSettingStorage")
                                );

                                newLocalSettingStorage.obfsb2f73d3a058209ea =
                                  newValue;
                                localStorage.setItem(
                                  "myLocalSettingStorage",
                                  JSON.stringify(newLocalSettingStorage)
                                );
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <B1Tiny
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setFilters();
                        }}
                      >
                        Set filters and refresh output
                      </B1Tiny>
                    </Box>
                  </Collapse>

                  <Box sx={{ mt: 0, mb: 1 }} />

                  <FetchTable
                    tableArray={contentFiltered}
                    refreshLoading={refreshLoading}
                  />

                  <Stack sx={{ mt: 3 }} alignItems="center">
                    <FetchTablePagination
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                      }}
                    />
                  </Stack>
                </Collapse>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget() {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {};

  const { stateStorage, setStateStorage } = useContext(appContext);
  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // Filter
  let applicationSettingsPublicApplicationSettingsTableFilterIdInitValue = "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs33628e6db9542c05 !== null &&
      myLocalSettingStorage.obfs33628e6db9542c05 !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterIdInitValue =
        myLocalSettingStorage.obfs33628e6db9542c05;
    }
  }
  const [filterId, setFilterId] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterIdInitValue
  );

  // Filter
  let applicationSettingsPublicApplicationSettingsTableFilterProductIdInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs6332e0ca833e5390 !== null &&
      myLocalSettingStorage.obfs6332e0ca833e5390 !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterProductIdInitValue =
        myLocalSettingStorage.obfs6332e0ca833e5390;
    }
  }
  const [filterProductId, setFilterProductId] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterProductIdInitValue
  );

  // Filter
  let applicationSettingsPublicApplicationSettingsTableFilterPlanIdInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs141fae1ceccdfc6d !== null &&
      myLocalSettingStorage.obfs141fae1ceccdfc6d !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterPlanIdInitValue =
        myLocalSettingStorage.obfs141fae1ceccdfc6d;
    }
  }
  const [filterPlanId, setFilterPlanId] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterPlanIdInitValue
  );

  // Filter
  let applicationSettingsPublicApplicationSettingsTableFilterBrokerIdInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfse44b33540c0d7849 !== null &&
      myLocalSettingStorage.obfse44b33540c0d7849 !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterBrokerIdInitValue =
        myLocalSettingStorage.obfse44b33540c0d7849;
    }
  }
  const [filterBrokerId, setFilterBrokerId] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterBrokerIdInitValue
  );

  // Filter
  let applicationSettingsPublicApplicationSettingsTableFilterValueInitValue =
    "";
  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs7bbe3154cb531b61 !== null &&
      myLocalSettingStorage.obfs7bbe3154cb531b61 !== undefined
    ) {
      applicationSettingsPublicApplicationSettingsTableFilterValueInitValue =
        myLocalSettingStorage.obfs7bbe3154cb531b61;
    }
  }
  const [filterValue, setFilterValue] = useState(
    applicationSettingsPublicApplicationSettingsTableFilterValueInitValue
  );

  const [refreshLoading, setRefreshLoading] = useState(false);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setStateStorage((previousState) => ({
          ...previousState,
          widgetUpdateApplicationSettingsApplicationSettingsAllUser: false,
        }));

        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
      }
      const sendData = {
        request_type: "dashboard",
        route_info: "application_settings",
        operation: "fetch",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "asc",
          page_order_by: "condition_id",
        },
        filters: {
          user_idendifier: "public_used_setting",
          condition_id: filterId,
          product_id: filterProductId,
          plan_id: filterPlanId,
          broker_id: filterBrokerId,
          value: filterValue,
        },
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setRefreshLoading(false);
              setContent(contentFromBackend);

              if (contentFromBackend.num_rows === 0) {
                if (
                  filterId === "" &&
                  filterProductId === "" &&
                  filterPlanId === "" &&
                  filterBrokerId === "" &&
                  filterValue === ""
                ) {
                  setSuccess(true);
                  setReturnMessage("No application settings found yet ...");
                } else {
                  setReturnMessage("empty_filtered");
                }
              } else {
                setSuccess(false);
                setReturnMessage("");
              }
            } else if (contentFromBackend.message === "token invalid") {
              setWarning(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Logged out ...");

              setStateStorage(stateStorageInit);
              localStorage.setItem("forcedLoggedOut", true);

              localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalStateStorage");

              sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
              sessionStorage.removeItem("myLocalLastRoute");

              localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
              localStorage.removeItem("myLocalSettingStorage");

              localStorage.setItem("myReauthentication", JSON.stringify(""));
              localStorage.removeItem("myReauthentication");
            } else {
              setError(true);
              setLoading(false);
              setRefreshLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setRefreshLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateApplicationSettingsApplicationSettingsAllUser)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateApplicationSettingsApplicationSettingsAllUser]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalApplicationSettingsApplicationSettingsAllUser);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = <MissingContent message="Public Application Settings" />;
  if (loading) {
    WidgetOutlet = <LoadingAnimation title="Public Application Settings" />;
  } else if (error) {
    WidgetOutlet = <MissingContent message="Public Application Settings" />;
  } else if (content) {
    WidgetOutlet = (
      <WidgetMainContentProcessed
        content={content}
        error={error}
        warning={warning}
        success={success}
        returnMessage={returnMessage}
        refreshLoading={refreshLoading}
        setRefreshLoading={setRefreshLoading}
        filterId={filterId}
        setFilterId={setFilterId}
        filterProductId={filterProductId}
        setFilterProductId={setFilterProductId}
        filterPlanId={filterPlanId}
        setFilterPlanId={setFilterPlanId}
        filterBrokerId={filterBrokerId}
        setFilterBrokerId={setFilterBrokerId}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
    );
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
