// react and js
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { React, useState, useEffect, useContext, useMemo } from "react";

// mui
import { Grid, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import Page from "../../components/Page";

// hooks
import defines from "../../hooks/defines";
import appContext from "../../hooks/appContext";

// layouts

// pages

// theme

// utils

// widgets
import WidgetDistributorSelector from "../../widgets/distributor_portal/DistributorSelector";
import WidgetDistributorWelcome from "../../widgets/distributor_portal/DistributorWelcome";
//
import WidgetDistributionCustomers from "../../widgets/distributor_portal/DistributionCustomers";
import WidgetManageDistributionCustomer from "../../widgets/distributor_portal/ManageDistributionCustomer";
import WidgetTickDataDistributionCustomers from "../../widgets/distributor_portal/TickDataDistributionCustomers";
import WidgetManageTickDataDistributionCustomer from "../../widgets/distributor_portal/ManageTickDataDistributionCustomer";
import WidgetContingents from "../../widgets/distributor_portal/Contingents";
import WidgetAvailablePlans from "../../widgets/distributor_portal/AvailablePlans";
import WidgetAvailableTickDataPlans from "../../widgets/distributor_portal/AvailableTickDataPlans";
import WidgetApplicationLimitations from "../../widgets/distributor_portal/ApplicationLimitations";

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Properties
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function DistributorPortal() {
  // eslint-disable-next-line
  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const navigate = useNavigate();
  const location = useLocation();

  const [
    regularRefreshIntervalCounterComponent,
    setRegularRefreshIntervalCounterComponent,
  ] = useState(0);

  // ######################################################
  // Scroll to top on mount
  // ######################################################
  useEffect(() => {
    const autoScrollContainer = document.querySelector(
      "#auto-scroll-container"
    );
    if (autoScrollContainer !== null) {
      autoScrollContainer.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Outlet protection
  // ######################################################
  let isOutletAllowed = false;
  if (stateStorage.userInformation !== "") {
    if (
      stateStorage.userInformation.account_settings !== null &&
      stateStorage.userInformation.account_settings !== undefined
    ) {
      if (
        stateStorage.userInformation.account_settings.dashboard_features !==
          null &&
        stateStorage.userInformation.account_settings.dashboard_features !==
          undefined
      ) {
        if (
          stateStorage.userInformation.account_settings.dashboard_features
            .obfs103d6174421f3079 !== null &&
          stateStorage.userInformation.account_settings.dashboard_features
            .obfs103d6174421f3079 !== undefined
        ) {
          if (
            stateStorage.userInformation.account_settings.dashboard_features
              .obfs103d6174421f3079 === "obfsdf2b6301086ec5eb"
          ) {
            isOutletAllowed = true;
          }
        }
      }
    }
  }

  const [
    widgetManageDistributionCustomerOpen,
    setWidgetManageDistributionCustomerOpen,
  ] = useState(false);

  const [
    widgetManageTickDataDistributionCustomerOpen,
    setWidgetManageTickDataDistributionCustomerOpen,
  ] = useState(false);

  const routeParams = useParams();

  if (routeParams.dashboard_id !== undefined) {
    if (location.pathname.includes("/distributor-portal/application")) {
      if (widgetManageDistributionCustomerOpen === false) {
        setWidgetManageDistributionCustomerOpen(true);
        setWidgetManageTickDataDistributionCustomerOpen(false);
      }
    }
    if (location.pathname.includes("/distributor-portal/tick-data")) {
      if (widgetManageTickDataDistributionCustomerOpen === false) {
        setWidgetManageTickDataDistributionCustomerOpen(true);
        setWidgetManageDistributionCustomerOpen(false);
      }
    }
  }

  if (
    !location.pathname.includes("/distributor-portal/application") &&
    !location.pathname.includes("/distributor-portal/tick-data")
  ) {
    if (widgetManageDistributionCustomerOpen === true) {
      setWidgetManageDistributionCustomerOpen(false);
    }
    if (widgetManageTickDataDistributionCustomerOpen === true) {
      setWidgetManageTickDataDistributionCustomerOpen(false);
    }
  }

  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [success, setSuccess] = useState(false);

  const [content, setContent] = useState();
  const [returnMessage, setReturnMessage] = useState("");

  const [loading, setLoading] = useState(navigator.onLine);
  const [
    loadingWidgetDistributionCustomers,
    setLoadingWidgetDistributionCustomers,
  ] = useState(navigator.onLine);
  const [
    loadingWidgetTickDataDistributionCustomers,
    setLoadingWidgetTickDataDistributionCustomers,
  ] = useState(navigator.onLine);
  const [loadingWidgetContingents, setLoadingWidgetContingents] = useState(
    navigator.onLine
  );
  const [loadingWidgetAvailablePlans, setLoadingWidgetAvailablePlans] =
    useState(navigator.onLine);
  const [
    loadingWidgetAvailableTickDataPlans,
    setLoadingWidgetAvailableTickDataPlans,
  ] = useState(navigator.onLine);
  const [
    loadingWidgetApplicationLimitations,
    setLoadingWidgetApplicationLimitations,
  ] = useState(navigator.onLine);

  let distributorPortalDistributorInitValue = 0;

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs02964be666064015 !== null &&
      myLocalSettingStorage.obfs02964be666064015 !== undefined
    ) {
      distributorPortalDistributorInitValue =
        myLocalSettingStorage.obfs02964be666064015;
    }
  }
  const [distributor, setDistributor] = useState(0);

  // ######################################################
  // Distributor change
  // ######################################################
  useEffect(() => {
    setLoadingWidgetDistributionCustomers(true);
    setLoadingWidgetTickDataDistributionCustomers(true);
    setLoadingWidgetContingents(true);
    setLoadingWidgetAvailablePlans(true);
    setLoadingWidgetAvailableTickDataPlans(true);
    setLoadingWidgetApplicationLimitations(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Back rescroll
  // ######################################################
  useEffect(() => {
    if (
      !stateStorage.widgetPerformBackScrollDistributorPortalManageDistributionCustomer &&
      !stateStorage.widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer
    )
      return;

    if (
      !loadingWidgetDistributionCustomers &&
      !loadingWidgetTickDataDistributionCustomers &&
      !loadingWidgetContingents &&
      !loadingWidgetAvailablePlans &&
      !loadingWidgetAvailableTickDataPlans &&
      !loadingWidgetApplicationLimitations
    ) {
      let element = null;

      setStateStorage((previousState) => ({
        ...previousState,
        widgetPerformBackScrollDistributorPortalManageDistributionCustomer: false,
      }));

      setStateStorage((previousState) => ({
        ...previousState,
        widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer: false,
      }));

      if (
        stateStorage.widgetPerformBackScrollDistributorPortalManageDistributionCustomer
      ) {
        element = document.getElementById("distribution-customers-scroll");
      } else if (
        stateStorage.widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer
      ) {
        element = document.getElementById(
          "tick-data-distribution-customers-scroll"
        );
      }

      const autoScrollContainer = document.querySelector(
        "#auto-scroll-container"
      );
      const elementPosition = element.getBoundingClientRect().top;

      let offsetPosition = 0;
      if (isDesktop) {
        offsetPosition =
          elementPosition +
          autoScrollContainer.scrollTop -
          defines.headerHeightDesktop;
      } else {
        offsetPosition =
          elementPosition +
          autoScrollContainer.scrollTop -
          defines.headerHeightMobile;
      }

      if (autoScrollContainer !== null) {
        autoScrollContainer.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingWidgetDistributionCustomers,
    loadingWidgetTickDataDistributionCustomers,
    loadingWidgetContingents,
    loadingWidgetAvailablePlans,
    loadingWidgetAvailableTickDataPlans,
    loadingWidgetApplicationLimitations,
  ]);

  useEffect(() => {
    if (
      !stateStorage.widgetPerformBackScrollDistributorPortalManageDistributionCustomer
    )
      return;

    setLoadingWidgetDistributionCustomers(true);
    setLoadingWidgetTickDataDistributionCustomers(true);
    setLoadingWidgetContingents(true);
    setLoadingWidgetAvailablePlans(true);
    setLoadingWidgetAvailableTickDataPlans(true);
    setLoadingWidgetApplicationLimitations(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateStorage.widgetPerformBackScrollDistributorPortalManageDistributionCustomer,
  ]);

  useEffect(() => {
    if (
      !stateStorage.widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer
    )
      return;

    setLoadingWidgetDistributionCustomers(true);
    setLoadingWidgetTickDataDistributionCustomers(true);
    setLoadingWidgetContingents(true);
    setLoadingWidgetAvailablePlans(true);
    setLoadingWidgetAvailableTickDataPlans(true);
    setLoadingWidgetApplicationLimitations(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateStorage.widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer,
  ]);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (periodic === false) {
        setError(false);
        setWarning(false);
        setSuccess(false);
        setReturnMessage("");
        setLoading(true);
      }

      const sendData = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        operation: "get_essentials",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        selection: {
          page_start: "",
          page_count: "",
          page_size: "",
          page_order_type: "",
          page_order_by: "",
        },
        filters: {},
      };
      backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
        .then((contentFromBackend) => {
          const keyOnly = Object.keys(contentFromBackend);
          const checkKey = keyOnly.includes("message");

          if (checkKey === true) {
            if (contentFromBackend.message === "success") {
              setError(false);
              setLoading(false);
              setContent(contentFromBackend);

              if (distributorPortalDistributorInitValue === 0) {
                setDistributor(contentFromBackend.distributors.data[0][0]);

                const newLocalSettingStorage = JSON.parse(
                  localStorage.getItem("myLocalSettingStorage")
                );

                const myLocalStateStorage = JSON.parse(
                  localStorage.getItem("myLocalStateStorage")
                );

                newLocalSettingStorage.obfs02964be666064015 =
                  contentFromBackend.distributors.data[0][0];

                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );

                if (
                  myLocalStateStorage.account_settings.config_data
                    .config_data_distributor !== null &&
                  myLocalStateStorage.account_settings.config_data
                    .config_data_distributor !== undefined
                ) {
                  const myDistributorId =
                    myLocalStateStorage.account_settings.config_data
                      .config_data_distributor.distributor_id;

                  if (distributor !== myDistributorId) {
                    setDistributor(myDistributorId);

                    newLocalSettingStorage.obfs02964be666064015 =
                      myDistributorId;
                    localStorage.setItem(
                      "myLocalSettingStorage",
                      JSON.stringify(newLocalSettingStorage)
                    );
                  }
                }
              } else {
                setDistributor(distributorPortalDistributorInitValue);

                const newLocalSettingStorage = JSON.parse(
                  localStorage.getItem("myLocalSettingStorage")
                );

                newLocalSettingStorage.obfs02964be666064015 =
                  distributorPortalDistributorInitValue;
                localStorage.setItem(
                  "myLocalSettingStorage",
                  JSON.stringify(newLocalSettingStorage)
                );
              }
            } else {
              setError(true);
              setLoading(false);
              setReturnMessage("Invalid request!");
            }
          } else {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        })
        .catch(() => {
          if (navigator.onLine === true) {
            setError(true);
            setLoading(false);
            setReturnMessage("Unknown error!");
          }
        });
    }
  }

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounterComponent(
        regularRefreshIntervalCounterComponent + 1
      );
    }, 5000);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounterComponent]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounterComponent]);

  let DistributorPortalSelectable = false;

  if (stateStorage !== undefined && stateStorage !== null) {
    if (
      stateStorage.userInformation.account_settings.config_data !== undefined &&
      stateStorage.userInformation.account_settings.config_data !== null
    ) {
      if (
        stateStorage.userInformation.account_settings.config_data
          .config_data_administrator !== undefined &&
        stateStorage.userInformation.account_settings.config_data
          .config_data_administrator !== null
      ) {
        const DistributorPortalFeatureSetting =
          stateStorage.userInformation.account_settings.config_data
            .config_data_administrator.dashboard_feature_settings;

        const DistributorPortalExtracted =
          DistributorPortalFeatureSetting.split(",");

        if (
          DistributorPortalExtracted.find((user) =>
            user.includes("distributor_portal")
          ) === "distributor_portal=1"
        ) {
          DistributorPortalSelectable = true;
        }
      }
    }
  }

  useEffect(() => {
    if (isOutletAllowed === false) {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage]);

  // ######################################################
  // Main outlet
  // ######################################################

  // eslint-disable-next-line
  return useMemo(() => {
    return isOutletAllowed ? (
      <Page title="Distributor Portal">
        <Container maxWidth="false">
          <Grid container spacing={0}>
            {widgetManageDistributionCustomerOpen === true ? (
              <Grid item xs={12} md={12} lg={12}>
                {routeParams.dashboard_id !== undefined ? (
                  <WidgetManageDistributionCustomer
                    category="Application Distribution Customer Manager"
                    title={`Selected User ID (UID): ${routeParams.dashboard_id}`}
                    subheader="Use the widgets below to manage your distribution customer. Each modification on this page will be visible to your customer on his dashboard."
                    dashboardId={routeParams.dashboard_id}
                    distributor={distributor}
                  />
                ) : null}
              </Grid>
            ) : null}

            {widgetManageTickDataDistributionCustomerOpen === true ? (
              <Grid item xs={12} md={12} lg={12}>
                {routeParams.dashboard_id !== undefined ? (
                  <WidgetManageTickDataDistributionCustomer
                    category="Tick Data Distribution Customer Manager"
                    title={`Selected User ID (UID): ${routeParams.dashboard_id}`}
                    subheader="Use the widgets below to manage your tick data distribution customer. Each modification on this page will be visible to your tick data customer in his dashboard."
                    dashboardId={routeParams.dashboard_id}
                    distributor={distributor}
                  />
                ) : null}
              </Grid>
            ) : null}

            {widgetManageDistributionCustomerOpen === false &&
            widgetManageTickDataDistributionCustomerOpen === false ? (
              <>
                {content !== undefined ? (
                  <Grid item xs={12} md={12} lg={12}>
                    {DistributorPortalSelectable === true ? (
                      <WidgetDistributorSelector
                        distributor={distributor}
                        onChange={(event) => {
                          setDistributor(event.target.value);

                          const newLocalSettingStorage = JSON.parse(
                            localStorage.getItem("myLocalSettingStorage")
                          );

                          newLocalSettingStorage.obfs02964be666064015 =
                            event.target.value;
                          localStorage.setItem(
                            "myLocalSettingStorage",
                            JSON.stringify(newLocalSettingStorage)
                          );
                        }}
                        loading={loading}
                        content={content}
                        error={error}
                        warning={warning}
                        success={success}
                        returnMessage={returnMessage}
                      />
                    ) : (
                      // eslint-disable-next-line
                      <>
                        {stateStorage.userInformation.account_settings
                          .config_data.config_data_distributor
                          .distributor_name === undefined ? (
                          <WidgetDistributorWelcome
                            title="Hi there!"
                            subheader="Use this page to manage your distribution customers!"
                          />
                        ) : (
                          <WidgetDistributorWelcome
                            title={`Hello, ${stateStorage.userInformation.account_settings.config_data.config_data_distributor.distributor_name}!`}
                            subheader="Use this page to manage your distribution customers!"
                          />
                        )}
                      </>
                    )}
                  </Grid>
                ) : null}

                <Grid item xs={12} md={12} lg={12}>
                  <div id="distribution-customers-scroll">
                    <WidgetDistributionCustomers
                      distributor={distributor}
                      loadingWidgetDistributionCustomers={
                        loadingWidgetDistributionCustomers
                      }
                      setLoadingWidgetDistributionCustomers={
                        setLoadingWidgetDistributionCustomers
                      }
                      mobileBackOpen={
                        stateStorage.widgetPerformBackScrollDistributorPortalManageDistributionCustomer
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <div id="tick-data-distribution-customers-scroll">
                    <WidgetTickDataDistributionCustomers
                      distributor={distributor}
                      loadingWidgetTickDataDistributionCustomers={
                        loadingWidgetTickDataDistributionCustomers
                      }
                      setLoadingWidgetTickDataDistributionCustomers={
                        setLoadingWidgetTickDataDistributionCustomers
                      }
                      mobileBackOpen={
                        stateStorage.widgetPerformBackScrollDistributorPortalManageTickDataDistributionCustomer
                      }
                    />
                  </div>
                </Grid>
             
                <Grid item xs={12} md={12} lg={12}>
                  <WidgetContingents
                    distributor={distributor}
                    loadingWidgetContingents={loadingWidgetContingents}
                    setLoadingWidgetContingents={setLoadingWidgetContingents}
                  />
                </Grid>
          
                <Grid item xs={12} md={12} lg={12}>
                  <WidgetAvailablePlans
                    distributor={distributor}
                    loadingWidgetAvailablePlans={loadingWidgetAvailablePlans}
                    setLoadingWidgetAvailablePlans={
                      setLoadingWidgetAvailablePlans
                    }
                  />
                </Grid>
              
                <Grid item xs={12} md={12} lg={12}>
                  <WidgetAvailableTickDataPlans
                    distributor={distributor}
                    loadingWidgetAvailableTickDataPlans={
                      loadingWidgetAvailableTickDataPlans
                    }
                    setLoadingWidgetAvailableTickDataPlans={
                      setLoadingWidgetAvailableTickDataPlans
                    }
                  />
                </Grid>
              
                <Grid item xs={12} md={12} lg={12}>
                  <WidgetApplicationLimitations
                    distributor={distributor}
                    loadingWidgetApplicationLimitations={
                      loadingWidgetApplicationLimitations
                    }
                    setLoadingWidgetApplicationLimitations={
                      setLoadingWidgetApplicationLimitations
                    }
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
      </Page>
    ) : null;
  });
}
