// react and js
import PropTypes from "prop-types";
import { React, useState, useEffect, useContext } from "react";

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  Box,
  Typography,
  Select,
  NativeSelect,
  Stack,
  Collapse,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import backendRequest from "../../components/BackendRequest";
import CardOutlet from "../../components/CardOutlet";
import LoadingAnimation from "../../components/LoadingAnimation";
import MissingContent from "../../components/MissingContent";

// hooks
import appContext from "../../hooks/appContext";
import { stateStorageInit } from "../../hooks/stateStorageInit";

// layouts

// pages

// theme
import { CardOutlinedInput, CardMenuItem, B3Tiny } from "../../theme/styled";

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function FetchTable({ tableArray, referenceTradingCurrency }) {
  FetchTable.propTypes = {
    tableArray: PropTypes.any.isRequired,
    referenceTradingCurrency: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop ? (
    <TableContainer component={Box} sx={{ background: "white" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}>
              <Typography variant="contentSmallBold"> Broker </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold"> Plattform </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">Min. equity</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">Max. equity</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">Min. leverage</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
            >
              <Typography variant="contentSmallBold">Max. leverage</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableArray.data.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="left"
              >
                <Typography variant="contentSmall">{row[0]}</Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">{row[2]}</Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[3] === null
                    ? "-"
                    : `${numberWithCommas(row[3])} ${referenceTradingCurrency}`}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[4] === null
                    ? "-"
                    : `${numberWithCommas(row[4])} ${referenceTradingCurrency}`}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[5] === null ? "-" : row[5]}
                </Typography>
              </TableCell>

              <TableCell
                sx={{ borderBottom: 3, borderColor: "#F2F2F2" }}
                align="center"
              >
                <Typography variant="contentSmall">
                  {row[6] === null ? "-" : row[6]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {tableArray.data.map((row, index) => (
        <Stack
          key={index}
          spacing="3px"
          sx={{ background: "white", mb: 2, px: "10px", py: "10px" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Broker </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">{row[0]}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Plattform </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">{row[2]}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Min. equity </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[3] === null
                  ? "-"
                  : `${numberWithCommas(row[3])} ${referenceTradingCurrency}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold"> Max. equity </Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[4] === null
                  ? "-"
                  : `${numberWithCommas(row[4])} ${referenceTradingCurrency}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold">Min. leverage</Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[5] === null ? "-" : row[5]}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "40%", pr: 1 }}>
              <Typography variant="contentSmallBold">Max. leverage</Typography>
            </Box>
            <Box sx={{ width: "60%", pl: 1 }}>
              <Typography variant="contentSmall">
                {row[6] === null ? "-" : row[6]}
              </Typography>
            </Box>
          </Box>
        </Stack>
      ))}
    </>
  );
}

function WidgetMainContentProcessed({ content }) {
  WidgetMainContentProcessed.propTypes = {
    content: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const versionKeys = Object.keys(content.t_limitations);
  const versions = [];

  for (let index = 0; index < versionKeys.length; index += 1) {
    versions.push(versionKeys[index]);
  }

  let distributorPortalDistributionApplicationLimitationsVersionInitValue =
    versions[0];

  const myLocalSettingStorage = JSON.parse(
    localStorage.getItem("myLocalSettingStorage")
  );

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfsd043b7a5866a81b2 !== null &&
      myLocalSettingStorage.obfsd043b7a5866a81b2 !== undefined
    ) {
      if (versionKeys.includes(myLocalSettingStorage.obfsd043b7a5866a81b2)) {
        distributorPortalDistributionApplicationLimitationsVersionInitValue =
          myLocalSettingStorage.obfsd043b7a5866a81b2;
      }
    }
  }

  const [applicationVersion, setApplicationVersion] = useState(
    distributorPortalDistributionApplicationLimitationsVersionInitValue
  );

  const applications = [];

  if (versionKeys.includes(applicationVersion) === false) {
    setApplicationVersion(versions[0]);
  } else {
    const applicationKeys = Object.keys(
      content.t_limitations[applicationVersion]
    );
    for (let index = 0; index < applicationKeys.length; index += 1) {
      applications.push(
        content.t_limitations[applicationVersion][index].product_name
      );
    }
  }

  let distributorPortalDistributionApplicationLimitationsApplicationInitValue = 0;

  if (myLocalSettingStorage !== null) {
    if (
      myLocalSettingStorage.obfs4bebfc736e2f169f !== null &&
      myLocalSettingStorage.obfs4bebfc736e2f169f !== undefined
    ) {
      if (versionKeys.includes(applicationVersion) === true) {
        if (
          Number(myLocalSettingStorage.obfs4bebfc736e2f169f) <
          content.t_limitations[applicationVersion].length
        ) {
          distributorPortalDistributionApplicationLimitationsApplicationInitValue =
            myLocalSettingStorage.obfs4bebfc736e2f169f;
        }
      }
    }
  }

  const [applicationApplication, setApplicationApplication] = useState(
    distributorPortalDistributionApplicationLimitationsApplicationInitValue
  );

  if (versionKeys.includes(applicationVersion) === true) {
    if (
      Number(applicationApplication) >=
        content.t_limitations[applicationVersion].length &&
      Number(applicationApplication) !==
        content.t_limitations[applicationVersion].length
    ) {
      setApplicationApplication(0);
    }
  }

  if (versionKeys.includes(applicationVersion) === false) {
    setApplicationVersion(versions[0]);
  }

  const [mobileCollapse, setMobileCollapse] = useState(false);
  useEffect(() => {
    setMobileCollapse(isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <CardOutlet
      variant="outlet"
      backgroundProp="#F2F2F2"
      title="Application Limitations"
      subheader="Some of our apps have limitations due to proper risk and money management. Please be transparent about these limits with users."
      mainOutlet={
        <Box sx={{ mb: !mobileCollapse ? -2 : 0 }}>
          {isDesktop === false ? (
            <B3Tiny
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
              onClick={() => {
                setMobileCollapse(!mobileCollapse);
              }}
            >
              {mobileCollapse ? "Close" : "Open"}
            </B3Tiny>
          ) : null}

          <Collapse
            sx={{ mx: -1, px: 1 }}
            in={mobileCollapse}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={1} sx={{ p: 0 }}>
              {versions.length > 1 ? (
                <Grid item xs={12} md={12} lg={12}>
                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">Version</Typography>
                      <FormControl fullWidth>
                        <Select
                          value={applicationVersion}
                          onChange={(event) => {
                            setApplicationVersion(
                              event.target.value.replace(".", "_")
                            );

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfsd043b7a5866a81b2 =
                              event.target.value.replace(".", "_");
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          input={<CardOutlinedInput />}
                        >
                          {versions.map((currentVersion, index) => (
                            <CardMenuItem key={index} value={currentVersion}>
                              {currentVersion.replace("_", ".")}
                            </CardMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">Version</Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={applicationVersion}
                          onChange={(event) => {
                            setApplicationVersion(
                              event.target.value.replace(".", "_")
                            );

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfsd043b7a5866a81b2 =
                              event.target.value.replace(".", "_");
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          input={<CardOutlinedInput />}
                        >
                          {versions.map((currentVersion, index) => (
                            <option key={index} value={currentVersion}>
                              {currentVersion.replace("_", ".")}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              ) : null}

              {applications.length > 1 ? (
                <Grid item xs={12} md={12} lg={12}>
                  {isDesktop ? (
                    <Box>
                      <Typography variant="contentSmall">
                        Application
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          value={applicationApplication}
                          onChange={(event) => {
                            setApplicationApplication(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs4bebfc736e2f169f =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          input={<CardOutlinedInput />}
                        >
                          {applications.map((currentApplication, index) => (
                            <CardMenuItem key={index} value={index}>
                              {currentApplication}
                            </CardMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="contentSmall">
                        Application
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          value={applicationApplication}
                          onChange={(event) => {
                            setApplicationApplication(event.target.value);

                            const newLocalSettingStorage = JSON.parse(
                              localStorage.getItem("myLocalSettingStorage")
                            );

                            newLocalSettingStorage.obfs4bebfc736e2f169f =
                              event.target.value;
                            localStorage.setItem(
                              "myLocalSettingStorage",
                              JSON.stringify(newLocalSettingStorage)
                            );
                          }}
                          input={<CardOutlinedInput />}
                        >
                          {applications.map((currentApplication, index) => (
                            <option key={index} value={index}>
                              {currentApplication}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              ) : null}

              {versions.length > 1 || applications.length > 1 ? (
                <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }} />
              ) : null}

              <Grid item xs={12} md={12} lg={12}>
                {content.t_limitations !== undefined &&
                content.t_limitations[applicationVersion] !== undefined &&
                content.t_limitations[applicationVersion][
                  applicationApplication
                ] !== undefined ? (
                  <FetchTable
                    referenceTradingCurrency={content.reference_trading_currency.replace(
                      "EUR",
                      "€"
                    )}
                    tableArray={
                      content.t_limitations[applicationVersion][
                        applicationApplication
                      ].limitations
                    }
                  />
                ) : null}
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      }
    />
  );
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function Widget({
  distributor,
  loadingWidgetApplicationLimitations,
  setLoadingWidgetApplicationLimitations,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  Widget.propTypes = {
    distributor: PropTypes.any.isRequired,
    loadingWidgetApplicationLimitations: PropTypes.any.isRequired,
    setLoadingWidgetApplicationLimitations: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);

  const [error, setError] = useState(false);
  const [, setWarning] = useState(false);
  const [, setSuccess] = useState(false);

  const [loading, setLoading] = useState(navigator.onLine);
  const [content, setContent] = useState();
  const [, setReturnMessage] = useState("");

  const [regularRefreshIntervalCounter, setRegularRefreshIntervalCounter] =
    useState(0);

  // ######################################################
  // Main backend request
  // ######################################################
  function MainRequest(periodic) {
    if (
      localStorage.getItem("myReauthentication") === "false" &&
      navigator.onLine === true
    ) {
      if (distributor !== 0) {
        if (periodic === false) {
          setStateStorage((previousState) => ({
            ...previousState,
            widgetUpdateDistributorPortalApplicationLimitations: false,
          }));

          setLoading(true);
          setError(false);
          setWarning(false);
          setSuccess(false);
          setReturnMessage("");
        }

        const sendData = {
          request_type: "dashboard",
          route_info: "distributor_portal",
          widget: "application_limitations",
          operation: "fetch",
          client: {
            dashboard_access_token:
              stateStorage.userInformation.answer.dashboard_access_token,
          },
          admin: {
            selected_distributor_id: distributor,
          },
          selection: {
            page_start: "",
            page_count: "",
            page_size: "",
            page_order_type: "",
            page_order_by: "",
          },
          filters: {},
        };
        backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData)
          .then((contentFromBackend) => {
            const keyOnly = Object.keys(contentFromBackend);
            const checkKey = keyOnly.includes("message");

            if (checkKey === true) {
              if (contentFromBackend.message === "success") {
                setError(false);
                setLoading(false);
                setLoadingWidgetApplicationLimitations(false);
                setContent(contentFromBackend);
              } else if (contentFromBackend.message === "token invalid") {
                setWarning(true);
                setLoading(false);
                setLoadingWidgetApplicationLimitations(false);
                setReturnMessage("Logged out ...");

                setStateStorage(stateStorageInit);
                localStorage.setItem("forcedLoggedOut", true);

                localStorage.setItem("myLocalStateStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalStateStorage");

                sessionStorage.setItem("myLocalLastRoute", JSON.stringify(""));
                sessionStorage.removeItem("myLocalLastRoute");

                localStorage.setItem("myLocalSettingStorage", JSON.stringify(""));
                localStorage.removeItem("myLocalSettingStorage");

                localStorage.setItem("myReauthentication", JSON.stringify(""));
                localStorage.removeItem("myReauthentication");
              } else {
                setError(true);
                setLoading(false);
                setLoadingWidgetApplicationLimitations(false);
                setReturnMessage("Invalid request!");
              }
            } else {
              setError(true);
              setLoading(false);
              setLoadingWidgetApplicationLimitations(false);
              setReturnMessage("Unknown error!");
            }
          })
          .catch(() => {
            if (navigator.onLine === true) {
              setError(true);
              setLoading(false);
              setLoadingWidgetApplicationLimitations(false);
              setReturnMessage("Unknown error!");
            }
          });
      }
    }
  }

  // ######################################################
  // Refresh if distributor changes
  // ######################################################
  useEffect(() => {
    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  // ######################################################
  // Inital and triggered refreshs
  // ######################################################
  useEffect(() => {
    if (!stateStorage.widgetUpdateDistributorPortalApplicationLimitations)
      return;

    MainRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStorage.widgetUpdateDistributorPortalApplicationLimitations]);

  // ######################################################
  // Regular refreshs
  // ######################################################
  useEffect(() => {
    const timerID = setTimeout(() => {
      setRegularRefreshIntervalCounter(regularRefreshIntervalCounter + 1);
    }, stateStorage.widgetUpdateIntervalDistributorPortalApplicationLimitations);

    return () => {
      clearTimeout(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  useEffect(() => {
    MainRequest(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularRefreshIntervalCounter]);

  // ######################################################
  // Data representation
  // ######################################################
  let WidgetOutlet = (
    <Box sx={{mt:3}}>
      <MissingContent message="Application Limitations" />
    </Box>
  );

  if (loading || loadingWidgetApplicationLimitations) {
    WidgetOutlet = (
      <Box sx={{mt:3}}>
        <LoadingAnimation title="Application Limitations" />
      </Box>
    );
  } else if (error) {
    WidgetOutlet = (
      <Box sx={{mt:3}}>
        <MissingContent message="Application Limitations" />
      </Box>
    );

  } else if (content) {
    if(content.hide_widget === false)
    {
      WidgetOutlet = (
        <Box sx={{mt:3}}>
          <WidgetMainContentProcessed content={content} distributor={distributor} />
        </Box>
      );
    }
    else
    {
      WidgetOutlet = null
    }
  }

  // ######################################################
  // Outlet
  // ######################################################
  return WidgetOutlet;
}
