// react and js
import PropTypes from "prop-types";
import * as Yup from "yup";
import { React, useState, useRef, useEffect, useContext } from "react";
import { useFormik, FormikProvider } from "formik";

// mui
import {
  Stack,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  NativeSelect,
  FormControl,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import ModalDialog from "../../../components/ModalDialog";
import RequestMessage from "../../../components/RequestMessage";
import Iconify from "../../../components/Iconify";
import backendRequest from "../../../components/BackendRequest";

// hooks
import appContext from "../../../hooks/appContext";

// layouts

// pages

// theme
import {
  B1Tiny,
  ModalContentTextField,
  ModalActionsLoadingButton,
  ModalContentOutlinedInput,
  ModalContentMenuItem,
  AutocompleteOption,
} from "../../../theme/styled";

// utils
import { combineReturnMessage } from "../../../utils/common";

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function RequestBackendAction(
  setSubmitting,
  setError,
  setSuccess,
  setWarning,
  setReturnMessage,
  postRequestUpdate,
  SubmitRequestContent
) {
  if (
    localStorage.getItem("myReauthentication") === "false" &&
    navigator.onLine === true
  ) {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setReturnMessage("");

    backendRequest(
      process.env.REACT_APP_URL_API_DASHBOARD,
      SubmitRequestContent
    )
      .then((contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            setSuccess(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
            postRequestUpdate(750);
          } else {
            setWarning(true);
            combineReturnMessage(contentFromBackend, setReturnMessage);
          }
        } else {
          setWarning(true);
          combineReturnMessage(contentFromBackend, setReturnMessage);
        }
        setSubmitting(false);
      })
      .catch(() => {
        if (navigator.onLine === true) {
          setReturnMessage("Unknown error!");
          setError(true);
          setSubmitting(false);
        }
      });
  }
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
export default function HandleDistributorPortalAddNewTradingAccount({
  distributor,
  permissionId,
  KnownAccountServer,
  LiveAdable,
  DemoAdable,
}) {
  // ----------------------------------------------------------------------
  // Properties
  // ----------------------------------------------------------------------
  HandleDistributorPortalAddNewTradingAccount.propTypes = {
    distributor: PropTypes.any.isRequired,
    permissionId: PropTypes.any.isRequired,
    KnownAccountServer: PropTypes.any.isRequired,
    LiveAdable: PropTypes.any.isRequired,
    DemoAdable: PropTypes.any.isRequired,
  };

  const { stateStorage, setStateStorage } = useContext(appContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const wrapperRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  const postRequestUpdate = (autoClose) => {
    setTimeout(() => {
      handleClose();
      setStateStorage((previousState) => ({
        ...previousState,
        widgetUpdateDistributorPortalManageDistributionCustomer: true,
      }));
      setError(false);
      setSuccess(false);
      setWarning(false);
    }, autoClose);
  };

  const KnownAccountServerShown = { known_account: [], known_server: [] };

  let accountTypeInitValue = "live";
  if (LiveAdable === false && DemoAdable === false) {
    accountTypeInitValue = "live";
  } else if (LiveAdable === true && DemoAdable === false) {
    accountTypeInitValue = "live";

    const KnownAccount = KnownAccountServer.known_account;
    const KnownServer = [];

    KnownAccountServer.known_server.forEach((server) => {
      if (!server.toUpperCase().includes("DEMO")) {
        KnownServer.push(server);
      }
    });

    KnownAccountServerShown.known_account = KnownAccount;
    KnownAccountServerShown.known_server = KnownServer;
  } else if (LiveAdable === false && DemoAdable === true) {
    accountTypeInitValue = "demo";

    const KnownAccount = KnownAccountServer.known_account;
    const KnownServer = [];

    KnownAccountServer.known_server.forEach((server) => {
      if (!server.toUpperCase().includes("LIVE")) {
        KnownServer.push(server);
      }
    });

    KnownAccountServerShown.known_account = KnownAccount;
    KnownAccountServerShown.known_server = KnownServer;
  } else if (LiveAdable === true && DemoAdable === true) {
    accountTypeInitValue = "live";
  }

  const [accountType, setAccountType] = useState(accountTypeInitValue);

  if (LiveAdable === true && DemoAdable === true) {
    const KnownAccount = KnownAccountServer.known_account;
    const KnownServer = [];

    if (accountType === "live") {
      KnownAccountServer.known_server.forEach((server) => {
        if (!server.toUpperCase().includes("DEMO")) {
          KnownServer.push(server);
        }
      });
    } else if (accountType === "demo") {
      KnownAccountServer.known_server.forEach((server) => {
        if (!server.toUpperCase().includes("LIVE")) {
          KnownServer.push(server);
        }
      });
    }

    KnownAccountServerShown.known_account = KnownAccount;
    KnownAccountServerShown.known_server = KnownServer;
  }

  if (LiveAdable === true && DemoAdable === false && accountType !== "live") {
    setAccountType("live");
  } else if (
    LiveAdable === false &&
    DemoAdable === true &&
    accountType !== "demo"
  ) {
    setAccountType("demo");
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setError(false);
        setWarning(false);
        setSuccess(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside, {
      passive: false,
      capture: false,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const myValidationScheme = Yup.object().shape({
    selected_account: Yup.number()
      .required("Account number is required")
      .positive()
      .integer(),
    selected_server: Yup.string().required("Trading server is required"),
  });

  const formik = useFormik({
    initialValues: {
      selected_account: "",
      selected_server: "",
    },
    validationSchema: myValidationScheme,
    onSubmit: (values, { setSubmitting }) => {
      const SubmitRequestContent = {
        request_type: "dashboard",
        route_info: "distributor_portal",
        widget: "customer_and_account_management",
        operation: "add_new_trading_account",
        client: {
          dashboard_access_token:
            stateStorage.userInformation.answer.dashboard_access_token,
        },
        admin: {
          selected_distributor_id: distributor,
        },
        permission: {
          selected_permission_id: permissionId,
        },
        account: {
          selected_type: accountType,
          selected_account: values.selected_account,
          selected_server: values.selected_server,
        },
      };

      RequestBackendAction(
        setSubmitting,
        setError,
        setSuccess,
        setWarning,
        setReturnMessage,
        postRequestUpdate,
        SubmitRequestContent
      );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <B1Tiny
        variant="contained"
        onClick={handleClickOpen("paper")}
        sx={{ mb: 1 }}
        fullWidth
      >
        Add new trading account
      </B1Tiny>

      <ModalDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        outlet={
          <>
            <DialogTitle
              id="dialog-title"
              sx={{
                background: "#F2F2F2",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                py: 1,
                pr: 1,
              }}
            >
              <Typography variant="contentBig">
                Add new trading account
              </Typography>

              <IconButton
                onClick={handleClickClose}
                sx={{ borderRadius: "0px" }}
              >
                <Iconify
                  icon="eva:close-outline"
                  minWidth={25}
                  minHeight={25}
                  color="#474A4C"
                />
              </IconButton>
            </DialogTitle>

            <FormikProvider value={formik}>
              <DialogContent
                dividers
                sx={{
                  background: "#F2F2F2",
                  borderTop: "1px solid #C8C8C8",
                  borderBottom: "1px solid #C8C8C8",
                }}
              >
                <Stack spacing={1} sx={{ mb: 2 }}>
                  {LiveAdable === true && DemoAdable === true ? (
                    // eslint-disable-next-line
                    <>
                      {isDesktop ? (
                        <Box>
                          <Typography variant="contentSmall">
                            Account type
                          </Typography>
                          <FormControl fullWidth>
                            <Select
                              value={accountType}
                              onChange={(event) => {
                                setAccountType(event.target.value);
                              }}
                              input={<ModalContentOutlinedInput />}
                            >
                              <ModalContentMenuItem value="live">
                                Live
                              </ModalContentMenuItem>
                              <ModalContentMenuItem value="demo">
                                Demo
                              </ModalContentMenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        <Box>
                          <Typography variant="contentSmall">
                            Account type
                          </Typography>
                          <FormControl fullWidth>
                            <NativeSelect
                              value={accountType}
                              onChange={(event) => {
                                setAccountType(event.target.value);
                              }}
                              input={<ModalContentOutlinedInput />}
                            >
                              <option value="live">Live</option>
                              <option value="demo">Demo</option>
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      )}
                    </>
                  ) : null}

                  {LiveAdable === false && DemoAdable === true ? (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="contentMiddleBold">
                        Please enter your demo account details below:
                      </Typography>
                    </Box>
                  ) : null}

                  {LiveAdable === true && DemoAdable === false ? (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="contentMiddleBold">
                        Please enter your live account details below:
                      </Typography>
                    </Box>
                  ) : null}

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.selected_account && errors.selected_account
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.selected_account && errors.selected_account
                      ) === true
                        ? "Invalid account number"
                        : "Account number"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, value) => {
                        values.selected_account = value;
                      }}
                      id="combo-box-demo"
                      options={KnownAccountServerShown.known_account}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps("selected_account")}
                          error={Boolean(
                            touched.selected_account && errors.selected_account
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="contentSmall"
                      sx={{
                        color:
                          Boolean(
                            touched.selected_server && errors.selected_server
                          ) === true
                            ? "red"
                            : "none",
                      }}
                    >
                      {Boolean(
                        touched.selected_server && errors.selected_server
                      ) === true
                        ? "Invalid trading server"
                        : "Trading server"}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, value) => {
                        values.selected_server = value;
                      }}
                      id="combo-box-demo"
                      options={KnownAccountServerShown.known_server}
                      renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option}>
                          {option}
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => (
                        <ModalContentTextField
                          {...params}
                          type="text"
                          {...getFieldProps("selected_server")}
                          error={Boolean(
                            touched.selected_server && errors.selected_server
                          )}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </DialogContent>

              <DialogActions
                sx={{
                  background: "#F2F2F2",
                  p: 0,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <RequestMessage
                  wrapperRef={wrapperRef}
                  error={error}
                  warning={warning}
                  success={success}
                  returnMessage={returnMessage}
                />

                <ModalActionsLoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Add
                </ModalActionsLoadingButton>
              </DialogActions>
            </FormikProvider>
          </>
        }
      />
    </>
  );
}
