// react and js
import PropTypes from "prop-types";
import JsPDF from "jspdf";
import autoTable from "jspdf-autotable";

// mui

// components
import addFontNormal from "./OpenSans-normal";
import addFontBold from "./OpenSans-bold";
import backendRequest from "../BackendRequest";

// hooks

// layouts

// pages

// theme

// utils

// widgets

// ----------------------------------------------------------------------
// Code
// ----------------------------------------------------------------------
function numberWithCommas(x) {
  return parseFloat(x)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function topline(doc, dataStructure, startYParam, fontSizes, lineSpacing) {
  // Coordinates
  let startX = 57;
  const startY = startYParam;
  let endX = doc.internal.pageSize.width - startX;
  const spaceBetweenWords = 8;

  // Underline
  doc.setDrawColor(71, 74, 76);
  doc.setLineWidth(0.5);
  doc.line(startX, startY + lineSpacing / 3, endX, startY + lineSpacing / 3);

  // Left aligned items
  doc.setFontSize(fontSizes.NormalFontSize);
  const itemsLeft = Object.values(dataStructure.topline.left);
  itemsLeft.forEach((text) => {
    if (text) {
      doc.text(text, startX, startY);
      startX =
        startX +
        doc.getStringUnitWidth(text) * fontSizes.NormalFontSize +
        spaceBetweenWords;
    }
  });

  // Right aligned items
  let text = null;
  if (dataStructure.custom.customReceiverVatNumber !== "") {
    text = `${dataStructure.topline.right.email}, ${dataStructure.custom.customSenderPhone}`;
  } else {
    text = dataStructure.topline.right.email;
  }
  doc.setFontSize(fontSizes.NormalFontSize);
  if (text) {
    doc.text(text, endX, startY, "right");
    endX =
      endX -
      doc.getStringUnitWidth(text) * fontSizes.NormalFontSize -
      spaceBetweenWords;
  }

  // Return new y coordinate
  return startY;
}

function recipent(doc, dataStructure, startYParam, fontSizes, lineSpacing) {
  // Coordinates
  const startX = 57;
  let startY = startYParam;

  // Recipent
  doc.setFontSize(fontSizes.SubTitleFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);

  const lines = dataStructure.recipent;
  if (
    dataStructure.custom.customReceiverVatNumber !== "" ||
    dataStructure.custom.customReceiverRegistryCode !== ""
  ) {
    lines.placeholder = " ";
  }

  if (dataStructure.custom.customReceiverVatNumber !== "") {
    lines.customReceiverVatNumber = `VAT ID: ${dataStructure.custom.customReceiverVatNumber}`;
  }

  if (dataStructure.custom.customReceiverRegistryCode !== "") {
    lines.customReceiverRegistryCode = `Registry code: ${dataStructure.custom.customReceiverRegistryCode}`;
  }

  const recipentObject = Object.values(lines);
  recipentObject.forEach((recipentLine) => {
    if (recipentLine) {
      doc.text(recipentLine, startX, (startY += lineSpacing));
    }
  });

  // Return new y coordinate
  return startY;
}

function heading(doc, dataStructure, startYParam, fontSizes, lineSpacing) {
  // Coordinates
  let startX = 57;
  let startY = startYParam;
  const endX = doc.internal.pageSize.width - startX;
  const spaceBetweenWords = 3;

  // Invoice no.
  startY = 273;
  doc.setFontSize(fontSizes.SubTitleFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(dataStructure.labels.number, startX, startY);
  //
  startX =
    startX +
    doc.getStringUnitWidth(dataStructure.labels.number) *
      fontSizes.SubTitleFontSize +
    spaceBetweenWords;
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(dataStructure.meta.number, startX, startY);

  // Date
  startX =
    endX -
    doc.getStringUnitWidth(dataStructure.meta.date) *
      fontSizes.SubTitleFontSize -
    spaceBetweenWords;
  doc.setFontSize(fontSizes.SubTitleFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(dataStructure.labels.date, startX, startY, "right");
  //
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(dataStructure.meta.date, endX, startY, "right");

  // Custom message top
  if (dataStructure.custom.customMessageTop !== "") {
    startY += lineSpacing * 2;

    const messageTop = Object.values(dataStructure.custom.customMessageTop);
    messageTop.forEach((text) => {
      if (text) {
        startX = 57;
        startY += lineSpacing * 1;
        doc.setFontSize(fontSizes.NormalFontSize);
        doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
        doc.text(text, startX, startY);
      }
    });
  }

  // Title
  startX = 57;
  if (dataStructure.custom.customCustomLineItems === "") {
    startY += lineSpacing * 4;
  } else {
    startY += lineSpacing * 2;
  }
  doc.setFontSize(fontSizes.TitleFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(dataStructure.labels.for, startX, (startY += lineSpacing + 2));

  // Add pay online button
  if (
    dataStructure.custom.customReceipeDate === null &&
    dataStructure.custom.customOnlinePayable !== ""
  ) {
    // Create rectangle
    doc.setFillColor(245, 195, 66);
    doc.rect(endX - 113, startY - 14, 113, 20, "F");

    // Create text
    doc.setFontSize(fontSizes.SubTitleFontSize);
    doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
    doc.text("Pay online", endX - 28, startY, "right");

    // Create link box
    doc.link(endX - 113, startY - 14, 113, 20, {
      url: `${process.env.REACT_APP_URL_API_EVENT_LISTENER}?request_type=pay_invoice_online&billing_id=${dataStructure.meta.number}&online_payable=${dataStructure.custom.customOnlinePayable}&dashboard_forward=1`,
    });
  }

  // Underline
  startX = 57;
  startY += lineSpacing;
  doc.setDrawColor(255, 150, 0);
  doc.setLineWidth(0.5);
  doc.line(startX, startY, endX, startY);

  // Return new y coordinate
  return startY;
}

function itemTable(doc, dataStructure, startYParam, fontSizes, lineSpacing) {
  // Coordinates
  const startX = 57;
  let startY = startYParam;
  const endX = doc.internal.pageSize.width - startX;
  const colum2StartX = 386;
  const colum3StartX = 460;

  // Head
  doc.setFontSize(fontSizes.NormalFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  startY += lineSpacing * 1.5;
  doc.text(dataStructure.labels.tableItems, startX, startY);
  doc.text(dataStructure.labels.tableQty, colum2StartX, startY, {
    align: "right",
  });
  doc.text(dataStructure.labels.tableSinglePrice, colum3StartX, startY, {
    align: "right",
  });
  doc.text(dataStructure.labels.tableSingleTotal, endX, startY, {
    align: "right",
  });

  // Underline
  startY += lineSpacing;
  doc.setDrawColor(255, 150, 0);
  doc.setLineWidth(0.5);
  doc.line(startX, startY, endX, startY);

  if (dataStructure.custom.customCustomLineItems === "") {
    // Item title
    startY += lineSpacing * 1.5;
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
    doc.text(dataStructure.item.title, startX, startY);

    // Item description
    startY += doc.internal.getLineHeight();
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
    const splitDescription = doc.splitTextToSize(
      dataStructure.item.description,
      colum2StartX - startX - lineSpacing * 1.5
    );
    doc.text(splitDescription, startX, startY);
    doc.text(dataStructure.item.qty, colum2StartX, startY, { align: "right" });
    doc.text(dataStructure.item.amount, colum3StartX, startY, {
      align: "right",
    });
    doc.text(dataStructure.item.total, endX, startY, { align: "right" });
    startY += splitDescription.length * doc.internal.getLineHeight();
  } else {
    dataStructure.custom.customCustomLineItems.forEach(
      (customCustomLineItem, index) => {
        // Item title
        if (index === 0) {
          startY += lineSpacing * 1.5;
        } else {
          startY += lineSpacing * 0.5;
        }
        doc.setFontSize(fontSizes.NormalFontSize);
        doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
        doc.text(customCustomLineItem.item_bold, startX, startY);

        // Item description
        startY += doc.internal.getLineHeight();
        doc.setFontSize(fontSizes.NormalFontSize);
        doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
        const splitDescription = doc.splitTextToSize(
          customCustomLineItem.item_normal,
          colum2StartX - startX - lineSpacing * 1.5
        );
        doc.text(String(splitDescription), startX, startY);
        doc.text(String(customCustomLineItem.quantity), colum2StartX, startY, {
          align: "right",
        });
        doc.text(
          `${numberWithCommas(customCustomLineItem.price)} ${
            dataStructure.custom.customSenderCurrencyConverted
          }`,
          colum3StartX,
          startY,
          {
            align: "right",
          }
        );
        doc.text(
          `${numberWithCommas(customCustomLineItem.amount)} ${
            dataStructure.custom.customSenderCurrencyConverted
          }`,
          endX,
          startY,
          {
            align: "right",
          }
        );
        startY += splitDescription.length * doc.internal.getLineHeight();
      }
    );
  }

  // Return new y coordinate
  return startY;
}

function sumUp(doc, dataStructure, startYParam, fontSizes, lineSpacing) {
  // Coordinates
  let startX = 57;
  let startY = startYParam;
  const endX = doc.internal.pageSize.width - startX;

  // Underline
  doc.setDrawColor(255, 150, 0);
  doc.setLineWidth(1);
  doc.line(startX, startY, endX, startY);

  // Sub-total
  startY += lineSpacing * 2;
  doc.setFontSize(fontSizes.NormalFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(dataStructure.labels.totalSub, 460, startY, "right");
  //
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(dataStructure.meta.totalSub, endX, startY, "right");

  // Vat
  startY += lineSpacing * 2;
  doc.setFontSize(fontSizes.NormalFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(dataStructure.labels.totalVat, 460, startY, "right");
  //
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(dataStructure.meta.totalVat, endX, startY, "right");

  // Total
  startY += lineSpacing * 2;
  doc.setFontSize(fontSizes.NormalFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(dataStructure.labels.totalTotal, 460, startY, "right");
  //
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(dataStructure.meta.totalTotal, endX, startY, "right");

  // Underlines
  startX =
    endX -
    doc.getStringUnitWidth(dataStructure.meta.totalTotal) *
      fontSizes.NormalFontSize -
    5;
  doc.setLineWidth(0.5);
  startY += 4;
  doc.line(startX - 1, startY, endX + 1, startY);
  startY += 2;
  doc.line(startX - 2, startY, endX + 2, startY);

  // Return new y coordinate
  return startY;
}

function message(doc, dataStructure, startYParam, fontSizes, lineSpacing) {
  // Coordinates
  const startX = 57;
  let startY = startYParam;

  if (dataStructure.custom.customReceipeDate !== null) {
    // Message A
    startY += lineSpacing * 1;
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
    const splitTextA = doc.splitTextToSize(dataStructure.meta.messageA, 350);
    splitTextA.forEach((textLine) => {
      doc.text(textLine, startX, startY);
    });
  }

  // Custom message bottom
  if (dataStructure.custom.customMessageBottom !== "") {
    startY += lineSpacing * 1;

    const messageBottom = Object.values(
      dataStructure.custom.customMessageBottom
    );
    messageBottom.forEach((text) => {
      if (text) {
        startY += lineSpacing * 1;
        doc.setFontSize(fontSizes.NormalFontSize);
        doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
        doc.text(text, startX, startY);
      }
    });
  }

  // Message B
  startY += lineSpacing * 2;
  doc.setFontSize(fontSizes.NormalFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  const splitTextB = doc.splitTextToSize(dataStructure.meta.messageB, 350);
  splitTextB.forEach((textLine) => {
    doc.text(textLine, startX, startY);
    startY += lineSpacing;
  });

  // Return new y coordinate
  return startY;
}

function footerItem(
  doc,
  items,
  headline,
  startX,
  startYParam,
  fontSizes,
  lineSpacing
) {
  // Coordinates
  const padding = lineSpacing / 2;
  const startY = startYParam;
  let tempY = startY;

  // Headline
  doc.setFontSize(fontSizes.SmallFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(headline, startX + padding, (tempY += lineSpacing));
  tempY += lineSpacing / 3;

  // Items
  doc.setFontSize(fontSizes.SmallFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  const itemsObject = Object.values(items);
  itemsObject.forEach((item) => {
    if (item) {
      doc.text(item, startX + padding, (tempY += lineSpacing));
    }
  });

  // Vertical seperator
  doc.setDrawColor(71, 74, 76);
  doc.setLineWidth(0.5);
  doc.line(startX + 1, startY + padding, startX + 1, tempY + padding / 3);

  // Return new y coordinate
  return startY;
}

function timeTable(doc, dataStructure, startYParam, fontSizes) {
  // Coordinates
  const startX = 57;

  // 1st topline
  let startY = 160;
  doc.setFontSize(fontSizes.TitleFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text("Time table", startX, startY);

  // 2n topline
  startY = 180;
  doc.setFontSize(fontSizes.SubTitleFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightBold);
  doc.text(dataStructure.custom.customTimeTableData[0][0], startX, startY);

  const dodyData = [];
  dataStructure.custom.customTimeTableData.forEach((element, index) => {
    if (
      index >= 2 &&
      index < dataStructure.custom.customTimeTableData.length - 1
    ) {
      const elementModified = element;
      elementModified[3] = elementModified[3].replace("<br>", "\n");
      dodyData.push(elementModified);
    }
  });

  // Timetable
  doc.setFontSize(fontSizes.SmallFontSize);
  autoTable(doc, {
    startY: 195,
    headStyles: {
      lineWidth: 1,
      lineColor: [71, 74, 76],
      fillColor: [241, 155, 55],
      fontSize: 9,
      cellPadding: { horizontal: 5, vertical: 2 },
    },
    footStyles: {
      lineWidth: 1,
      lineColor: [71, 74, 76],
      fillColor: [248, 237, 206],
      fontSize: 9,
      cellPadding: { horizontal: 5, vertical: 2 },
    },
    styles: {
      lineWidth: 1,
      lineColor: [71, 74, 76],
      textColor: [0, 0, 0],
      fontSize: 9,
      cellPadding: { horizontal: 5, vertical: 2 },
    },
    theme: "plain",
    head: [dataStructure.custom.customTimeTableData[1]],
    body: dodyData,
    foot: [
      dataStructure.custom.customTimeTableData[
        dataStructure.custom.customTimeTableData.length - 1
      ],
    ],
  });

  // Return new y coordinate
  return startY;
}

// ----------------------------------------------------------------------
// Main element export(s)
// ----------------------------------------------------------------------
function DownloadDocument(documentData) {
  DownloadDocument.propTypes = {
    documentData: PropTypes.any.isRequired,
  };

  // ----------------------------------------------------------------------
  // Input data structures
  // ----------------------------------------------------------------------
  /* eslint-disable no-unused-vars */
  const [
    billingId,
    dashboardId,
    invoiceDate,
    receipeDate,
    billingAggreementId,
    transactionId,
    paymentMethod,
    product,
    netAmount,
    taxAmount,
    totalAmount,
    totalAmountAlt,
    toName,
    toEmail,
    toCountry,
    toProvince,
    toCity,
    toPostalCode,
    toStreet,
    toOptionalLine,
    senderEmail,
    senderOrganization,
    senderCountry,
    senderProvince,
    senderCity,
    senderPostalCode,
    senderStreet,
    senderOptionalLine,
    senderPhone,
    senderCurrency,
    senderRegistryCode,
    senderVatNumber,
    senderVatRate,
    senderVatName,
    senderDueToDays,
    externalInvoiceId,
    latestKnownLocation,
    receiverVatNumber,
    receiverRegistryCode,
    timeTableData,
    customLineItems,
    paymentDetails,
    messageTop,
    messageBottom,
    onlinePayable,
  ] = documentData;
  /* eslint-enable no-unused-vars */

  const senderCurrencyConverted = senderCurrency.replace("EUR", "€");

  let timeTableDataJsonized = "";
  if (timeTableData !== "") {
    timeTableDataJsonized = JSON.parse(timeTableData);
  }
  let customLineItemsJsonized = "";
  if (customLineItems !== "") {
    customLineItemsJsonized = JSON.parse(customLineItems);
  }
  let paymentDetailsJsonized = "";
  if (paymentDetails !== "") {
    paymentDetailsJsonized = JSON.parse(paymentDetails);
  }
  let messageTopJsonized = "";
  if (messageTop !== "") {
    messageTopJsonized = JSON.parse(messageTop);
  }

  let messageBottomJsonized = "";
  if (messageBottom !== "") {
    messageBottomJsonized = JSON.parse(messageBottom);
  }

  // ----------------------------------------------------------------------
  // Data structure config
  // ----------------------------------------------------------------------
  const selectedLabels = {
    number: "Invoice ID:",
    date: "Date:",
    for:
      dashboardId !== "0"
        ? `Invoice for user with ID U${dashboardId}`
        : "Invoice",
    tableItems: "Item(s)",
    tableQty: "Qty",
    tableSinglePrice: "Price",
    tableSingleTotal: "Amount",
    totalSub: "Sub-total",
    totalVat: `+ ${senderVatName} (${senderVatRate}%)`,
    totalTotal: "Total",
    footerContact: "Contact",
    footerPaymentDetails:
      paymentDetailsJsonized === ""
        ? "Payment Service Details"
        : "Account Details",
    footerLegalDetails: "Registration",
  };

  const selectedMeta = {
    number: externalInvoiceId === "" ? billingId : externalInvoiceId,
    date: invoiceDate.slice(0, 10),
    totalSub: `${numberWithCommas(netAmount)} ${senderCurrencyConverted}`,
    totalVat: `${numberWithCommas(taxAmount)} ${senderCurrencyConverted}`,
    totalTotal: `${numberWithCommas(totalAmount)} ${senderCurrencyConverted}`,
    messageA: `Amount payed.`,
    messageB: `Thank you very much for your trust in our products and services.`,
  };

  let recipentPostalCodeCity = "";
  if (toPostalCode !== "" && toCity !== "") {
    recipentPostalCodeCity = `${toPostalCode}, ${toCity}`;
  } else if (toPostalCode !== "") {
    recipentPostalCodeCity = `${toPostalCode}`;
  } else if (toCity !== "") {
    recipentPostalCodeCity = `${toCity}`;
  }

  let recipentCountryProvince = "";
  if (toCountry !== "" && toProvince !== "") {
    recipentCountryProvince = `${toCountry}, ${toProvince}`;
  } else if (toCountry !== "") {
    recipentCountryProvince = `${toCountry}`;
  } else if (toProvince !== "") {
    recipentCountryProvince = `${toProvince}`;
  }

  let senderPostalCodeCity = "";
  if (senderPostalCode !== "" && senderCity !== "") {
    senderPostalCodeCity = `${senderPostalCode}, ${senderCity}`;
  } else if (senderPostalCode !== "") {
    senderPostalCodeCity = `${senderPostalCode}`;
  } else if (senderCity !== "") {
    senderPostalCodeCity = `${senderCity}`;
  }

  let senderCountryProvince = "";
  if (senderCountry !== "" && senderProvince !== "") {
    senderCountryProvince = `${senderCountry}, ${senderProvince}`;
  } else if (senderCountry !== "") {
    senderCountryProvince = `${senderCountry}`;
  } else if (senderProvince !== "") {
    senderCountryProvince = `${senderProvince}`;
  }

  const dataStructure = {
    labels: selectedLabels,
    topline: {
      // Maped
      left: {
        organization: senderOrganization,
      },
      // Maped
      right: {
        email: senderEmail,
      },
    },

    recipent: {
      // Mapped
      organization: "",
      name: toName,
      street: toStreet,
      optionaleLine: toOptionalLine,
      postalCodeCity: recipentPostalCodeCity,
      countryProvince: recipentCountryProvince,
      email: toEmail,
    },

    meta: selectedMeta,

    item: {
      title: "Subscription",
      description: product,
      amount: `${numberWithCommas(netAmount)} ${senderCurrencyConverted}`,
      qty: "1",
      total: `${numberWithCommas(netAmount)} ${senderCurrencyConverted}`,
    },

    footer: {
      contact: {
        organization: senderOrganization,
        street: senderStreet,
        optionaleLine: senderOptionalLine,
        postalCodeCity: senderPostalCodeCity,
        countryProvince: senderCountryProvince,
        email: senderEmail,
      },
      paymentDetails: {
        method: paymentMethod,
        billingAgreement: billingAggreementId,
        transaction: transactionId,
      },
      LegalDetails: {
        vatNumber: `VAT ID: ${senderVatNumber}`,
        registryCode: `Registry code: ${senderRegistryCode}`,
      },
      page: {
        total: timeTableDataJsonized === "" ? "1" : "2",
      },
    },

    custom: {
      customSenderCurrencyConverted: senderCurrencyConverted,
      customReceipeDate: receipeDate,
      customTotalAmountAlt: totalAmountAlt,
      customSenderPhone: senderPhone,
      customSenderDueToDays: senderDueToDays,
      customExternalInvoiceId: externalInvoiceId,
      customLatestKnownLocation: latestKnownLocation,
      customReceiverVatNumber: receiverVatNumber,
      customReceiverRegistryCode: receiverRegistryCode,
      customTimeTableData: timeTableDataJsonized,
      customCustomLineItems: customLineItemsJsonized,
      customPaymentDetails: paymentDetailsJsonized,
      customMessageTop: messageTopJsonized,
      customMessageBottom: messageBottomJsonized,
      customOnlinePayable: onlinePayable,
    },
  };

  // ----------------------------------------------------------------------
  // Add font
  // ----------------------------------------------------------------------
  addFontNormal();
  addFontBold();

  // ----------------------------------------------------------------------
  // Doc object and basic config
  // ----------------------------------------------------------------------
  const doc = new JsPDF("p", "pt");
  doc.vars = {};
  // How to convert fonts: https://www.devlinpeck.com/content/jspdf-custom-font
  doc.vars.fontFamily = "OpenSans";
  doc.vars.fontWeightBold = "bold";
  doc.vars.fontWeightNormal = "normal";
  doc.setFont(doc.vars.fontFamily);

  const lineSpacing = 12;
  const fontSizes = {
    TitleFontSize: 14,
    SubTitleFontSize: 12,
    NormalFontSize: 10,
    SmallFontSize: 9,
  };

  // ----------------------------------------------------------------------
  // Coordinates
  // ----------------------------------------------------------------------
  let startY = 130;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  let startX = 57;
  const endX = pageWidth - startX;
  const thirdX = (pageWidth - startX * 2) / 3;

  // ----------------------------------------------------------------------
  // Logo
  // ----------------------------------------------------------------------
  const scale = 0.2;
  const logo = new Image();
  const logoWidth = 512;
  const logoHeight = 241;
  logo.src = "/logo/logo-512.png";
  doc.addImage(
    logo,
    "png",
    pageWidth / 2 - logoWidth * scale * 0.5,
    25,
    logoWidth * scale,
    logoHeight * scale
  );
  doc.link(
    pageWidth / 2 - logoWidth * scale * 0.5,
    25,
    logoWidth * scale,
    logoHeight * scale,
    { url: process.env.REACT_APP_URL_FRONTEND_WEBSITE }
  );

  // ----------------------------------------------------------------------
  // Sender
  // ----------------------------------------------------------------------
  startY = topline(doc, dataStructure, startY, fontSizes, lineSpacing);

  // ----------------------------------------------------------------------
  // Receiver
  // ----------------------------------------------------------------------
  startY += 10;
  startY = recipent(doc, dataStructure, startY, fontSizes, lineSpacing);

  // ----------------------------------------------------------------------
  // Heading
  // ----------------------------------------------------------------------
  startY = heading(doc, dataStructure, startY, fontSizes, lineSpacing);

  // ----------------------------------------------------------------------
  // Item table
  // ----------------------------------------------------------------------
  startY = itemTable(doc, dataStructure, startY, fontSizes, lineSpacing);

  // ----------------------------------------------------------------------
  // Sum up
  // ----------------------------------------------------------------------
  startY = sumUp(doc, dataStructure, startY, fontSizes, lineSpacing);

  // ----------------------------------------------------------------------
  // Message
  // ----------------------------------------------------------------------
  startY = message(doc, dataStructure, startY, fontSizes, lineSpacing);

  // ----------------------------------------------------------------------
  // Footer seperation line
  // ----------------------------------------------------------------------
  startY = pageHeight - 120;
  doc.setDrawColor(71, 74, 76);
  doc.setLineWidth(1);
  doc.line(startX, startY, endX, startY);

  // ----------------------------------------------------------------------
  // Contact details
  // ----------------------------------------------------------------------
  startY += lineSpacing / 3;
  footerItem(
    doc,
    dataStructure.footer.contact,
    dataStructure.labels.footerContact,
    startX,
    startY,
    fontSizes,
    lineSpacing
  );

  // ----------------------------------------------------------------------
  // Payment details
  // ----------------------------------------------------------------------
  if (paymentDetailsJsonized === "") {
    startX += thirdX + 15;
    footerItem(
      doc,
      dataStructure.footer.paymentDetails,
      dataStructure.labels.footerPaymentDetails,
      startX,
      startY,
      fontSizes,
      lineSpacing
    );
  } else {
    startX += thirdX + 15;
    footerItem(
      doc,
      paymentDetailsJsonized,
      dataStructure.labels.footerPaymentDetails,
      startX,
      startY,
      fontSizes,
      lineSpacing
    );
  }

  // ----------------------------------------------------------------------
  // Tax details
  // ----------------------------------------------------------------------
  startX += thirdX + 20;
  footerItem(
    doc,
    dataStructure.footer.LegalDetails,
    dataStructure.labels.footerLegalDetails,
    startX,
    startY,
    fontSizes,
    lineSpacing
  );

  // ----------------------------------------------------------------------
  // Page number
  // ----------------------------------------------------------------------
  startY = pageHeight - 14;
  doc.setFontSize(fontSizes.NormalFontSize);
  doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
  doc.text(
    `1 / ${dataStructure.footer.page.total}`,
    pageWidth / 2 -
      (doc.getStringUnitWidth(`1 / ${dataStructure.footer.page.total}`) *
        fontSizes.NormalFontSize) /
        2,
    startY
  );

  if (timeTableDataJsonized !== "") {
    // ----------------------------------------------------------------------
    // Second page
    // ----------------------------------------------------------------------
    doc.addPage();

    // ----------------------------------------------------------------------
    // Coordinates
    // ----------------------------------------------------------------------
    startY = 130;
    startX = 57;

    // ----------------------------------------------------------------------
    // Logo
    // ----------------------------------------------------------------------
    logo.src = "/logo/logo-512.png";
    doc.addImage(
      logo,
      "png",
      pageWidth / 2 - logoWidth * scale * 0.5,
      25,
      logoWidth * scale,
      logoHeight * scale
    );
    doc.link(
      pageWidth / 2 - logoWidth * scale * 0.5,
      25,
      logoWidth * scale,
      logoHeight * scale,
      { url: process.env.REACT_APP_URL_FRONTEND_WEBSITE }
    );

    // ----------------------------------------------------------------------
    // Sender
    // ----------------------------------------------------------------------
    startY = topline(doc, dataStructure, startY, fontSizes, lineSpacing);

    // ----------------------------------------------------------------------
    // Timetable
    // ----------------------------------------------------------------------
    startY = timeTable(doc, dataStructure, startY, fontSizes);

    // ----------------------------------------------------------------------
    // Footer seperation line
    // ----------------------------------------------------------------------
    startY = pageHeight - 120;
    doc.setDrawColor(71, 74, 76);
    doc.setLineWidth(1);
    doc.line(startX, startY, endX, startY);

    // ----------------------------------------------------------------------
    // Contact details
    // ----------------------------------------------------------------------
    startY += lineSpacing / 3;
    footerItem(
      doc,
      dataStructure.footer.contact,
      dataStructure.labels.footerContact,
      startX,
      startY,
      fontSizes,
      lineSpacing
    );

    // ----------------------------------------------------------------------
    // Payment details
    // ----------------------------------------------------------------------
    if (paymentDetailsJsonized === "") {
      startX += thirdX + 15;
      footerItem(
        doc,
        dataStructure.footer.paymentDetails,
        dataStructure.labels.footerPaymentDetails,
        startX,
        startY,
        fontSizes,
        lineSpacing
      );
    } else {
      startX += thirdX + 15;
      footerItem(
        doc,
        paymentDetailsJsonized,
        dataStructure.labels.footerPaymentDetails,
        startX,
        startY,
        fontSizes,
        lineSpacing
      );
    }

    // ----------------------------------------------------------------------
    // Tax details
    // ----------------------------------------------------------------------
    startX += thirdX + 20;
    footerItem(
      doc,
      dataStructure.footer.LegalDetails,
      dataStructure.labels.footerLegalDetails,
      startX,
      startY,
      fontSizes,
      lineSpacing
    );

    // ----------------------------------------------------------------------
    // Page number
    // ----------------------------------------------------------------------
    startY = pageHeight - 14;
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setFont(doc.vars.fontFamily, doc.vars.fontWeightNormal);
    doc.text(
      `2 / ${dataStructure.footer.page.total}`,
      pageWidth / 2 -
        (doc.getStringUnitWidth(`2 / ${dataStructure.footer.page.total}`) *
          fontSizes.NormalFontSize) /
          2,
      startY
    );
  }

  // ----------------------------------------------------------------------
  // Download
  // ----------------------------------------------------------------------
  if (externalInvoiceId === "") {
    doc.save(
      `${invoiceDate
        .slice(0, 10)
        .replaceAll("-", "_")}_invoice_${billingId}.pdf`
    );
  } else {
    doc.save(
      `${invoiceDate
        .slice(0, 10)
        .replaceAll("-", "_")}_invoice_${externalInvoiceId}.pdf`
    );
  }
}

function DownloadInvoice(invoiceData) {
  DownloadInvoice.propTypes = {
    invoiceData: PropTypes.any.isRequired,
  };

  // ----------------------------------------------------------------------
  // Input data structures
  // ----------------------------------------------------------------------
  /* eslint-disable no-unused-vars */
  const [
    billingId,
    dashboardId,
    invoiceDate,
    receipeDate,
    billingAggreementId,
    transactionId,
    paymentMethod,
    product,
    netAmount,
    taxAmount,
    totalAmount,
    totalAmountAlt,
    toName,
    toEmail,
    toCountry,
    toProvince,
    toCity,
    toPostalCode,
    toStreet,
    toOptionalLine,
    senderEmail,
    senderOrganization,
    senderCountry,
    senderProvince,
    senderCity,
    senderPostalCode,
    senderStreet,
    senderOptionalLine,
    senderPhone,
    senderCurrency,
    senderRegistryCode,
    senderVatNumber,
    senderVatRate,
    senderVatName,
    senderDueToDays,
    externalInvoiceId,
    latestKnownLocation,
    receiverVatNumber,
    receiverRegistryCode,
    timeTableData,
    customLineItems,
    paymentDetails,
    messageTop,
    messageBottom,
    passPhrase1,
    passPhrase2,
    passPhrase3,
    passPhrase4,
    passPhrase5,
  ] = invoiceData;
  /* eslint-enable no-unused-vars */

  if (
    passPhrase1 === "2146560ef5d518512c53ecb5caebe289" &&
    passPhrase2 === "75db178026ae7ce09f00b2ce6a99a62c" &&
    passPhrase3 === "9b948cde05822ee0eb7b5922976ed9b2" &&
    passPhrase4 === "9f2867705fdf152dccfeb0eaa1f18609" &&
    passPhrase5 === "639d199155d5022ae44227e31f3aa6d2"
  ) {
    const sendData = {
      request_type: "dashboard",
      route_info: "invoice_printer",
      operation: "data_lookup",
      data_context: {
        billing_id: billingId,
        pass_phrase_1: passPhrase1,
        pass_phrase_2: passPhrase2,
        pass_phrase_3: passPhrase3,
        pass_phrase_4: passPhrase4,
        pass_phrase_5: passPhrase5,
      },
    };

    backendRequest(process.env.REACT_APP_URL_API_DASHBOARD, sendData).then(
      (contentFromBackend) => {
        const keyOnly = Object.keys(contentFromBackend);
        const checkKey = keyOnly.includes("message");

        if (checkKey === true) {
          if (contentFromBackend.message === "success") {
            // Modify invoice data
            const modifiedInvoiceData = invoiceData;
            modifiedInvoiceData[0] = contentFromBackend.billing_id;
            modifiedInvoiceData[1] = contentFromBackend.dashboard_id;
            modifiedInvoiceData[2] = contentFromBackend.invoice_date;
            modifiedInvoiceData[3] = contentFromBackend.receipe_date;
            modifiedInvoiceData[4] = contentFromBackend.billing_aggreement_id;
            modifiedInvoiceData[5] = contentFromBackend.transaction_id;
            modifiedInvoiceData[6] = contentFromBackend.payment_method;
            modifiedInvoiceData[7] = contentFromBackend.product;
            modifiedInvoiceData[8] = contentFromBackend.net_amount;
            modifiedInvoiceData[9] = contentFromBackend.tax_amount;
            modifiedInvoiceData[10] = contentFromBackend.total_amount;
            modifiedInvoiceData[11] = contentFromBackend.total_amount_alt;
            modifiedInvoiceData[12] = contentFromBackend.to_name;
            modifiedInvoiceData[13] = contentFromBackend.to_email;
            modifiedInvoiceData[14] = contentFromBackend.to_country;
            modifiedInvoiceData[15] = contentFromBackend.to_province;
            modifiedInvoiceData[16] = contentFromBackend.to_city;
            modifiedInvoiceData[17] = contentFromBackend.to_postal_code;
            modifiedInvoiceData[18] = contentFromBackend.to_street;
            modifiedInvoiceData[19] = contentFromBackend.to_opional_line;
            modifiedInvoiceData[20] = contentFromBackend.sender_email;
            modifiedInvoiceData[21] = contentFromBackend.sender_organization;
            modifiedInvoiceData[22] = contentFromBackend.sender_country;
            modifiedInvoiceData[23] = contentFromBackend.sender_province;
            modifiedInvoiceData[24] = contentFromBackend.sender_city;
            modifiedInvoiceData[25] = contentFromBackend.sender_postal_code;
            modifiedInvoiceData[26] = contentFromBackend.sender_street;
            modifiedInvoiceData[27] = contentFromBackend.sender_opional_line;
            modifiedInvoiceData[28] = contentFromBackend.sender_phone;
            modifiedInvoiceData[29] = contentFromBackend.sender_currency;
            modifiedInvoiceData[30] = contentFromBackend.sender_registry_code;
            modifiedInvoiceData[31] = contentFromBackend.sender_vat_number;
            modifiedInvoiceData[32] = contentFromBackend.sender_vat_rate;
            modifiedInvoiceData[33] = contentFromBackend.sender_vat_name;
            modifiedInvoiceData[34] = contentFromBackend.sender_due_to_days;
            modifiedInvoiceData[35] = contentFromBackend.external_invoice_id;
            modifiedInvoiceData[36] = contentFromBackend.latest_known_location;
            modifiedInvoiceData[37] = contentFromBackend.receiver_vat_number;
            modifiedInvoiceData[38] = contentFromBackend.receiver_registry_code;
            modifiedInvoiceData[39] = contentFromBackend.time_table_data;
            modifiedInvoiceData[40] = contentFromBackend.custom_line_items;
            modifiedInvoiceData[41] = contentFromBackend.payment_details;
            modifiedInvoiceData[42] = contentFromBackend.message_top;
            modifiedInvoiceData[43] = contentFromBackend.message_bottom;
            modifiedInvoiceData[44] = contentFromBackend.online_payable;
            modifiedInvoiceData[45] = contentFromBackend.threshold_save;

            // Print invoice
            DownloadDocument(modifiedInvoiceData);
          }
        }
      }
    );
  } else {
    // Print invoice
    DownloadDocument(invoiceData);
  }
}

export { DownloadInvoice };
